// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

// Utilities
const inputModule = require('../modules/input');
const externalUserModule = require('../modules/external-user');
const userModule = require('../modules/user');
const helperModule = require('../modules/helper');
const appModule = require('../modules/app');
const { getCookie } = require('../modules/utilities');

// Constants
const Constants = require('../../../Constants');


/**
 * State
 */

let usersGlobalConfiguration;
let usersGlobalDisplayArray;
let usersGlobalSelectedFilter;
let usersGlobalSearchTimeout;


/**
 * Global State
 */

window.users_globalTotalNumber = 0;
window.users_shouldReloadUsers = false;


/**
 * Handlers
 */

const updateUserDisplay = () => {

	// Get elements
	const holder = document.getElementById('users-collection');
	const titleNumber = document.getElementById('users-title-number');
	const removeButton = document.getElementById('users-remove-button');
	const emptyView = document.getElementById('users-empty-view');

	// Create new display array
	let displayArray = [];
	if (usersGlobalSelectedFilter != null) {
		for (let i = 0; i < usersGlobalDisplayArray.length; i += 1) {
			const user = usersGlobalDisplayArray[i];
			const filterOptions = usersGlobalSelectedFilter.split('-');
			if (user.roles.indexOf(filterOptions[0]) > -1) {
				if (filterOptions.length === 1) {
					if (user.hasTerritories !== true || process.env.ENTITY !== 'keelerqik') {
						displayArray.push(user);
					}
				} else if (filterOptions[1] === 'sales' && user.hasTerritories === true) {
					displayArray.push(user);
				}
			}
		}
	} else {
		displayArray = [...usersGlobalDisplayArray];
	}

	// Ensure element is loaded
	if (titleNumber != null) {

		// Set user count
		titleNumber.innerHTML = `(${inputModule.formatCountNumber(displayArray.length)}/${inputModule.formatCountNumber(window.users_globalTotalNumber)})`;
	}

	// Hide remove button
	if (removeButton != null) {
		removeButton.style.display = 'none';
		removeButton.enabled = true;
	}

	// Ensure element is loaded
	if (holder != null) {

		// Iterate through users
		let html = '';
		for (let i = 0; i < displayArray.length; i += 1) {
			const user = displayArray[i];

			// Create user parameters
			let initial = 'S';
			let userName = '';
			if (user.firstName) {
				initial = user.firstName.charAt(0).toUpperCase();
				userName = `${user.firstName} ${user.lastName}`;
			} else {
				initial = user.email.charAt(0).toUpperCase();
				userName = user.email;
			}
			const url = `/dashboard/user/${user.id}`;

			// Create profile picture content
			let profileImage = '';
			if (user.profileImage != null && user.profileImage !== '') {
				profileImage += (`<div id='profile-image-icon' class='profile-image-icon'
					style='background-image:url("${user.profileImage}")'>
					</div>`);
			} else {
				profileImage += (`<div id='profile-image-icon' class='profile-image-icon'>
					<h3 name='default-initial' id='comp-default-initial'>${initial}</h3>
					</div>`);
			}

			// Create card content
			html += (`<div class='user-card' name='user-card-content-element' data-url='${url}' id='${user.id}'>
				${profileImage}
				<div class='name-section'>
				<h2>${userName}</h2>
				<h3>${user.email}</h3>
				</div>
				<div class='school-section'>
				<h3>${user.organization}</h3>`);

			// Add card content from schema
			let emptyFields = 0;
			if (usersGlobalConfiguration.display.user_schema.fields.length > 0) {
				for (let j = 0; j < usersGlobalConfiguration.display.user_schema.fields.length; j += 1) {
					let field = usersGlobalConfiguration.display.user_schema.fields[j];
					if (field.type === 'choice') {
						if (field.choices != null) {
							const choiceId = user.data[field.id];
							for (let k = 0; k < field.choices.length; k += 1) {
								if (field.choices[k].id === choiceId) {
									field = field.choices[k].value;
									break;
								}
							}
						}
					} else {
						field = user.data[field.id];
					}
					if (field != null && field !== '' && typeof field === 'string') {
						html += (`<h4>${field}</h4>`);
					} else {
						emptyFields += 1;
					}
				}
			}

			// Set parent content
			html += (`<h4>${user.parent}</h4>`);

			// Set empty fields
			if (emptyFields > 0) {
				for (let j = 0; j < emptyFields; j += 1) {
					html += ('<h4></h4>');
				}
			}

			// Cap card content
			html += "</div><div class='role-section'>";

			// Set role content
			for (let k = 0; k < user.roles.length; k += 1) {

				// Build role display name
				let role = usersGlobalConfiguration.general.role_names[user.roles[k]];
				if (user.roles[k] === 'super' && user.hasTerritories === true && process.env.ENTITY === 'keelerqik') {
					role = Constants.ENTITY_CONSTANTS[process.env.ENTITY].SALES_REP_ROLE_DISPLAY;
				}

				// Create role badge element
				html += (`<div class='role-badge'>${role}</div>`);
			}

			// Set selector content
			html += (`</div>
				<div class='card-selector' name='users-selector' id='users-selector-${user.id}'
				data-user-id='${user.id}'></div>
				</div>`);
		}

		// Hide loading indicator
		const loadingIndicator = document.getElementById('users-activity-indicator');
		if (loadingIndicator != null) {
			loadingIndicator.remove();
		}

		// Append html
		holder.innerHTML = html;

		// Update empty display
		if (html === '') {
			emptyView.style.display = 'block';
		} else {
			emptyView.style.display = 'none';
		}
	}
};

const updateUsersForUser = async (shouldReload, page) => {

	// Get users for user
	const users = await userModule.getPlatformUsersForUser(shouldReload, page);

	// Get users count
	if (users.length > 0) window.users_globalTotalNumber = users[users.length - 1].userCount;

	// Set user arrays
	usersGlobalDisplayArray = [...window.usersGlobalUserArray];

	// Update user display
	$(document).ready(() => {
		updateUserDisplay();
	});
};

const loadMoreUsers = () => {

	// Update users
	updateUsersForUser(true, window.usersGlobalPageNumber + 1);
};

const setupUserFilter = () => {

	// Get filter elements
	$(document).ready(() => {
		const elements = document.getElementsByName('user-type-filter');
		for (let i = 0; i < elements.length; i += 1) {
			const element = elements[i];

			// Ensure element is loaded
			if (element.children.length <= 1) {

				// Create user role filter options
				let html = '';
				for (let j = 0; j < window.users_globalRoles.length; j += 1) {
					const role = window.users_globalRoles[j];
					const value = role.value.replace(/"/g, '&#34;').replace(/'/g, '&#39;');
					const { display } = role;

					// Check if should display roles
					if (value === 'admin' && usersGlobalConfiguration.features.show_admin_role !== true) {
						// eslint-disable-next-line no-continue
						continue;
					}

					// Build new option
					html += (`<option value='${value}'>${display}</option>`);
				}

				// Append option to filter
				element.innerHTML += html;
			}
		}
	});
};

const filterUserType = (element) => {

	// Get selected option
	const option = element.options[element.selectedIndex].value;

	// Set selected filter
	if (option !== '') {
		usersGlobalSelectedFilter = option;
	} else {
		usersGlobalSelectedFilter = null;
	}

	// Update user display
	updateUserDisplay();
};

const performGlobalUserSearch = (searchValue) => {

	// Perform global search
	Parse.Cloud.run('performPlatformUserSearchWithParameters', { searchText: searchValue }).then((users) => {
		if (usersGlobalDisplayArray != null) {

			// Filter out existing users
			for (let i = 0; i < users.length; i += 1) {
				const user = users[i];
				let found = false;
				for (let j = 0; j < usersGlobalDisplayArray.length; j += 1) {
					const testUser = usersGlobalDisplayArray[j];
					const { id } = user;
					const testId = testUser.id;
					if (id != null && testId != null) {
						if (id === testId) found = true;
					}
				}
				if (found === false) {
					usersGlobalDisplayArray.push(user);
				}
			}
		} else {
			usersGlobalDisplayArray = [...users];
		}

		// Update user display
		updateUserDisplay();
	});
};

const performUserSearch = (element) => {

	// Cancel global search timer
	if (usersGlobalSearchTimeout != null) clearTimeout(usersGlobalSearchTimeout);

	// Get search value
	let searchValue = element.value;

	// If search value is empty, reset display array
	if (searchValue === '') {
		usersGlobalDisplayArray = [...window.usersGlobalUserArray];
	} else {

		// Format search value
		searchValue = searchValue.toLowerCase().replace(/\W/g, '');

		// Update values matching search string
		usersGlobalDisplayArray = [];
		for (let i = 0; i < window.usersGlobalUserArray.length; i += 1) {
			const { searchTag } = window.usersGlobalUserArray[i];
			if (searchTag.indexOf(searchValue) > -1) usersGlobalDisplayArray.push(window.usersGlobalUserArray[i]);
		}

		// Create global search timer
		usersGlobalSearchTimeout = setTimeout(() => {
			performGlobalUserSearch(searchValue);
		}, 800);
	}

	// Update user display
	updateUserDisplay();
};

const selectUserCard = function (e, id) {
	e.stopPropagation();

	// Select or deselect card
	if (document.getElementById(`users-selector-${id}`).style.backgroundColor === '') {
		document.getElementById(`users-selector-${id}`).style.backgroundColor = 'var(--brand-primary)';
	} else {
		document.getElementById(`users-selector-${id}`).style.backgroundColor = '';
	}

	// Check selected counts
	let count = 0;
	const users = document.getElementsByName('users-selector');
	for (let i = 0; i < users.length; i += 1) {
		if (users[i].style.backgroundColor !== '') {
			count += 1;
		}
	}

	// Show or hide remove button
	if (count > 0) {
		document.getElementById('users-remove-button').style.display = 'block';
		helperModule.handleRemoveButtonPosition();
	} else {
		document.getElementById('users-remove-button').style.display = 'none';
	}
};

const removeUsers = () => {

	// Disable remove button
	document.getElementById('users-remove-button').enabled = false;

	// Show remove confirmation
	inputModule.showModalWithId('user-archive-confirmation');
};

const handleRemoveUsers = (confirm) => {
	if (confirm === true) {

		// Get user role
		const userRole = getCookie('userRole');

		// Hide remove confirmation
		inputModule.hideModalWithId('user-archive-confirmation');

		// Initialize remove array
		const removeArray = [];

		// Get selected users
		const users = document.getElementsByName('users-selector');
		if (users.length > 0) {
			for (let i = users.length - 1; i >= 0; i -= 1) {
				if (users[i].style.backgroundColor !== '') {

					// Get user id
					const userId = users[i].id.replace('users-selector-', '');

					// Check user role
					let canRemove = false;
					for (let j = 0; j < window.usersGlobalUserArray.length; j += 1) {
						if (window.usersGlobalUserArray[j].id === userId) {
							let { roles } = window.usersGlobalUserArray[j];
							if (!roles) roles = [];
							if ((userRole === 'leader' && (roles.indexOf('leader') === -1 && roles.indexOf('super') === -1 && roles.indexOf('admin') === -1))
								|| (userRole === 'super' && (roles.indexOf('super') === -1 && roles.indexOf('admin') === -1))
								|| (userRole === 'admin' && roles.indexOf('admin') === -1)) {
								canRemove = true;
							}
							break;
						}
					}

					// Append to remove array
					if (canRemove === true) {
						removeArray.push(userId);

						// Remove card from display
						users[i].parentElement.remove();
					}
				}
			}

			// Hide remove button
			document.getElementById('users-remove-button').enabled = true;
			document.getElementById('users-remove-button').style.display = 'none';

			// Remove users from user array
			for (let i = window.usersGlobalUserArray.length - 1; i >= 0; i -= 1) {
				if (removeArray.indexOf(window.usersGlobalUserArray[i].id) > -1) {
					window.usersGlobalUserArray.splice(i, 1);
				}
			}

			// Remove users from display array
			for (let i = usersGlobalDisplayArray.length - 1; i >= 0; i -= 1) {
				if (removeArray.indexOf(usersGlobalDisplayArray[i].id) > -1) {
					usersGlobalDisplayArray.splice(i, 1);
				}
			}

			// Subtract from total
			if (window.users_globalTotalNumber != null) window.users_globalTotalNumber -= removeArray.length;

			// Set user array to session storage
			try {
				sessionStorage.setItem('user-platform-users', JSON.stringify(window.usersGlobalUserArray));
			} catch (e) { }

			// Archive users
			if (removeArray.length > 0) {
				Parse.Cloud.run('archiveUsersWithParameters', { userIDArray: removeArray, platform: 'web' }).then(() => {

					// Update user display
					updateUserDisplay();

				}).error((error) => {
					if (error && error.code === 209) {
						externalUserModule.handleUserLogOut(true);
					} else {

						// Show error
						inputModule.showModalWithId('user-archive-error-modal');
					}
				});
			} else {

				// Update user display
				updateUserDisplay();
			}
		} else {

			// Enable remove button
			document.getElementById('users-remove-button').enabled = true;
		}
	} else {

		// Hide remove confirmation
		inputModule.hideModalWithId('user-archive-confirmation');

		// Enable remove button
		document.getElementById('users-remove-button').enabled = true;
	}
};

const importUsers = () => {
	window.origin_importFile = 'users';
	appModule.handleRouting('/dashboard/import');
};

const addUser = () => {
	appModule.handleRouting('/dashboard/user/new');
};


/**
 * Action Handlers
 */

const createActionHandlers = () => {

	// Handle click on add button
	$('#users-add-user-button').click(() => {
		addUser();
	});

	// Handle click on empty add button
	$('#users-empty-create-button').click(() => {
		addUser();
	});

	// Handle click on load more button
	$('#users-load-more-button').click(() => {
		helperModule.displayActionSheet('users-action-dropdown');
		loadMoreUsers();
	});

	// Handle click on import button
	$('#users-import-users-button').click(() => {
		helperModule.displayActionSheet('users-action-dropdown');
		importUsers();
	});

	// Handle click on remove button
	$('#users-remove-button').click(() => {
		removeUsers();
	});

	// Handle click on cancel button
	$('#user-archive-confirmation-cancel').click(() => {
		handleRemoveUsers(false);
	});

	// Handle click on remove button
	$('#user-archive-confirmation-remove').click(() => {
		handleRemoveUsers(true);
	});

	// Handle click on confirm button
	$('#user-archive-error-modal-confirm').click(() => {
		inputModule.hideModalWithId('user-archive-error-modal');
	});

	// Handle click on confirm button
	$('#user-subscription-transition-modal-confirm').click(() => {
		inputModule.hideModalWithId('user-subscription-transition-modal');
	});

	// Handle click on user card
	$(document).off('click', "[name='user-card-content-element']");
	$(document).on('click', "[name='user-card-content-element']", function () {
		appModule.handleRouting($(this).data('url'));
	});

	// Handle click on user selector
	$(document).off('click', "[id^='users-selector-']");
	$(document).on('click', "[id^='users-selector-']", function (event) {
		selectUserCard(event, $(this).data('user-id'));
	});

	// Handle click on action menu
	$('#users-action-menu').click(() => {
		helperModule.displayActionSheet('users-action-dropdown');
	});
};


/**
 * Input Handlers
 */

const createInputHandlers = () => {

	// Handle filter change
	$("[name='user-type-filter']").change(function () {
		filterUserType(this);
	});

	// Handle search change content
	$('#user-search-input-large').keyup(function () {
		performUserSearch(this);
	});
};


/**
 * State Handlers
 */

exports.handlerDidLoad = async () => {

	// Create action handlers
	createActionHandlers();

	// Create input handlers
	createInputHandlers();

	// Check for visible activity indicator
	inputModule.checkElementVisible('users-activity-indicator', async () => {

		// Get user role
		const userRole = getCookie('userRole');

		// Validate role
		if (userRole === 'leader' || userRole === 'super' || userRole === 'admin') {

			// Reset variables
			usersGlobalSelectedFilter = null;

			// Fetch configuration for user
			const config = await userModule.handleConfigurationForUser(false);
			usersGlobalConfiguration = JSON.parse(config);

			// Get roles
			window.users_globalRoles = [];
			Object.keys(usersGlobalConfiguration.general.role_names).forEach((property) => {
				if (Object.prototype.hasOwnProperty.call(usersGlobalConfiguration.general.role_names, property)) {
					window.users_globalRoles.push({
						value: property,
						display: usersGlobalConfiguration.general.role_names[property]
					});
				}
			});

			// Add specific roles
			if (process.env.ENTITY === 'keelerqik') {
				window.users_globalRoles.push({
					value: Constants.ENTITY_CONSTANTS[process.env.ENTITY].SALES_REP_ROLE_VALUE,
					display: Constants.ENTITY_CONSTANTS[process.env.ENTITY].SALES_REP_ROLE_DISPLAY
				});
			}

			// Set up filter
			setupUserFilter();

			// Initialize arrays
			const shouldReload = true;
			if (shouldReload) window.usersGlobalUserArray = [];
			usersGlobalDisplayArray = [];

			// Get users for user
			updateUsersForUser(shouldReload, 0);
		} else { // Invalid role
			appModule.handleRouting('/dashboard/assets');
		}
	});
};

exports.handlerDidAppear = async () => {

	// Update user display
	if (window.users_shouldReloadUsers === true) {

		// Perform search and update
		performUserSearch($('#user-search-input-large')[0]);

		// Update state
		window.users_shouldReloadUsers = false;
	}
};
