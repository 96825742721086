// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

const externalUserModule = require('./external-user');
const userModule = require('./user');
const accountHandler = require('../handlers/account-handler');
const assetHandler = require('../handlers/asset-handler');
const assetsHandler = require('../handlers/assets-handler');
const configurationHandler = require('../handlers/configuration-handler');
const schedulingHandler = require('../handlers/scheduling-handler');
const confirmHandler = require('../handlers/confirm-handler');
const defineHandler = require('../handlers/define-handler');
const importHandler = require('../handlers/import-handler');
const organizationHandler = require('../handlers/organization-handler');
const organizationsHandler = require('../handlers/organizations-handler');
const peopleHandler = require('../handlers/people-handler');
const personHandler = require('../handlers/person-handler');
const metricsHandler = require('../handlers/metrics-handler');
const profileHandler = require('../handlers/profile-handler');
const subscriptionHandler = require('../handlers/subscription-handler');
const userHandler = require('../handlers/user-handler');
const usersHandler = require('../handlers/users-handler');
const invitesHandler = require('../handlers/invites-handler');
const cartHandler = require('../handlers/cart-handler');
const requestsHandler = require('../handlers/requests-handler');
const Constants = require('../../../Constants');
const { getCookie } = require('./utilities');


/**
 * Handlers
 */

const displayAppPage = (route, shouldReload) => {
	let newRoute = route;
	let newShouldReload = shouldReload;

	// Hide navigation element
	if ($('#dropdown-menu').hasClass('show')) {
		$('#dropdown-menu').toggleClass('show');
		$('#menu-arrow').toggleClass('open');
	}

	// Format route
	if (newRoute.charAt(newRoute.length - 1) === '/') newRoute = newRoute.slice(0, -1);

	// Get container element
	const container = document.getElementById('app-container');

	// Get state cookies
	const userRole = getCookie('userRole');
	const showPersonnel = getCookie('showPersonnel') === 'true';
	const showMetrics = getCookie('showMetrics') === 'true';
	const showScheduling = getCookie('showScheduling') === 'true';
	const showPurchase = getCookie('showPurchase') === 'true';

	// Get app state title
	let title = '';
	if (newRoute === '/dashboard/assets') {
		title = `Assets | ${process.env.ENTITY_OBJECT.NAME}`;
	} else if (newRoute === '/dashboard/users' && (userRole === 'leader' || userRole === 'super' || userRole === 'admin')) {
		title = `Users | ${process.env.ENTITY_OBJECT.NAME}`;
	} else if (newRoute === '/dashboard/organizations' && (userRole === 'super' || userRole === 'admin')) {
		title = `Organizations | ${process.env.ENTITY_OBJECT.NAME}`;
	} else if (newRoute === '/dashboard/groups' && showPersonnel === true) {
		title = `Groups | ${process.env.ENTITY_OBJECT.NAME}`;
	} else if (newRoute === '/dashboard/profile') {
		title = `Profile | ${process.env.ENTITY_OBJECT.NAME}`;
	} else if (newRoute === '/dashboard/account') {
		title = `Account | ${process.env.ENTITY_OBJECT.NAME}`;
	} else if (newRoute === '/dashboard/account/scheduling' && showScheduling === true) {
		title = `Scheduling | ${process.env.ENTITY_OBJECT.NAME}`;
	} else if (newRoute.indexOf('/dashboard/account/subscription') > -1) {
		title = `Subscription | ${process.env.ENTITY_OBJECT.NAME}`;
	} else if (newRoute === '/dashboard/account/configuration') {
		title = `Configuration | ${process.env.ENTITY_OBJECT.NAME}`;
	} else if (newRoute.indexOf('/dashboard/asset/activity') > -1) {
		title = `Asset Activity | ${process.env.ENTITY_OBJECT.NAME}`;
	} else if (newRoute.indexOf('/dashboard/asset/') > -1) {
		title = `Asset | ${process.env.ENTITY_OBJECT.NAME}`;
	} else if (newRoute.indexOf('/dashboard/user/') > -1 && (userRole === 'leader' || userRole === 'super' || userRole === 'admin')) {
		title = `User | ${process.env.ENTITY_OBJECT.NAME}`;
	} else if (newRoute.indexOf('/dashboard/organization') > -1 && (userRole === 'super' || userRole === 'admin')) {
		title = `Organization | ${process.env.ENTITY_OBJECT.NAME}`;
	} else if (newRoute.indexOf('/dashboard/person/activity') > -1 && showPersonnel === true) {
		title = `Personnel Activity | ${process.env.ENTITY_OBJECT.NAME}`;
	} else if (newRoute.indexOf('/dashboard/person/history') > -1 && showPersonnel === true) {
		title = `Personnel Health History | ${process.env.ENTITY_OBJECT.NAME}`;
	} else if (newRoute.indexOf('/dashboard/person') > -1 && showPersonnel === true) {
		title = `Person | ${process.env.ENTITY_OBJECT.NAME}`;
	} else if (newRoute.indexOf('/dashboard/metrics') > -1 && showMetrics === true) {
		title = `Metrics | ${process.env.ENTITY_OBJECT.NAME}`;
	} else if (newRoute === '/dashboard/import') {
		title = `Import | ${process.env.ENTITY_OBJECT.NAME}`;
	} else if (newRoute === '/dashboard/define') {
		title = `Define | ${process.env.ENTITY_OBJECT.NAME}`;
	} else if (newRoute === '/dashboard/confirm') {
		title = `Confirm | ${process.env.ENTITY_OBJECT.NAME}`;
	} else if (newRoute === '/dashboard/cart' && showPurchase === true) {
		title = `My Cart | ${process.env.ENTITY_OBJECT.NAME}`;
	} else if (newRoute === '/dashboard/requests') {
		title = `Requests | ${process.env.ENTITY_OBJECT.NAME}`;
	} else {
		title = `Page Not Found - ${process.env.ENTITY_OBJECT.NAME}`;
	}

	// Check if state is new
	if (container.children.length === 0) {

		// Create new state url
		const stateUrl = newRoute + window.location.search;

		// Push new app state
		const stateObj = { title, route: stateUrl };
		window.history.replaceState(stateObj, title, stateUrl);

		// Set should reload
		newShouldReload = true;
	} else if (window.location.pathname !== newRoute) {

		// Create new state url
		const stateUrl = newRoute;

		// Push new app state
		const stateObj = { title, route: stateUrl };
		window.history.pushState(stateObj, title, stateUrl);

		// Set should reload
		newShouldReload = true;
	}

	// Load new content
	if (newShouldReload === true) {

		// Get new app state component name
		let component = '';
		if (newRoute === '/dashboard/assets') {
			component = '/includes/components/pages/assets.php';
		} else if (newRoute === '/dashboard/users' && (userRole === 'leader' || userRole === 'super' || userRole === 'admin')) {
			component = '/includes/components/pages/users.php';
		} else if (newRoute === '/dashboard/organizations' && (userRole === 'super' || userRole === 'admin')) {
			component = '/includes/components/pages/organizations.php';
		} else if (newRoute === '/dashboard/groups' && showPersonnel === true) {
			component = '/includes/components/pages/people.php';
		} else if (newRoute === '/dashboard/profile') {
			component = '/includes/components/pages/profile.php';
		} else if (newRoute === '/dashboard/account') {
			component = '/includes/components/pages/account.php';
		} else if (newRoute === '/dashboard/account/scheduling' && showScheduling === true) {
			component = '/includes/components/pages/scheduling.php';
		} else if (newRoute.indexOf('/dashboard/account/subscription') > -1) {
			component = '/includes/components/pages/subscription.php';
		} else if (newRoute === '/dashboard/account/configuration') {
			component = '/includes/components/pages/configuration.php';
		} else if (newRoute.indexOf('/dashboard/asset/activity') > -1) {
			component = '/includes/components/pages/asset_activity.php';
		} else if (newRoute.indexOf('/dashboard/asset/') > -1) {
			component = '/includes/components/pages/asset.php';
		} else if (newRoute.indexOf('/dashboard/user/') > -1 && (userRole === 'leader' || userRole === 'super' || userRole === 'admin')) {
			component = '/includes/components/pages/user.php';
		} else if (newRoute.indexOf('/dashboard/organization') > -1 && (userRole === 'super' || userRole === 'admin')) {
			component = '/includes/components/pages/organization.php';
		} else if (newRoute.indexOf('/dashboard/person/activity') > -1 && showPersonnel === true) {
			component = '/includes/components/pages/person_activity.php';
		} else if (newRoute.indexOf('/dashboard/person/history') > -1 && showPersonnel === true) {
			component = '/includes/components/pages/person_health_history.php';
		} else if (newRoute.indexOf('/dashboard/person') > -1 && showPersonnel === true) {
			component = '/includes/components/pages/person.php';
		} else if (newRoute.indexOf('/dashboard/metrics') > -1 && showMetrics === true) {
			component = '/includes/components/pages/metrics.php';
		} else if (newRoute === '/dashboard/import') {
			component = '/includes/components/pages/import.php';
		} else if (newRoute === '/dashboard/define') {
			component = '/includes/components/pages/define.php';
		} else if (newRoute === '/dashboard/confirm') {
			component = '/includes/components/pages/confirm.php';
		} else if (newRoute === '/dashboard/cart') {
			component = '/includes/components/pages/cart.php';
		} else if (newRoute === '/dashboard/requests') {
			component = '/includes/components/pages/requests.php';
		} else {
			component = '/includes/components/pages/error.php';
		}

		// Set document title
		document.title = title;

		// Destruct existing non-collection components
		let displayed = false;
		for (let i = container.children.length - 1; i >= 0; i -= 1) {
			if (container.children[i].id === '/dashboard/assets'
                || container.children[i].id === '/dashboard/users'
                || container.children[i].id === '/dashboard/organizations'
				|| container.children[i].id === '/dashboard/groups'
				|| container.children[i].id === '/dashboard/metrics'
                || container.children[i].id === '/dashboard/profile'
				|| container.children[i].id === '/dashboard/cart'
				|| container.children[i].id === '/dashboard/requests'
                || container.children[i].id === '/dashboard/account') {
				if (container.children[i].id === newRoute) {
					container.children[i].style.display = 'block';
					displayed = true;
				} else {
					container.children[i].style.display = 'none';
				}
			} else {
				container.children[i].remove();
			}
		}

		// Change selected nav item
		const navItems = document.getElementsByName('left-nav-item');
		const routeItem = `${newRoute.replace('/dashboard/', '')}-item`;
		for (let i = 0; i < navItems.length; i += 1) {
			navItems[i].className = '';
		}
		if (document.getElementById(routeItem)) {
			document.getElementById(routeItem).className = 'link-active';
		}

		// Load new component if necessary
		if (!displayed) {
			const $div = $('<div>', { id: newRoute, name: 'app-page' });
			$div.load(component, () => {

				// Append content
				$('#app-container').prepend($div);

				// Set handler state
				switch (component) {
					case '/includes/components/pages/assets.php':
						if (!displayed) assetsHandler.handlerDidLoad();
						break;
					case '/includes/components/pages/users.php':
						if (!displayed) usersHandler.handlerDidLoad();
						break;
					case '/includes/components/pages/organizations.php':
						if (!displayed) organizationsHandler.handlerDidLoad();
						break;
					case '/includes/components/pages/people.php':
						if (!displayed) peopleHandler.handlerDidLoad();
						break;
					case '/includes/components/pages/metrics.php':
						if (!displayed) metricsHandler.handlerDidLoad();
						break;
					case '/includes/components/pages/profile.php':
						if (!displayed) profileHandler.handlerDidLoad();
						break;
					case '/includes/components/pages/account.php':
						if (!displayed) accountHandler.handlerDidLoad();
						break;
					case '/includes/components/pages/subscription.php':
						if (!displayed) subscriptionHandler.handlerDidLoad();
						break;
					case '/includes/components/pages/configuration.php':
						if (!displayed) configurationHandler.handlerDidLoad(Constants.VIEW_LOAD_CONTEXTS.CONTEXT_DASHBOARD);
						break;
					case '/includes/components/pages/scheduling.php':
						if (!displayed) schedulingHandler.handlerDidLoad();
						break;
					case '/includes/components/pages/asset.php':
						if (!displayed) assetHandler.handlerDidLoad();
						break;
					case '/includes/components/pages/asset_activity.php':
						if (!displayed) assetHandler.handlerAssetActivity();
						break;
					case '/includes/components/pages/user.php':
						if (!displayed) userHandler.handlerDidLoad();
						break;
					case '/includes/components/pages/organization.php':
						if (!displayed) organizationHandler.handlerDidLoad();
						break;
					case '/includes/components/pages/person.php':
						if (!displayed) personHandler.handlerDidLoad();
						break;
					case '/includes/components/pages/person_activity.php':
						if (!displayed) personHandler.handlerPersonActivity();
						break;
					case '/includes/components/pages/person_health_history.php':
						if (!displayed) personHandler.handlerPersonHealthHistory();
						break;
					case '/includes/components/pages/import.php':
						if (!displayed) importHandler.handlerDidLoad();
						break;
					case '/includes/components/pages/define.php':
						if (!displayed) defineHandler.handlerDidLoad();
						break;
					case '/includes/components/pages/confirm.php':
						if (!displayed) confirmHandler.handlerDidLoad();
						break;
					case '/includes/components/pages/cart.php':
						if (!displayed) cartHandler.handlerDidLoad();
						break;
					case '/includes/components/pages/requests.php':
						if (!displayed) requestsHandler.handlerDidLoad();
						break;
					case '/includes/components/pages/invite.php':
						if (!displayed) invitesHandler.handlerDidLoad(Constants.VIEW_LOAD_CONTEXTS.CONTEXT_DASHBOARD);
						break;
					default:
						break;
				}
			});
		}

		// Set handler state
		switch (component) {
			case '/includes/components/pages/assets.php':
				if (displayed) assetsHandler.handlerDidAppear();
				break;
			case '/includes/components/pages/users.php':
				if (displayed) usersHandler.handlerDidAppear();
				break;
			case '/includes/components/pages/organizations.php':
				if (displayed) organizationsHandler.handlerDidAppear();
				break;
			case '/includes/components/pages/people.php':
				if (displayed) peopleHandler.handlerDidAppear();
				break;
			case '/includes/components/pages/metrics.php':
				if (displayed) metricsHandler.handlerDidAppear();
				break;
			case '/includes/components/pages/profile.php':
				if (displayed) profileHandler.handlerDidAppear();
				break;
			case '/includes/components/pages/account.php':
				if (displayed) accountHandler.handlerDidAppear();
				break;
			case '/includes/components/pages/subscription.php':
				if (displayed) subscriptionHandler.handlerDidAppear();
				break;
			case '/includes/components/pages/configuration.php':
				if (displayed) configurationHandler.handlerDidAppear(Constants.VIEW_LOAD_CONTEXTS.CONTEXT_DASHBOARD);
				break;
			case '/includes/components/pages/scheduling.php':
				if (displayed) schedulingHandler.handlerDidAppear();
				break;
			case '/includes/components/pages/asset.php':
				if (displayed) assetHandler.handlerDidAppear();
				break;
			case '/includes/components/pages/asset_activity.php':
				if (displayed) assetHandler.handlerAssetActivity();
				break;
			case '/includes/components/pages/user.php':
				if (displayed) userHandler.handlerDidAppear();
				break;
			case '/includes/components/pages/organization.php':
				if (displayed) organizationHandler.handlerDidAppear();
				break;
			case '/includes/components/pages/person.php':
				if (displayed) personHandler.handlerDidAppear();
				break;
			case '/includes/components/pages/person_activity.php':
				if (displayed) personHandler.handlerPersonActivity();
				break;
			case '/includes/components/pages/person_health_history.php':
				if (displayed) personHandler.handlerPersonHealthHistory();
				break;
			case '/includes/components/pages/import.php':
				if (displayed) importHandler.handlerDidAppear();
				break;
			case '/includes/components/pages/define.php':
				if (displayed) defineHandler.handlerDidAppear();
				break;
			case '/includes/components/pages/confirm.php':
				if (displayed) confirmHandler.handlerDidAppear();
				break;
			case '/includes/components/pages/cart.php':
				if (displayed) cartHandler.handlerDidAppear();
				break;
			case '/includes/components/pages/requests.php':
				if (displayed) requestsHandler.handlerDidAppear();
				break;
			case '/includes/components/pages/invite.php':
				if (displayed) invitesHandler.handlerDidAppear(Constants.VIEW_LOAD_CONTEXTS.CONTEXT_DASHBOARD);
				break;
			default:
				break;
		}
	}
};

exports.beginApplicationSetup = async () => {

	// Add pop state listener
	$(window).bind('popstate', () => {

		// Get app path
		const route = window.location.pathname;

		// Display app page
		displayAppPage(route, true);
	});

	// Handle click on routing link
	$(document).on('click', 'a', function (e) {
		if ($(this).attr('href') && $(this).attr('href').match('^/dashboard')) {
			e.preventDefault();
			exports.handleRouting($(this).attr('href'));
		}
	});

	// Handle external user
	const { redirect } = await externalUserModule.handleExternalUser(true, false, null);
	if (redirect != null) {

		// Handle redirect
		window.location = redirect;

		// Handle return
		return;
	}

	// Handle configuration
	const config = await userModule.handleConfigurationForUser(true);

	// Set up asset page dict
	Object.keys(config.get('display').type_schemas).forEach((property) => {
		if (Object.prototype.hasOwnProperty.call(config.get('display').type_schemas, property)) {
			window.assetsGlobalPageNumberDict[property] = 0;
		}
	});

	// Set up people page dict
	Object.keys(config.get('display').personnel_type_schemas).forEach((property) => {
		if (Object.prototype.hasOwnProperty.call(config.get('display').personnel_type_schemas, property)) {
			window.peopleGlobalPageNumberDict[property] = 0;
		}
	});

	// Get app path
	const route = window.location.pathname;

	// Display app page
	displayAppPage(route);
};

exports.handleRouting = (route) => {
	displayAppPage(route);
	window.scrollTo(0, 0);
};
