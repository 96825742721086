// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

const externalUserModule = require('./external-user');


/*
 * Global State
 */

window.assetsGlobalAssetArray = [];
window.usersGlobalUserArray = [];
window.orgsGlobalOrgArray = [];
window.peopleGlobalPeopleArray = [];
window.assetsGlobalPageNumberDict = {};
window.peopleGlobalPageNumberDict = {};
window.usersGlobalPageNumber = 0;
window.orgsGlobalPageNumber = 0;


/*
 * Handlers
 */

const setCookiesForConfiguration = (configuration) => {

	// Get search schema
	if (configuration.search != null) {
		if (configuration.search.search_hint_extras != null
            && configuration.search.search_hint_extras.length > 0) {

			// Create search string
			let searchString = 'Start typing an asset ID, ';

			// Append hint extras
			for (let i = 0; i < configuration.search.search_hint_extras.length; i += 1) {
				searchString += `${configuration.search.search_hint_extras[i]}, `;
			}

			// Complete search string
			searchString += 'etc...';

			// Set search string cookie
			const date = new Date();
			date.setFullYear(date.getFullYear() + 1);
			const cookie = `searchString=${encodeURIComponent(searchString)}; expires=${date.toGMTString()}; path=/;`;
			document.cookie = cookie;
		}
	}
};

exports.handleConfigurationForUser = async (shouldFetch) => {

	// Get configuration
	let configuration = sessionStorage.getItem('user-configuration');

	// Check if should fetch
	if (shouldFetch === true || configuration == null) {

		// Fetch configuration
		const result = await externalUserModule.fetchConfigurationForUser();
		configuration = result.configuration;

		// Validate configuration
		if (configuration != null) {

			// Set cookies
			setCookiesForConfiguration(configuration);

			// Set configuration to session storage
			try {
				sessionStorage.setItem('user-configuration', JSON.stringify(configuration));
			} catch (err) {}

			// Return configuration
			return configuration;
		}
		return null;
	}

	// Set cookies
	setCookiesForConfiguration(configuration);

	// Return configuration
	return configuration;
};

exports.getAssetsForUser = async (shouldUpdate, typeArray, page) => {
	let newPage = page;

	// Create page
	const shouldUpdatePageNumber = (newPage != null);
	if (newPage == null) newPage = 0;

	// Check update state
	if (shouldUpdate === false) {

		// Return asset array
		return window.assetsGlobalAssetArray;
	}

	// Fetch asset array
	const assetArray = await exports.fetchAssetsForUser(false, typeArray, newPage);

	// Validate asset array
	if (window.assetsGlobalAssetArray == null || window.assetsGlobalAssetArray.length === 0) {
		window.assetsGlobalAssetArray = [...assetArray];
	} else {
		window.assetsGlobalAssetArray.push(...assetArray);
	}

	// Update page number
	if (shouldUpdatePageNumber === true) {
		for (let i = 0; i < typeArray.length; i += 1) {
			window.assetsGlobalPageNumberDict[typeArray[i]] = newPage;
		}
	}

	// Return assets
	return window.assetsGlobalAssetArray;
};

exports.getPlatformUsersForUser = async (shouldUpdate, page) => {
	let newPage = page;

	// Create page
	const shouldUpdatePageNumber = (newPage != null);
	if (newPage == null) newPage = 0;

	// Check update state
	if (shouldUpdate === false) {

		// Return users array
		return window.usersGlobalUserArray;
	}

	// Fetch users array
	const userArray = await exports.fetchPlatformUsersForUser(false, newPage);

	// Validate user array
	if (newPage === 0) {
		window.usersGlobalUserArray = [...userArray];
	} else if (newPage > window.usersGlobalPageNumber) {
		window.usersGlobalUserArray.push(...userArray);
	}

	// Update page number
	if (shouldUpdatePageNumber === true) {
		window.usersGlobalPageNumber = newPage;
	}

	// Return users
	return window.usersGlobalUserArray;
};

exports.getOrganizationsForUser = async (shouldUpdate, page) => {
	let newPage = page;

	// Create page
	const shouldUpdatePageNumber = (newPage != null);
	if (newPage == null) newPage = 0;

	// Check update state
	if (shouldUpdate === false) {

		// Return orgs array
		return window.orgsGlobalOrgArray;
	}

	// Fetch orgs array
	const orgArray = await exports.fetchPlatformOrganizationsForUser(false, newPage);

	// Validate orgs array
	if (newPage === 0) {
		window.orgsGlobalOrgArray = [...orgArray];
	} else if (newPage > window.orgsGlobalOrgArray) {
		window.orgsGlobalOrgArray.push(...orgArray);
	}

	// Update page number
	if (shouldUpdatePageNumber === true) {
		window.orgsGlobalPageNumber = newPage;
	}

	// Return organizations
	return window.orgsGlobalOrgArray;
};

exports.getPeopleForUser = async (shouldUpdate, typeArray, page) => {
	let newPage = page;

	// Create page
	const shouldUpdatePageNumber = (newPage != null);
	if (newPage == null) newPage = 0;

	// Check update state
	if (shouldUpdate === false) {

		// Return people array
		return window.peopleGlobalPeopleArray;
	}

	// Fetch people array
	const peopleArray = await exports.fetchPeopleForUser(false, typeArray, newPage);

	// Validate people array
	if (window.peopleGlobalPeopleArray == null || window.peopleGlobalPeopleArray.length === 0) {
		window.peopleGlobalPeopleArray = [...peopleArray];
	} else {
		window.peopleGlobalPeopleArray.push(...peopleArray);
	}

	// Update page number
	if (shouldUpdatePageNumber === true) {
		for (let i = 0; i < typeArray.length; i += 1) {
			window.peopleGlobalPageNumberDict[typeArray[i]] = newPage;
		}
	}

	// Return people
	return window.peopleGlobalPeopleArray;
};

exports.fetchAssetsForUser = async (fetchAll, typeArray, page) => {
	let newPage = page;
	let newTypeArray = typeArray;

	// Set page number
	if (newPage == null) newPage = 0;
	if (newTypeArray == null) newTypeArray = [];

	// Create args
	const args = (!fetchAll) ? { page: newPage, asset_types: newTypeArray } : { asset_types: newTypeArray };

	// Fetch assets
	try {
		const results = await Parse.Cloud.run('fetchAssetsForUser', args);
		return results;
	} catch (error) { // Handle session errors
		if (error.code === 209) {
			externalUserModule.handleUserLogOut(true);
		}
		return null;
	}
};

exports.fetchPlatformUsersForUser = async (fetchAll, page) => {
	let newPage = page;

	// Set page number
	if (newPage == null) newPage = 0;

	// Create args
	const args = (!fetchAll) ? { page: newPage } : {};

	// Fetch users
	try {
		const results = await Parse.Cloud.run('fetchPlatformUsersForUser', args);
		return results;
	} catch (error) { // Handle session errors
		if (error.code === 209) {
			externalUserModule.handleUserLogOut(true);
		}
		return null;
	}
};

exports.fetchPlatformOrganizationsForUser = async (fetchAll, page) => {
	let newPage = page;

	// Set page number
	if (newPage == null) newPage = 0;

	// Create args
	const args = (!fetchAll) ? { page: newPage } : {};

	// Fetch orgs
	try {
		const results = await Parse.Cloud.run('fetchPlatformOrganizationsForUser', args);
		return results;
	} catch (error) { // Handle session errors
		if (error.code === 209) {
			externalUserModule.handleUserLogOut(true);
		}
		return null;
	}
};

exports.fetchPeopleForUser = async (fetchAll, typeArray, page, returnHistory = false) => {
	let newPage = page;
	let newTypeArray = typeArray;

	// Set page number
	if (newPage == null) newPage = 0;
	if (newTypeArray == null) newTypeArray = [];

	// Create args
	const args = (!fetchAll) ? { page: newPage, personnel_types: newTypeArray } : { personnel_types: newTypeArray };
	args.return_history = returnHistory;

	// Fetch people
	try {
		const results = await Parse.Cloud.run('fetchPersonnelForUser', args);
		return results;
	} catch (error) { // Handle session errors
		if (error.code === 209) {
			externalUserModule.handleUserLogOut(true);
		}
		return null;
	}
};
