// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

require('jquery-ui/ui/widgets/sortable');
const { v4: uuidv4 } = require('uuid');
const inputModule = require('../modules/input');
const externalUserModule = require('../modules/external-user');
const userModule = require('../modules/user');
const helperModule = require('../modules/helper');
const appModule = require('../modules/app');
const Constants = require('../../../Constants');
const { getCookie, getSignUpType } = require('../modules/utilities');
const { filterDefaultConfigurationItems } = require('../modules/configuration');


/**
 * State
 */

let configGlobalConfiguration;
let configGlobalConfigurationId;
let configAssetSchema;
let configUserSchema;
let configOrgSchema;
let configPersonnelSchema;
let configButtonTitle;
let configContext;


/**
 * Helpers
 */

const generateRandomId = () => uuidv4();


/**
 * Handlers
 */

const updateElementHeights = () => {
	const sideView = document.getElementById('left-nav-bar-content');
	const mainView = document.getElementById('app-container');
	if (sideView != null) {
		mainView.style.height = 'auto';
		setTimeout(() => {
			sideView.style.height = `${mainView.clientHeight}px`;
		}, 200);
	}
};

const getSelectedAssetType = () => {
	let selectedAssetId;
	let selectedAssetType;
	const elements = document.getElementsByName('config-asset-type');
	for (let i = 0; i < elements.length; i += 1) {
		if (elements[i].className.indexOf('selected') > -1) {
			selectedAssetId = elements[i].id.replace('config-asset-type-', '');
			break;
		}
	}
	for (let i = 0; i < configAssetSchema.fields.length; i += 1) {
		if (configAssetSchema.fields[i].id === selectedAssetId) {
			selectedAssetType = configAssetSchema.fields[i];
			break;
		}
	}
	return selectedAssetType;
};

const getSelectedAssetParam = () => {

	// Get selected asset type
	const selectedAssetType = getSelectedAssetType();

	// Get asset param
	let selectedAssetId;
	let selectedAssetParam;
	const elements = document.getElementsByName('config-asset-param');
	for (let i = 0; i < elements.length; i += 1) {
		if (elements[i].className.indexOf('selected') > -1) {
			selectedAssetId = elements[i].id.replace('config-asset-param-', '');
			break;
		}
	}
	for (let i = 0; i < selectedAssetType.fields.length; i += 1) {
		if (selectedAssetType.fields[i].id === selectedAssetId) {
			selectedAssetParam = selectedAssetType.fields[i];
			break;
		}
	}
	return selectedAssetParam;
};

const getSelectedPersonnelType = () => {
	let selectedPersonnelId;
	let selectedPersonnelType;
	const elements = document.getElementsByName('config-personnel-type');
	for (let i = 0; i < elements.length; i += 1) {
		if (elements[i].className.indexOf('selected') > -1) {
			selectedPersonnelId = elements[i].id.replace('config-personnel-type-', '');
			break;
		}
	}
	for (let i = 0; i < configPersonnelSchema.fields.length; i += 1) {
		if (configPersonnelSchema.fields[i].id === selectedPersonnelId) {
			selectedPersonnelType = configPersonnelSchema.fields[i];
			break;
		}
	}
	return selectedPersonnelType;
};

const getSelectedPersonnelParam = () => {

	// Get selected personnel type
	const selectedPersonnelType = getSelectedPersonnelType();

	// Get personnel param
	let selectedPersonnelId;
	let selectedPersonnelParam;
	const elements = document.getElementsByName('config-personnel-param');
	for (let i = 0; i < elements.length; i += 1) {
		if (elements[i].className.indexOf('selected') > -1) {
			selectedPersonnelId = elements[i].id.replace('config-personnel-param-', '');
			break;
		}
	}
	for (let i = 0; i < selectedPersonnelType.fields.length; i += 1) {
		if (selectedPersonnelType.fields[i].id === selectedPersonnelId) {
			selectedPersonnelParam = selectedPersonnelType.fields[i];
			break;
		}
	}
	return selectedPersonnelParam;
};

const getSelectedUserParam = () => {
	let selectedUserId;
	let selectedUserType;
	const elements = document.getElementsByName('config-user-param');
	for (let i = 0; i < elements.length; i += 1) {
		if (elements[i].className.indexOf('selected') > -1) {
			selectedUserId = elements[i].id.replace('config-user-param-', '');
			break;
		}
	}
	for (let i = 0; i < configUserSchema.fields.length; i += 1) {
		if (configUserSchema.fields[i].id === selectedUserId) {
			selectedUserType = configUserSchema.fields[i];
			break;
		}
	}
	return selectedUserType;
};

const getSelectedOrgParam = () => {
	let selectedOrgId;
	let selectedOrgType;
	const elements = document.getElementsByName('config-org-param');
	for (let i = 0; i < elements.length; i += 1) {
		if (elements[i].className.indexOf('selected') > -1) {
			selectedOrgId = elements[i].id.replace('config-org-param-', '');
			break;
		}
	}
	for (let i = 0; i < configOrgSchema.fields.length; i += 1) {
		if (configOrgSchema.fields[i].id === selectedOrgId) {
			selectedOrgType = configOrgSchema.fields[i];
			break;
		}
	}
	return selectedOrgType;
};

const fetchDefaultConfigurationForUser = async () => {

	// Get config type
	const configType = getSignUpType();

	// Fetch default configuration for user
	try {
		const configuration = await Parse.Cloud.run('fetchDefaultConfigurationForUser', {
			type: configType,
			entity: process.env.ENTITY
		});

		// Return configuration
		return configuration;

	} catch (error) {
		if (error && error.code === 209) {
			externalUserModule.handleUserLogOut(true);
		}
		return null;
	}
};

const saveConfigurationForUser = async (parameters) => {
	try {

		// Save configuration
		const result = await Parse.Cloud.run('saveConfigurationForUser', parameters);
		if (result === true) {
			await userModule.handleConfigurationForUser(true);
			return '';
		}

		// Get current user data
		const current = Parse.User.current();
		const email = current.get('email');

		// Set route for result
		if (result != null) {
			let url = '/enroll/configuration';
			if (result.next === 'configuration') {
				url = `/enroll/configuration${window.location.search}`;
			} else if (result.next === 'subscription') {
				url = `/enroll/payment${window.location.search}`;
			} else if (result.next === 'invite') {
				url = `/enroll/invite${window.location.search}`;
			} else if (result.next === 'confirmation') {
				let { search } = window.location;
				if (search !== '') search += '&status=complete';
				else search += '?status=complete';
				search += `&em=${email}`;
				url = `/enroll/confirmation${search}`;
			} else if (result.next === 'waiting-for-approval') {
				url = '/waiting-for-approval';
			}

			// Fetch state parameters
			await externalUserModule.fetchStateParametersForUser(false);

			// Set configRoute cookie
			const date = new Date();
			date.setFullYear(date.getFullYear() + 1);
			const cookie = `configRoute=true; expires=${date.toGMTString()}; path=/;`;
			document.cookie = cookie;

			// Respond with url
			return url;
		}
	} catch (error) {
		if (error && error.code === 209) {
			externalUserModule.handleUserLogOut(true);
		}
	}
	return null;
};

const handleConfigurationActionButton = async () => {

	// Get values
	const configurationStandardRole = document.getElementById('configuration-standard-role').value.replace(/<br>/gi, '');
	const configurationLeaderRole = document.getElementById('configuration-leader-role').value.replace(/<br>/gi, '');
	const configurationSuperRole = document.getElementById('configuration-super-role').value.replace(/<br>/gi, '');
	const configurationAdminRole = document.getElementById('configuration-admin-role').value.replace(/<br>/gi, '');

	// Get validation results
	const standardRoleResult = inputModule.validateText(configurationStandardRole);
	const leaderRoleResult = inputModule.validateText(configurationLeaderRole);
	const superRoleResult = inputModule.validateText(configurationSuperRole);
	const adminRoleResult = inputModule.validateText(configurationAdminRole);

	// Get elements
	const errorModalTitle = document.getElementById('configuration-validation-error-title');
	const errorModalSubtitle = document.getElementById('configuration-validation-error-subtitle');

	// Get config type
	const configType = configGlobalConfiguration.get('general').config_type;

	// Handle validation
	if (standardRoleResult[0] === false) {
		document.getElementById('configuration-standard-role').focus();
		errorModalTitle.innerHTML = 'Oh Snap...';
		if (configType === 'organization') {
			errorModalSubtitle.innerHTML = 'Please enter a name for your individual user role.';
		} else {
			errorModalSubtitle.innerHTML = 'Please enter a name for your teacher user role.';
		}
		inputModule.showModalWithId('configuration-validation-error-modal');
	} else if (leaderRoleResult[0] === false) {
		document.getElementById('configuration-leader-role').focus();
		errorModalTitle.innerHTML = 'Oh Snap...';
		if (configType === 'organization') {
			errorModalSubtitle.innerHTML = 'Please enter a name for your organization leader user role.';
		} else {
			errorModalSubtitle.innerHTML = 'Please enter a name for your school leader user role.';
		}
		inputModule.showModalWithId('configuration-validation-error-modal');
	} else if (superRoleResult[0] === false) {
		document.getElementById('configuration-super-role').focus();
		errorModalTitle.innerHTML = 'Oh Snap...';
		if (configType === 'organization') {
			errorModalSubtitle.innerHTML = 'Please enter a name for your network leader user role.';
		} else {
			errorModalSubtitle.innerHTML = 'Please enter a name for your district leader user role.';
		}
		inputModule.showModalWithId('configuration-validation-error-modal');
	} else if (adminRoleResult[0] === false) {
		document.getElementById('configuration-admin-role').focus();
		errorModalTitle.innerHTML = 'Oh Snap...';
		if (configType === 'organization') {
			errorModalSubtitle.innerHTML = 'Please enter a name for your network admin user role.';
		} else {
			errorModalSubtitle.innerHTML = 'Please enter a name for your district admin user role.';
		}
		inputModule.showModalWithId('configuration-validation-error-modal');
	} else {

		// Initialize isValid
		let isValid = true;

		// Validate asset schema
		if (configAssetSchema.fields != null && configAssetSchema.fields.length > 0) {
			for (let i = 0; i < configAssetSchema.fields.length; i += 1) {

				// Get field
				const field = configAssetSchema.fields[i];

				// Validate parameters
				if (!(field.id && typeof field.id === 'string'
					&& field.id.replace(/&nbsp;/gi, '').replace(/<br>/gi, '').replace(/\s/g, '') !== '')) {

					// Set invalid state
					isValid = false;

					// Set modal content
					errorModalTitle.innerHTML = 'Oh Snap...';
					errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

				} else if (!(field.name && typeof field.name === 'string'
					&& field.name.replace(/&nbsp;/gi, '').replace(/<br>/gi, '').replace(/\s/g, '') !== '')) {

					// Set invalid state
					isValid = false;

					// Set modal content
					errorModalTitle.innerHTML = 'Oh Snap...';
					errorModalSubtitle.innerHTML = 'Please enter a name for each of your asset types and try again.';

				} else if (field.options == null) {

					// Set invalid state
					isValid = false;

					// Set modal content
					errorModalTitle.innerHTML = 'Oh Snap...';
					errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

				} else {

					// Get options
					const { options } = field;

					// Validate parameters
					if (!(options.search_isbn != null && typeof options.search_isbn === 'boolean')) {

						// Set invalid state
						isValid = false;

						// Set modal content
						errorModalTitle.innerHTML = 'Oh Snap...';
						errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

					} else if (!(options.creates_global != null && typeof options.creates_global === 'boolean')) {

						// Set invalid state
						isValid = false;

						// Set modal content
						errorModalTitle.innerHTML = 'Oh Snap...';
						errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

					} else if (!(options.track_check_in_out_status != null && typeof options.track_check_in_out_status === 'boolean')) {

						// Set invalid state
						isValid = false;

						// Set modal content
						errorModalTitle.innerHTML = 'Oh Snap...';
						errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

					} else if (!(options.track_geolocation != null && typeof options.track_geolocation === 'boolean')) {

						// Set invalid state
						isValid = false;

						// Set modal content
						errorModalTitle.innerHTML = 'Oh Snap...';
						errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

					} else if (!(options.initial_state_expanded != null && typeof options.initial_state_expanded === 'boolean')) {

						// Set invalid state
						isValid = false;

						// Set modal content
						errorModalTitle.innerHTML = 'Oh Snap...';
						errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

					} else if (!(options.track_stock_threshold != null && typeof options.track_stock_threshold === 'boolean')) {

						// Set invalid state
						isValid = false;

						// Set modal content
						errorModalTitle.innerHTML = 'Oh Snap...';
						errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

					} else if (!(options.show_stock_level_widget != null && typeof options.show_stock_level_widget === 'boolean')) {

						// Set invalid state
						isValid = false;

						// Set modal content
						errorModalTitle.innerHTML = 'Oh Snap...';
						errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

					} else if (!(options.custom_owner_field != null && typeof options.custom_owner_field === 'string'
						&& options.custom_owner_field.replace(/&nbsp;/gi, '').replace(/<br>/gi, '').replace(/\s/g, '') !== '')) {

						// Set invalid state
						isValid = false;

						// Set modal content
						errorModalTitle.innerHTML = 'Oh Snap...';
						errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

					} else if (options.geolocation_thresholds == null || options.geolocation_thresholds.length === 0) {

						// Set invalid state
						isValid = false;

						// Set modal content
						errorModalTitle.innerHTML = 'Oh Snap...';
						errorModalSubtitle.innerHTML = 'Please add at least one threshold for geolocation to each asset type in your schema and try again.';

					} else if (field.fields == null || field.fields.length === 0) {

						// Set invalid state
						isValid = false;

						// Set modal content
						errorModalTitle.innerHTML = 'Oh Snap...';
						errorModalSubtitle.innerHTML = 'Please add at least one field to each asset type in your schema and try again.';

					} else {

						// Initialize shared parameters
						let foundPrimarySearch = 0;
						let foundSecondarySearch = 0;
						let foundQuantity = false;
						let foundPrice = false;

						// Initialize asset duplicate check
						const assetFieldIds = [];
						const assetFieldPrompt = [];

						// Get fields
						const { fields } = field;
						for (let j = 0; j < fields.length; j += 1) {

							// Get inner field
							const innerField = fields[j];

							// Check if duplicate naming
							if (assetFieldIds.indexOf(innerField.id) > -1) {

								// Set invalid state
								isValid = false;

							} else if (assetFieldPrompt.indexOf(innerField.name) > -1) {

								// Set invalid state
								isValid = false;
							}

							// Add parameters to duplicate state
							assetFieldIds.push(innerField.id);
							assetFieldPrompt.push(innerField.name);

							// Validate parameters
							if (!(innerField.id && typeof innerField.id === 'string'
								&& innerField.id.replace(/&nbsp;/gi, '').replace(/<br>/gi, '').replace(/\s/g, '') !== '')) {

								// Set invalid state
								isValid = false;

								// Set modal content
								errorModalTitle.innerHTML = 'Oh Snap...';
								errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

							} else if (!(innerField.name && typeof innerField.name === 'string'
								&& innerField.name.replace(/&nbsp;/gi, '').replace(/<br>/gi, '').replace(/\s/g, '') !== '')) {

								// Set invalid state
								isValid = false;

								// Set modal content
								errorModalTitle.innerHTML = 'Oh Snap...';
								errorModalSubtitle.innerHTML = 'Please enter a name for each of your asset fields and try again.';

							} else if (innerField.options == null) {

								// Set invalid state
								isValid = false;

								// Set modal content
								errorModalTitle.innerHTML = 'Oh Snap...';
								errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

							} else {

								// Get inner options
								const innerOptions = innerField.options;

								// Check field types
								if (innerOptions.type === 'quantity') foundQuantity = true;
								if (innerOptions.type === 'price') foundPrice = true;

								// Validate parameters
								if (!(innerOptions.creates_global != null && typeof innerOptions.creates_global === 'boolean')) {

									// Set invalid state
									isValid = false;

									// Set modal content
									errorModalTitle.innerHTML = 'Oh Snap...';
									errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

								} else if (!(innerOptions.display_on_dashboard != null && typeof innerOptions.display_on_dashboard === 'boolean')) {

									// Set invalid state
									isValid = false;

									// Set modal content
									errorModalTitle.innerHTML = 'Oh Snap...';
									errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

								} else if (!(innerOptions.displayed_on_search != null && typeof innerOptions.displayed_on_search === 'boolean')) {

									// Set invalid state
									isValid = false;

									// Set modal content
									errorModalTitle.innerHTML = 'Oh Snap...';
									errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

								} else if (!(innerOptions.editable != null && typeof innerOptions.editable === 'boolean')) {

									// Set invalid state
									isValid = false;

									// Set modal content
									errorModalTitle.innerHTML = 'Oh Snap...';
									errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

								} else if (!(innerOptions.is_detail_image != null && typeof innerOptions.is_detail_image === 'boolean')) {

									// Set invalid state
									isValid = false;

									// Set modal content
									errorModalTitle.innerHTML = 'Oh Snap...';
									errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

								} else if (!(innerOptions.primary_search != null && typeof innerOptions.primary_search === 'boolean')) {

									// Set invalid state
									isValid = false;

									// Set modal content
									errorModalTitle.innerHTML = 'Oh Snap...';
									errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

								} else if (!(innerOptions.required_to_create_global != null && typeof innerOptions.required_to_create_global === 'boolean')) {

									// Set invalid state
									isValid = false;

									// Set modal content
									errorModalTitle.innerHTML = 'Oh Snap...';
									errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

								} else if (!(innerOptions.secondary_search != null && typeof innerOptions.secondary_search === 'boolean')) {

									// Set invalid state
									isValid = false;

									// Set modal content
									errorModalTitle.innerHTML = 'Oh Snap...';
									errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

								} else if (!(innerOptions.updates_global != null && typeof innerOptions.updates_global === 'boolean')) {

									// Set invalid state
									isValid = false;

									// Set modal content
									errorModalTitle.innerHTML = 'Oh Snap...';
									errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

								} else if (!(innerOptions.type && (innerOptions.type === 'text' || innerOptions.type === 'array'
									|| innerOptions.type === 'number' || innerOptions.type === 'quantity' || innerOptions.type === 'price'
									|| innerOptions.type === 'stockLevelThreshold' || innerOptions.type === 'image' || (innerOptions.type === 'choice'
										&& (innerOptions.choices != null && innerOptions.choices.length > 0))))) {

									// Set invalid state
									isValid = false;

									// Set modal content
									if (innerOptions.type === 'choice') {
										errorModalTitle.innerHTML = 'Oh Snap...';
										errorModalSubtitle.innerHTML = 'Please enter options for each of your dropdown fields.';
									} else {
										errorModalTitle.innerHTML = 'Oh Snap...';
										errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";
									}
								} else {

									// Get search parameters
									if (innerOptions.primary_search === true) {
										if (innerOptions.type === 'image') {

											// Set invalid state
											isValid = false;

											// Set modal content
											errorModalTitle.innerHTML = 'Oh Snap...';
											errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

										} else {
											foundPrimarySearch += 1;
										}
									}
									if (innerOptions.secondary_search === true) {
										if (innerOptions.type === 'image') {

											// Set invalid state
											isValid = false;

											// Set modal content
											errorModalTitle.innerHTML = 'Oh Snap...';
											errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

										} else {
											foundSecondarySearch += 1;
										}
									}

									// Validate book parameters
									if (options.search_isbn === true) {
										if (innerField.slug === 'title') {
											if (!(innerOptions.creates_global === true && innerOptions.updates_global === false
												&& innerOptions.required_to_create_global === true && innerOptions.type === 'text'
												&& innerOptions.default == null)) {

												// Set invalid state
												isValid = false;

												// Set modal content
												errorModalTitle.innerHTML = 'Oh Snap...';
												errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";
											}
										} else if (innerField.slug === 'authors') {
											if (!(innerOptions.creates_global === true && innerOptions.updates_global === false
												&& innerOptions.required_to_create_global === false && innerOptions.type === 'array'
												&& innerOptions.default == null)) {

												// Set invalid state
												isValid = false;

												// Set modal content
												errorModalTitle.innerHTML = 'Oh Snap...';
												errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";
											}
										} else if (innerField.slug === 'fountas_pinnell') {
											if (!(innerOptions.creates_global === true && innerOptions.updates_global === true
												&& innerOptions.required_to_create_global === false && innerOptions.type === 'text'
												&& innerOptions.default == null)) {

												// Set invalid state
												isValid = false;

												// Set modal content
												errorModalTitle.innerHTML = 'Oh Snap...';
												errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";
											}
										} else if (innerField.slug === 'categories') {
											if (!(innerOptions.creates_global === true && innerOptions.updates_global === false
												&& innerOptions.required_to_create_global === false && innerOptions.type === 'array'
												&& innerOptions.default == null)) {

												// Set invalid state
												isValid = false;

												// Set modal content
												errorModalTitle.innerHTML = 'Oh Snap...';
												errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";
											}
										} else if (innerField.slug === 'image') {
											if (!(innerOptions.creates_global === true && innerOptions.updates_global === false
												&& innerOptions.required_to_create_global === false && innerOptions.type === 'image'
												&& innerOptions.default == null)) {

												// Set invalid state
												isValid = false;

												// Set modal content
												errorModalTitle.innerHTML = 'Oh Snap...';
												errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";
											}
										} else if (!(innerOptions.creates_global === false && innerOptions.updates_global === false
											&& innerOptions.required_to_create_global === false)) {

											// Set invalid state
											isValid = false;

											// Set modal content
											errorModalTitle.innerHTML = 'Oh Snap...';
											errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";
										}
									}
								}
							}
						}

						// Validate search rules
						if (isValid === true) {
							if (foundPrimarySearch > 1) {

								// Set invalid state
								isValid = false;

								// Set modal content
								errorModalTitle.innerHTML = 'Oh Snap...';
								errorModalSubtitle.innerHTML = 'Please select only one field to be the primary search field for each asset type.';

							} else if (foundPrimarySearch === 0) {

								// Set invalid state
								isValid = false;

								// Set modal content
								errorModalTitle.innerHTML = 'Oh Snap...';
								errorModalSubtitle.innerHTML = 'Please select a field to be the primary search field for each asset type.';

							} else if (foundSecondarySearch > 1) {

								// Set invalid state
								isValid = false;

								// Set modal content
								errorModalTitle.innerHTML = 'Oh Snap...';
								errorModalSubtitle.innerHTML = 'Please select only one field to be the secondary search field for each asset type.';
							}
						}

						// Validate required field rules
						if (isValid === true) {
							if (!foundQuantity) {

								// Set invalid state
								isValid = false;

								// Set modal content
								errorModalTitle.innerHTML = 'Oh Snap...';
								errorModalSubtitle.innerHTML = 'This asset type must have a quantity field. Please contact support if this problem persists.';

							} else if (!foundPrice) {

								// Set invalid state
								isValid = false;

								// Set modal content
								errorModalTitle.innerHTML = 'Oh Snap...';
								errorModalSubtitle.innerHTML = 'This asset type must have a price field. Please contact support if this problem persists.';
							}
						}
					}
				}
			}
		} else {

			// Set invalid state
			isValid = false;

			// Set modal content
			errorModalTitle.innerHTML = 'Oh Snap...';
			errorModalSubtitle.innerHTML = 'Please add at least one asset type to your schema and try again.';
		}

		// Validate personnel schema
		if (isValid === true) {
			if (configPersonnelSchema.fields != null && configPersonnelSchema.fields.length > 0) {
				for (let i = 0; i < configPersonnelSchema.fields.length; i += 1) {

					// Get field
					const field = configPersonnelSchema.fields[i];

					// Validate parameters
					if (!(field.id && typeof field.id === 'string'
						&& field.id.replace(/&nbsp;/gi, '').replace(/<br>/gi, '').replace(/\s/g, '') !== '')) {

						// Set invalid state
						isValid = false;

						// Set modal content
						errorModalTitle.innerHTML = 'Oh Snap...';
						errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

					} else if (!(field.name && typeof field.name === 'string'
						&& field.name.replace(/&nbsp;/gi, '').replace(/<br>/gi, '').replace(/\s/g, '') !== '')) {

						// Set invalid state
						isValid = false;

						// Set modal content
						errorModalTitle.innerHTML = 'Oh Snap...';
						errorModalSubtitle.innerHTML = 'Please enter a name for each of your groups and try again.';

					} else if (field.options == null) {

						// Set invalid state
						isValid = false;

						// Set modal content
						errorModalTitle.innerHTML = 'Oh Snap...';
						errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

					} else if (field.fields == null || field.fields.length === 0) {

						// Set invalid state
						isValid = false;

						// Set modal content
						errorModalTitle.innerHTML = 'Oh Snap...';
						errorModalSubtitle.innerHTML = 'Please add at least one field to each group in your schema and try again.';

					} else {

						// Initialize personnel duplicate check
						const personnelFieldIds = [];
						const personnelFieldPrompt = [];

						// Get fields
						const { fields } = field;
						for (let j = 0; j < fields.length; j += 1) {

							// Get inner field
							const innerField = fields[j];

							// Check if duplicate naming
							if (personnelFieldIds.indexOf(innerField.id) > -1) {

								// Set invalid state
								isValid = false;
							} else if (personnelFieldPrompt.indexOf(innerField.name) > -1) {

								// Set invalid state
								isValid = false;
							}

							// Add parameters to duplicate state
							personnelFieldIds.push(innerField.id);
							personnelFieldPrompt.push(innerField.name);

							// Validate parameters
							if (!(innerField.id && typeof innerField.id === 'string'
									&& innerField.id.replace(/&nbsp;/gi, '').replace(/<br>/gi, '').replace(/\s/g, '') !== '')) {

								// Set invalid state
								isValid = false;

								// Set modal content
								errorModalTitle.innerHTML = 'Oh Snap...';
								errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

							} else if (!(innerField.name && typeof innerField.name === 'string'
									&& innerField.name.replace(/&nbsp;/gi, '').replace(/<br>/gi, '').replace(/\s/g, '') !== '')) {

								// Set invalid state
								isValid = false;

								// Set modal content
								errorModalTitle.innerHTML = 'Oh Snap...';
								errorModalSubtitle.innerHTML = 'Please enter a name for each of your group fields and try again.';

							} else if (innerField.options == null) {

								// Set invalid state
								isValid = false;

								// Set modal content
								errorModalTitle.innerHTML = 'Oh Snap...';
								errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

							} else {

								// Get inner options
								const innerOptions = innerField.options;

								// Validate parameters
								if (!(innerOptions.display_on_dashboard != null && typeof innerOptions.display_on_dashboard === 'boolean')) {

									// Set invalid state
									isValid = false;

									// Set modal content
									errorModalTitle.innerHTML = 'Oh Snap...';
									errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

								} else if (!(innerOptions.editable != null && typeof innerOptions.editable === 'boolean')) {

									// Set invalid state
									isValid = false;

									// Set modal content
									errorModalTitle.innerHTML = 'Oh Snap...';
									errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

								} else if (!(innerOptions.type && (innerOptions.type === 'text' || innerOptions.type === 'array'
										|| innerOptions.type === 'number' || innerOptions.type === 'image' || (innerOptions.type === 'choice'
											&& (innerOptions.choices != null && innerOptions.choices.length > 0))))) {

									// Set invalid state
									isValid = false;

									// Set modal content
									if (innerOptions.type === 'choice') {
										errorModalTitle.innerHTML = 'Oh Snap...';
										errorModalSubtitle.innerHTML = 'Please enter options for each of your dropdown fields.';
									} else {
										errorModalTitle.innerHTML = 'Oh Snap...';
										errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";
									}
								}
							}
						}
					}
				}
			} else {

				// Set invalid state
				isValid = false;

				// Set modal content
				errorModalTitle.innerHTML = 'Oh Snap...';
				errorModalSubtitle.innerHTML = 'Please add at least one group to your schema and try again.';
			}
		}

		// Validate user schema
		if (isValid === true) {
			if (configUserSchema.fields != null) {

				// Initialize user duplicate check
				const userFieldIds = [];
				const userFieldPrompt = [];

				// Iterate through user profile
				for (let i = 0; i < configUserSchema.fields.length; i += 1) {

					// Get field
					const field = configUserSchema.fields[i];

					// Check if duplicate naming
					if (userFieldIds.indexOf(field.id) > -1) {

						// Set invalid state
						isValid = false;
					} else if (userFieldPrompt.indexOf(field.name) > -1) {

						// Set invalid state
						isValid = false;
					}

					// Add parameters to duplicate state
					userFieldIds.push(field.id);
					userFieldPrompt.push(field.name);

					// Validate parameters
					if (!(field.id && typeof field.id === 'string'
						&& field.id.replace(/&nbsp;/gi, '').replace(/<br>/gi, '').replace(/\s/g, '') !== '')) {

						// Set invalid state
						isValid = false;

						// Set modal content
						errorModalTitle.innerHTML = 'Oh Snap...';
						errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

					} else if (!(field.name && typeof field.name === 'string'
						&& field.name.replace(/&nbsp;/gi, '').replace(/<br>/gi, '').replace(/\s/g, '') !== '')) {

						// Set invalid state
						isValid = false;

						// Set modal content
						errorModalTitle.innerHTML = 'Oh Snap...';
						errorModalSubtitle.innerHTML = 'Please enter a name for each of your user profile fields and try again.';

					} else if (field.options == null) {

						// Set invalid state
						isValid = false;

						// Set modal content
						errorModalTitle.innerHTML = 'Oh Snap...';
						errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

					} else {

						// Get options
						const { options } = field;

						// Validate parameters
						if (!(options.display_on_dashboard != null && typeof options.display_on_dashboard === 'boolean')) {

							// Set invalid state
							isValid = false;

							// Set modal content
							errorModalTitle.innerHTML = 'Oh Snap...';
							errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

						} else if (!(options.type && (options.type === 'text' || options.type === 'array'
							|| options.type === 'number' || (options.type === 'choice'
								&& (options.choices != null && options.choices.length > 0))))) {

							// Set invalid state
							isValid = false;

							// Set modal content
							if (options.type === 'choice') {
								errorModalTitle.innerHTML = 'Oh Snap...';
								errorModalSubtitle.innerHTML = 'Please enter options for each of your dropdown fields.';
							} else {
								errorModalTitle.innerHTML = 'Oh Snap...';
								errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";
							}
						}
					}
				}
			} else {

				// Set invalid state
				isValid = false;

				// Set modal content
				errorModalTitle.innerHTML = 'Oh Snap...';
				errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";
			}
		}

		// Validate organization schema
		if (isValid === true) {
			if (configOrgSchema.fields != null) {

				// Initialize asset duplicate check
				const orgFieldIds = [];
				const orgFieldPrompt = [];

				// Iterate through organization fields
				for (let i = 0; i < configOrgSchema.fields.length; i += 1) {

					// Get field
					const field = configOrgSchema.fields[i];

					// Check if duplicate naming
					if (orgFieldIds.indexOf(field.id) > -1) {

						// Set invalid state
						isValid = false;
					} else if (orgFieldPrompt.indexOf(field.name) > -1) {

						// Set invalid state
						isValid = false;
					}

					// Add parameters to duplicate state
					orgFieldIds.push(field.id);
					orgFieldPrompt.push(field.name);

					// Validate parameters
					if (!(field.id && typeof field.id === 'string'
						&& field.id.replace(/&nbsp;/gi, '').replace(/<br>/gi, '').replace(/\s/g, '') !== '')) {

						// Set invalid state
						isValid = false;

						// Set modal content
						errorModalTitle.innerHTML = 'Oh Snap...';
						errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

					} else if (!(field.name && typeof field.name === 'string'
						&& field.name.replace(/&nbsp;/gi, '').replace(/<br>/gi, '').replace(/\s/g, '') !== '')) {

						// Set invalid state
						isValid = false;

						// Set modal content
						errorModalTitle.innerHTML = 'Oh Snap...';
						errorModalSubtitle.innerHTML = 'Please enter a name for each of your organizational profile fields and try again.';

					} else if (field.options == null) {

						// Set invalid state
						isValid = false;

						// Set modal content
						errorModalTitle.innerHTML = 'Oh Snap...';
						errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

					} else {

						// Get options
						const { options } = field;

						// Validate parameters
						if (!(options.display_on_dashboard != null && typeof options.display_on_dashboard === 'boolean')) {

							// Set invalid state
							isValid = false;

							// Set modal content
							errorModalTitle.innerHTML = 'Oh Snap...';
							errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";

						} else if (!(options.type && (options.type === 'text' || options.type === 'array'
							|| options.type === 'number' || (options.type === 'choice'
								&& (options.choices != null && options.choices.length > 0))))) {

							// Set invalid state
							isValid = false;

							// Set modal content
							if (options.type === 'choice') {
								errorModalTitle.innerHTML = 'Oh Snap...';
								errorModalSubtitle.innerHTML = 'Please enter options for each of your dropdown fields.';
							} else {
								errorModalTitle.innerHTML = 'Oh Snap...';
								errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";
							}
						}
					}
				}
			} else {

				// Set invalid state
				isValid = false;

				// Set modal content
				errorModalTitle.innerHTML = 'Oh Snap...';
				errorModalSubtitle.innerHTML = "We've found an issue with your schema. Please try again.";
			}
		}

		// Check if valid
		if (isValid === false) { // An error occurred

			// Show error modal
			inputModule.showModalWithId('configuration-validation-error-modal');

		} else {

			// Update display
			configButtonTitle = document.getElementById('config-action-button-text').innerHTML;
			document.getElementById('config-action-button').disabled = true;
			document.getElementById('config-action-button-text').innerHTML = '';
			document.getElementById('config-action-button-arrow').style.display = 'none';
			document.getElementById('config-button-activity-indicator').style.display = 'block';

			// Create parameters
			const parameters = {
				role_names: {
					standard: configurationStandardRole,
					leader: configurationLeaderRole,
					super: configurationSuperRole,
					admin: configurationAdminRole
				},
				config_type: configGlobalConfiguration.get('general').config_type,
				mobile_initial_view: configGlobalConfiguration.get('general').mobile.initial_view,
				asset_types: configAssetSchema,
				personnel_types: configPersonnelSchema,
				user_profile: configUserSchema,
				org_profile: configOrgSchema
			};

			// If updating configuration, pass config id
			if (configGlobalConfigurationId != null) {
				parameters.configurationId = configGlobalConfigurationId;
			}

			// Save configuration for user
			const route = await saveConfigurationForUser(parameters);
			if (route != null) {
				if (window.location.pathname.indexOf('/enroll/') > -1) { // Onboarding
					window.location = route;
				} else { // Account

					// Update display
					document.getElementById('config-action-button-text').innerHTML = 'Successfully Saved Configuration';
					document.getElementById('config-button-activity-indicator').style.display = 'none';

					// Set delay
					setTimeout(() => {

						// Update display
						document.getElementById('config-action-button').disabled = false;
						document.getElementById('config-action-button-text').innerHTML = configButtonTitle;
						document.getElementById('config-action-button-arrow').style.display = 'inline-block';

					}, 2000);
				}
			} else {

				// Update display
				document.getElementById('config-action-button').disabled = false;
				document.getElementById('config-action-button-text').innerHTML = configButtonTitle;
				document.getElementById('config-action-button-arrow').style.display = 'inline-block';
				document.getElementById('config-button-activity-indicator').style.display = 'none';

				// Display error
				inputModule.showModalWithId('configuration-save-error-modal');
			}
		}
	}
};

const addNewAssetTypeRow = (name) => {

	// Get name
	let fieldName;
	if (name != null) fieldName = name.display;
	else fieldName = 'New Asset Type';

	// Get container element
	const container = document.getElementById('configuration-asset-types-holder');

	// Get random id
	const randomId = generateRandomId();

	// Create new element
	const element = `<div class='config-field-row' name='config-asset-type' id='config-asset-type-${randomId}'>
        <div class='config-field-row-inner'><p
        class='contenteditable' contenteditable='true'
         id='config-asset-type-content-${randomId}'>${fieldName}</p></div>
        <div class='remove-icon' id='config-asset-type-remove-${randomId}'></div></div>`;

	// Append new element
	container.insertAdjacentHTML('beforeend', element);

	// Set new asset type schema
	if (name == null) {

		// Set asset type
		configAssetSchema.fields.push({
			id: randomId,
			name: fieldName,
			options: {
				search_isbn: false,
				creates_global: false,
				track_check_in_out_status: false,
				track_geolocation: false,
				initial_state_expanded: false,
				track_stock_threshold: false,
				show_stock_level_widget: false,
				custom_owner_field: 'Scanned by',
				geolocation_thresholds: [
					{
						title: 'Strong Confidence',
						confidence_level: 'high',
						min: 0,
						max: 2
					},
					{
						title: 'Medium Confidence',
						confidence_level: 'medium',
						min: 2,
						max: 15
					},
					{
						title: 'Low Confidence',
						confidence_level: 'low',
						min: 15,
						max: 50
					},
					{
						title: 'No Confidence',
						confidence_level: 'none',
						min: 50,
					}
				]
			},
			fields: [
				{
					id: 'quantity',
					name: 'Stock Level',
					slug: 'quantity',
					ref: ['quantity'],
					options: {
						display_on_dashboard: true,
						type: 'quantity',
						editable: true,
						updates_global: false,
						creates_global: false,
						required_to_create_global: false,
						displayed_on_search: true,
						primary_search: false,
						secondary_search: false,
						is_detail_image: false
					}
				},
				{
					id: 'price',
					name: 'Price',
					slug: 'price',
					ref: ['price'],
					options: {
						display_on_dashboard: true,
						type: 'price',
						editable: true,
						updates_global: false,
						creates_global: false,
						required_to_create_global: false,
						displayed_on_search: true,
						primary_search: false,
						secondary_search: false,
						is_detail_image: false
					}
				}
			]
		});
	} else {

		// Get search config
		const searchConfig = configGlobalConfiguration.get('search');
		const search = searchConfig.type_schemas[name.slug];

		// Get display config
		const displayConfig = configGlobalConfiguration.get('display');
		const display = displayConfig.type_schemas[name.slug];

		// Get fields
		const typeFields = [];
		const inputArray = name.input;
		for (let i = 0; i < inputArray.length; i += 1) {

			// Get parameters
			const input = inputArray[i];
			const { prompt } = input;

			// Check if should create row
			if (input.type !== 'stockLevelThreshold') {

				// Get random id
				const newRandomId = generateRandomId();

				// Get display parameters
				let displayOnDashboard = false;
				for (let j = 0; j < display.columns.length; j += 1) {
					if (display.columns[j].id != null && display.columns[j].id === input.id) {
						displayOnDashboard = true;
					}
				}

				// Get search parameters
				let displayedOnSearch = false;
				let primarySearch = false;
				let secondarySearch = false;
				let isDetailImage = false;
				if (search.primary.id === input.id) {
					displayedOnSearch = true;
					primarySearch = true;
				}
				if (search.secondary.id === input.id) {
					displayedOnSearch = true;
					secondarySearch = true;
				}
				if (search.detail.image != null && search.detail.image.id === input.id) {
					displayedOnSearch = true;
					isDetailImage = true;
				}
				for (let j = 0; j < search.detail.fields.length; j += 1) {
					if (search.detail.fields[j].id === input.id) {
						displayedOnSearch = true;
						break;
					}
				}

				// Validate prompt
				if (prompt != null) {

					// Append new field
					typeFields.push({
						id: newRandomId,
						name: prompt,
						slug: input.id,
						ref: input.ref,
						options: {
							display_on_dashboard: displayOnDashboard,
							type: input.type,
							choices: input.choices,
							default: input.user_default,
							editable: input.editable,
							updates_global: input.updates_global,
							creates_global: input.creates_global,
							required_to_create_global: input.required_to_create_global,
							displayed_on_search: displayedOnSearch,
							primary_search: primarySearch,
							secondary_search: secondarySearch,
							is_detail_image: isDetailImage
						}
					});
				}
			}
		}

		// Set asset type
		configAssetSchema.fields.push({
			id: randomId,
			name: fieldName,
			slug: name.slug,
			options: {
				search_isbn: name.search_isbn,
				creates_global: name.creates_global,
				track_check_in_out_status: (name.track_check_in_out_status != null) ? name.track_check_in_out_status : false,
				track_geolocation: (name.track_geolocation != null) ? name.track_geolocation : false,
				initial_state_expanded: search.initial_state_expanded,
				track_stock_threshold: (name.track_stock_threshold != null) ? name.track_stock_threshold : false,
				show_stock_level_widget: (name.show_stock_level_widget != null) ? name.show_stock_level_widget : false,
				custom_owner_field: (name.custom_owner_field != null) ? name.custom_owner_field : 'Scanned by',
				geolocation_thresholds: (name.geolocation_thresholds != null) ? name.geolocation_thresholds : [
					{
						title: 'Strong Confidence',
						confidence_level: 'high',
						min: 0,
						max: 2
					},
					{
						title: 'Medium Confidence',
						confidence_level: 'medium',
						min: 2,
						max: 15
					},
					{
						title: 'Low Confidence',
						confidence_level: 'low',
						min: 15,
						max: 50
					},
					{
						title: 'No Confidence',
						confidence_level: 'none',
						min: 50,
					}
				]
			},
			fields: typeFields
		});
	}

	// Update element heights
	updateElementHeights();
};

const addNewAssetParamRow = () => {

	// Get parameters
	const fieldName = 'New Asset Field';

	// Get container element
	const container = document.getElementById('configuration-asset-params-holder');

	// Get selected asset type
	const selectedAssetType = getSelectedAssetType();
	if (selectedAssetType != null) {

		// Get random id
		const randomId = generateRandomId();

		// Create new element
		const element = `<div class='config-field-row' name='config-asset-param' id='config-asset-param-${randomId}'>
            <div class='config-field-row-inner'><p
            class='contenteditable' contenteditable='true'
             id='config-asset-content-${randomId}'>${fieldName}</p></div>
            <div class='remove-icon' id='config-asset-remove-${randomId}'></div></div>`;

		// Append new element
		container.insertAdjacentHTML('beforeend', element);

		// Check for search parameter
		let primarySearch = true;
		let secondarySearch = true;
		for (let i = 0; i < selectedAssetType.fields.length; i += 1) {
			if (selectedAssetType.fields[i].options.primary_search === true) {
				primarySearch = false;
			}
			if (selectedAssetType.fields[i].options.secondary_search === true) {
				secondarySearch = false;
			}
		}

		// Get if book type
		let updatesGlobal = true;
		let createsGlobal = true;
		if (selectedAssetType.options.search_isbn === true) {
			updatesGlobal = false;
			createsGlobal = false;
		}

		// Set field content
		selectedAssetType.fields.push({
			id: randomId,
			name: fieldName,
			options: {
				display_on_dashboard: true,
				type: 'text',
				default: null,
				editable: true,
				updates_global: updatesGlobal,
				creates_global: createsGlobal,
				required_to_create_global: false,
				displayed_on_search: true,
				primary_search: primarySearch,
				secondary_search: secondarySearch,
				is_detail_image: false
			}
		});
	}

	// Update element heights
	updateElementHeights();
};

const addNewPersonnelTypeRow = (name) => {

	// Get name
	let fieldName;
	if (name != null) fieldName = name.display;
	else fieldName = 'New Group';

	// Get container element
	const container = document.getElementById('configuration-personnel-types-holder');

	// Get random id
	const randomId = generateRandomId();

	// Create new element
	const element = `<div class='config-field-row' name='config-personnel-type' id='config-personnel-type-${randomId}'>
        <div class='config-field-row-inner'><p
        class='contenteditable' contenteditable='true'
         id='config-personnel-type-content-${randomId}'>${fieldName}</p></div>
        <div class='remove-icon' id='config-personnel-type-remove-${randomId}'></div></div>`;

	// Append new element
	container.insertAdjacentHTML('beforeend', element);

	// Set new personnel type schema
	if (name == null) {

		// Set personnel type
		configPersonnelSchema.fields.push({
			id: randomId,
			name: fieldName,
			options: {},
			fields: []
		});
	} else {

		// Get display config
		const displayConfig = configGlobalConfiguration.get('display');
		const display = displayConfig.personnel_type_schemas[name.slug];

		// Get fields
		const typeFields = [];
		const inputArray = name.input;
		for (let i = 0; i < inputArray.length; i += 1) {

			// Get parameters
			const input = inputArray[i];
			const { prompt } = input;

			// Get random id
			const newRandomId = generateRandomId();

			// Get display parameters
			let displayOnDashboard = false;
			for (let j = 0; j < display.columns.length; j += 1) {
				if (display.columns[j].id != null && display.columns[j].id === input.id) {
					displayOnDashboard = true;
				}
			}

			// Validate prompt
			if (prompt != null) {

				// Append new field
				typeFields.push({
					id: newRandomId,
					name: prompt,
					slug: input.id,
					ref: input.ref,
					options: {
						display_on_dashboard: displayOnDashboard,
						type: input.type,
						choices: input.choices,
						default: input.user_default,
						editable: input.editable
					}
				});
			}
		}

		// Set personnel type
		configPersonnelSchema.fields.push({
			id: randomId,
			name: fieldName,
			slug: name.slug,
			options: {},
			fields: typeFields
		});
	}

	// Update element heights
	updateElementHeights();
};

const addNewPersonnelParamRow = () => {

	// Get parameters
	const fieldName = 'New Group Field';

	// Get container element
	const container = document.getElementById('configuration-personnel-params-holder');

	// Get selected personnel type
	const selectedPersonnelType = getSelectedPersonnelType();
	if (selectedPersonnelType != null) {

		// Get random id
		const randomId = generateRandomId();

		// Create new element
		const element = `<div class='config-field-row' name='config-personnel-param' id='config-personnel-param-${randomId}'>
            <div class='config-field-row-inner'><p
            class='contenteditable' contenteditable='true'
             id='config-personnel-content-${randomId}'>${fieldName}</p></div>
            <div class='remove-icon' id='config-personnel-remove-${randomId}'></div></div>`;

		// Append new element
		container.insertAdjacentHTML('beforeend', element);

		// Set field content
		selectedPersonnelType.fields.push({
			id: randomId,
			name: fieldName,
			options: {
				display_on_dashboard: true,
				type: 'text',
				default: null,
				editable: true
			}
		});
	}

	// Update element heights
	updateElementHeights();
};

const addNewUserParamRow = (name) => {

	// Get name
	let fieldName;
	if (name != null) fieldName = name.prompt;
	else fieldName = 'New Profile Field';

	// Get container element
	const container = document.getElementById('configuration-user-params-holder');

	// Get random id
	const randomId = generateRandomId();

	// Create new element
	const element = `<div class='config-field-row' name='config-user-param' id='config-user-param-${randomId}'>
        <div class='config-field-row-inner'><p
        class='contenteditable' contenteditable='true'
         id='config-profile-content-${randomId}'>${fieldName}</p></div>
        <div class='remove-icon' id='config-profile-remove-${randomId}'></div></div>`;

	// Append new element
	container.insertAdjacentHTML('beforeend', element);

	// Get default select element
	const defaultSelectElement = document.getElementById('config-custom-a-p-7');

	// Set new user field schema
	if (name == null) {

		// Set field content
		configUserSchema.fields.push({
			id: randomId,
			name: fieldName,
			options: {
				display_on_dashboard: true,
				type: 'text'
			}
		});

		// Set new default option
		defaultSelectElement.innerHTML += `<option value='${randomId}'>${fieldName}</option>`;
	} else {

		// Get display config
		const displayConfig = configGlobalConfiguration.get('display');
		const displayArray = displayConfig.user_schema.fields;
		let displayOnDashboard = false;
		for (let i = 0; i < displayArray.length; i += 1) {
			if (displayArray[i].id === name.id) {
				displayOnDashboard = true;
				break;
			}
		}

		// Set field content
		configUserSchema.fields.push({
			id: randomId,
			name: fieldName,
			slug: name.id,
			ref: name.ref,
			options: {
				display_on_dashboard: displayOnDashboard,
				type: name.type,
				choices: name.choices
			}
		});

		// Set new default option
		defaultSelectElement.innerHTML += `<option value='${name.id}'>${fieldName}</option>`;
	}

	// Update element heights
	updateElementHeights();
};

const addNewOrgParamRow = (name) => {

	// Get name
	let fieldName;
	if (name != null) fieldName = name.prompt;
	else {
		fieldName = 'New School Field';
		if (configGlobalConfiguration.get('general').config_type === 'organization') {
			fieldName = 'New Organization Field';
		}
	}

	// Get container element
	const container = document.getElementById('configuration-org-params-holder');

	// Get random id
	const randomId = generateRandomId();

	// Create new element
	const element = `<div class='config-field-row' name='config-org-param' id='config-org-param-${randomId}'>
        <div class='config-field-row-inner'><p
        class='contenteditable' contenteditable='true'
         id='config-org-content-${randomId}'>${fieldName}</p></div>
        <div class='remove-icon' id='config-org-remove-${randomId}'></div></div>`;

	// Append new element
	container.insertAdjacentHTML('beforeend', element);

	// Set new org field schema
	if (name == null) {

		// Set field content
		configOrgSchema.fields.push({
			id: randomId,
			name: fieldName,
			options: {
				display_on_dashboard: true,
				type: 'text'
			}
		});
	} else {

		// Get display config
		const displayConfig = configGlobalConfiguration.get('display');
		const displayArray = displayConfig.org_schema.fields;
		let displayOnDashboard = false;
		for (let i = 0; i < displayArray.length; i += 1) {
			if (displayArray[i].id === name.id) {
				displayOnDashboard = true;
				break;
			}
		}

		// Set field content
		configOrgSchema.fields.push({
			id: randomId,
			name: fieldName,
			slug: name.id,
			ref: name.ref,
			options: {
				display_on_dashboard: displayOnDashboard,
				type: name.type,
				choices: name.choices
			}
		});
	}

	// Update element heights
	updateElementHeights();
};

const selectConfigRow = (name, id) => {

	// Disable all items
	const elements = document.getElementsByName(name);
	for (let i = 0; i < elements.length; i += 1) {
		elements[i].className = 'config-field-row';
	}

	// Select item
	document.getElementById(id).className += ' selected';
};

const selectAssetTypeRow = (element) => {

	// Get elements
	const optionsContainer = document.getElementById('configuration-asset-options-container');
	const optionsHelperContainer = document.getElementById('configuration-asset-options-helper');
	const paramsContainer = document.getElementById('configuration-asset-params-container');
	const paramsHelperContainer = document.getElementById('configuration-asset-params-helper');
	const paramsHolder = document.getElementById('configuration-asset-params-holder');
	const assetTypeHolder = document.getElementById('configuration-asset-type-options-holder');
	const assetParamsHolder = document.getElementById('configuration-asset-params-options-holder');

	// Select option
	selectConfigRow(element.getAttribute('name'), element.id);

	// Clear field content
	paramsHolder.innerHTML = '';

	// Get asset type config
	let selectedAssetType;
	const selectedAssetId = element.id.replace('config-asset-type-', '');
	for (let i = 0; i < configAssetSchema.fields.length; i += 1) {
		if (configAssetSchema.fields[i].id === selectedAssetId) {
			selectedAssetType = configAssetSchema.fields[i];
			break;
		}
	}

	// Set fields
	for (let i = 0; i < selectedAssetType.fields.length; i += 1) {

		// Get field
		const field = selectedAssetType.fields[i];

		// Check if restricted
		let restricted = false;
		if (field.options.type === 'quantity' || field.options.type === 'price'
			|| (selectedAssetType.options.search_isbn === true
			&& (field.slug === 'title' || field.slug === 'authors'
				|| field.slug === 'categories' || field.slug === 'fountas_pinnell'
				|| field.slug === 'image'))) {
			restricted = true;
		}

		// Create remove icon
		const removeIcon = field.options.type === 'quantity' || field.options.type === 'price' ? '' : `<div class='remove-icon'
		id='config-asset-remove-${field.id}'></div>`;

		// Set if editable
		let editContent = '';
		let restrictedIcon = '';
		if (restricted === false) {
			editContent = `class='contenteditable' contenteditable='true' id='config-asset-content-${field.id}'`;
		} else {
			restrictedIcon = `<div class='restricted-icon ${removeIcon === '' ? 'static' : ''}'></div>`;
		}

		// Create new element
		const newElement = `<div class='config-field-row' name='config-asset-param' id='config-asset-param-${field.id}'>
            <div class='config-field-row-inner'><p
            ${editContent}>${field.name}</p></div>${removeIcon}${restrictedIcon}</div>`;

		// Append new element
		paramsHolder.insertAdjacentHTML('beforeend', newElement);
	}

	// Set options
	const { options } = selectedAssetType;
	inputModule.selectCheckmarkState('config-custom-a-t-1', 'config-a-t-1', options.search_isbn);
	inputModule.selectCheckmarkState('config-custom-a-t-2', 'config-a-t-2', options.creates_global);
	inputModule.selectCheckmarkState('config-custom-a-t-3', 'config-a-t-3', options.initial_state_expanded);
	inputModule.selectCheckmarkState('config-custom-a-t-4', 'config-a-t-4', options.track_check_in_out_status);
	inputModule.selectCheckmarkState('config-custom-a-t-5', 'config-a-t-5', options.track_stock_threshold);
	inputModule.selectCheckmarkState('config-custom-a-t-6', 'config-a-t-6', options.show_stock_level_widget);
	inputModule.selectCheckmarkState('config-custom-a-t-7', 'config-a-t-7', options.track_geolocation);
	$('#config-custom-a-t-7').val(options.custom_owner_field);

	// Hide helper element
	if (optionsHelperContainer.style.display !== 'none') optionsHelperContainer.style.display = 'none';
	if (paramsHelperContainer.style.display !== 'none') paramsHelperContainer.style.display = 'none';

	// Show options container
	if (optionsContainer.style.display === 'none') optionsContainer.style.display = 'block';
	if (paramsContainer.style.display === 'none') paramsContainer.style.display = 'block';

	// Update inner display
	assetTypeHolder.style.display = 'block';
	assetParamsHolder.style.display = 'none';

	// Update element heights
	updateElementHeights();
};

const selectAssetParamRow = (element) => {

	// Get elements
	const optionsContainer = document.getElementById('configuration-asset-options-container');
	const helperContainer = document.getElementById('configuration-asset-options-helper');
	const assetTypeHolder = document.getElementById('configuration-asset-type-options-holder');
	const assetParamsHolder = document.getElementById('configuration-asset-params-options-holder');

	// Get selected asset type
	const selectedAssetType = getSelectedAssetType();
	if (selectedAssetType != null) {

		// Select option
		selectConfigRow(element.getAttribute('name'), element.id);

		// Get asset field config
		let selectedField;
		const selectedFieldId = element.id.replace('config-asset-param-', '');
		for (let i = 0; i < selectedAssetType.fields.length; i += 1) {
			if (selectedAssetType.fields[i].id === selectedFieldId) {
				selectedField = selectedAssetType.fields[i];
				break;
			}
		}

		// Get elements
		const typeCheckbox = document.getElementById('config-custom-a-p-6');
		const defaultCheckbox = document.getElementById('config-custom-a-p-7');
		const searchPrimarySection = document.getElementById('config-section-a-p-9');
		const searchSecondarySection = document.getElementById('config-section-a-p-10');
		const searchImageSection = document.getElementById('config-section-a-p-11');
		const dropdownChoicesSection = document.getElementById('config-a-p-dd-section');
		const fieldTypeSection = document.getElementById('config-container-a-p-6');
		const defaultSection = document.getElementById('config-container-a-p-7');

		// Reset element display
		searchPrimarySection.style.display = 'none';
		searchSecondarySection.style.display = 'none';
		searchImageSection.style.display = 'none';
		dropdownChoicesSection.style.display = 'none';
		defaultSection.style.display = 'block';

		// Set options
		const { options } = selectedField;
		inputModule.selectCheckmarkState('config-custom-a-p-1', 'config-a-p-1', options.editable);
		inputModule.selectCheckmarkState('config-custom-a-p-2', 'config-a-p-2', options.creates_global);
		inputModule.selectCheckmarkState('config-custom-a-p-3', 'config-a-p-3', options.updates_global);
		inputModule.selectCheckmarkState('config-custom-a-p-4', 'config-a-p-4', options.required_to_create_global);
		inputModule.selectCheckmarkState('config-custom-a-p-5', 'config-a-p-5', options.display_on_dashboard);
		inputModule.selectCheckmarkState('config-custom-a-p-8', 'config-a-p-8', options.displayed_on_search);
		inputModule.selectCheckmarkState('config-custom-a-p-9', 'config-a-p-9', options.primary_search);
		inputModule.selectCheckmarkState('config-custom-a-p-10', 'config-a-p-10', options.secondary_search);
		inputModule.selectCheckmarkState('config-custom-a-p-11', 'config-a-p-11', options.is_detail_image);
		typeCheckbox.value = options.type;
		if (!options.default || options.default === '') defaultCheckbox.value = '';
		else defaultCheckbox.value = options.default;

		// Get elements
		const dropdownHolder = document.getElementById('configuration-asset-dropdown-holder');
		dropdownHolder.innerHTML = '';

		// Add choices to dropdown
		if (options.choices != null) {

			// Build choices
			for (let i = 0; i < options.choices.length; i += 1) {

				// Create new element
				const newElement = `<div class='config-field-row' name='config-asset-dropdown' id='config-asset-dropdown-${options.choices[i].id}'>
                    <div class='config-field-row-inner'><p class='contenteditable' contenteditable='true'
                    id='config-asset-dropdown-content-${options.choices[i].id}'>${options.choices[i].value}</p>
                    </div><div class='remove-icon' id='config-asset-dropdown-remove-${options.choices[i].id}'></div></div>`;

				// Append new element
				dropdownHolder.insertAdjacentHTML('beforeend', newElement);
			}
		}

		// Update display
		if (options.displayed_on_search === true) {
			if (options.type === 'image') {
				searchImageSection.style.display = 'block';
				searchPrimarySection.style.display = 'none';
				searchSecondarySection.style.display = 'none';
			} else {
				searchPrimarySection.style.display = 'block';
				searchSecondarySection.style.display = 'block';
			}
		}
		if (options.type === 'choice') {
			dropdownChoicesSection.style.display = 'block';
			defaultSection.style.display = 'none';
		}

		// Get elements
		const editableCheck = document.getElementById('config-custom-a-p-1');
		const createGlobalCheck = document.getElementById('config-custom-a-p-2');
		const updatesGlobalCheck = document.getElementById('config-custom-a-p-3');
		const requiredGlobalCheck = document.getElementById('config-custom-a-p-4');
		const editableLabel = document.getElementById('config-label-a-p-1');
		const createGlobalLabel = document.getElementById('config-label-a-p-2');
		const updatesGlobalLabel = document.getElementById('config-label-a-p-3');
		const requiredGlobalLabel = document.getElementById('config-label-a-p-4');
		const typeSelect = document.getElementById('config-custom-a-p-6');
		const defaultSelect = document.getElementById('config-custom-a-p-7');
		const typeSelectLabel = document.getElementById('config-label-a-p-6');
		const defaultSelectLabel = document.getElementById('config-label-a-p-7');

		// Update element display if restricted
		if (selectedAssetType.options.search_isbn === true) {

			// Update display
			if (createGlobalCheck.className.indexOf('inactive') === -1) {
				createGlobalCheck.className += ' inactive';
			}
			if (updatesGlobalCheck.className.indexOf('inactive') === -1) {
				updatesGlobalCheck.className += ' inactive';
			}
			if (requiredGlobalCheck.className.indexOf('inactive') === -1) {
				requiredGlobalCheck.className += ' inactive';
			}

			// Update labels
			createGlobalLabel.style.opacity = 0.5;
			updatesGlobalLabel.style.opacity = 0.5;
			requiredGlobalLabel.style.opacity = 0.5;

			// Update display
			if (selectedField.slug === 'title' || selectedField.slug === 'authors'
				|| selectedField.slug === 'categories' || selectedField.slug === 'fountas_pinnell'
				|| selectedField.slug === 'image') {
				if (typeSelect.className.indexOf('inactive') === -1) {
					typeSelect.className += ' inactive';
				}
				if (defaultSelect.className.indexOf('inactive') === -1) {
					defaultSelect.className += ' inactive';
				}

				// Update labels
				typeSelectLabel.style.opacity = 0.5;
				defaultSelectLabel.style.opacity = 0.5;
			} else {
				if (typeSelect.className.indexOf('inactive') > -1) {
					typeSelect.className = typeSelect.className.replace(' inactive', '');
				}
				if (defaultSelect.className.indexOf('inactive') > -1) {
					defaultSelect.className = defaultSelect.className.replace(' inactive', '');
				}

				// Update labels
				defaultSelectLabel.style.opacity = 1.0;
				typeSelectLabel.style.opacity = 1.0;
			}
		} else {

			// Update display
			if (createGlobalCheck.className.indexOf('inactive') > -1) {
				createGlobalCheck.className = createGlobalCheck.className.replace(' inactive', '');
			}
			if (updatesGlobalCheck.className.indexOf('inactive') > -1) {
				updatesGlobalCheck.className = updatesGlobalCheck.className.replace(' inactive', '');
			}
			if (requiredGlobalCheck.className.indexOf('inactive') > -1) {
				requiredGlobalCheck.className = requiredGlobalCheck.className.replace(' inactive', '');
			}
			if (typeSelect.className.indexOf('inactive') > -1) {
				typeSelect.className = typeSelect.className.replace(' inactive', '');
			}
			if (defaultSelect.className.indexOf('inactive') > -1) {
				defaultSelect.className = defaultSelect.className.replace(' inactive', '');
			}

			// Update labels
			updatesGlobalLabel.style.opacity = 1.0;
			requiredGlobalLabel.style.opacity = 1.0;
			createGlobalLabel.style.opacity = 1.0;
			typeSelectLabel.style.opacity = 1.0;
			defaultSelectLabel.style.opacity = 1.0;
		}

		if (selectedField.options.type === 'quantity' || selectedField.options.type === 'price') {

			// Update display
			if (editableCheck.className.indexOf('inactive') === -1) {
				editableCheck.className += ' inactive';
			}
			if (requiredGlobalCheck.className.indexOf('inactive') === -1) {
				requiredGlobalCheck.className += ' inactive';
			}
			fieldTypeSection.style.display = 'none';
			defaultSection.style.display = 'none';

			// Update labels
			editableLabel.style.opacity = 0.5;
			requiredGlobalLabel.style.opacity = 0.5;

		} else {

			// Update display
			if (editableCheck.className.indexOf('inactive') > -1) {
				editableCheck.className = editableCheck.className.replace(' inactive', '');
			}
			if (requiredGlobalCheck.className.indexOf('inactive') > -1) {
				requiredGlobalCheck.className = requiredGlobalCheck.className.replace(' inactive', '');
			}
			fieldTypeSection.style.display = 'block';
			defaultSection.style.display = 'block';

			// Update labels
			editableLabel.style.opacity = 1.0;
			requiredGlobalLabel.style.opacity = 1.0;
		}

		// Hide helper element
		if (helperContainer.style.display !== 'none') helperContainer.style.display = 'none';

		// Show options container
		if (optionsContainer.style.display === 'none') optionsContainer.style.display = 'block';

		// Update inner display
		assetTypeHolder.style.display = 'none';
		assetParamsHolder.style.display = 'block';
	}

	// Update element heights
	updateElementHeights();
};

const selectPersonnelTypeRow = (element) => {

	// Get elements
	const optionsContainer = document.getElementById('configuration-personnel-options-container');
	const optionsHelperContainer = document.getElementById('configuration-personnel-options-helper');
	const paramsContainer = document.getElementById('configuration-personnel-params-container');
	const paramsHelperContainer = document.getElementById('configuration-personnel-params-helper');
	const paramsHolder = document.getElementById('configuration-personnel-params-holder');
	const personnelTypeHolder = document.getElementById('configuration-personnel-type-options-holder');
	const personnelParamsHolder = document.getElementById('configuration-personnel-params-options-holder');

	// Select option
	selectConfigRow(element.getAttribute('name'), element.id);

	// Clear field content
	paramsHolder.innerHTML = '';

	// Get personnel type config
	let selectedPersonnelType;
	const selectedPersonnelId = element.id.replace('config-personnel-type-', '');
	for (let i = 0; i < configPersonnelSchema.fields.length; i += 1) {
		if (configPersonnelSchema.fields[i].id === selectedPersonnelId) {
			selectedPersonnelType = configPersonnelSchema.fields[i];
			break;
		}
	}

	// Set fields
	for (let i = 0; i < selectedPersonnelType.fields.length; i += 1) {

		// Get field
		const field = selectedPersonnelType.fields[i];

		// Check if restricted
		const restricted = false;

		// Set if editable
		let editContent = '';
		let restrictedIcon = '';
		if (restricted === false) {
			editContent = `class='contenteditable' contenteditable='true' id='config-personnel-content-${field.id}'`;
		} else {
			restrictedIcon = "<div class='restricted-icon'></div>";
		}

		// Create new element
		const newElement = `<div class='config-field-row' name='config-personnel-param' id='config-personnel-param-${field.id}'>
            <div class='config-field-row-inner'><p
            ${editContent}>${field.name}</p></div><div class='remove-icon'
            id='config-personnel-remove-${field.id}'></div>${restrictedIcon}</div>`;

		// Append new element
		paramsHolder.insertAdjacentHTML('beforeend', newElement);
	}

	// Set options
	// const { options } = selectedPersonnelType;

	// Hide helper element
	if (optionsHelperContainer.style.display !== 'none') optionsHelperContainer.style.display = 'none';
	if (paramsHelperContainer.style.display !== 'none') paramsHelperContainer.style.display = 'none';

	// Show options container
	if (optionsContainer.style.display === 'none') optionsContainer.style.display = 'block';
	if (paramsContainer.style.display === 'none') paramsContainer.style.display = 'block';

	// Update inner display
	personnelTypeHolder.style.display = 'block';
	personnelParamsHolder.style.display = 'none';

	// Update element heights
	updateElementHeights();
};

const selectPersonnelParamRow = (element) => {

	// Get elements
	const optionsContainer = document.getElementById('configuration-personnel-options-container');
	const helperContainer = document.getElementById('configuration-personnel-options-helper');
	const personnelTypeHolder = document.getElementById('configuration-personnel-type-options-holder');
	const personnelParamsHolder = document.getElementById('configuration-personnel-params-options-holder');

	// Get selected personnel type
	const selectedPersonnelType = getSelectedPersonnelType();
	if (selectedPersonnelType != null) {

		// Select option
		selectConfigRow(element.getAttribute('name'), element.id);

		// Get personnel field config
		let selectedField;
		const selectedFieldId = element.id.replace('config-personnel-param-', '');
		for (let i = 0; i < selectedPersonnelType.fields.length; i += 1) {
			if (selectedPersonnelType.fields[i].id === selectedFieldId) {
				selectedField = selectedPersonnelType.fields[i];
				break;
			}
		}

		// Get elements
		const typeCheckbox = document.getElementById('config-custom-p-p-6');
		const defaultCheckbox = document.getElementById('config-custom-p-p-7');
		const dropdownChoicesSection = document.getElementById('config-p-p-dd-section');
		const defaultSection = document.getElementById('config-p-default-section');

		// Reset element display
		dropdownChoicesSection.style.display = 'none';
		defaultSection.style.display = 'block';

		// Set options
		const { options } = selectedField;
		inputModule.selectCheckmarkState('config-custom-p-p-1', 'config-p-p-1', options.editable);
		inputModule.selectCheckmarkState('config-custom-p-p-5', 'config-p-p-5', options.display_on_dashboard);
		typeCheckbox.value = options.type;
		if (!options.default || options.default === '') defaultCheckbox.value = '';
		else defaultCheckbox.value = options.default;

		// Get elements
		const dropdownHolder = document.getElementById('configuration-personnel-dropdown-holder');
		dropdownHolder.innerHTML = '';

		// Add choices to dropdown
		if (options.choices != null) {

			// Build choices
			for (let i = 0; i < options.choices.length; i += 1) {

				// Create new element
				const newElement = `<div class='config-field-row' name='config-personnel-dropdown' id='config-personnel-dropdown-${options.choices[i].id}'>
                    <div class='config-field-row-inner'><p class='contenteditable' contenteditable='true'
                    id='config-personnel-dropdown-content-${options.choices[i].id}'>${options.choices[i].value}</p>
                    </div><div class='remove-icon' id='config-personnel-dropdown-remove-${options.choices[i].id}'></div></div>`;

				// Append new element
				dropdownHolder.insertAdjacentHTML('beforeend', newElement);
			}
		}

		// Update display
		if (options.type === 'choice') {
			dropdownChoicesSection.style.display = 'block';
			defaultSection.style.display = 'none';
		}

		// Get elements
		const typeSelect = document.getElementById('config-custom-p-p-6');
		const defaultSelect = document.getElementById('config-custom-p-p-7');
		const typeSelectLabel = document.getElementById('config-label-p-p-6');
		const defaultSelectLabel = document.getElementById('config-label-p-p-7');

		// Update display
		if (typeSelect.className.indexOf('inactive') > -1) {
			typeSelect.className = typeSelect.className.replace(' inactive', '');
		}
		if (defaultSelect.className.indexOf('inactive') > -1) {
			defaultSelect.className = defaultSelect.className.replace(' inactive', '');
		}

		// Update labels
		typeSelectLabel.style.opacity = 1.0;
		defaultSelectLabel.style.opacity = 1.0;

		// Hide helper element
		if (helperContainer.style.display !== 'none') helperContainer.style.display = 'none';

		// Show options container
		if (optionsContainer.style.display === 'none') optionsContainer.style.display = 'block';

		// Update inner display
		personnelTypeHolder.style.display = 'none';
		personnelParamsHolder.style.display = 'block';
	}

	// Update element heights
	updateElementHeights();
};

const selectUserParamRow = (element) => {

	// Get elements
	const optionsContainer = document.getElementById('configuration-user-options-container');
	const helperContainer = document.getElementById('configuration-user-options-helper');
	const dropdownChoicesSection = document.getElementById('config-u-p-dd-section');

	// Select option
	selectConfigRow(element.getAttribute('name'), element.id);

	// Get user field config
	let selectedField;
	const selectedFieldId = element.id.replace('config-user-param-', '');
	for (let i = 0; i < configUserSchema.fields.length; i += 1) {
		if (configUserSchema.fields[i].id === selectedFieldId) {
			selectedField = configUserSchema.fields[i];
			break;
		}
	}

	// Set options
	const { options } = selectedField;
	inputModule.selectCheckmarkState('config-custom-u-p-1', 'config-u-p-1', options.display_on_dashboard);
	document.getElementById('config-custom-u-p-2').value = options.type;

	// Get elements
	const dropdownHolder = document.getElementById('configuration-profile-dropdown-holder');
	dropdownHolder.innerHTML = '';

	// Add choices to dropdown
	if (options.choices != null) {

		// Build choices
		for (let i = 0; i < options.choices.length; i += 1) {

			// Create new element
			const newElement = `<div class='config-field-row' name='config-profile-dropdown' id='config-profile-dropdown-${options.choices[i].id}'>
                <div class='config-field-row-inner'><p class='contenteditable' contenteditable='true'
                id='config-profile-dropdown-content-${options.choices[i].id}'>${options.choices[i].value}</p>
                </div><div class='remove-icon' id='config-profile-dropdown-remove-${options.choices[i].id}'></div></div>`;

			// Append new element
			dropdownHolder.insertAdjacentHTML('beforeend', newElement);
		}
	}

	// Reset element display
	dropdownChoicesSection.style.display = 'none';

	// Update display
	if (options.type === 'choice') {
		dropdownChoicesSection.style.display = 'block';
	}

	// Hide helper element
	if (helperContainer.style.display !== 'none') helperContainer.style.display = 'none';

	// Show options container
	if (optionsContainer.style.display === 'none') optionsContainer.style.display = 'block';

	// Update element heights
	updateElementHeights();
};

const selectOrgParamRow = (element) => {

	// Get elements
	const optionsContainer = document.getElementById('configuration-org-options-container');
	const helperContainer = document.getElementById('configuration-org-options-helper');
	const dropdownChoicesSection = document.getElementById('config-o-p-dd-section');

	// Select option
	selectConfigRow(element.getAttribute('name'), element.id);

	// Get user field config
	let selectedField;
	const selectedFieldId = element.id.replace('config-org-param-', '');
	for (let i = 0; i < configOrgSchema.fields.length; i += 1) {
		if (configOrgSchema.fields[i].id === selectedFieldId) {
			selectedField = configOrgSchema.fields[i];
			break;
		}
	}

	// Set options
	const { options } = selectedField;
	inputModule.selectCheckmarkState('config-custom-o-p-1', 'config-o-p-1', options.display_on_dashboard);
	document.getElementById('config-custom-o-p-2').value = options.type;

	// Get elements
	const dropdownHolder = document.getElementById('configuration-org-dropdown-holder');
	dropdownHolder.innerHTML = '';

	// Add choices to dropdown
	if (options.choices != null) {

		// Build choices
		for (let i = 0; i < options.choices.length; i += 1) {

			// Create new element
			const newElement = `<div class='config-field-row' name='config-org-dropdown' id='config-org-dropdown-${options.choices[i].id}'>
                <div class='config-field-row-inner'><p class='contenteditable' contenteditable='true'
                id='config-org-dropdown-content-${options.choices[i].id}'>${options.choices[i].value}</p>
                </div><div class='remove-icon' id='config-org-dropdown-remove-${options.choices[i].id}'></div></div>`;

			// Append new element
			dropdownHolder.insertAdjacentHTML('beforeend', newElement);
		}
	}

	// Reset element display
	dropdownChoicesSection.style.display = 'none';

	// Update display
	if (options.type === 'choice') {
		dropdownChoicesSection.style.display = 'block';
	}

	// Hide helper element
	if (helperContainer.style.display !== 'none') helperContainer.style.display = 'none';

	// Show options container
	if (optionsContainer.style.display === 'none') optionsContainer.style.display = 'block';

	// Update element heights
	updateElementHeights();
};

const rowTitleChange = (elem) => {
	const element = elem;

	// Get default select element
	const defaultSelectElement = document.getElementById('config-custom-a-p-7');

	// Ensure row always has content
	if (element.innerHTML === '') {
		element.innerHTML += ' ';
	}

	// Get change family
	let selectedField;
	if (element.id.indexOf('config-asset-type-content-') > -1) {

		// Set content
		const selectedAssetId = element.id.replace('config-asset-type-content-', '');
		for (let i = 0; i < configAssetSchema.fields.length; i += 1) {
			if (configAssetSchema.fields[i].id === selectedAssetId) {
				configAssetSchema.fields[i].name = element.innerHTML;
				break;
			}
		}
	} else if (element.id.indexOf('config-asset-content-') > -1) {

		// Get selected asset type
		const selectedAssetType = getSelectedAssetType();

		// Set content
		if (selectedAssetType != null) {
			const selectedFieldId = element.id.replace('config-asset-content-', '');
			for (let i = 0; i < selectedAssetType.fields.length; i += 1) {
				if (selectedAssetType.fields[i].id === selectedFieldId) {
					selectedAssetType.fields[i].name = element.innerHTML;
					break;
				}
			}
		}
	} else if (element.id.indexOf('config-personnel-type-content-') > -1) {

		// Set content
		const selectedPersonnelId = element.id.replace('config-personnel-type-content-', '');
		for (let i = 0; i < configPersonnelSchema.fields.length; i += 1) {
			if (configPersonnelSchema.fields[i].id === selectedPersonnelId) {
				configPersonnelSchema.fields[i].name = element.innerHTML;
				break;
			}
		}
	} else if (element.id.indexOf('config-personnel-content-') > -1) {

		// Get selected personnel type
		const selectedPersonnelType = getSelectedPersonnelType();

		// Set content
		if (selectedPersonnelType != null) {
			const selectedFieldId = element.id.replace('config-personnel-content-', '');
			for (let i = 0; i < selectedPersonnelType.fields.length; i += 1) {
				if (selectedPersonnelType.fields[i].id === selectedFieldId) {
					selectedPersonnelType.fields[i].name = element.innerHTML;
					break;
				}
			}
		}
	} else if (element.id.indexOf('config-profile-content-') > -1) {

		// Set content
		const selectedFieldId = element.id.replace('config-profile-content-', '');
		for (let i = 0; i < configUserSchema.fields.length; i += 1) {
			if (configUserSchema.fields[i].id === selectedFieldId) {
				configUserSchema.fields[i].name = element.innerHTML;
				selectedField = configUserSchema.fields[i];
				break;
			}
		}

		// Update default select element
		if (selectedField != null) {
			for (let i = 0; i < defaultSelectElement.childNodes.length; i += 1) {
				if (defaultSelectElement.childNodes[i] && defaultSelectElement.childNodes[i].value) {
					const childVal = defaultSelectElement.childNodes[i].value;
					if (childVal === selectedField.slug || childVal === selectedField.id) {
						defaultSelectElement.childNodes[i].innerHTML = element.innerHTML;
						break;
					}
				}
			}
		}
	} else if (element.id.indexOf('config-org-content-') > -1) {

		// Set content
		const selectedFieldId = element.id.replace('config-org-content-', '');
		for (let i = 0; i < configOrgSchema.fields.length; i += 1) {
			if (configOrgSchema.fields[i].id === selectedFieldId) {
				configOrgSchema.fields[i].name = element.innerHTML;
				break;
			}
		}
	} else if (element.id.indexOf('config-asset-dropdown-content-') > -1) {

		// Get selected asset parameter
		const selectedAssetParam = getSelectedAssetParam();

		// Set content
		const selectedDropdownId = element.id.replace('config-asset-dropdown-content-', '');
		if (selectedAssetParam.options.choices != null) {
			for (let i = 0; i < selectedAssetParam.options.choices.length; i += 1) {
				if (selectedAssetParam.options.choices[i].id === selectedDropdownId) {
					selectedAssetParam.options.choices[i].value = element.innerHTML;
					break;
				}
			}
		}
	} else if (element.id.indexOf('config-personnel-dropdown-content-') > -1) {

		// Get selected personnel parameter
		const selectedPersonnelParam = getSelectedPersonnelParam();

		// Set content
		const selectedDropdownId = element.id.replace('config-personnel-dropdown-content-', '');
		if (selectedPersonnelParam.options.choices != null) {
			for (let i = 0; i < selectedPersonnelParam.options.choices.length; i += 1) {
				if (selectedPersonnelParam.options.choices[i].id === selectedDropdownId) {
					selectedPersonnelParam.options.choices[i].value = element.innerHTML;
					break;
				}
			}
		}
	} else if (element.id.indexOf('config-profile-dropdown-content-') > -1) {

		// Get selected user parameter
		const selectedUserParam = getSelectedUserParam();

		// Set content
		const selectedDropdownId = element.id.replace('config-profile-dropdown-content-', '');
		if (selectedUserParam.options.choices != null) {
			for (let i = 0; i < selectedUserParam.options.choices.length; i += 1) {
				if (selectedUserParam.options.choices[i].id === selectedDropdownId) {
					selectedUserParam.options.choices[i].value = element.innerHTML;
					break;
				}
			}
		}
	} else if (element.id.indexOf('config-org-dropdown-content-') > -1) {

		// Get selected org parameter
		const selectedOrgParam = getSelectedOrgParam();

		// Set content
		const selectedDropdownId = element.id.replace('config-org-dropdown-content-', '');
		if (selectedOrgParam.options.choices != null) {
			for (let i = 0; i < selectedOrgParam.options.choices.length; i += 1) {
				if (selectedOrgParam.options.choices[i].id === selectedDropdownId) {
					selectedOrgParam.options.choices[i].value = element.innerHTML;
					break;
				}
			}
		}
	}

	// Set timeout for placeholder
	setTimeout(() => {
		if (element.innerHTML.trim() === '') {
			if (element.id.indexOf('config-asset-type-content-') > -1) {
				element.innerHTML = 'New Asset Type';
			} else if (element.id.indexOf('config-asset-content-') > -1) {
				element.innerHTML = 'New Asset Field';
			} else if (element.id.indexOf('config-personnel-type-content-') > -1) {
				element.innerHTML = 'New Group';
			} else if (element.id.indexOf('config-personnel-content-') > -1) {
				element.innerHTML = 'New Group Field';
			} else if (element.id.indexOf('config-profile-content-') > -1) {
				element.innerHTML = 'New Profile Field';

				// Update default select element
				if (selectedField != null) {
					for (let i = 0; i < defaultSelectElement.childNodes.length; i += 1) {
						if (defaultSelectElement.childNodes[i] && defaultSelectElement.childNodes[i].value) {
							const childVal = defaultSelectElement.childNodes[i].value;
							if (childVal === selectedField.slug || childVal === selectedField.id) {
								defaultSelectElement.childNodes[i].innerHTML = element.innerHTML;
								break;
							}
						}
					}
				}
			} else if (element.id.indexOf('config-org-content-') > -1) {
				element.innerHTML = 'New School Field';
			} else if (element.id.indexOf('config-asset-dropdown-content-') > -1) {
				element.innerHTML = 'New Option';
			} else if (element.id.indexOf('config-personnel-dropdown-content-') > -1) {
				element.innerHTML = 'New Option';
			} else if (element.id.indexOf('config-profile-dropdown-content-') > -1) {
				element.innerHTML = 'New Option';
			} else if (element.id.indexOf('config-org-dropdown-content-') > -1) {
				element.innerHTML = 'New Option';
			}
		}
	}, 1000);
};

const rowTitleBlur = (elem) => {
	const element = elem;

	// Check content
	if (element.innerHTML.trim() !== '') {

		// Edit title content
		rowTitleChange(element);

	} else if (element.id.indexOf('config-asset-type-content-') > -1) { // Get change family
		element.innerHTML = 'New Asset Type';
	} else if (element.id.indexOf('config-asset-content-') > -1) {
		element.innerHTML = 'New Asset Field';
	} else if (element.id.indexOf('config-personnel-type-content-') > -1) { // Get change family
		element.innerHTML = 'New Group';
	} else if (element.id.indexOf('config-personnel-content-') > -1) {
		element.innerHTML = 'New Group Field';
	} else if (element.id.indexOf('config-profile-content-') > -1) {
		element.innerHTML = 'New Profile Field';

		// Get default select element
		const defaultSelectElement = document.getElementById('config-custom-a-p-7');

		// Set content
		let selectedField;
		const selectedFieldId = element.id.replace('config-profile-content-', '');
		for (let i = 0; i < configUserSchema.fields.length; i += 1) {
			if (configUserSchema.fields[i].id === selectedFieldId) {
				configUserSchema.fields[i].name = element.innerHTML;
				selectedField = configUserSchema.fields[i];
				break;
			}
		}

		// Update default select element
		if (selectedField != null) {
			for (let i = 0; i < defaultSelectElement.childNodes.length; i += 1) {
				if (defaultSelectElement.childNodes[i] && defaultSelectElement.childNodes[i].value) {
					const childVal = defaultSelectElement.childNodes[i].value;
					if (childVal === selectedField.slug || childVal === selectedField.id) {
						defaultSelectElement.childNodes[i].innerHTML = element.innerHTML;
						break;
					}
				}
			}
		}
	} else if (element.id.indexOf('config-org-content-') > -1) {
		element.innerHTML = 'New School Field';
	} else if (element.id.indexOf('config-asset-dropdown-content-') > -1) {
		element.innerHTML = 'New Option';
	} else if (element.id.indexOf('config-personnel-dropdown-content-') > -1) {
		element.innerHTML = 'New Option';
	} else if (element.id.indexOf('config-profile-dropdown-content-') > -1) {
		element.innerHTML = 'New Option';
	} else if (element.id.indexOf('config-org-dropdown-content-') > -1) {
		element.innerHTML = 'New Option';
	}
};

const handleRemoveRow = (id, fromDropdown) => {

	// Get change family
	if (id.indexOf('config-asset-type-remove-') > -1) {

		// Remove content
		const selectedAssetId = id.replace('config-asset-type-remove-', '');
		for (let i = 0; i < configAssetSchema.fields.length; i += 1) {
			if (configAssetSchema.fields[i].id === selectedAssetId) {
				configAssetSchema.fields.splice(i, 1);
				break;
			}
		}

		// Get elements
		const optionsContainer = document.getElementById('configuration-asset-options-container');
		const optionsHelperContainer = document.getElementById('configuration-asset-options-helper');
		const paramsContainer = document.getElementById('configuration-asset-params-container');
		const paramsHelperContainer = document.getElementById('configuration-asset-params-helper');
		const rowToRemove = document.getElementById(`config-asset-type-${selectedAssetId}`);

		// Update display
		rowToRemove.outerHTML = '';
		optionsContainer.style.display = 'none';
		paramsContainer.style.display = 'none';
		optionsHelperContainer.style.display = 'flex';
		paramsHelperContainer.style.display = 'flex';

	} else if (id.indexOf('config-asset-remove-') > -1) {

		// Get selected asset type
		const selectedAssetType = getSelectedAssetType();

		// Remove content
		const selectedFieldId = id.replace('config-asset-remove-', '');
		if (selectedAssetType != null) {
			for (let i = 0; i < selectedAssetType.fields.length; i += 1) {
				if (selectedAssetType.fields[i].id === selectedFieldId) {
					selectedAssetType.fields.splice(i, 1);
					break;
				}
			}
		}

		// Get elements
		const optionsContainer = document.getElementById('configuration-asset-options-container');
		const optionsHelperContainer = document.getElementById('configuration-asset-options-helper');
		const rowToRemove = document.getElementById(`config-asset-param-${selectedFieldId}`);

		// Remove row
		rowToRemove.outerHTML = '';
		optionsContainer.style.display = 'none';
		optionsHelperContainer.style.display = 'flex';

	} else if (id.indexOf('config-personnel-type-remove-') > -1) {

		// Remove content
		const selectedPersonnelId = id.replace('config-personnel-type-remove-', '');
		for (let i = 0; i < configPersonnelSchema.fields.length; i += 1) {
			if (configPersonnelSchema.fields[i].id === selectedPersonnelId) {
				configPersonnelSchema.fields.splice(i, 1);
				break;
			}
		}

		// Get elements
		const optionsContainer = document.getElementById('configuration-personnel-options-container');
		const optionsHelperContainer = document.getElementById('configuration-personnel-options-helper');
		const paramsContainer = document.getElementById('configuration-personnel-params-container');
		const paramsHelperContainer = document.getElementById('configuration-personnel-params-helper');
		const rowToRemove = document.getElementById(`config-personnel-type-${selectedPersonnelId}`);

		// Update display
		rowToRemove.outerHTML = '';
		optionsContainer.style.display = 'none';
		paramsContainer.style.display = 'none';
		optionsHelperContainer.style.display = 'flex';
		paramsHelperContainer.style.display = 'flex';

	} else if (id.indexOf('config-personnel-remove-') > -1) {

		// Get selected personnel type
		const selectedPersonnelType = getSelectedPersonnelType();

		// Remove content
		const selectedFieldId = id.replace('config-personnel-remove-', '');
		if (selectedPersonnelType != null) {
			for (let i = 0; i < selectedPersonnelType.fields.length; i += 1) {
				if (selectedPersonnelType.fields[i].id === selectedFieldId) {
					selectedPersonnelType.fields.splice(i, 1);
					break;
				}
			}
		}

		// Get elements
		const optionsContainer = document.getElementById('configuration-personnel-options-container');
		const optionsHelperContainer = document.getElementById('configuration-personnel-options-helper');
		const rowToRemove = document.getElementById(`config-personnel-param-${selectedFieldId}`);

		// Remove row
		rowToRemove.outerHTML = '';
		optionsContainer.style.display = 'none';
		optionsHelperContainer.style.display = 'flex';

	} else if (id.indexOf('config-profile-remove-') > -1) {

		// Remove content
		const selectedFieldId = id.replace('config-profile-remove-', '');
		let selectedField;
		for (let i = 0; i < configUserSchema.fields.length; i += 1) {
			if (configUserSchema.fields[i].id === selectedFieldId) {
				selectedField = configUserSchema.fields[i];
				configUserSchema.fields.splice(i, 1);
				break;
			}
		}

		// Get elements
		const optionsContainer = document.getElementById('configuration-user-options-container');
		const helperContainer = document.getElementById('configuration-user-options-helper');
		const rowToRemove = document.getElementById(`config-user-param-${selectedFieldId}`);
		const defaultSelectElement = document.getElementById('config-custom-a-p-7');

		// Update display
		rowToRemove.outerHTML = '';
		optionsContainer.style.display = 'none';
		helperContainer.style.display = 'flex';

		// Update default select element
		for (let i = 0; i < defaultSelectElement.childNodes.length; i += 1) {
			if (defaultSelectElement.childNodes[i] && defaultSelectElement.childNodes[i].value) {
				const childVal = defaultSelectElement.childNodes[i].value;
				if (childVal === selectedField.slug || childVal === selectedField.id) {
					defaultSelectElement.childNodes[i].outerHTML = '';
					break;
				}
			}
		}
	} else if (id.indexOf('config-org-remove-') > -1) {

		// Remove content
		const selectedFieldId = id.replace('config-org-remove-', '');
		for (let i = 0; i < configOrgSchema.fields.length; i += 1) {
			if (configOrgSchema.fields[i].id === selectedFieldId) {
				configOrgSchema.fields.splice(i, 1);
				break;
			}
		}

		// Get elements
		const optionsContainer = document.getElementById('configuration-org-options-container');
		const helperContainer = document.getElementById('configuration-org-options-helper');
		const rowToRemove = document.getElementById(`config-org-param-${selectedFieldId}`);

		// Update display
		rowToRemove.outerHTML = '';
		optionsContainer.style.display = 'none';
		helperContainer.style.display = 'flex';

	} else if (id.indexOf('config-asset-dropdown-remove-') > -1) {

		// Get selected asset parameter
		const selectedAssetParam = getSelectedAssetParam();

		// Remove content
		const selectedFieldId = id.replace('config-asset-dropdown-remove-', '');
		for (let i = 0; i < selectedAssetParam.options.choices.length; i += 1) {
			if (selectedAssetParam.options.choices[i].id === selectedFieldId) {
				selectedAssetParam.options.choices.splice(i, 1);
				break;
			}
		}

		// Get elements
		const rowToRemove = document.getElementById(`config-asset-dropdown-${selectedFieldId}`);

		// Update display
		rowToRemove.outerHTML = '';

	} else if (id.indexOf('config-personnel-dropdown-remove-') > -1) {

		// Get selected personnel parameter
		const selectedPersonnelParam = getSelectedPersonnelParam();

		// Remove content
		const selectedFieldId = id.replace('config-personnel-dropdown-remove-', '');
		for (let i = 0; i < selectedPersonnelParam.options.choices.length; i += 1) {
			if (selectedPersonnelParam.options.choices[i].id === selectedFieldId) {
				selectedPersonnelParam.options.choices.splice(i, 1);
				break;
			}
		}

		// Get elements
		const rowToRemove = document.getElementById(`config-personnel-dropdown-${selectedFieldId}`);

		// Update display
		rowToRemove.outerHTML = '';

	} else if (id.indexOf('config-profile-dropdown-remove-') > -1) {

		// Get selected user parameter
		const selectedUserParam = getSelectedUserParam();

		// Remove content
		const selectedFieldId = id.replace('config-profile-dropdown-remove-', '');
		for (let i = 0; i < selectedUserParam.options.choices.length; i += 1) {
			if (selectedUserParam.options.choices[i].id === selectedFieldId) {
				selectedUserParam.options.choices.splice(i, 1);
				break;
			}
		}

		// Get elements
		const rowToRemove = document.getElementById(`config-profile-dropdown-${selectedFieldId}`);

		// Update display
		rowToRemove.outerHTML = '';

	} else if (id.indexOf('config-org-dropdown-remove-') > -1) {

		// Get selected org parameter
		const selectedOrgParam = getSelectedOrgParam();

		// Remove content
		const selectedFieldId = id.replace('config-org-dropdown-remove-', '');
		for (let i = 0; i < selectedOrgParam.options.choices.length; i += 1) {
			if (selectedOrgParam.options.choices[i].id === selectedFieldId) {
				selectedOrgParam.options.choices.splice(i, 1);
				break;
			}
		}

		// Get elements
		const rowToRemove = document.getElementById(`config-org-dropdown-${selectedFieldId}`);

		// Update display
		rowToRemove.outerHTML = '';
	}

	// Update display
	if (fromDropdown === false) {

		// Hide modal
		inputModule.hideModalWithId('configuration-remove-row-confirmation');
	}

	// Update element heights
	updateElementHeights();
};

const rowRemovePrompt = (element) => {

	// Get elements
	const button = document.getElementById('configuration-remove-row-button');
	const title = document.getElementById('configuration-remove-row-title');
	const subtitle = document.getElementById('configuration-remove-row-subtitle');

	// Set content
	button.onclick = function () { handleRemoveRow(element.id, false); };
	if (element.id.indexOf('config-asset-type-remove-') > -1) {
		title.innerHTML = 'Remove Asset Type?';
		subtitle.innerHTML = 'Are you sure you want to remove this asset type?';
	} else if (element.id.indexOf('config-asset-remove-') > -1) {
		title.innerHTML = 'Remove Asset Field?';
		subtitle.innerHTML = 'Are you sure you want to remove this asset field?';
	} else if (element.id.indexOf('config-personnel-type-remove-') > -1) {
		title.innerHTML = 'Remove Group?';
		subtitle.innerHTML = 'Are you sure you want to remove this group?';
	} else if (element.id.indexOf('config-personnel-remove-') > -1) {
		title.innerHTML = 'Remove Group Field?';
		subtitle.innerHTML = 'Are you sure you want to remove this group field?';
	} else if (element.id.indexOf('config-profile-remove-') > -1) {
		title.innerHTML = 'Remove Profile Field?';
		subtitle.innerHTML = 'Are you sure you want to remove this user profile field?';
	} else if (element.id.indexOf('config-org-remove-') > -1) {
		title.innerHTML = 'Remove Profile Field?';
		subtitle.innerHTML = 'Are you sure you want to remove this organization profile field?';
	} else if (element.id.indexOf('config-asset-dropdown-remove-') > -1) {
		title.innerHTML = 'Remove Dropdown Option?';
		subtitle.innerHTML = 'Are you sure you want to remove this dropdown option? This may impact your existing library.';
	} else if (element.id.indexOf('config-personnel-dropdown-remove-') > -1) {
		title.innerHTML = 'Remove Dropdown Option?';
		subtitle.innerHTML = 'Are you sure you want to remove this dropdown option? This may impact your existing personnel.';
	} else if (element.id.indexOf('config-profile-dropdown-remove-') > -1) {
		title.innerHTML = 'Remove Dropdown Option?';
		subtitle.innerHTML = 'Are you sure you want to remove this dropdown option? This may impact existing users.';
	} else if (element.id.indexOf('config-org-dropdown-remove-') > -1) {
		title.innerHTML = 'Remove Dropdown Option?';
		subtitle.innerHTML = 'Are you sure you want to remove this dropdown option? This may impact existing organizations.';
	}

	// Show modal
	inputModule.showModalWithId('configuration-remove-row-confirmation');
};

const dropdownAddValue = (element) => {

	// Get change family
	if (element.id === 'config-asset-dropdown-add') {

		// Get selected asset parameter
		const selectedAssetParam = getSelectedAssetParam();

		// Get elements
		const container = document.getElementById('configuration-asset-dropdown-holder');

		// Get parameters
		const randomId = generateRandomId();
		const optionName = 'New Option';

		// Create new element
		const elem = `<div class='config-field-row' name='config-asset-dropdown' id='config-asset-dropdown-${randomId}'>
            <div class='config-field-row-inner'><p class='contenteditable' contenteditable='true'
            id='config-asset-dropdown-content-${randomId}'>${optionName}</p></div><div class='remove-icon'
            id='config-asset-dropdown-remove-${randomId}'></div></div>`;

		// Append new element
		container.insertAdjacentHTML('beforeend', elem);

		// Set asset type
		if (selectedAssetParam.options.choices == null) selectedAssetParam.options.choices = [];
		selectedAssetParam.options.choices.push({
			id: randomId,
			value: optionName
		});
	} else if (element.id === 'config-personnel-dropdown-add') {

		// Get selected personnel parameter
		const selectedPersonnelParam = getSelectedPersonnelParam();

		// Get elements
		const container = document.getElementById('configuration-personnel-dropdown-holder');

		// Get parameters
		const randomId = generateRandomId();
		const optionName = 'New Option';

		// Create new element
		const elem = `<div class='config-field-row' name='config-personnel-dropdown' id='config-personnel-dropdown-${randomId}'>
            <div class='config-field-row-inner'><p class='contenteditable' contenteditable='true'
            id='config-personnel-dropdown-content-${randomId}'>${optionName}</p></div><div class='remove-icon'
            id='config-personnel-dropdown-remove-${randomId}'></div></div>`;

		// Append new element
		container.insertAdjacentHTML('beforeend', elem);

		// Set personnel type
		if (selectedPersonnelParam.options.choices == null) selectedPersonnelParam.options.choices = [];
		selectedPersonnelParam.options.choices.push({
			id: randomId,
			value: optionName
		});
	} else if (element.id === 'config-profile-dropdown-add') {

		// Get selected user parameter
		const selectedUserParam = getSelectedUserParam();

		// Get elements
		const container = document.getElementById('configuration-profile-dropdown-holder');

		// Get parameters
		const randomId = generateRandomId();
		const optionName = 'New Option';

		// Create new element
		const elem = `<div class='config-field-row' name='config-profile-dropdown' id='config-profile-dropdown-${randomId}'>
            <div class='config-field-row-inner'><p class='contenteditable' contenteditable='true'
            id='config-profile-dropdown-content-${randomId}'>${optionName}</p></div><div class='remove-icon'
            id='config-profile-dropdown-remove-${randomId}'></div></div>`;

		// Append new element
		container.insertAdjacentHTML('beforeend', elem);

		// Set asset type
		if (selectedUserParam.options.choices == null) selectedUserParam.options.choices = [];
		selectedUserParam.options.choices.push({
			id: randomId,
			value: optionName
		});
	} else if (element.id === 'config-org-dropdown-add') {

		// Get selected org parameter
		const selectedOrgParam = getSelectedOrgParam();

		// Get elements
		const container = document.getElementById('configuration-org-dropdown-holder');

		// Get parameters
		const randomId = generateRandomId();
		const optionName = 'New Option';

		// Create new element
		const elem = `<div class='config-field-row' name='config-org-dropdown' id='config-org-dropdown-${randomId}'>
            <div class='config-field-row-inner'><p class='contenteditable' contenteditable='true'
            id='config-org-dropdown-content-${randomId}'>${optionName}</p></div><div class='remove-icon'
            id='config-org-dropdown-remove-${randomId}'></div></div>`;

		// Append new element
		container.insertAdjacentHTML('beforeend', elem);

		// Set asset type
		if (selectedOrgParam.options.choices == null) selectedOrgParam.options.choices = [];
		selectedOrgParam.options.choices.push({
			id: randomId,
			value: optionName
		});
	}

	// Update element heights
	updateElementHeights();
};

const checkAddRequiredFields = (selectedAssetType) => {

	// Check if primary or secondary search exists
	let primarySearch1 = true;
	let secondarySearch1 = false;
	const primarySearch2 = false;
	let secondarySearch2 = true;
	let isDetailImage = true;
	for (let i = 0; i < selectedAssetType.fields.length; i += 1) {
		if (selectedAssetType.fields[i].options.primary_search === true) {
			primarySearch1 = false;
		}
		if (selectedAssetType.fields[i].options.secondary_search === true) {
			secondarySearch2 = false;
		}
		if (selectedAssetType.fields[i].options.is_detail_image === true) {
			isDetailImage = false;
		}
	}
	if (primarySearch1 === false && secondarySearch2 === true) {
		secondarySearch1 = true;
		secondarySearch2 = false;
	}

	// Set up required fields
	const requiredFields = [
		{
			id: generateRandomId(),
			name: 'Genre(s)',
			slug: 'categories',
			options: {
				display_on_dashboard: true,
				type: 'array',
				default: null,
				editable: true,
				updates_global: false,
				creates_global: true,
				required_to_create_global: false,
				displayed_on_search: true,
				primary_search: false,
				secondary_search: false,
				is_detail_image: false
			}
		},
		{
			id: generateRandomId(),
			name: 'Reading Level (F & P)',
			slug: 'fountas_pinnell',
			options: {
				display_on_dashboard: false,
				type: 'text',
				default: null,
				editable: true,
				updates_global: true,
				creates_global: true,
				required_to_create_global: false,
				displayed_on_search: true,
				primary_search: false,
				secondary_search: false,
				is_detail_image: false
			}
		},
		{
			id: generateRandomId(),
			name: 'Author(s)',
			slug: 'authors',
			options: {
				display_on_dashboard: true,
				type: 'array',
				default: null,
				editable: true,
				updates_global: false,
				creates_global: true,
				required_to_create_global: false,
				displayed_on_search: true,
				primary_search: primarySearch2,
				secondary_search: secondarySearch2,
				is_detail_image: false
			}
		},
		{
			id: generateRandomId(),
			name: 'Title',
			slug: 'title',
			options: {
				display_on_dashboard: true,
				type: 'text',
				default: null,
				editable: true,
				updates_global: false,
				creates_global: true,
				required_to_create_global: true,
				displayed_on_search: true,
				primary_search: primarySearch1,
				secondary_search: secondarySearch1,
				is_detail_image: false
			}
		},
		{
			id: generateRandomId(),
			name: 'Cover',
			slug: 'image',
			options: {
				display_on_dashboard: false,
				type: 'image',
				default: null,
				editable: false,
				updates_global: false,
				creates_global: true,
				required_to_create_global: false,
				displayed_on_search: true,
				primary_search: false,
				secondary_search: false,
				is_detail_image: isDetailImage
			}
		}
	];

	// Reset sharing parameters
	for (let j = 0; j < selectedAssetType.fields.length; j += 1) {
		const field = selectedAssetType.fields[j];
		if (field.slug !== 'title' && field.slug !== 'authors'
			&& field.slug !== 'fountas_pinnell' && field.slug !== 'categories'
			&& field.slug !== 'image') {
			field.options.updates_global = false;
			field.options.creates_global = false;
			field.options.required_to_create_global = false;
		}
	}

	// Add required fields if not present
	for (let j = 0; j < requiredFields.length; j += 1) {

		// Get required field
		const requiredField = requiredFields[j];

		// Check if should add field
		let needsField = true;
		for (let i = 0; i < selectedAssetType.fields.length; i += 1) {
			const field = selectedAssetType.fields[i];
			if (field.slug === requiredField.slug) {
				needsField = false;
				break;
			}
		}

		// If should add field, insert
		if (needsField === true) {
			selectedAssetType.fields.splice(0, 0, requiredField);
		}
	}
};

const handleConfigCheck = (element) => {

	// Get state
	const newState = (element.className.indexOf('active') > -1);

	// Get family
	if (element.id.indexOf('config-custom-a-t-') > -1) {

		// Get selected asset type
		const selectedAssetType = getSelectedAssetType();

		// Get changed option
		if (element.id.indexOf('config-custom-a-t-1') > -1) {
			selectedAssetType.options.search_isbn = newState;

			// Check and add required fields
			if (selectedAssetType.options.search_isbn === true) {
				checkAddRequiredFields(selectedAssetType);
			}

			// Get new element
			const newElement = document.getElementById(`config-asset-type-${selectedAssetType.id}`);

			// Update display
			selectAssetTypeRow(newElement);
		} else if (element.id.indexOf('config-custom-a-t-2') > -1) {
			selectedAssetType.options.creates_global = newState;
		} else if (element.id.indexOf('config-custom-a-t-3') > -1) {
			selectedAssetType.options.initial_state_expanded = newState;
		} else if (element.id.indexOf('config-custom-a-t-4') > -1) {
			selectedAssetType.options.track_check_in_out_status = newState;
		} else if (element.id.indexOf('config-custom-a-t-5') > -1) {
			selectedAssetType.options.track_stock_threshold = newState;
		} else if (element.id.indexOf('config-custom-a-t-6') > -1) {
			selectedAssetType.options.show_stock_level_widget = newState;
		} else if (element.id.indexOf('config-custom-a-t-7') > -1) {
			selectedAssetType.options.track_geolocation = newState;
		}
	} else if (element.id.indexOf('config-custom-a-p-') > -1) {

		// Get selected asset parameter
		const selectedAssetParam = getSelectedAssetParam();

		// Get changed option
		if (element.id === 'config-custom-a-p-1') {
			selectedAssetParam.options.editable = newState;
		} else if (element.id === 'config-custom-a-p-2') {
			selectedAssetParam.options.creates_global = newState;
		} else if (element.id === 'config-custom-a-p-3') {
			selectedAssetParam.options.updates_global = newState;
		} else if (element.id === 'config-custom-a-p-4') {
			selectedAssetParam.options.required_to_create_global = newState;
		} else if (element.id === 'config-custom-a-p-5') {
			selectedAssetParam.options.display_on_dashboard = newState;
		} else if (element.id === 'config-custom-a-p-8') {
			selectedAssetParam.options.displayed_on_search = newState;

			// Update search options display
			const searchPrimarySection = document.getElementById('config-section-a-p-9');
			const searchSecondarySection = document.getElementById('config-section-a-p-10');
			const searchImageSection = document.getElementById('config-section-a-p-11');
			if (newState === true) {
				if (selectedAssetParam.options.type === 'image') {
					searchImageSection.style.display = 'block';
					searchPrimarySection.style.display = 'none';
					searchSecondarySection.style.display = 'none';
				} else {
					searchImageSection.style.display = 'none';
					searchPrimarySection.style.display = 'block';
					searchSecondarySection.style.display = 'block';
				}
			} else {
				searchPrimarySection.style.display = 'none';
				searchSecondarySection.style.display = 'none';
				searchImageSection.style.display = 'none';
			}
		} else if (element.id === 'config-custom-a-p-9') {
			selectedAssetParam.options.primary_search = newState;

			// Get selected asset type
			const selectedAssetType = getSelectedAssetType();

			// If true, remove existing primary search
			if (newState === true) {
				for (let i = 0; i < selectedAssetType.fields.length; i += 1) {
					if (selectedAssetType.fields[i].options.primary_search === true
						&& selectedAssetType.fields[i].id !== selectedAssetParam.id) {
						selectedAssetType.fields[i].options.primary_search = false;
					}
				}
			}
		} else if (element.id === 'config-custom-a-p-10') {
			selectedAssetParam.options.secondary_search = newState;

			// Get selected asset type
			const selectedAssetType = getSelectedAssetType();

			// If true, remove existing secondary search
			if (newState === true) {
				for (let i = 0; i < selectedAssetType.fields.length; i += 1) {
					if (selectedAssetType.fields[i].options.secondary_search === true
						&& selectedAssetType.fields[i].id !== selectedAssetParam.id) {
						selectedAssetType.fields[i].options.secondary_search = false;
					}
				}
			}
		} else if (element.id === 'config-custom-a-p-11') {
			selectedAssetParam.options.is_detail_image = newState;
		}
	} else if (element.id.indexOf('config-custom-p-t-') > -1) {

		// Get selected personnel type
		const selectedPersonnelType = getSelectedPersonnelType();

		// Get changed option
		if (element.id.indexOf('config-custom-p-t-1') > -1) {

			// Get new element
			const newElement = document.getElementById(`config-personnel-type-${selectedPersonnelType.id}`);

			// Update display
			selectPersonnelTypeRow(newElement);

		}
	} else if (element.id.indexOf('config-custom-p-p-') > -1) {

		// Get selected personnel parameter
		const selectedPersonnelParam = getSelectedPersonnelParam();

		// Get changed option
		if (element.id === 'config-custom-p-p-1') {
			selectedPersonnelParam.options.editable = newState;
		} else if (element.id === 'config-custom-p-p-5') {
			selectedPersonnelParam.options.display_on_dashboard = newState;
		}
	} else if (element.id.indexOf('config-custom-u-p-') > -1) {

		// Get selected user parameter
		const selectedUserParam = getSelectedUserParam();

		// Get changed option
		if (element.id.indexOf('config-custom-u-p-1') > -1) {
			selectedUserParam.options.display_on_dashboard = newState;
		}
	} else if (element.id.indexOf('config-custom-o-p-') > -1) {

		// Get selected org parameter
		const selectedOrgParam = getSelectedOrgParam();

		// Get changed option
		if (element.id.indexOf('config-custom-o-p-1') > -1) {
			selectedOrgParam.options.display_on_dashboard = newState;
		}
	}
};

const handleConfigSelect = (element) => {

	// Get state
	let newState = element.value;

	// Get family
	if (element.id.indexOf('config-custom-a-p-') > -1) {

		// Get selected asset parameter
		const selectedAssetParam = getSelectedAssetParam();

		// Get changed option
		if (element.id.indexOf('config-custom-a-p-6') > -1) {
			selectedAssetParam.options.type = newState;

			// Update display
			if (newState === 'image' && selectedAssetParam.options.displayed_on_search === true) {
				document.getElementById('config-section-a-p-11').style.display = 'block';
			} else {
				document.getElementById('config-section-a-p-11').style.display = 'none';
			}
			if (newState === 'image') {
				document.getElementById('config-section-a-p-9').style.display = 'none';
				document.getElementById('config-section-a-p-10').style.display = 'none';
			} else {
				document.getElementById('config-section-a-p-9').style.display = 'block';
				document.getElementById('config-section-a-p-10').style.display = 'block';
			}
			if (newState === 'choice') {
				document.getElementById('config-a-p-dd-section').style.display = 'block';
				document.getElementById('config-container-a-p-7').style.display = 'none';
			} else {
				document.getElementById('config-a-p-dd-section').style.display = 'none';
				document.getElementById('config-container-a-p-7').style.display = 'block';
			}

			// Update element heights
			updateElementHeights();

		} else if (element.id.indexOf('config-custom-a-p-7') > -1) {
			if (newState === '') newState = null;
			selectedAssetParam.options.default = newState;
		}
	} else if (element.id.indexOf('config-custom-p-p-') > -1) {

		// Get selected personnel parameter
		const selectedPersonnelParam = getSelectedPersonnelParam();

		// Get changed option
		if (element.id.indexOf('config-custom-p-p-6') > -1) {
			selectedPersonnelParam.options.type = newState;

			// Update display
			if (newState === 'choice') {
				document.getElementById('config-p-p-dd-section').style.display = 'block';
				document.getElementById('config-p-default-section').style.display = 'none';
			} else {
				document.getElementById('config-p-p-dd-section').style.display = 'none';
				document.getElementById('config-p-default-section').style.display = 'block';
			}

			// Update element heights
			updateElementHeights();

		} else if (element.id.indexOf('config-custom-p-p-7') > -1) {
			if (newState === '') newState = null;
			selectedPersonnelParam.options.default = newState;
		}
	} else if (element.id.indexOf('config-custom-u-p-') > -1) {

		// Get selected user parameter
		const selectedUserParam = getSelectedUserParam();

		// Get changed option
		if (element.id.indexOf('config-custom-u-p-2') > -1) {
			selectedUserParam.options.type = newState;

			// Update display
			if (newState === 'choice') {
				document.getElementById('config-u-p-dd-section').style.display = 'block';
			} else {
				document.getElementById('config-u-p-dd-section').style.display = 'none';
			}

			// Update element heights
			updateElementHeights();
		}
	} else if (element.id.indexOf('config-custom-o-p-') > -1) {

		// Get selected org parameter
		const selectedOrgParam = getSelectedOrgParam();

		// Get changed option
		if (element.id.indexOf('config-custom-o-p-2') > -1) {
			selectedOrgParam.options.type = newState;

			// Update display
			if (newState === 'choice') {
				document.getElementById('config-o-p-dd-section').style.display = 'block';
			} else {
				document.getElementById('config-o-p-dd-section').style.display = 'none';
			}

			// Update element heights
			updateElementHeights();
		}
	}
};

const handleConfigText = (element) => {

	// Get state
	const newState = element.value;

	// Get family
	if (element.id.indexOf('config-custom-a-t-') > -1) {

		// Get selected asset type
		const selectedAssetType = getSelectedAssetType();

		// Get changed option
		if (element.id.indexOf('config-custom-a-t-7') > -1) {
			selectedAssetType.options.custom_owner_field = newState;
		}
	}
};

const populateConfiguration = () => {

	// Create sortable action
	$(() => {
		$('[name="configuration-sortable"]').sortable({
			cancel: ':input,button,.contenteditable',
			update(event, ui) {

				// Get element
				const elementId = ui.item.attr('id');

				// Resort elements
				if (elementId.indexOf('config-asset-type-') > -1) {

					// Get container and id
					const container = document.getElementById('configuration-asset-types-holder');
					const id = elementId.replace('config-asset-type-', '');

					// Get new index
					let newIndex = -1;
					for (let i = 0; i < container.childNodes.length; i += 1) {
						if (container.childNodes[i].id === elementId) {
							newIndex = i;
							break;
						}
					}

					// Get old index
					let oldIndex = -1;
					for (let i = 0; i < configAssetSchema.fields.length; i += 1) {
						if (configAssetSchema.fields[i].id === id) {
							oldIndex = i;
							break;
						}
					}

					// Transition array
					helperModule.arrayMove(configAssetSchema.fields, oldIndex, newIndex);
				} else if (elementId.indexOf('config-asset-param-') > -1) {

					// Get container and id
					const container = document.getElementById('configuration-asset-params-holder');
					const id = elementId.replace('config-asset-param-', '');

					// Get selected asset type
					const selectedAssetType = getSelectedAssetType();

					// Get new index
					let newIndex = -1;
					for (let i = 0; i < container.childNodes.length; i += 1) {
						if (container.childNodes[i].id === elementId) {
							newIndex = i;
							break;
						}
					}

					// Get old index
					let oldIndex = -1;
					for (let i = 0; i < selectedAssetType.fields.length; i += 1) {
						if (selectedAssetType.fields[i].id === id) {
							oldIndex = i;
							break;
						}
					}

					// Transition array
					helperModule.arrayMove(selectedAssetType.fields, oldIndex, newIndex);
				} else if (elementId.indexOf('config-personnel-type-') > -1) {

					// Get container and id
					const container = document.getElementById('configuration-personnel-types-holder');
					const id = elementId.replace('config-personnel-type-', '');

					// Get new index
					let newIndex = -1;
					for (let i = 0; i < container.childNodes.length; i += 1) {
						if (container.childNodes[i].id === elementId) {
							newIndex = i;
							break;
						}
					}

					// Get old index
					let oldIndex = -1;
					for (let i = 0; i < configPersonnelSchema.fields.length; i += 1) {
						if (configPersonnelSchema.fields[i].id === id) {
							oldIndex = i;
							break;
						}
					}

					// Transition array
					helperModule.arrayMove(configPersonnelSchema.fields, oldIndex, newIndex);

				} else if (elementId.indexOf('config-personnel-param-') > -1) {

					// Get container and id
					const container = document.getElementById('configuration-personnel-params-holder');
					const id = elementId.replace('config-personnel-param-', '');

					// Get selected personnel type
					const selectedPersonnelType = getSelectedPersonnelType();

					// Get new index
					let newIndex = -1;
					for (let i = 0; i < container.childNodes.length; i += 1) {
						if (container.childNodes[i].id === elementId) {
							newIndex = i;
							break;
						}
					}

					// Get old index
					let oldIndex = -1;
					for (let i = 0; i < selectedPersonnelType.fields.length; i += 1) {
						if (selectedPersonnelType.fields[i].id === id) {
							oldIndex = i;
							break;
						}
					}

					// Transition array
					helperModule.arrayMove(selectedPersonnelType.fields, oldIndex, newIndex);
				} else if (elementId.indexOf('config-user-param-') > -1) {

					// Get container and id
					const container = document.getElementById('configuration-user-params-holder');
					const id = elementId.replace('config-user-param-', '');

					// Get new index
					let newIndex = -1;
					for (let i = 0; i < container.childNodes.length; i += 1) {
						if (container.childNodes[i].id === elementId) {
							newIndex = i;
							break;
						}
					}

					// Get old index
					let oldIndex = -1;
					for (let i = 0; i < configUserSchema.fields.length; i += 1) {
						if (configUserSchema.fields[i].id === id) {
							oldIndex = i;
							break;
						}
					}

					// Transition array
					helperModule.arrayMove(configUserSchema.fields, oldIndex, newIndex);
				} else if (elementId.indexOf('config-org-param-') > -1) {

					// Get container and id
					const container = document.getElementById('configuration-org-params-holder');
					const id = elementId.replace('config-org-param-', '');

					// Get new index
					let newIndex = -1;
					for (let i = 0; i < container.childNodes.length; i += 1) {
						if (container.childNodes[i].id === elementId) {
							newIndex = i;
							break;
						}
					}

					// Get old index
					let oldIndex = -1;
					for (let i = 0; i < configOrgSchema.fields.length; i += 1) {
						if (configOrgSchema.fields[i].id === id) {
							oldIndex = i;
							break;
						}
					}

					// Transition array
					helperModule.arrayMove(configOrgSchema.fields, oldIndex, newIndex);
				} else if (elementId.indexOf('config-asset-dropdown-') > -1) {

					// Get selected asset parameter
					const selectedAssetParam = getSelectedAssetParam();

					// Get container and id
					const container = document.getElementById('configuration-asset-dropdown-holder');
					const id = elementId.replace('config-asset-dropdown-', '');

					// Get new index
					let newIndex = -1;
					for (let i = 0; i < container.childNodes.length; i += 1) {
						if (container.childNodes[i].id === elementId) {
							newIndex = i;
							break;
						}
					}

					// Get old index
					let oldIndex = -1;
					for (let i = 0; i < selectedAssetParam.options.choices.length; i += 1) {
						if (selectedAssetParam.options.choices[i].id === id) {
							oldIndex = i;
							break;
						}
					}

					// Transition array
					helperModule.arrayMove(selectedAssetParam.options.choices, oldIndex, newIndex);
				} else if (elementId.indexOf('config-personnel-dropdown-') > -1) {

					// Get selected personnel parameter
					const selectedPersonnelParam = getSelectedPersonnelParam();

					// Get container and id
					const container = document.getElementById('configuration-personnel-dropdown-holder');
					const id = elementId.replace('config-personnel-dropdown-', '');

					// Get new index
					let newIndex = -1;
					for (let i = 0; i < container.childNodes.length; i += 1) {
						if (container.childNodes[i].id === elementId) {
							newIndex = i;
							break;
						}
					}

					// Get old index
					let oldIndex = -1;
					for (let i = 0; i < selectedPersonnelParam.options.choices.length; i += 1) {
						if (selectedPersonnelParam.options.choices[i].id === id) {
							oldIndex = i;
							break;
						}
					}

					// Transition array
					helperModule.arrayMove(selectedPersonnelParam.options.choices, oldIndex, newIndex);
				} else if (elementId.indexOf('config-profile-dropdown-') > -1) {

					// Get selected user parameter
					const selectedUserParam = getSelectedUserParam();

					// Get container and id
					const container = document.getElementById('configuration-profile-dropdown-holder');
					const id = elementId.replace('config-profile-dropdown-', '');

					// Get new index
					let newIndex = -1;
					for (let i = 0; i < container.childNodes.length; i += 1) {
						if (container.childNodes[i].id === elementId) {
							newIndex = i;
							break;
						}
					}

					// Get old index
					let oldIndex = -1;
					for (let i = 0; i < selectedUserParam.options.choices.length; i += 1) {
						if (selectedUserParam.options.choices[i].id === id) {
							oldIndex = i;
							break;
						}
					}

					// Transition array
					helperModule.arrayMove(selectedUserParam.options.choices, oldIndex, newIndex);
				} else if (elementId.indexOf('config-org-dropdown-') > -1) {

					// Get selected org parameter
					const selectedOrgParam = getSelectedOrgParam();

					// Get container and id
					const container = document.getElementById('configuration-org-dropdown-holder');
					const id = elementId.replace('config-org-dropdown-', '');

					// Get new index
					let newIndex = -1;
					for (let i = 0; i < container.childNodes.length; i += 1) {
						if (container.childNodes[i].id === elementId) {
							newIndex = i;
							break;
						}
					}

					// Get old index
					let oldIndex = -1;
					for (let i = 0; i < selectedOrgParam.options.choices.length; i += 1) {
						if (selectedOrgParam.options.choices[i].id === id) {
							oldIndex = i;
							break;
						}
					}

					// Transition array
					helperModule.arrayMove(selectedOrgParam.options.choices, oldIndex, newIndex);
				}
			}
		});
	});

	// Ensure element is loaded
	$('#config-form-holder').ready(() => {

		// Get user role
		const userRole = getCookie('userRole');

		// Get config type
		const configType = configGlobalConfiguration.get('general').config_type;
		const roleTypes = configGlobalConfiguration.get('general').role_names;

		// Get container elements
		const formHolder = document.getElementById('config-form-holder');
		const loadingView = document.getElementById('configuration-activity-indicator');
		const leaderRoleHolder = document.getElementById('leader-role-holder');
		const superRoleHolder = document.getElementById('super-role-holder');
		const adminRoleHolder = document.getElementById('admin-role-holder');
		const organizationSection = document.getElementById('organization-config-section');

		// Get label elements
		const configurationOrgTitle = document.getElementById('configuration-org-title');
		const configurationOrgSubtitle = document.getElementById('configuration-org-subtitle');
		const configurationStandardRoleTitle = document.getElementById('configuration-standard-role-title');
		const configurationLeaderRoleTitle = document.getElementById('configuration-leader-role-title');
		const configurationSuperRoleTitle = document.getElementById('configuration-super-role-title');
		const configurationAdminRoleTitle = document.getElementById('configuration-admin-role-title');
		const configurationStandardRoleSubtitle = document.getElementById('configuration-standard-role-subtitle');
		const configurationLeaderRoleSubtitle = document.getElementById('configuration-leader-role-subtitle');
		const configurationSuperRoleSubtitle = document.getElementById('configuration-super-role-subtitle');
		const configurationAdminRoleSubtitle = document.getElementById('configuration-admin-role-subtitle');

		// Get input elements
		const configurationStandardRole = document.getElementById('configuration-standard-role');
		const configurationLeaderRole = document.getElementById('configuration-leader-role');
		const configurationSuperRole = document.getElementById('configuration-super-role');
		const configurationAdminRole = document.getElementById('configuration-admin-role');

		// Update display
		if (userRole === 'standard') {
			leaderRoleHolder.style.display = 'none';
			superRoleHolder.style.display = 'none';
			adminRoleHolder.style.display = 'none';
		}
		if (userRole === 'standard' || userRole === 'leader') {
			organizationSection.style.display = 'none';
		}
		if (userRole !== 'admin' || configGlobalConfiguration.get('features').show_admin_role !== true) {
			adminRoleHolder.style.display = 'none';
		}

		// Update elements for config type
		if (configType === 'organization') {
			configurationOrgTitle.innerHTML = 'Customize Your Organizations';
			configurationOrgSubtitle.innerHTML = `Add fields to your ${process.env.ENTITY_OBJECT.NAME} organizational profiles to track specific information about organizations in your network.`;
			configurationStandardRoleTitle.innerHTML = 'Individual';
			configurationLeaderRoleTitle.innerHTML = 'Organization Leader';
			configurationSuperRoleTitle.innerHTML = 'Network Leader';
			configurationAdminRoleTitle.innerHTML = 'Network Admin';
			configurationStandardRoleSubtitle.innerHTML = 'Give your individual role a custom name';
			configurationLeaderRoleSubtitle.innerHTML = 'Give your leader role a custom name';
			configurationSuperRoleSubtitle.innerHTML = 'Give your network leader role a custom name';
			configurationAdminRoleSubtitle.innerHTML = 'Give your network admin role a custom name';
		} else {
			configurationOrgTitle.innerHTML = 'Customize Your Schools';
			configurationOrgSubtitle.innerHTML = `Add fields to your ${process.env.ENTITY_OBJECT.NAME} organizational profiles to track specific information about schools in your district.`;
			configurationStandardRoleTitle.innerHTML = 'Teacher';
			configurationLeaderRoleTitle.innerHTML = 'School Leader';
			configurationSuperRoleTitle.innerHTML = 'District Leader';
			configurationAdminRoleTitle.innerHTML = 'District Admin';
			configurationStandardRoleSubtitle.innerHTML = 'Give your teacher role a custom name';
			configurationLeaderRoleSubtitle.innerHTML = 'Give your school leader role a custom name';
			configurationSuperRoleSubtitle.innerHTML = 'Give your district leader role a custom name';
			configurationAdminRoleSubtitle.innerHTML = 'Give your district admin role a custom name';
		}

		// Set role names
		configurationStandardRole.value = roleTypes.standard;
		configurationStandardRole.placeholder = `e.g. ${roleTypes.standard}`;
		configurationLeaderRole.value = roleTypes.leader;
		configurationLeaderRole.placeholder = `e.g. ${roleTypes.leader}`;
		configurationSuperRole.value = roleTypes.super;
		configurationSuperRole.placeholder = `e.g. ${roleTypes.super}`;
		configurationAdminRole.value = (roleTypes.admin != null) ? roleTypes.admin : 'Admin';
		configurationAdminRole.placeholder = (roleTypes.admin != null) ? `e.g. ${roleTypes.admin}` : 'e.g. Admin';

		// Get configuration parameters
		const scanConfig = configGlobalConfiguration.get('scan');
		const inputConfig = configGlobalConfiguration.get('input');

		// Initialize config parameters
		configAssetSchema = {};
		configUserSchema = {};
		configOrgSchema = {};
		configPersonnelSchema = {};
		configAssetSchema.fields = [];
		configUserSchema.fields = [];
		configOrgSchema.fields = [];
		configPersonnelSchema.fields = [];

		// Get asset types
		Object.keys(scanConfig.type_schemas).forEach((typeSchema) => {
			if (Object.prototype.hasOwnProperty.call(scanConfig.type_schemas, typeSchema)) {
				const schema = scanConfig.type_schemas[typeSchema];
				schema.slug = typeSchema;

				// Create asset type
				addNewAssetTypeRow(schema);
			}
		});

		// Get personnel types
		Object.keys(inputConfig.personnel_type_schemas).forEach((typeSchema) => {
			if (Object.prototype.hasOwnProperty.call(inputConfig.personnel_type_schemas, typeSchema)) {
				const schema = inputConfig.personnel_type_schemas[typeSchema];
				schema.slug = typeSchema;

				// Create personnel type
				addNewPersonnelTypeRow(schema);
			}
		});

		// Get user fields
		for (let i = 0; i < inputConfig.user_profile.fields.length; i += 1) {
			const field = inputConfig.user_profile.fields[i];

			// Create user field
			addNewUserParamRow(field);
		}

		// Get organization fields
		for (let i = 0; i < inputConfig.org_profile.fields.length; i += 1) {
			const field = inputConfig.org_profile.fields[i];

			// Create organization field
			addNewOrgParamRow(field);
		}

		// Update display
		formHolder.style.display = 'block';
		loadingView.style.display = 'none';

		// Reflow foundation
		require('foundation-sites'); // eslint-disable-line global-require
		$(document).foundation();
	});

	// Create return action
	$(document).keypress((e) => {
		if (e.keyCode === 13) {
			document.activeElement.blur();
		}
	});
};


/**
 * Action Handlers
 */

const createActionHandlers = () => {

	// Handle general click actions
	$('#config-back-button').click(() => {
		appModule.handleRouting('/dashboard/account');
	});
	$('#back-button').click(() => {
		window.location = '/enroll/organization';
	});

	// Handle asset click actions
	$('#config-add-new-asset-type').click(() => {
		addNewAssetTypeRow();
	});
	$('#config-add-new-asset-param').click(() => {
		addNewAssetParamRow();
	});
	$('#config-custom-a-t-1').click(function () {
		inputModule.selectCheckmark('config-custom-a-t-1', 'config-a-t-1');
		handleConfigCheck(this);
	});
	$('#config-custom-a-t-2').click(function () {
		inputModule.selectCheckmark('config-custom-a-t-2', 'config-a-t-2');
		handleConfigCheck(this);
	});
	$('#config-custom-a-t-3').click(function () {
		inputModule.selectCheckmark('config-custom-a-t-3', 'config-a-t-3');
		handleConfigCheck(this);
	});
	$('#config-custom-a-t-4').click(function () {
		inputModule.selectCheckmark('config-custom-a-t-4', 'config-a-t-4');
		handleConfigCheck(this);
	});
	$('#config-custom-a-t-5').click(function () {
		inputModule.selectCheckmark('config-custom-a-t-5', 'config-a-t-5');
		handleConfigCheck(this);
	});
	$('#config-custom-a-t-6').click(function () {
		inputModule.selectCheckmark('config-custom-a-t-6', 'config-a-t-6');
		handleConfigCheck(this);
	});
	$('#config-custom-a-t-7').click(function () {
		inputModule.selectCheckmark('config-custom-a-t-7', 'config-a-t-7');
		handleConfigCheck(this);
	});
	$('#config-custom-a-p-1').click(function () {
		inputModule.selectCheckmark('config-custom-a-p-1', 'config-a-p-1');
		handleConfigCheck(this);
	});
	$('#config-custom-a-p-2').click(function () {
		inputModule.selectCheckmark('config-custom-a-p-2', 'config-a-p-2');
		handleConfigCheck(this);
	});
	$('#config-custom-a-p-3').click(function () {
		inputModule.selectCheckmark('config-custom-a-p-3', 'config-a-p-3');
		handleConfigCheck(this);
	});
	$('#config-custom-a-p-4').click(function () {
		inputModule.selectCheckmark('config-custom-a-p-4', 'config-a-p-4');
		handleConfigCheck(this);
	});
	$('#config-custom-a-p-5').click(function () {
		inputModule.selectCheckmark('config-custom-a-p-5', 'config-a-p-5');
		handleConfigCheck(this);
	});
	$('#config-asset-dropdown-add').click(function () {
		dropdownAddValue(this);
	});
	$('#config-custom-a-p-8').click(function () {
		inputModule.selectCheckmark('config-custom-a-p-8', 'config-a-p-8');
		handleConfigCheck(this);
	});
	$('#config-custom-a-p-9').click(function () {
		inputModule.selectCheckmark('config-custom-a-p-9', 'config-a-p-9');
		handleConfigCheck(this);
	});
	$('#config-custom-a-p-10').click(function () {
		inputModule.selectCheckmark('config-custom-a-p-10', 'config-a-p-10');
		handleConfigCheck(this);
	});
	$('#config-custom-a-p-11').click(function () {
		inputModule.selectCheckmark('config-custom-a-p-11', 'config-a-p-11');
		handleConfigCheck(this);
	});

	// Handle personnel click actions
	$('#config-add-new-personnel-type').click(() => {
		addNewPersonnelTypeRow();
	});
	$('#config-add-new-personnel-param').click(() => {
		addNewPersonnelParamRow();
	});
	$('#config-custom-p-t-1').click(function () {
		inputModule.selectCheckmark('config-custom-p-t-1', 'config-p-t-1');
		handleConfigCheck(this);
	});
	$('#config-custom-p-p-1').click(function () {
		inputModule.selectCheckmark('config-custom-p-p-1', 'config-p-p-1');
		handleConfigCheck(this);
	});
	$('#config-custom-p-p-5').click(function () {
		inputModule.selectCheckmark('config-custom-p-p-5', 'config-p-p-5');
		handleConfigCheck(this);
	});
	$('#config-personnel-dropdown-add').click(function () {
		dropdownAddValue(this);
	});

	// Handle user profile click actions
	$('#config-add-new-user-param').click(() => {
		addNewUserParamRow();
	});
	$('#config-custom-u-p-1').click(function () {
		inputModule.selectCheckmark('config-custom-u-p-1', 'config-u-p-1');
		handleConfigCheck(this);
	});
	$('#config-profile-dropdown-add').click(function () {
		dropdownAddValue(this);
	});

	// Handle org profile click actions
	$('#config-add-new-org-param').click(() => {
		addNewOrgParamRow();
	});
	$('#config-custom-o-p-1').click(function () {
		inputModule.selectCheckmark('config-custom-o-p-1', 'config-o-p-1');
		handleConfigCheck(this);
	});
	$('#config-org-dropdown-add').click(function () {
		dropdownAddValue(this);
	});
	$('#config-action-button').click(() => {
		handleConfigurationActionButton();
	});

	// Handle modal click actions
	$('#configuration-save-error-modal-confirm').click(() => {
		inputModule.hideModalWithId('configuration-save-error-modal');
	});
	$('#configuration-validation-error-modal-confirm').click(() => {
		inputModule.hideModalWithId('configuration-validation-error-modal');
	});
	$('#configuration-remove-row-confirmation-cancel').click(() => {
		inputModule.hideModalWithId('configuration-remove-row-confirmation');
	});

	// Handle document asset click actions
	$(document).off('click', "[name='config-asset-type']");
	$(document).on('click', "[name='config-asset-type']", function () {
		selectAssetTypeRow(this);
	});
	$(document).off('click', "[id^='config-asset-type-remove-']");
	$(document).on('click', "[id^='config-asset-type-remove-']", function () {
		rowRemovePrompt(this);
	});
	$(document).off('click', "[name='config-asset-param']");
	$(document).on('click', "[name='config-asset-param']", function () {
		selectAssetParamRow(this);
	});
	$(document).off('click', "[id^='config-asset-remove-']");
	$(document).on('click', "[id^='config-asset-remove-']", function () {
		rowRemovePrompt(this);
	});
	$(document).off('click', "[id^='config-asset-dropdown-remove-']");
	$(document).on('click', "[id^='config-asset-dropdown-remove-']", function () {
		if (configContext === Constants.VIEW_LOAD_CONTEXTS.CONTEXT_ONBOARDING) {
			handleRemoveRow(this.id, true);
		} else {
			rowRemovePrompt(this);
		}
	});

	// Handle document personnel click actions
	$(document).off('click', "[name='config-personnel-type']");
	$(document).on('click', "[name='config-personnel-type']", function () {
		selectPersonnelTypeRow(this);
	});
	$(document).off('click', "[id^='config-personnel-type-remove-']");
	$(document).on('click', "[id^='config-personnel-type-remove-']", function () {
		rowRemovePrompt(this);
	});
	$(document).off('click', "[name='config-personnel-param']");
	$(document).on('click', "[name='config-personnel-param']", function () {
		selectPersonnelParamRow(this);
	});
	$(document).off('click', "[id^='config-personnel-remove-']");
	$(document).on('click', "[id^='config-personnel-remove-']", function () {
		rowRemovePrompt(this);
	});
	$(document).off('click', "[id^='config-personnel-dropdown-remove-']");
	$(document).on('click', "[id^='config-personnel-dropdown-remove-']", function () {
		if (configContext === Constants.VIEW_LOAD_CONTEXTS.CONTEXT_ONBOARDING) {
			handleRemoveRow(this.id, true);
		} else {
			rowRemovePrompt(this);
		}
	});

	// Handle document user profile click actions
	$(document).off('click', "[name='config-user-param']");
	$(document).on('click', "[name='config-user-param']", function () {
		selectUserParamRow(this);
	});
	$(document).off('click', "[id^='config-profile-remove-']");
	$(document).on('click', "[id^='config-profile-remove-']", function () {
		rowRemovePrompt(this);
	});
	$(document).off('click', "[id^='config-profile-dropdown-remove-']");
	$(document).on('click', "[id^='config-profile-dropdown-remove-']", function () {
		if (configContext === Constants.VIEW_LOAD_CONTEXTS.CONTEXT_ONBOARDING) {
			handleRemoveRow(this.id, true);
		} else {
			rowRemovePrompt(this);
		}
	});

	// Handle document organization profile click actions
	$(document).off('click', "[name='config-org-param']");
	$(document).on('click', "[name='config-org-param']", function () {
		selectOrgParamRow(this);
	});
	$(document).off('click', "[id^='config-org-remove-']");
	$(document).on('click', "[id^='config-org-remove-']", function () {
		rowRemovePrompt(this);
	});
	$(document).off('click', "[id^='config-org-dropdown-remove-']");
	$(document).on('click', "[id^='config-org-dropdown-remove-']", function () {
		if (configContext === Constants.VIEW_LOAD_CONTEXTS.CONTEXT_ONBOARDING) {
			handleRemoveRow(this.id, true);
		} else {
			rowRemovePrompt(this);
		}
	});
};


/**
 * Input Handlers
 */

const createInputHandlers = () => {

	// Handle general blur
	$('#configuration-standard-role').blur(() => {
		inputModule.removeBlockAlert();
	});
	$('#configuration-leader-role').blur(() => {
		inputModule.removeBlockAlert();
	});
	$('#configuration-super-role').blur(() => {
		inputModule.removeBlockAlert();
	});
	$('#configuration-admin-role').blur(() => {
		inputModule.removeBlockAlert();
	});

	// Handle asset blur
	$(document).off('blur', "[id^='config-asset-type-content-']");
	$(document).on('blur', "[id^='config-asset-type-content-']", function () {
		rowTitleBlur(this);
	});
	$(document).off('blur', "[id^='config-asset-content-']");
	$(document).on('blur', "[id^='config-asset-content-']", function () {
		rowTitleBlur(this);
	});
	$(document).off('blur', "[id^='config-asset-dropdown-content-']");
	$(document).on('blur', "[id^='config-asset-dropdown-content-']", function () {
		rowTitleBlur(this);
	});
	$(document).off('blur', "[id^='config-asset-dropdown-content-']");
	$(document).on('blur', "[id^='config-asset-dropdown-content-']", function () {
		rowTitleBlur(this);
	});

	// Handle personnel blur
	$(document).off('blur', "[id^='config-personnel-type-content-']");
	$(document).on('blur', "[id^='config-personnel-type-content-']", function () {
		rowTitleBlur(this);
	});
	$(document).off('blur', "[id^='config-personnel-content-']");
	$(document).on('blur', "[id^='config-personnel-content-']", function () {
		rowTitleBlur(this);
	});
	$(document).off('blur', "[id^='config-personnel-dropdown-content-']");
	$(document).on('blur', "[id^='config-personnel-dropdown-content-']", function () {
		rowTitleBlur(this);
	});
	$(document).off('blur', "[id^='config-personnel-dropdown-content-']");
	$(document).on('blur', "[id^='config-personnel-dropdown-content-']", function () {
		rowTitleBlur(this);
	});

	// Handle profile blur
	$(document).off('blur', "[id^='config-profile-content-']");
	$(document).on('blur', "[id^='config-profile-content-']", function () {
		rowTitleBlur(this);
	});
	$(document).off('blur', "[id^='config-profile-dropdown-content-']");
	$(document).on('blur', "[id^='config-profile-dropdown-content-']", function () {
		rowTitleBlur(this);
	});
	$(document).off('blur', "[id^='config-profile-dropdown-content-']");
	$(document).on('blur', "[id^='config-profile-dropdown-content-']", function () {
		rowTitleBlur(this);
	});

	// Handle org blur
	$(document).off('blur', "[id^='config-org-content-']");
	$(document).on('blur', "[id^='config-org-content-']", function () {
		rowTitleBlur(this);
	});
	$(document).off('blur', "[id^='config-org-dropdown-content-']");
	$(document).on('blur', "[id^='config-org-dropdown-content-']", function () {
		rowTitleBlur(this);
	});
	$(document).off('blur', "[id^='config-org-dropdown-content-']");
	$(document).on('blur', "[id^='config-org-dropdown-content-']", function () {
		rowTitleBlur(this);
	});

	// Handle asset change content
	$(document).off('keyup', "[id^='config-asset-type-content-']");
	$(document).on('keyup', "[id^='config-asset-type-content-']", function () {
		rowTitleChange(this);
	});
	$(document).off('keyup', "[id^='config-asset-content-']");
	$(document).on('keyup', "[id^='config-asset-content-']", function () {
		rowTitleChange(this);
	});
	$(document).off('keyup', "[id^='config-asset-dropdown-content-']");
	$(document).on('keyup', "[id^='config-asset-dropdown-content-']", function () {
		rowTitleChange(this);
	});
	$(document).off('keyup', "[id^='config-asset-dropdown-content-']");
	$(document).on('keyup', "[id^='config-asset-dropdown-content-']", function () {
		rowTitleChange(this);
	});

	// Handle personnel change content
	$(document).off('keyup', "[id^='config-personnel-type-content-']");
	$(document).on('keyup', "[id^='config-personnel-type-content-']", function () {
		rowTitleChange(this);
	});
	$(document).off('keyup', "[id^='config-personnel-content-']");
	$(document).on('keyup', "[id^='config-personnel-content-']", function () {
		rowTitleChange(this);
	});
	$(document).off('keyup', "[id^='config-personnel-dropdown-content-']");
	$(document).on('keyup', "[id^='config-personnel-dropdown-content-']", function () {
		rowTitleChange(this);
	});
	$(document).off('keyup', "[id^='config-personnel-dropdown-content-']");
	$(document).on('keyup', "[id^='config-personnel-dropdown-content-']", function () {
		rowTitleChange(this);
	});

	// Handle profile change content
	$(document).off('keyup', "[id^='config-profile-content-']");
	$(document).on('keyup', "[id^='config-profile-content-']", function () {
		rowTitleChange(this);
	});
	$(document).off('keyup', "[id^='config-profile-dropdown-content-']");
	$(document).on('keyup', "[id^='config-profile-dropdown-content-']", function () {
		rowTitleChange(this);
	});
	$(document).off('keyup', "[id^='config-profile-dropdown-content-']");
	$(document).on('keyup', "[id^='config-profile-dropdown-content-']", function () {
		rowTitleChange(this);
	});

	// Handle org change content
	$(document).off('keyup', "[id^='config-org-content-']");
	$(document).on('keyup', "[id^='config-org-content-']", function () {
		rowTitleChange(this);
	});
	$(document).off('keyup', "[id^='config-org-dropdown-content-']");
	$(document).on('keyup', "[id^='config-org-dropdown-content-']", function () {
		rowTitleChange(this);
	});
	$(document).off('keyup', "[id^='config-org-dropdown-content-']");
	$(document).on('keyup', "[id^='config-org-dropdown-content-']", function () {
		rowTitleChange(this);
	});

	// Handle change content
	$('#config-custom-a-t-7').keydown(() => {
		inputModule.removeBlockAlert();
	});
	$('#configuration-standard-role').keydown(() => {
		inputModule.removeBlockAlert();
	});
	$('#configuration-leader-role').keydown(() => {
		inputModule.removeBlockAlert();
	});
	$('#configuration-super-role').keydown(() => {
		inputModule.removeBlockAlert();
	});
	$('#configuration-admin-role').keydown(() => {
		inputModule.removeBlockAlert();
	});

	// Handle focus
	$('#config-custom-a-t-7').focus(() => {
		inputModule.removeBlockAlert();
	});
	$('#configuration-standard-role').focus(() => {
		inputModule.removeBlockAlert();
	});
	$('#configuration-leader-role').focus(() => {
		inputModule.removeBlockAlert();
	});
	$('#configuration-super-role').focus(() => {
		inputModule.removeBlockAlert();
	});
	$('#configuration-admin-role').focus(() => {
		inputModule.removeBlockAlert();
	});

	// Handle input changed
	$('#config-custom-a-t-7').keyup(function () {
		handleConfigText(this);
	});
	$('#config-custom-a-p-6').change(function () {
		handleConfigSelect(this);
	});
	$('#config-custom-a-p-7').change(function () {
		handleConfigSelect(this);
	});
	$('#config-custom-p-p-6').change(function () {
		handleConfigSelect(this);
	});
	$('#config-custom-p-p-7').change(function () {
		handleConfigSelect(this);
	});
	$('#config-custom-u-p-2').change(function () {
		handleConfigSelect(this);
	});
	$('#config-custom-o-p-2').change(function () {
		handleConfigSelect(this);
	});
};


/**
 * State Handlers
 */

exports.handlerDidLoad = async (context) => {

	// Create action handlers
	createActionHandlers();

	// Create input handlers
	createInputHandlers();

	// Set up view for context
	configContext = context;
	if (context === Constants.VIEW_LOAD_CONTEXTS.CONTEXT_ONBOARDING) {

		// Handle onboarding user
		await externalUserModule.handleOnboardingUser();

		// Attempt to find configuration for user
		let { configuration } = await externalUserModule.fetchConfigurationForUser();
		if (configuration != null) {
			configGlobalConfiguration = filterDefaultConfigurationItems(configuration);
			configGlobalConfigurationId = configuration.id;

			// Get current user
			const user = Parse.User.current();

			// Validate editor
			if (configGlobalConfiguration.get('editors').indexOf(user.id) > -1) {

				// Populate configuration
				populateConfiguration();
			}
		} else {

			// Fetch default configuration for user
			configuration = await fetchDefaultConfigurationForUser();
			configGlobalConfiguration = filterDefaultConfigurationItems(configuration);
			configGlobalConfigurationId = null;

			// Populate configuration
			populateConfiguration();
		}
	} else {

		// Fetch user's configuration
		const { configuration } = await externalUserModule.fetchConfigurationForUser();
		configGlobalConfiguration = filterDefaultConfigurationItems(configuration);
		configGlobalConfigurationId = configuration.id;

		// Get current user
		const user = Parse.User.current();

		// Validate editor
		if (configGlobalConfiguration.get('editors').indexOf(user.id) > -1) {

			// Populate configuration
			populateConfiguration();
		} else {
			appModule.handleRouting('/dashboard/account');
		}
	}
};

exports.handlerDidAppear = async () => { };
