// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

// Modules
const moment = require('moment-timezone');

// Utilities
const userModule = require('../modules/user');
const externalUserModule = require('../modules/external-user');
const { getCookie } = require('../modules/utilities');

// Constants
const Constants = require('../../../Constants');
const { filterDefaultConfigurationItems } = require('../modules/configuration');


/**
 * State
 */

let accountGlobalSubscription;
let accountGlobalConfiguration;
let accountGlobalOrganization;
let accountGlobalSchedulers;


/**
 * Handlers
 */

const updateAccountSchedulerDisplay = () => {

	// Get elements
	const editButton = document.getElementById('scheduling-edit');
	const scheduleField = document.getElementById('scheduling-schedule');
	const lastSentField = document.getElementById('scheduling-last-sent');
	const lastUpdatedField = document.getElementById('scheduling-last-updated');
	const schedulingContainer = document.getElementById('scheduling-container');
	const emptyContainer = document.getElementById('scheduling-empty-container');

	// Get current user
	const user = Parse.User.current();

	// Check if should show
	if (accountGlobalConfiguration.features.show_scheduling === true) {

		// Check if user has active schedulers
		if (accountGlobalSchedulers.length > 0) {

			// Update visibility
			schedulingContainer.style.display = 'block';
			emptyContainer.style.display = 'none';

			// Get editor array from configuration
			const editorArray = accountGlobalSchedulers.editors; // TODO
			if (editorArray == null || editorArray.indexOf(user.id) === -1) {

				// Remove edit button
				editButton.outerHTML = '';
			}

			// Get configuration parameters

			// TODO - FINISH


			// Set configuration parameters
			scheduleField.innerHTML = '';
			lastSentField.innerHTML = '';
			lastUpdatedField.innerHTML = '';

		} else {

			// Update visibility
			schedulingContainer.style.display = 'none';
			emptyContainer.style.display = 'block';

			// Remove edit button
			editButton.outerHTML = '';
		}
	}
};

const updateSchedulerForUser = async () => {

	// Get user role
	const userRole = getCookie('userRole');

	// Check if should show
	if (accountGlobalConfiguration.features.show_scheduling === true) {

		// Check user role
		if (userRole === 'standard' || userRole === 'leader' || userRole === 'super'
		|| userRole === 'admin') { // Show scheduler

			// Fetch scheduler for user
			const schedulers = await externalUserModule.fetchSchedulersForUser();
			accountGlobalSchedulers = schedulers;

			// Update scheduler display
			updateAccountSchedulerDisplay();

		} else { // Hide scheduling

			// Remove element
			const holder = document.getElementById('scheduling-card');
			holder.outerHTML = '';
		}
	}

	// Return successfully
	return true;
};

const updateAccountSubscriptionDisplay = () => {

	// Get elements
	const editButton = document.getElementById('subscription-edit');
	const subscriptionStatus = document.getElementById('subscription-status');
	const field2 = document.getElementById('subscription-2');
	const field3 = document.getElementById('subscription-3');
	const field4 = document.getElementById('subscription-4');
	const label2 = document.getElementById('subscription-2-label');
	const label3 = document.getElementById('subscription-3-label');
	const label4 = document.getElementById('subscription-4-label');

	// Get current user
	const user = Parse.User.current();
	const userRole = getCookie('userRole');

	// Get subscription status
	let status = 'Active';
	if (accountGlobalSubscription.isActive === false) status = 'Inactive';
	else if (accountGlobalSubscription.isTransition === true) status = 'Pending';

	// Set subscription parameters
	subscriptionStatus.innerHTML = status;

	// Check transition status
	if (accountGlobalSubscription.isTransition !== true) {

		// Get subscription owner
		const { owner } = accountGlobalSubscription;
		const ownerName = `${owner.firstName} ${owner.lastName}`;

		// Get subscription object
		let subscription;
		if (accountGlobalSubscription.customer) {
			[subscription] = accountGlobalSubscription.customer.subscriptions.data;
		} else if (accountGlobalSubscription.subscription) {
			subscription = accountGlobalSubscription.subscription;
		}

		// Ensure subscription is valid
		if (subscription != null) {

			// Get subscription organizations
			const organizations = `${accountGlobalSubscription.usedSeats} used of ${accountGlobalSubscription.totalSeats}`;

			// Get renews date
			const renewsDate = new Date(subscription.current_period_end * 1000);
			const renews = `${Constants.DAYS_OF_WEEK[renewsDate.getDay()]}, ${Constants.MONTH_NAMES[renewsDate.getMonth()]} ${renewsDate.getDate()}, ${renewsDate.getFullYear()}`;

			// If current user is not owner, hide edit button
			if (owner.id !== user.id) {

				// Remove edit button
				editButton.outerHTML = '';
			}

			// Check user role
			if (userRole === 'leader') {

				// Set renews
				label3.innerHTML = 'Renews';
				field3.innerHTML = renews;

				// Set owner
				label4.innerHTML = 'Creator';
				field4.innerHTML = ownerName;

				// Check owner status
				if (owner.id === user.id) {

					// Set payment method
					label2.innerHTML = 'Payment Method';
					if (subscription.billing === 'send_invoice') {
						field2.innerHTML = 'Invoice';
					} else if (accountGlobalSubscription.customer) {
						const defaultId = accountGlobalSubscription.customer.default_source;
						const cards = accountGlobalSubscription.customer.sources.data;
						for (let i = 0; i < cards.length; i += 1) {
							if (cards[i].id === defaultId) {
								const card = cards[i];
								field2.innerHTML = `${card.brand}  •••• ${card.last4}`;
							}
						}
					}
				} else {

					// Set subscription type
					label2.innerHTML = 'Subscription Type';
					field2.innerHTML = 'Organization';
				}
			} else {

				// Set available organizations
				label2.innerHTML = 'Organizations';
				field2.innerHTML = organizations;

				// Check owner status
				if (owner.id === user.id) {

					// Set payment method
					label3.innerHTML = 'Payment Method';
					if (subscription.billing === 'send_invoice') {
						field3.innerHTML = 'Invoice';
					} else if (accountGlobalSubscription.customer) {
						const defaultId = accountGlobalSubscription.customer.default_source;
						const cards = accountGlobalSubscription.customer.sources.data;
						for (let i = 0; i < cards.length; i += 1) {
							if (cards[i].id === defaultId) {
								const card = cards[i];
								field3.innerHTML = `${card.brand}  •••• ${card.last4}`;
							}
						}
					}

					// Set renews
					label4.innerHTML = 'Renews';
					field4.innerHTML = renews;
				} else {

					// Set renews
					label3.innerHTML = 'Renews';
					field3.innerHTML = renews;

					// Set owner
					label4.innerHTML = 'Creator';
					field4.innerHTML = ownerName;
				}
			}
		} else {

			// Remove edit button
			editButton.outerHTML = '';

			// Set subscription type
			label2.innerHTML = 'Subscription Type';
			field2.innerHTML = 'Organization';

			// Set renew
			label3.innerHTML = 'Renews';
			field3.innerHTML = 'Waiting for Approval';

			// Set organization owner
			label4.innerHTML = 'Creator';
			field4.innerHTML = accountGlobalSubscription.organization;
		}
	} else {

		// Remove edit button
		editButton.outerHTML = '';

		// Set subscription type
		label2.innerHTML = 'Subscription Type';
		field2.innerHTML = 'Organization';

		// Set renew
		label3.innerHTML = 'Renews';
		field3.innerHTML = 'Waiting for Approval';

		// Set organization owner
		label4.innerHTML = 'Creator';
		field4.innerHTML = accountGlobalSubscription.organization;
	}
};

const updateSubscriptionForUser = async () => {

	// Get user role
	const userRole = getCookie('userRole');

	// Check user role
	if (userRole === 'leader' || userRole === 'super' || userRole === 'admin') { // Show subscription

		// Fetch subscription for user
		const subscription = await externalUserModule.fetchSubscriptionForUser(true);
		accountGlobalSubscription = subscription;

		// Update subscription display
		updateAccountSubscriptionDisplay();
	} else { // Hide subscription

		// Remove element
		const holder = document.getElementById('subscription-card');
		holder.outerHTML = '';
	}

	// Return successfully
	return true;
};

const updateAccountConfigurationDisplay = () => {

	// Get elements
	const editButton = document.getElementById('configuration-edit');
	const configRoles = document.getElementById('config-roles');
	const configTypes = document.getElementById('config-types');
	const configProfile = document.getElementById('config-profile');
	const configOwner = document.getElementById('config-owner');

	// Get current user
	const user = Parse.User.current();

	// Get editor array from configuration
	const editorArray = accountGlobalConfiguration.editors;
	if (editorArray.indexOf(user.id) === -1) {

		// Remove edit button
		editButton.outerHTML = '';
	}

	// Get configuration parameters
	const { role_names: roleNames } = accountGlobalConfiguration.general;
	const scanSchema = accountGlobalConfiguration.scan.type_schemas;
	const userProfile = accountGlobalConfiguration.input.user_profile.fields;
	let owner = 'None';
	if (accountGlobalConfiguration.owner) owner = accountGlobalConfiguration.owner.name;
	let roles = `${roleNames.standard}, ${roleNames.leader}, ${roleNames.super}`;
	if (roleNames.admin != null) roles += `, ${roleNames.admin}`;

	// Create types display
	let types = '';
	let fields = '';
	Object.keys(scanSchema).forEach((key) => {
		if (Object.prototype.hasOwnProperty.call(scanSchema, key)) {
			if (types !== '') types += ', ';
			types += scanSchema[key].display;
		}
	});
	for (let i = 0; i < userProfile.length; i += 1) {
		if (i !== 0) fields += ', ';
		fields += userProfile[i].prompt;
	}
	if (types === '') types = 'None';
	if (fields === '') fields = 'None';

	// Set configuration parameters
	configRoles.innerHTML = roles;
	configProfile.innerHTML = fields;
	configTypes.innerHTML = types;
	configOwner.innerHTML = owner;
};

const updateAccountDisplay = () => {

	// Update profile display
	exports.updateAccountProfileDisplay();

	// Update configuration display
	updateAccountConfigurationDisplay();

	// Hide loading indicator
	const loadingIndicator = document.getElementById('account-activity-indicator');
	$(loadingIndicator).ready(() => {
		loadingIndicator.remove();
	});

	// Show container
	const container = document.getElementById('account-container');
	container.style.display = 'inline-block';
};

exports.updateAccountProfileDisplay = () => {

	// Get elements
	const profileName = document.getElementById('profile-name');
	const profileEmail = document.getElementById('profile-email');
	const profileRole = document.getElementById('profile-role');
	const profileMemberSince = document.getElementById('profile-member-since');

	// Get current user
	const user = Parse.User.current();

	// Get user parameters
	let firstName = user.get('firstName');
	let lastName = user.get('lastName');
	if (!firstName) firstName = '';
	if (!lastName) lastName = '';
	const email = user.get('email');
	const hasTerritories = user.get('territories') != null;

	// Set role content
	const userRole = getCookie('userRole');
	let role = accountGlobalConfiguration.general.role_names[userRole];

	// Modify role if necessary
	if (userRole === 'super' && hasTerritories === true && process.env.ENTITY === 'keelerqik') {
		role = Constants.ENTITY_CONSTANTS[process.env.ENTITY].SALES_REP_ROLE_DISPLAY;
	}

	// Set user data
	profileName.innerHTML = `${firstName} ${lastName}`;
	profileEmail.innerHTML = email;
	profileRole.innerHTML = `${role} (${accountGlobalOrganization.get('name')})`;
	profileMemberSince.innerHTML = moment(user.createdAt).format('MMMM D, YYYY');
};


/**
 * State Handlers
 */

exports.handlerDidLoad = async () => {

	// Fetch configuration for user
	const config = await userModule.handleConfigurationForUser(false);
	accountGlobalConfiguration = filterDefaultConfigurationItems(JSON.parse(config));

	// Fetch organizations for user
	const { organization } = await externalUserModule.fetchConfigurationForUser();
	accountGlobalOrganization = organization;

	// Ensure elements loaded
	$(document).ready(async () => {

		// Fetch subscription for user
		await updateSubscriptionForUser();

		// Fetch scheduler for user
		if (accountGlobalConfiguration.features.show_scheduling === true) {
			await updateSchedulerForUser();
		}

		// Update account display
		updateAccountDisplay();
	});
};

exports.handlerDidAppear = async () => { };
