// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

// Modules
const moment = require('moment-timezone');

// Utilities
const appModule = require('../modules/app');

// Constants
const { AVAILABLE_METRIC_TYPES, PURCHASE_FLOW_IDS } = require('../../../Constants');


/**
 * Global Variables
 */

let metricsObj = null;
const metricsTimePeriods = {};


/**
 * Handlers
 */

const buildMetricsDisplay = (type) => {

	// Get components
	const container = $(`#${type}-card .metric-container`);
	const emptyView = $(`#${type}-card .empty-view`);
	const timePeriodView = $(`#${type}-card [name="metric-time-period"]`);
	const countView = $(`#${type}-card [name="metric-count"]`);

	// Get parameters
	const timePeriod = metricsTimePeriods[type];

	// Get data for metric
	const { data } = metricsObj[type];

	// Build display for type
	switch (type) {
		case AVAILABLE_METRIC_TYPES.ORDER_HISTORY: {

			// Update metric parameter display
			countView.html(`(${data.length})`);
			timePeriodView.html(timePeriod.display);

			// Update empty view display
			if (data.length > 0) {
				container.show();
				emptyView.hide();
			} else {
				container.hide();
				emptyView.show();
			}

			// Build components
			const html = data.map((datum) => {

				// Create status components
				let statusColor = 'yellow';
				let status = 'Pending';
				if (datum.status === 'complete') {
					statusColor = 'green';
					status = 'Complete';
				}

				// Get moment dates
				const nowMoment = moment(new Date());
				const dateMoment = moment(datum.completedDate);

				// Format dates
				const formattedDate = `${dateMoment.format('MMM D, YYYY')} at ${dateMoment.format('h:mm A')}`;
				const dayNowOffset = Math.abs(nowMoment.diff(dateMoment, 'days')) + 1;
				const dayOffset = dayNowOffset === 1 ? `${dayNowOffset} day` : `${dayNowOffset} days`;

				// Create element
				return `
					<div class="metric-row clickable" name="metric-order-row-element" data-id="${datum.id}">
						<div class="inner">
							<div class="top-section">
								<h2>
									Order #${datum.data.purchaseOrderNumber}
									<span class="emphasis outline">${datum.organization}</span>
								</h2>
							</div>
							<div class="bottom-section row">
								<div class="columns small-12 medium-6 large-5">
									<h4>Created At</h4>
									<h3>
										<span class="emphasis outline">${dayOffset}</span>
										<span class="truncate">${formattedDate}</span>
									</h3>
								</div>
								<div class="columns small-12 medium-6 large-3">
									<h4>Status</h4>
									<h3><span class="emphasis padding ${statusColor}">${status}</span></h3>
								</div>
								<div class="columns small-12 medium-6 large-4">
									<h4>User</h4>
									<h3><span class="truncate">${datum.user.email}</span></h3>
								</div>
							</div>
						</div>
					</div>
				`;
			}).join('');

			// Set container html
			container.html(html);

			// Break
			break;
		}
		case AVAILABLE_METRIC_TYPES.INVENTORY_STOCK_LEVEL: {

			// Update metric parameter display
			countView.html(`(${data.length})`);
			timePeriodView.html(timePeriod.display);

			// Update empty view display
			if (data.length > 0) {
				container.show();
				emptyView.hide();
			} else {
				container.hide();
				emptyView.show();
			}

			// Build components
			const html = data.map((datum) => {

				// Get account number
				const accountNumber = datum.data[metricsObj[type].KEELER_ACCOUNT_NUMBER_CONFIG_ID];

				// Create elemenet
				return `
					<div class="metric-row clickable" name="metric-org-row-element" data-id="${datum.id}">
						<div class="inner">
							<div class="top-section">
								<div class="number-icon"><h2>${datum.name.charAt(0).toUpperCase()}</h2></div>
								<h2>
									${datum.name}
								</h2>
							</div>
							<div class="bottom-section row">
								<div class="columns small-12 medium-6 large-4">
									<h4>Inventory Count</h4>
									<h3><span class="emphasis outline">${datum.assetCount}</span></h3>
								</div>
								<div class="columns small-12 medium-6 large-4">
									<h4>User Count</h4>
									<h3><span class="emphasis outline">${datum.userCount != null && datum.userCount !== '' ? datum.userCount : 'N/A'}</span></h3>
								</div>
								<div class="columns small-12 medium-6 large-4">
									<h4>Account Number</h4>
									<h3>${accountNumber != null && accountNumber !== '' ? accountNumber : 'N/A'}</h3>
								</div>
							</div>
						</div>
					</div>
				`;
			}).join('');

			// Set container html
			container.html(html);

			// Break
			break;
		}
		case AVAILABLE_METRIC_TYPES.USAGE_QUANTITY: {

			// Update metric parameter display
			countView.html(`(${data.length})`);
			timePeriodView.html(timePeriod.display);

			// Update empty view display
			if (data.length > 0) {
				container.show();
				emptyView.hide();
			} else {
				container.hide();
				emptyView.show();
			}

			// Build components
			const html = data.map((datum) => {

				// Get moment dates
				const nowMoment = moment(new Date());
				const dateMoment = moment(datum.updatedAt);

				// Format dates
				const formattedDate = `${dateMoment.format('MMM D, YYYY')} at ${dateMoment.format('h:mm A')}`;
				const dayNowOffset = Math.abs(nowMoment.diff(dateMoment, 'days')) + 1;
				const dayOffset = dayNowOffset === 1 ? `${dayNowOffset} day` : `${dayNowOffset} days`;

				// Create element
				return `
					<div class="metric-row clickable" name="metric-asset-row-element" data-id="${datum.id}">
						<div class="inner">
							<div class="top-section">
								<h2>
									Asset #${datum.identifier.split(',')[0].trim()}
									<span class="emphasis outline">${datum.organization}</span>
								</h2>
							</div>
							<div class="bottom-section row">
								<div class="columns small-12 medium-6 large-4">
									<h4>Quantity Used</h4>
									<h3>
										<span class="emphasis outline">${datum.quantityUsed}</span>
									</h3>
								</div>
								<div class="columns small-12 medium-6 large-3">
									<h4>Updates Made</h4>
									<h3>
										<span class="emphasis outline">${datum.metrics.numEvents}</span>
									</h3>
								</div>
								<div class="columns small-12 medium-6 large-5">
									<h4>Last Updated</h4>
									<h3>
										<span class="emphasis outline">${dayOffset}</span>
										<span class="truncate">${formattedDate}</span>
									</h3>
								</div>
							</div>
						</div>
					</div>
				`;
			}).join('');

			// Set container html
			container.html(html);

			// Break
			break;
		}
		case AVAILABLE_METRIC_TYPES.LOW_STOCK_LEVEL: {

			// Update metric parameter display
			countView.html(`(${data.length})`);
			timePeriodView.html(timePeriod.display);

			// Update empty view display
			if (data.length > 0) {
				container.show();
				emptyView.hide();
			} else {
				container.hide();
				emptyView.show();
			}

			// Build components
			const html = data.map((datum) => {

				// Get moment dates
				const nowMoment = moment(new Date());
				const dateMoment = moment(datum.updatedAt);

				// Format dates
				const formattedDate = `${dateMoment.format('MMM D, YYYY')} at ${dateMoment.format('h:mm A')}`;
				const dayNowOffset = Math.abs(nowMoment.diff(dateMoment, 'days')) + 1;
				const dayOffset = dayNowOffset === 1 ? `${dayNowOffset} day` : `${dayNowOffset} days`;

				// Format quantity difference
				let difference = 'At threshold';
				let differenceColor = 'red';
				if (datum.stockThresholdDiff > 0) {
					difference = `${Math.abs(datum.stockThresholdDiff)} above threshold`;
					differenceColor = 'green';
				} else if (datum.stockThresholdDiff < 0) {
					difference = `${Math.abs(datum.stockThresholdDiff)} below threshold`;
					differenceColor = 'red';
				}

				// Create element
				return `
					<div class="metric-row clickable" name="metric-asset-row-element" data-id="${datum.id}">
						<div class="inner">
							<div class="top-section">
								<h2>
									Asset #${datum.identifier.split(',')[0].trim()}
									<span class="emphasis outline">${datum.organization}</span>
								</h2>
							</div>
							<div class="bottom-section row">
								<div class="columns small-12 medium-6 large-4">
									<h4>Stock Level</h4>
									<h3>
										<span class="emphasis ${differenceColor}">${difference}</span>
									</h3>
								</div>
								<div class="columns small-12 medium-6 large-3">
									<h4>Updates Made</h4>
									<h3>
										<span class="emphasis outline">${datum.metrics.numEvents}</span>
									</h3>
								</div>
								<div class="columns small-12 medium-6 large-5">
									<h4>Last Updated</h4>
									<h3>
										<span class="emphasis outline">${dayOffset}</span>
										<span class="truncate">${formattedDate}</span>
									</h3>
								</div>
							</div>
						</div>
					</div>
				`;
			}).join('');

			// Set container html
			container.html(html);

			// Break
			break;
		}
		case AVAILABLE_METRIC_TYPES.USAGE_TRENDS: {

			// Update metric parameter display
			countView.html(`(${data.length})`);
			timePeriodView.html(timePeriod.display);

			// Update empty view display
			if (data.length > 0) {
				container.show();
				emptyView.hide();
			} else {
				container.hide();
				emptyView.show();
			}

			// Build components
			const html = data.map((datum) => {

				// Get moment dates
				const nowMoment = moment(new Date());
				const dateMoment = moment(datum.updatedAt);

				// Format dates
				const formattedDate = `${dateMoment.format('MMM D, YYYY')} at ${dateMoment.format('h:mm A')}`;
				const dayNowOffset = Math.abs(nowMoment.diff(dateMoment, 'days')) + 1;
				const dayOffset = dayNowOffset === 1 ? `${dayNowOffset} day` : `${dayNowOffset} days`;

				// Format quantity difference
				let difference = 'No';
				let differenceColor = 'yellow';
				if (datum.quantityDiff > 0) {
					difference = `+${datum.quantityDiff}`;
					differenceColor = 'green';
				} else if (datum.quantityDiff < 0) {
					difference = `${datum.quantityDiff}`;
					differenceColor = 'red';
				}

				// Create element
				return `
					<div class="metric-row clickable" name="metric-asset-row-element" data-id="${datum.id}">
						<div class="inner">
							<div class="top-section">
								<h2>
									Asset #${datum.identifier.split(',')[0].trim()}
									<span class="emphasis outline">${datum.organization}</span>
								</h2>
							</div>
							<div class="bottom-section row">
								<div class="columns small-12 medium-6 large-4">
									<h4>Quantity Usage</h4>
									<h3>
										${datum.currentQuantity} <span class="emphasis ${differenceColor} right">${difference} difference</span>
									</h3>
								</div>
								<div class="columns small-12 medium-6 large-3">
									<h4>Updates Made</h4>
									<h3>
										<span class="emphasis outline">${datum.metrics.numEvents}</span>
									</h3>
								</div>
								<div class="columns small-12 medium-6 large-5">
									<h4>Last Updated</h4>
									<h3>
										<span class="emphasis outline">${dayOffset}</span>
										<span class="truncate">${formattedDate}</span>
									</h3>
								</div>
							</div>
						</div>
					</div>
				`;
			}).join('');

			// Set container html
			container.html(html);

			// Break
			break;
		}
		case AVAILABLE_METRIC_TYPES.SUBSCRIPTION_PAYMENTS: {

			// Update metric parameter display
			countView.html(`(${data.length})`);
			timePeriodView.html(timePeriod.display);

			// Update empty view display
			if (data.length > 0) {
				container.show();
				emptyView.hide();
			} else {
				container.hide();
				emptyView.show();
			}

			// Build components
			const html = data.map((datum) => {

				// Get moment dates
				const nowMoment = moment(new Date());
				const dateMoment = moment(datum.startDate);

				// Format dates
				const formattedDate = `${dateMoment.format('MMM D, YYYY')} at ${dateMoment.format('h:mm A')}`;
				const dayNowOffset = Math.abs(nowMoment.diff(dateMoment, 'days')) + 1;
				const dayOffset = dayNowOffset === 1 ? `${dayNowOffset} day` : `${dayNowOffset} days`;

				// Create element
				return `
					<div class="metric-row clickable" name="metric-org-row-element" data-id="${datum.organization.id}">
						<div class="inner">
							<div class="top-section">
								<h2>
									<div class="active-indicator ${datum.isActive ? 'active' : 'inactive'}"></div>
									${datum.organization.name}
									${datum.eBizMethodProfileId != null ? `<span class="emphasis outline multiple">EBiz Profile #${datum.eBizMethodProfileId}</span>` : ''}
									<span class="emphasis outline">${datum.totalSeats === 1 ? '1 Clinic' : `${datum.totalSeats} Seats`}</span>
								</h2>
							</div>
							<div class="bottom-section row">
								<div class="columns small-12 medium-6 large-5">
									<h4>Created At</h4>
									<h3>
										<span class="emphasis outline">${dayOffset}</span>
										<span class="truncate">${formattedDate}</span>
									</h3>
								</div>
								<div class="columns small-12 medium-6 large-3">
									<h4>Creator</h4>
									<h3>
										<span class="truncate">${datum.creator}</span>
									</h3>
								</div>
								<div class="columns small-12 medium-6 large-4">
									<h4>Payment</h4>
									<h3>
										<span class="emphasis outline">${datum.paymentPeriod === 'yearly' ? 'Annual' : 'Monthly'}</span>
										<span class="truncate">${datum.paymentMethod === 'card' ? 'Card Payment' : 'Invoice'}</span>
									</h3>
								</div>
							</div>
						</div>
					</div>
				`;
			}).join('');

			// Set container html
			container.html(html);

			// Break
			break;
		}
		default:
			break;
	}
};

const updateMetricsDisplay = () => {

	// Hide loading indicator
	const loadingIndicator = document.getElementById('metrics-activity-indicator');
	$(loadingIndicator).ready(() => {
		loadingIndicator.remove();
	});

	// Show container
	const container = document.getElementById('metrics-container');
	container.style.display = 'inline-block';

	// Build display for metrics
	buildMetricsDisplay(AVAILABLE_METRIC_TYPES.ORDER_HISTORY);
	buildMetricsDisplay(AVAILABLE_METRIC_TYPES.INVENTORY_STOCK_LEVEL);
	buildMetricsDisplay(AVAILABLE_METRIC_TYPES.USAGE_QUANTITY);
	buildMetricsDisplay(AVAILABLE_METRIC_TYPES.LOW_STOCK_LEVEL);
	buildMetricsDisplay(AVAILABLE_METRIC_TYPES.USAGE_TRENDS);
	buildMetricsDisplay(AVAILABLE_METRIC_TYPES.SUBSCRIPTION_PAYMENTS);
};


/**
 * Action Handlers
 */

const createActionHandlers = () => {

	// Handle click on asset row
	$(document).off('click', "[name='metric-asset-row-element']");
	$(document).on('click', "[name='metric-asset-row-element']", function () {
		appModule.handleRouting(`/dashboard/asset/${$(this).data('id')}`);
	});

	// Handle click on organization row
	$(document).off('click', "[name='metric-org-row-element']");
	$(document).on('click', "[name='metric-org-row-element']", function () {
		appModule.handleRouting(`/dashboard/organization/${$(this).data('id')}`);
	});

	// Handle click on order row
	$(document).off('click', "[name='metric-order-row-element']");
	$(document).on('click', "[name='metric-order-row-element']", function () {

		// Get order id
		const orderId = $(this).data('id');

		// Get order object
		const orderObj = metricsObj[AVAILABLE_METRIC_TYPES.ORDER_HISTORY].data.find((order) => order.id === orderId);

		// Handle action for order
		const { purchaseOption, data } = orderObj;
		if (purchaseOption.flow_id === PURCHASE_FLOW_IDS.KEELER_QIK_PO) {
			window.open(data.purchaseOrderUrl, '_blank');
		}
	});
};


/**
 * State Handlers
 */

exports.handlerDidLoad = async () => {

	// Create action handlers
	createActionHandlers();

	// Create parameters
	let startDate = new Date();
	startDate.setDate(1);
	startDate.setHours(0, 0, 0, 0);
	const endDate = new Date();

	// Set initial time periods
	Object.values(AVAILABLE_METRIC_TYPES).forEach((type) => {

		// Set time period for specific metrics
		if (type === AVAILABLE_METRIC_TYPES.SUBSCRIPTION_PAYMENTS) {

			// Update start date
			startDate = new Date('2015-01-01T00:00:00.000Z');

			// Set time period
			metricsTimePeriods[type] = {
				display: 'All time',
				range: { start: startDate, end: endDate }
			};
		} else {

			// Create date parameters
			const startDateDisplay = new Date(startDate.getTime());

			// Update date parameters for specific metrics
			if (type === AVAILABLE_METRIC_TYPES.USAGE_TRENDS) {
				startDateDisplay.setMonth(startDateDisplay.getMonth() - 1);
			}

			// Create date range display
			const display = `${moment(startDateDisplay).format('MMM Do')} - Today`;

			// Set time period
			metricsTimePeriods[type] = {
				display,
				range: { start: startDate, end: endDate }
			};
		}
	});

	// Get metrics
	metricsObj = await Parse.Cloud.run('fetchPlatformMetricsForUser', {
		metricTypes: [
			{
				id: AVAILABLE_METRIC_TYPES.ORDER_HISTORY,
				options: { startDate, endDate }
			},
			{
				id: AVAILABLE_METRIC_TYPES.LOW_STOCK_LEVEL,
				options: { startDate, endDate }
			},
			{
				id: AVAILABLE_METRIC_TYPES.USAGE_TRENDS,
				options: { startDate, endDate }
			},
			{
				id: AVAILABLE_METRIC_TYPES.USAGE_QUANTITY,
				options: { startDate, endDate }
			},
			{
				id: AVAILABLE_METRIC_TYPES.INVENTORY_STOCK_LEVEL,
				options: { startDate, endDate }
			},
			{
				id: AVAILABLE_METRIC_TYPES.SUBSCRIPTION_PAYMENTS,
				options: { startDate, endDate }
			}
		]
	});

	// Ensure elements loaded
	$(document).ready(async () => {

		// Update metrics display
		updateMetricsDisplay();
	});
};

exports.handlerDidAppear = async () => { };
