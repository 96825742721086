// COPYRIGHT SMARTTRACK

/**
 * Handlers
 */

exports.arrayMove = (arr, oIndex, nIndex) => {
	let oldIndex = oIndex;
	let newIndex = nIndex;
	while (oldIndex < 0) {
		oldIndex += arr.length;
	}
	while (newIndex < 0) {
		newIndex += arr.length;
	}
	if (newIndex >= arr.length) {
		let k = newIndex - arr.length + 1;
		while (k -= 1) { /* eslint-disable-line no-cond-assign */
			arr.push(undefined);
		}
	}
	arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
};

exports.handleRemoveButtonPosition = function () {
	if ($('#assets-remove-button').length) {
		if ($('#assets-remove-button').offset().top + $('#assets-remove-button').height() >= $('#footer-section').offset().top - 30) {
			$('#assets-remove-button').css('position', 'absolute');
			$('#assets-remove-button').css('right', '25px');
		}
		if ($(document).scrollTop() + window.innerHeight < $('#footer-section').offset().top) {
			$('#assets-remove-button').css('position', 'fixed');
			$('#assets-remove-button').css('right', '40px');
		}
	}
	if ($('#orgs-remove-button').length) {
		if ($('#orgs-remove-button').offset().top + $('#orgs-remove-button').height() >= $('#footer-section').offset().top - 30) {
			$('#orgs-remove-button').css('position', 'absolute');
			$('#orgs-remove-button').css('right', '25px');
		}
		if ($(document).scrollTop() + window.innerHeight < $('#footer-section').offset().top) {
			$('#orgs-remove-button').css('position', 'fixed');
			$('#orgs-remove-button').css('right', '40px');
		}
	}
	if ($('#users-remove-button').length) {
		if ($('#users-remove-button').offset().top + $('#users-remove-button').height() >= $('#footer-section').offset().top - 30) {
			$('#users-remove-button').css('position', 'absolute');
			$('#users-remove-button').css('right', '25px');
		}
		if ($(document).scrollTop() + window.innerHeight < $('#footer-section').offset().top) {
			$('#users-remove-button').css('position', 'fixed');
			$('#users-remove-button').css('right', '40px');
		}
	}
};

exports.displayActionSheet = (id) => {
	const dropdown = document.getElementById(id);
	const arrow = document.getElementById(`${id}-arrow`);
	if (dropdown.className.indexOf('show') === -1) { // Show sheet
		dropdown.className = 'action-dropdown show';
		arrow.src = '/img/icons/action-arrow-open.png';
	} else { // Hide sheet
		dropdown.className = 'action-dropdown';
		arrow.src = '/img/icons/action-arrow.png';
	}
};
