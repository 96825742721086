// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

const inputModule = require('../modules/input');
const userModule = require('../modules/user');
const appModule = require('../modules/app');
const externalUserModule = require('../modules/external-user');


/**
 * State
 */

let confirmGlobalConfiguration;


/**
 * Helpers
 */

const addDataToTableContent = (importDataObject) => {

	// Initialize state
	let header; let fieldContent; let
		html = '';

	// Add data to table
	for (let i = 0; i < window.import_globalFileHeader.length; i += 1) {
		header = window.import_globalFileHeader[i];
		fieldContent = importDataObject[inputModule.toCamelCase(header)];
		html += (`<td>${fieldContent}</td>`);
	}

	// Return table content
	return html;
};

const backToUploadConfirm = () => {
	appModule.handleRouting('/dashboard/import');
};

const backToDefineConfirm = () => {
	appModule.handleRouting('/dashboard/define');
};

const getOrganizationId = async (orgName) => {

	// Fetch organizations for user
	const organizations = await userModule.fetchPlatformOrganizationsForUser(true);

	// Get id for organization
	for (let i = 0; i < organizations.length; i += 1) {
		if (organizations[i].name === orgName) return organizations[i].id;
	}
	return '';
};

const getRole = (roleName) => {
	const keys = Object.keys(confirmGlobalConfiguration.general.role_names);
	for (let i = 0; i < keys.length; i += 1) {
		const property = keys[i];
		if (Object.prototype.hasOwnProperty.call(confirmGlobalConfiguration.general.role_names, property)) {
			if (confirmGlobalConfiguration.general.role_names[property] === roleName) {
				return property;
			}
		}
	}
	return 'standard';
};

const validateSubscription = async (newSeats) => {

	// Check subscription
	const subscription = await externalUserModule.fetchSubscriptionForUser(true);

	// Validate subscription
	if (subscription != null) {

		// Check if transition user
		if (subscription.isTransition === true) {

			// Show transition modal
			inputModule.showModalWithId('confirm-subscription-transition-modal');

		} else {

			// Check available seats
			const availableSeats = subscription.totalSeats - subscription.usedSeats;
			if (availableSeats >= newSeats) {
				return true;
			}

			// An error occured
			inputModule.showModalWithId('confirm-subscription-upgrade-modal');

		}
	} else { // An error occured
		inputModule.showModalWithId('confirm-subscription-upgrade-modal');
	}
	return false;
};


/**
 * Asset Handlers
 */

const saveAssetsFromImport = async () => {

	// Set global assets
	const globalAssets = [];
	for (let i = 0; i < window.import_globalFileData.length; i += 1) {

		// Set general parameters
		const globalAssetData = {
			assetData: {}
		};

		// Validate identifier
		if (window.import_globalFileData[i].asset != null && window.import_globalFileData[i].asset !== '') {

			// Set global state
			globalAssetData.identifier = window.import_globalFileData[i].asset;
			globalAssetData.type = window.importAssetFilter;

			// Set asset type columns
			const assetTypeColumns = confirmGlobalConfiguration.scan.type_schemas[window.importAssetFilter].input;

			// Update assets for columns
			for (let j = 0; j < assetTypeColumns.length; j += 1) {
				const columnLabel = assetTypeColumns[j].prompt;
				const refArray = assetTypeColumns[j].ref;
				const { type } = assetTypeColumns[j];

				// Iterate through file header
				for (let k = 0; k < window.import_globalFileHeader.length; k += 1) {
					if (columnLabel === window.import_globalFileHeader[k]) {
						if (type === 'text' || type === 'number' || type === 'quantity' || type === 'price' || type === 'stockLevelThreshold') {

							// Get parameter
							const parameter = window.import_globalFileData[i][inputModule.toCamelCase(window.import_globalFileHeader[k])];

							// Set parameter
							let schema = globalAssetData.assetData;
							for (let l = 0; l < refArray.length - 1; l += 1) {
								const ref = refArray[l];
								if (!schema[ref]) schema[ref] = {};
								schema = schema[ref];
							}
							schema[refArray[refArray.length - 1]] = parameter;
							break;
						} else if (type === 'choice') {

							// Get parameter
							for (let l = 0; l < assetTypeColumns[j].choices.length; l += 1) {
								const columnValue = window.import_globalFileData[i][inputModule.toCamelCase(window.import_globalFileHeader[k])];
								if (columnValue === assetTypeColumns[j].choices[l].value) {

									// Set parameter
									let schema = globalAssetData.assetData;
									for (let m = 0; m < refArray.length - 1; m += 1) {
										const ref = refArray[m];
										if (!schema[ref]) schema[ref] = {};
										schema = schema[ref];
									}
									schema[refArray[refArray.length - 1]] = assetTypeColumns[j].choices[l].id;
									break;
								}
							}
						} else if (type === 'array') {

							// Get parameter
							const columnValues = window.import_globalFileData[i][inputModule.toCamelCase(window.import_globalFileHeader[k])].split(',');
							for (let m = 0; m < columnValues.length; m += 1) {
								columnValues[m] = columnValues[m].trim();
							}

							// Set parameter
							let schema = globalAssetData.assetData;
							for (let m = 0; m < refArray.length - 1; m += 1) {
								const ref = refArray[m];
								if (!schema[ref]) schema[ref] = {};
								schema = schema[ref];
							}
							schema[refArray[refArray.length - 1]] = columnValues;
							break;
						}
					}
				}
			}

			// Set asset data
			const assetDataToSave = JSON.parse(JSON.stringify(globalAssetData));
			assetDataToSave.data = null;
			assetDataToSave.id = null;

			// Add asset
			globalAssets.push(assetDataToSave);
		}
	}

	// If new assets, add
	if (globalAssets.length > 0) {

		// Handle data import
		await Parse.Cloud.run('handleDataImportForUser', {
			dataArray: globalAssets, type: 'asset', platform: 'web'
		}).then(async () => {

			// Update display
			document.getElementById('confirm-button-activity-indicator').style.display = 'none';
			document.getElementById('confirm-action-button-arrow').style.display = 'none';
			document.getElementById('confirm-action-button-text').innerHTML = 'Processing...';

			// Show confirmation modal
			const html = `<p>
				We are currently processing your import. When it's ready, you will receive a confirmation email with instructions to view your new assets.
				<br><br><b>Row(s) Imported: </b> ${window.import_globalFileData.length}<br></br>
				<b>Row(s) Skipped: </b>0
			</p>`;
			document.getElementById('confirm-confirm-message-holder').innerHTML = html;
			inputModule.showModalWithId('confirm-confirm-modal');

			// Set delay to Assets
			setTimeout(() => { appModule.handleRouting('/dashboard/assets'); }, 6000);

		}).catch((error) => {

			// Update display
			document.getElementById('confirm-action-button-text').innerHTML = 'Submit';
			document.getElementById('confirm-action-button-arrow').style.display = 'inline-block';
			document.getElementById('confirm-button-activity-indicator').style.display = 'none';

			// Show error modal
			const html = `<p>${error.message}</p>`;
			document.getElementById('confirm-error-message-holder').innerHTML = html;
			inputModule.showModalWithId('confirm-error-modal');
		});
	} else {

		// Update display
		document.getElementById('confirm-action-button-text').innerHTML = 'Submit';
		document.getElementById('confirm-action-button-arrow').style.display = 'inline-block';
		document.getElementById('confirm-button-activity-indicator').style.display = 'none';

		// Show error modal
		const html = '<p>The data being imported already exists. Please try again.</p>';
		document.getElementById('confirm-error-message-holder').innerHTML = html;
		inputModule.showModalWithId('confirm-error-modal');
	}
};


/**
 * Organization Handlers
 */

const saveOrgsFromImport = async () => {

	// Reset org state
	const globalOrgDataArray = [];

	// Iterate through file data
	for (let i = 0; i < window.import_globalFileData.length; i += 1) {

		// Initialize data
		const globalOrgData = {
			data: {}
		};

		// Get import data
		const importData = window.import_globalFileData[i];

		// Set global state
		globalOrgData.name = importData.name;

		// Set organization inputs
		const inputArray = confirmGlobalConfiguration.input.org_profile.fields;

		// Update organization for columns
		for (let j = 0; j < inputArray.length; j += 1) {
			const columnLabel = inputArray[j].prompt;
			const refArray = inputArray[j].ref;
			const { type } = inputArray[j];

			// Iterate through file header
			for (let k = 0; k < window.import_globalFileHeader.length; k += 1) {
				if (columnLabel === window.import_globalFileHeader[k]) {
					if (type === 'text' || type === 'number' || type === 'quantity' || type === 'price' || type === 'stockLevelThreshold') {

						// Get parameter
						const parameter = window.import_globalFileData[i][inputModule.toCamelCase(window.import_globalFileHeader[k])];

						// Set parameter
						let schema = globalOrgData.data;
						for (let l = 0; l < refArray.length - 1; l += 1) {
							const ref = refArray[l];
							if (!schema[ref]) schema[ref] = {};
							schema = schema[ref];
						}
						schema[refArray[refArray.length - 1]] = parameter;
						break;
					} else if (type === 'choice') {

						// Get parameter
						for (let l = 0; l < inputArray[j].choices.length; l += 1) {
							const columnValue = window.import_globalFileData[i][inputModule.toCamelCase(window.import_globalFileHeader[k])];
							if (columnValue === inputArray[j].choices[l].value) {

								// Set parameter
								let schema = globalOrgData.data;
								for (let m = 0; m < refArray.length - 1; m += 1) {
									const ref = refArray[m];
									if (!schema[ref]) schema[ref] = {};
									schema = schema[ref];
								}
								schema[refArray[refArray.length - 1]] = inputArray[j].choices[l].id;
								break;
							}
						}
					} else if (type === 'array') {

						// Get parameter
						const columnValues = window.import_globalFileData[i][inputModule.toCamelCase(window.import_globalFileHeader[k])].split(',');
						for (let m = 0; m < columnValues.length; m += 1) {
							columnValues[m] = columnValues[m].trim();
						}

						// Set parameter
						let schema = globalOrgData.data;
						for (let n = 0; n < refArray.length - 1; n += 1) {
							const ref = refArray[n];
							if (!schema[ref]) schema[ref] = {};
							schema = schema[ref];
						}
						schema[refArray[refArray.length - 1]] = columnValues;
						break;
					}
				}
			}
		}

		// Add organization
		globalOrgDataArray.push(globalOrgData);
	}

	// If new organizations, add
	if (globalOrgDataArray.length > 0) {

		// Validate subscription
		const subscriptionResult = await validateSubscription(globalOrgDataArray.length);
		if (subscriptionResult !== true) {
			document.getElementById('confirm-action-button-text').innerHTML = 'Submit';
			document.getElementById('confirm-action-button-arrow').style.display = 'inline-block';
			document.getElementById('confirm-button-activity-indicator').style.display = 'none';
			return;
		}

		// Handle data import
		await Parse.Cloud.run('handleDataImportForUser', {
			dataArray: globalOrgDataArray, type: 'organization', platform: 'web'
		}).then(async () => {

			// Update display
			document.getElementById('confirm-action-button-arrow').style.display = 'none';
			document.getElementById('confirm-button-activity-indicator').style.display = 'none';
			document.getElementById('confirm-action-button-text').innerHTML = 'Processing...';

			// Show confirmation modal
			const html = `<p>
				We are currently processing your import. When it's ready, you will receive a confirmation email with instructions to view your new organizations.
				<br><br><b>Row(s) Imported: </b> ${globalOrgDataArray.length}<br></br>
				<b>Row(s) Skipped: </b>0
			</p>`;

			document.getElementById('confirm-confirm-message-holder').innerHTML = html;
			inputModule.showModalWithId('confirm-confirm-modal');

			// Set delay to Organizations
			setTimeout(() => { appModule.handleRouting(`/dashboard/${window.origin_importFile}`); }, 6000);

		}).catch((error) => {

			// Update display
			document.getElementById('confirm-action-button-text').innerHTML = 'Submit';
			document.getElementById('confirm-action-button-arrow').style.display = 'inline-block';
			document.getElementById('confirm-button-activity-indicator').style.display = 'none';

			// Show error modal
			const html = `<p>${error.message}</p>`;
			document.getElementById('confirm-error-message-holder').innerHTML = html;
			inputModule.showModalWithId('confirm-error-modal');
		});
	} else {

		// Update display
		document.getElementById('confirm-action-button-text').innerHTML = 'Submit';
		document.getElementById('confirm-action-button-arrow').style.display = 'inline-block';
		document.getElementById('confirm-button-activity-indicator').style.display = 'none';

		// Show error modal
		const html = '<p>The data being imported already exists. Please try again.</p>';
		document.getElementById('confirm-error-message-holder').innerHTML = html;
		inputModule.showModalWithId('confirm-error-modal');
	}
};


/**
 * User Handlers
 */

const saveUsersFromImport = async () => {

	// Initialize user data
	const globalUserDataArray = [];

	// Iterate through file data
	for (let i = 0; i < window.import_globalFileData.length; i += 1) {

		// Initialize user data
		const globalUserData = {
			data: {}
		};

		// Get import data
		const importData = window.import_globalFileData[i];

		// Get parameters
		const role = getRole(importData.role);
		const orgId = await getOrganizationId(importData.organization); // eslint-disable-line no-await-in-loop

		// Set global state
		globalUserData.firstName = importData.firstName;
		globalUserData.lastName = importData.lastName;
		globalUserData.email = importData.email;
		globalUserData.role = importData.role ? role : 'standard';
		globalUserData.roles = [globalUserData.role];
		globalUserData.organizationId = importData.organization ? orgId : '';

		// Set user inputs
		const inputArray = confirmGlobalConfiguration.input.user_profile.fields;

		// Update user for columns
		for (let j = 0; j < inputArray.length; j += 1) {
			const columnLabel = inputArray[j].prompt;
			const refArray = inputArray[j].ref;
			const { type } = inputArray[j];

			// Iterate through file header
			for (let k = 0; k < window.import_globalFileHeader.length; k += 1) {
				if (columnLabel === window.import_globalFileHeader[k]) {
					if (type === 'text' || type === 'number' || type === 'quantity' || type === 'price' || type === 'stockLevelThreshold') {

						// Get parameter
						const parameter = window.import_globalFileData[i][inputModule.toCamelCase(window.import_globalFileHeader[k])];

						// Set parameter
						let schema = globalUserData.data;
						for (let m = 0; m < refArray.length - 1; m += 1) {
							const ref = refArray[m];
							if (!schema[ref]) schema[ref] = {};
							schema = schema[ref];
						}
						schema[refArray[refArray.length - 1]] = parameter;
						break;
					} else if (type === 'choice') {

						// Get parameter
						for (let l = 0; l < inputArray[j].choices.length; l += 1) {
							const columnValue = window.import_globalFileData[i][inputModule.toCamelCase(window.import_globalFileHeader[k])];
							if (columnValue === inputArray[j].choices[l].value) {

								// Set parameter
								let schema = globalUserData.data;
								for (let m = 0; m < refArray.length - 1; m += 1) {
									const ref = refArray[m];
									if (!schema[ref]) schema[ref] = {};
									schema = schema[ref];
								}
								schema[refArray[refArray.length - 1]] = inputArray[j].choices[l].id;
								break;
							}
						}
					} else if (type === 'array') {

						// Get parameter
						const columnValues = window.import_globalFileData[i][inputModule.toCamelCase(window.import_globalFileHeader[k])].split(',');
						for (let m = 0; m < columnValues.length; m += 1) {
							columnValues[m] = columnValues[m].trim();
						}

						// Set parameter
						let schema = globalUserData.data;
						for (let m = 0; m < refArray.length - 1; m += 1) {
							const ref = refArray[m];
							if (!schema[ref]) schema[ref] = {};
							schema = schema[ref];
						}
						schema[refArray[refArray.length - 1]] = columnValues;
						break;
					}
				}
			}
		}

		// Append user
		globalUserDataArray.push(globalUserData);
	}

	// If new users, add
	if (globalUserDataArray.length > 0) {

		// Save users
		await Parse.Cloud.run('handleDataImportForUser', {
			dataArray: globalUserDataArray, type: 'user', platform: 'web'
		}).then(async () => {

			// Update display
			document.getElementById('confirm-action-button-arrow').style.display = 'none';
			document.getElementById('confirm-button-activity-indicator').style.display = 'none';
			document.getElementById('confirm-action-button-text').innerHTML = 'Processing...';

			// Show confirmation modal
			const html = `<p>
				We are currently processing your import. When it's ready, you will receive a confirmation email with instructions to view your new users.
				<br><br><b>Row(s) Imported: </b> ${globalUserDataArray.length}<br></br>
				<b>Row(s) Skipped: </b>0
			</p>`;

			document.getElementById('confirm-confirm-message-holder').innerHTML = html;
			inputModule.showModalWithId('confirm-confirm-modal');

			// Set delay to users
			setTimeout(() => { appModule.handleRouting(`/dashboard/${window.origin_importFile}`); }, 6000);

		}).catch((error) => {

			// Update display
			document.getElementById('confirm-action-button-text').innerHTML = 'Submit';
			document.getElementById('confirm-action-button-arrow').style.display = 'inline-block';
			document.getElementById('confirm-button-activity-indicator').style.display = 'none';

			// Show error modal
			const html = `<p>${error.message}</p>`;
			document.getElementById('confirm-error-message-holder').innerHTML = html;
			inputModule.showModalWithId('confirm-error-modal');
		});
	} else {

		// Update display
		document.getElementById('confirm-action-button-text').innerHTML = 'Submit';
		document.getElementById('confirm-action-button-arrow').style.display = 'inline-block';
		document.getElementById('confirm-button-activity-indicator').style.display = 'none';

		// Show error modal
		const html = '<p>The data being imported already exists. Please try again.</p>';
		document.getElementById('confirm-error-message-holder').innerHTML = html;
		inputModule.showModalWithId('confirm-error-modal');
	}
};


/**
 * Handlers
 */

const onSubmitConfirm = () => {

	// Update display
	document.getElementById('confirm-action-button-text').innerHTML = '';
	document.getElementById('confirm-action-button-arrow').style.display = 'none';
	document.getElementById('confirm-button-activity-indicator').style.display = 'inline-block';

	// Save items for origin
	if (window.origin_importFile === 'users') {
		saveUsersFromImport();
	} else if (window.origin_importFile === 'assets') {
		saveAssetsFromImport();
	} else {
		saveOrgsFromImport();
	}
};

const moveToEditSubscription = () => {

	// Hide modal
	inputModule.hideModalWithId('org-subscription-upgrade-modal');

	// Move to edit subscription
	appModule.handleRouting('/dashboard/account/subscription?toggle=subscription');
};


/**
 * Action Handlers
 */

const createActionHandlers = () => {

	// Handle click on back button
	$('#confirm-back-upload').click(() => {
		backToUploadConfirm();
	});

	// Handle click on back button
	$('#confirm-back-define').click(() => {
		backToDefineConfirm();
	});

	// Handle click on submit button
	$('#confirm-submit-confirm').click(() => {
		onSubmitConfirm();
	});

	// Handle click on modal confirm button
	$('#confirm-error-modal-confirm').click(() => {
		inputModule.hideModalWithId('confirm-error-modal');
	});

	// Handle click on error modal confirm button
	$('#confirm-archive-error-modal-confirm').click(() => {
		inputModule.hideModalWithId('confirm-archive-error-modal');
	});

	// Handle click on upgrade modal cancel button
	$('#confirm-subscription-upgrade-modal-cancel').click(() => {
		inputModule.hideModalWithId('confirm-subscription-upgrade-modal');
	});

	// Handle click on upgrade modal upgrade button
	$('#confirm-subscription-upgrade-modal-upgrade').click(() => {
		moveToEditSubscription();
	});

	// Handle click on transition modal confirm button
	$('#confirm-subscription-transition-modal-confirm').click(() => {
		inputModule.hideModalWithId('confirm-subscription-transition-modal');
	});
};


/**
 * State Handlers
 */

exports.handlerDidLoad = async () => {

	// Ensure origin exists
	if (window.origin_importFile != null) {

		// Create action handlers
		createActionHandlers();

		// Fetch configuration
		const config = await userModule.handleConfigurationForUser(false);
		confirmGlobalConfiguration = JSON.parse(config);

		// Get elements
		const holder = document.getElementById('confirm-table-holder');

		// Ensure element is loaded
		if (holder != null) {

			// Initialize content
			let htmlContent = '';

			// Create collection content
			let html = "<div class='card-content'>"
				+ "<div style='overflow-x:scroll;'>"
				+ '<table><tr>';

			// Create column headers
			for (let j = 0; j < window.import_globalFileHeader.length; j += 1) {
				const column = window.import_globalFileHeader[j];
				html += (`<th>${(window.origin_importFile === 'assets' ? column : inputModule.camelToTitle(inputModule.toCamelCase(column)))}</th>`);
			}
			html += '</tr>';
			const importSelectedData = window.import_globalFileData.length > 11 ? window.import_globalFileData.slice(0, 10) : window.import_globalFileData.slice(0, window.import_globalFileData.length);

			// Iterate through data
			for (let j = 0; j < importSelectedData.length; j += 1) {
				const data = importSelectedData[j];

				// Create new row
				html += '<tr>';
				html += addDataToTableContent(data);
				html += '</tr>';
			}

			// Append html
			html += '</table>';
			htmlContent += (`${html}</div></div>`);

			// Set content
			holder.innerHTML = htmlContent;
		}
	} else {
		appModule.handleRouting('/dashboard/assets');
	}
};

exports.handlerDidAppear = async () => { };
