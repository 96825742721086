// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

// CSS
require('intl-tel-input/build/css/intlTelInput.css');

// Javascript
require('intl-tel-input');
const moment = require('moment-timezone');
const inputModule = require('../modules/input');
const helperModule = require('../modules/helper');
const userModule = require('../modules/user');
const externalUserModule = require('../modules/external-user');
const appModule = require('../modules/app');
const { getCookie } = require('../modules/utilities');


/**
 * State
 */

let personGlobalPersonId;
let personGlobalPersonData;
let personGlobalPersonType;
let personGlobalConfiguration;
let personGlobalOrganization;
let personGlobalAvailableTypes;
let personGlobalActivityPersonType;
let personGlobalHealthHistory;


/**
 * Helpers
 */

const updateCurrentHealthStatus = () => {
	let healthStatus = '<span class="health-span none">No Data</span><div class="health-indicator right none"></div>';
	if (personGlobalHealthHistory != null && personGlobalHealthHistory.length > 0) {
		const currentLevel = personGlobalHealthHistory[personGlobalHealthHistory.length - 1].level;
		if (currentLevel === 'green') {
			healthStatus = '<span class="health-span green">Healthy</span><div class="health-indicator right green"></div>';
		} else if (currentLevel === 'yellow') {
			healthStatus = '<span class="health-span yellow">Check In</span><div class="health-indicator right yellow"></div>';
		} else if (currentLevel === 'red') {
			healthStatus = '<span class="health-span red">Not Healthy</span><div class="health-indicator right red"></div>';
		}
	}
	$("[name='person-current-health-status']").html(healthStatus);
};


/**
 * Health History Handlers
 */

const populateHealthHistoryDisplay = (component) => {

	// Get elements
	const container = document.getElementById(`${component}-health-history-container`);
	const holder = document.getElementById(`${component}-health-history-holder`);

	// Update display
	if (personGlobalHealthHistory != null && personGlobalHealthHistory.length > 0) {

		// Limit length
		let limiter = 0;
		if (component === 'person' && personGlobalHealthHistory.length > 4) {
			limiter = (personGlobalHealthHistory.length - 4);
		}

		// Create health history
		let html = '';
		for (let i = personGlobalHealthHistory.length - 1; i >= limiter; i -= 1) {

			// Get parameters
			const healthHistory = personGlobalHealthHistory[i];
			const { date, level } = healthHistory;

			// Build dates
			const dateString = moment(date).format('MMM D');
			const dateTime = moment(date).format('h:mm a');

			// Build status
			let healthStatus = '<div class="health-indicator left none"></div><span class="health-span none">No Data</span>';
			if (level === 'green') {
				healthStatus = '<div class="health-indicator left green"></div><span class="health-span green">Healthy</span>';
			} else if (level === 'yellow') {
				healthStatus = '<div class="health-indicator left yellow"></div><span class="health-span yellow">Check In</span>';
			} else if (level === 'red') {
				healthStatus = '<div class="health-indicator left red"></div><span class="health-span red">Not Healthy</span>';
			}

			// Create content
			html += `
			<div class="row health-history-row">
				<div class="small-3 columns align-left">${dateString}</div>
				<div class="small-5 columns align-left">Answered at ${dateTime}</div>
				<div class="small-4 columns align-right">${healthStatus}</div>
			</div>
			<div class="person-activity-divider"></div>
			`;
		}

		// Update content
		holder.innerHTML = html;

		// Update visibility
		container.style.display = 'block';

	} else {

		// Update visibility
		container.style.display = 'none';
	}
};

const populateHealthHistoryFeed = async () => {

	// Set person health history
	personGlobalHealthHistory = personGlobalPersonData.history.health;

	// Update current health status
	updateCurrentHealthStatus();

	// Get person type
	personGlobalActivityPersonType = personGlobalConfiguration.input.personnel_type_schemas[personGlobalPersonData.type].display;

	// Update content
	document.getElementById('personhistory-error-label').innerHTML = `This ${personGlobalActivityPersonType} Hasn't Updated Their Health History Yet.`;

	// Populate health history
	populateHealthHistoryDisplay('personhistory');
};


/**
 * Activity Handlers
 */

const populateActivityFeed = async () => {

	// Get person type
	personGlobalActivityPersonType = personGlobalConfiguration.input.personnel_type_schemas[personGlobalPersonData.type].display;

	// Update content
	document.getElementById('personactivity-error-label').innerHTML = `This ${personGlobalActivityPersonType} Hasn't Been Updated Yet. Begin The Edit Process To See Updates.`;

	// Parse activity array
	const activityArray = [];
	const { activity: activityObj } = personGlobalPersonData;
	if (activityObj != null) {
		const { events } = activityObj;
		if (events != null) {
			for (let i = 0; i < events.length; i += 1) {
				const status = events[i];
				activityArray.push(status);
			}
		}
	}

	// Get elements
	const feedHolder = document.getElementById('personactivity-feed-holder');
	const errorHolder = document.getElementById('personactivity-error-holder');

	// Update display
	if (activityArray.length === 0) {
		feedHolder.style.display = 'none';
		errorHolder.style.display = 'block';
	} else {
		feedHolder.style.display = 'block';
		errorHolder.style.display = 'none';
	}

	// Build content
	let html = '';
	for (let i = 0; i < activityArray.length; i += 1) {

		// Get activity
		const activity = activityArray[i];

		// Get parameters
		let actionString = '';
		let nameString = '';
		let dateString = '';
		const {
			action,
			user_name: userName,
			date
		} = activity;
		if (action != null) {

			// TODO - ACTIONS FOR ANSWERING SURVEY

			switch (action) {
				case 'create':
					actionString = `${personGlobalActivityPersonType} Created`;
					break;
				case 'edit':
					actionString = `${personGlobalActivityPersonType} Edited`;
					break;
				default:
					break;
			}
		}
		if (userName != null) {
			nameString = userName;
			const { platform } = activity;
			if (platform != null) {
				switch (platform) {
					case 'ios':
						nameString += ' (iOS)';
						break;
					case 'android':
						nameString += ' (Android)';
						break;
					case 'web':
						nameString += ' (Web)';
						break;
					default:
						break;
				}
			}
		}
		if (date != null) {
			dateString = `${moment(date).format('MMM D, YYYY')} at ${moment(date).format('h:mm a')}`;
		}

		// Create content
		html += `<div class="person-activity-holder">
        <h4>Action</h4>
        <h3>${actionString}</h3>
        <div class="person-activity-divider"></div>
        <h4>User</h4>
        <h3>${nameString}</h3>
        <div class="person-activity-divider"></div>
        <h4>Date</h4>
        <h3>${dateString}</h3>
        </div>`;
	}

	// Set content
	feedHolder.innerHTML = html;
};


/**
 * Handlers
 */

const populatePersonSchema = () => {

	// Get current user
	const currentUser = Parse.User.current();

	// Get user role
	const userRole = getCookie('userRole');

	// Get elements
	const formHolder = document.getElementById('person-form-holder');

	// Ensure element is loaded
	$(formHolder).ready(() => {

		// Get type
		let type = personGlobalPersonType;
		if (type == null) {
			type = personGlobalPersonData.type;
			if (type == null) {

				// Attempt to get last scan type for user
				if (currentUser.get('data').last_personnel_type != null
					&& currentUser.get('data').last_personnel_type !== '') {
					type = currentUser.get('data').last_personnel_type;
				} else { // If null, get first available type in schema
					// eslint-disable-next-line no-restricted-syntax
					for (const property in personGlobalConfiguration.input.personnel_type_schemas) {
						if (Object.prototype.hasOwnProperty.call(personGlobalConfiguration.input.personnel_type_schemas, property)) {
							type = property;
							break;
						}
					}
				}
			}
		}

		// Get schema
		const inputArray = personGlobalConfiguration.input.personnel_type_schemas[type].input;

		// Set person type
		personGlobalPersonType = type;
		if (personGlobalPersonData.type == null) {
			personGlobalPersonData.type = type;
		}

		// If not new, format health history
		if (personGlobalPersonId !== 'new') {

			// Set person health history
			personGlobalHealthHistory = personGlobalPersonData.history.health;

			// Update current health status
			updateCurrentHealthStatus();
		}

		// Get person data
		let { data } = personGlobalPersonData;
		if (data == null) data = {};

		// Check for user defaults
		for (let i = 0; i < inputArray.length; i += 1) {
			const input = inputArray[i];
			if (input.ref != null && input.ref.length > 0) {
				if (input.user_default != null && data[input.ref[0]] == null) {
					const userDefault = input.user_default;
					if (userDefault === 'firstName' || userDefault === 'lastName' || userDefault === 'email') {
						if (currentUser.get(userDefault) != null) {
							data[input.ref[0]] = currentUser.get(userDefault);
						}
					} else if (currentUser.get('data')[userDefault] != null) {
						data[input.ref[0]] = currentUser.get('data')[userDefault];
					}
				}
			}
		}

		// Set person data
		personGlobalPersonData.data = data;

		// Get identifier value
		let idValue = '';
		if (personGlobalPersonData.identifier != null) {
			idValue = ` value= '${personGlobalPersonData.identifier.replace(/"/g, '&#34;').replace(/'/g, '&#39;')}'`;
		}

		// Get identifier value
		let emailValue = '';
		if (personGlobalPersonData.email != null) {
			emailValue = ` value= '${personGlobalPersonData.email}'`;
		}

		// Get identifier value
		let phoneValue = '';
		if (personGlobalPersonData.phone != null) {
			phoneValue = ` value= '${personGlobalPersonData.phone}'`;
		}

		// Create identifier field
		const personPrompt = `${personGlobalConfiguration.input.personnel_type_schemas[personGlobalPersonType].display} #`;
		let html = `<fieldset style='margin:0px auto 0px;'>
            <input id='identifier' name='identifier' type='text' data-person-prompt='${personPrompt}' ${idValue}
             style='margin:0px;' required><label for='identifier' id='identifierLabel'>${personPrompt}</label>
            </fieldset>`;

		// Create type select if more than one available types
		if (personGlobalAvailableTypes.length > 1) {
			html += (`<fieldset style='margin:15px auto 0px;'>
                <select id='person-type-person-input-element'>`);

			// Create options
			for (let i = 0; i < personGlobalAvailableTypes.length; i += 1) {
				let selected = '';
				if (personGlobalAvailableTypes[i].value === type) {
					selected = " selected='selected' ";
				}
				html += (`<option ${selected}value='${
					personGlobalAvailableTypes[i].value.replace(/"/g, '&#34;').replace(/'/g, '&#39;')}'>${
					personGlobalAvailableTypes[i].display}</option>`);
			}
			html += (`</select>
                <label for='personType' id='personTypeLabel'>Type</label>
                </fieldset>`);
		}

		// Create owner field (disabled)
		if ((userRole === 'leader' || userRole === 'super' || userRole === 'admin') && personGlobalPersonData.user != null) {
			html += (`<fieldset style='margin:15px auto 0px;'>
                <input id='owner' name='owner' type='text' style='margin:0px;' disabled value='${personGlobalPersonData.user.replace(/"/g, '&#34;').replace(/'/g, '&#39;')}'
                required><label for='owner' id='ownerLabel'>Owner</label>
                </fieldset>`);
		}

		// Create schema for type
		for (let i = 0; i < inputArray.length; i += 1) {

			// Get configuration
			const inputConfig = inputArray[i];
			const { prompt } = inputConfig;
			const inputType = inputConfig.type;
			const refArray = inputConfig.ref;
			const inputId = `${inputConfig.id}-person-input`;
			const { editable } = inputConfig;
			const { choices } = inputConfig;

			// Get current data
			let refData = personGlobalPersonData.data;
			let displayData = '';
			for (let j = 0; j < refArray.length; j += 1) {
				const ref = refArray[j];
				const check = refData[ref];
				if (check != null) {
					if (Array.isArray(check)) {
						for (let k = 0; k < check.length; k += 1) {
							if (k !== 0) {
								displayData += ', ';
							}
							displayData += check[k];
						}
					} else if (typeof check === 'string') {
						displayData = check;
					} else if (typeof check === 'object') {
						refData = check;
					}
				}
			}

			// Create input for type
			if (inputType === 'text' || inputType === 'array' || inputType === 'number') { // Input

				// Get input type
				let inputDisplayType = 'text';
				if (inputType === 'number') inputDisplayType = 'number';

				// Get enabled/disabled
				let enabledState = '';
				if (editable === false) enabledState = ' disabled ';

				// If numeric input, display increment / decrement buttons
				let buttonContent = '';
				if (inputType === 'number') {
					buttonContent = `<div class='incr-decr-holder'>
                    <button class='increment-button animate' name='person-increment-button' data-input-id='${inputId}'>
                    <i class="fas fa-plus"></i>
                    </button>
                    <button class='decrement-button animate' name='person-decrement-button' data-input-id='${inputId}'>
                    <i class="fas fa-minus"></i>
                    </button>
                    </div>`;
				}

				// Set html
				html += (`<fieldset style='margin:15px auto 0px;'>
                    <input id='${inputId}' name='${inputId}' data-prompt='${prompt}' type='${inputDisplayType}'
                    ${enabledState} value='${displayData.replace(/"/g, '&#34;').replace(/'/g, '&#39;')}'
                     style='margin:0px;' required><label for='${inputId}' id='${inputId}Label'>${prompt}</label>
                     ${buttonContent}</fieldset>`);
			} else if (inputType === 'choice' && choices != null && choices.length > 0) {

				// Set html
				html += (`<fieldset style='margin:15px auto 0px;'>
                    <select id='${inputId}' name='${inputId}' required>
                    <option value=''>Select an Option</option>`);

				// Create options
				for (let j = 0; j < choices.length; j += 1) {
					let selected = '';
					if (choices[j] != null && choices[j].id === displayData) {
						selected = " selected='selected' ";
					}
					html += (`<option ${selected}value='${choices[j].id}'>${
						choices[j].value}</option>`);
				}

				// Set html
				html += (`</select>
                    <label for='${inputId}' id='${inputId}Label' class='activated-label'>${prompt}</label>
                    </fieldset>`);
			} else if (displayData !== '') { // Image view
				displayData = displayData.replace('http://', 'https://');
				html += (`<img class='person-image-holder' id='${inputId}' src='${displayData}' alt='Person Preview' title='Person Preview'>`);
			}
		}

		// Create email field
		html += `<fieldset style='margin:15px auto 0px;'>
			<input id='personEmail' name='email' type='text' ${emailValue}
			 style='margin:0px;' required><label for='personEmail' id='personEmailLabel'>Email</label>
			</fieldset>`;

		// Create phone field
		html += `<fieldset style='margin:15px auto 0px;'>
			<input id='personPhone' name='phone' type='tel' placeholder='Phone Number' style='padding:20px 0px 20px 55px !important;' ${phoneValue} required></input>
			</fieldset>`;


		// TODO - PREFERENCE FOR SENDING SELECTION


		// Add organization dropdown if super or admin role
		if (userRole === 'super' || userRole === 'admin') {

			// Add org selection field
			html += (`<fieldset style='margin:15px auto 0px;'>
                <select id='person-org-person-input'>`);

			// Create options
			for (let i = 0; i < window.personGlobalOrganizationArray.length; i += 1) {
				let selected = '';
				if (personGlobalPersonData.organizationId != null && window.personGlobalOrganizationArray[i].id === personGlobalPersonData.organizationId) {
					selected = " selected='selected' ";
				}
				html += (`<option ${selected}value='${window.personGlobalOrganizationArray[i].id}'>
                    ${window.personGlobalOrganizationArray[i].name}</option>`);
			}
			html += (`</select>
                <label for='personOrg' id='personOrgLabel'>Person's Organization</label>
                </fieldset>`);
		}

		// Add html content
		formHolder.innerHTML = html;

		// Format phone number
		$('#personPhone').intlTelInput({
			autoPlaceholder: 'off',
			formatOnDisplay: true,
			utilsScript: '/js/intl-tel-input/utils.js'
		});

		// Update health history display
		populateHealthHistoryDisplay('person');
	});
};

const handleDuplicatePerson = () => {

	// Display confirmation
	inputModule.showModalWithId('person-duplicate-confirmation');
};

const performDuplicatePerson = () => {

	// Hide options button
	$('#person-options-menu-holder').hide();
	$('#person-current-health-status').hide();
	$('#person-health-status-holder').hide();

	// Set content
	document.getElementById('person-action-button-text').innerHTML = 'Save Person';
	document.getElementById('person-title-label').innerHTML = 'Save Your Person';

	// Reset globals
	personGlobalPersonType = null;

	// Create new person data
	const newPersonData = JSON.parse(JSON.stringify(personGlobalPersonData));
	newPersonData.id = null;
	newPersonData.objectId = null;
	personGlobalPersonData = newPersonData;

	// Populate person schema
	populatePersonSchema();

	// Hide confirmation modal
	inputModule.hideModalWithId('person-duplicate-confirmation');
};

const personTypeDidChange = (selectObj) => {
	personGlobalPersonType = selectObj.value;
	populatePersonSchema();
};

const handlePersonPageActionButton = async () => {

	// Get user role
	const userRole = getCookie('userRole');

	// Get general parameters
	const identifier = document.getElementById('identifier').value.replace(/-/g, '').replace(/\s/g, '');
	const email = document.getElementById('personEmail').value;
	const phone = $('#personPhone').intlTelInput('getNumber', intlTelInputUtils.numberFormat.E164);
	const type = personGlobalPersonType;

	// Validate general parameters
	if (identifier === '') {

		// Display error
		const identifierLabel = document.getElementById('identifierLabel');
		identifierLabel.innerHTML = 'Person ID (Required)';
		identifierLabel.className = 'errorLabel';
		identifierLabel.focus();

	} else if (type == null || type === '') {

		// Display error
		inputModule.showModalWithId('person-type-error-modal');

	} else {

		// Update display
		const buttonTitle = document.getElementById('person-action-button-text').innerHTML;
		document.getElementById('person-action-button').disabled = true;
		document.getElementById('person-action-button-text').innerHTML = '';
		document.getElementById('person-action-button-arrow').style.display = 'none';
		document.getElementById('person-button-activity-indicator').style.display = 'block';

		// Set general parameters
		personGlobalPersonData.identifier = identifier;
		personGlobalPersonData.email = email;
		personGlobalPersonData.phone = phone;
		personGlobalPersonData.type = type;

		// Get person organization
		let organizationId;
		if (userRole === 'standard' || userRole === 'leader') {
			organizationId = personGlobalOrganization.id;
		} else if (userRole === 'super' || userRole === 'admin') {
			organizationId = document.getElementById('person-org-person-input').value;
		}

		// Validate organization
		if (organizationId != null) {

			// Set organization
			personGlobalPersonData.organizationId = organizationId;

			// Get specific parameters
			const inputArray = personGlobalConfiguration.input.personnel_type_schemas[type].input;
			for (let i = 0; i < inputArray.length; i += 1) {

				// Get configuration
				const inputConfig = inputArray[i];
				const inputType = inputConfig.type;
				const refArray = inputConfig.ref;
				const inputId = `${inputConfig.id}-person-input`;
				const { editable } = inputConfig;

				// Check if should save parameter
				if (editable === true) {

					// Get current parameter
					let parameter;
					if (inputType !== 'image') {
						if (document.getElementById(inputId)) {
							parameter = document.getElementById(inputId).value;
						}
					} else if (document.getElementById(inputId)) {
						parameter = document.getElementById(inputId).src;
					}

					// Validate parameter
					if (parameter != null) {
						if (inputType === 'array') {
							parameter = parameter.split(',');
							for (let j = 0; j < parameter.length; j += 1) {
								parameter[j] = parameter[j].trim();
							}
						}

						// Set parameter
						let schema = personGlobalPersonData.data;
						for (let j = 0; j < refArray.length - 1; j += 1) {
							const ref = refArray[j];
							if (!schema[ref]) schema[ref] = {};
							schema = schema[ref];
						}
						schema[refArray[refArray.length - 1]] = parameter;
					}
				}
			}

			// Set person data
			const personDataToSave = JSON.parse(JSON.stringify(personGlobalPersonData));
			personDataToSave.personnelData = personDataToSave.data;
			personDataToSave.data = null;

			// Set person object id
			personDataToSave.objectId = personDataToSave.id;

			// Save person
			Parse.Cloud.run('savePersonnelWithParameters', { personnelArray: [personDataToSave], platform: 'web' }).then(async (personEntity) => {

				// Get person data
				const people = await userModule.getPeopleForUser(false);

				// Set last_personnel_type
				const currentUser = Parse.User.current();
				currentUser.get('data').last_personnel_type = type;

				// Add standard parameters
				const now = new Date().toISOString();
				personGlobalPersonData.createdAt = now;
				personGlobalPersonData.updatedAt = now;

				// Set new person data to storage
				let foundPerson = false;
				for (let i = 0; i < people.length; i += 1) {
					if (people[i].id === personGlobalPersonData.id) {
						personGlobalPersonData.user = people[i].user;
						people[i] = personGlobalPersonData;
						foundPerson = true;
						break;
					}
				}
				if (foundPerson === false) {

					// Create current user name
					const userName = `${currentUser.get('firstName')} ${currentUser.get('lastName')}`;

					// Create organization name
					const orgName = personGlobalOrganization.get('name');
					if (orgName != null) personGlobalPersonData.organization = orgName;

					// Update global count
					if (window.peopleGlobalTotalNumber != null) window.peopleGlobalTotalNumber += 1;

					// Set parameters and append
					personGlobalPersonData.user = userName;
					personGlobalPersonData.id = personEntity.id;
					personGlobalPersonData.searchTag = personEntity.searchTag;
					personGlobalPersonData.personCount = window.peopleGlobalTotalNumber;
					people.unshift(personGlobalPersonData);

					// Set global count
					people[people.length - 1].personCount = window.peopleGlobalTotalNumber;
				}

				// Set person array to storage
				window.peopleGlobalPeopleArray = [...people];

				// Update state
				window.peopleShouldReloadPeople = true;

				// Update display
				document.getElementById('person-button-activity-indicator').style.display = 'none';
				document.getElementById('person-action-button-text').innerHTML = 'Successfully Saved Person';

				// Set delay to Groups
				setTimeout(() => { appModule.handleRouting('/dashboard/groups'); }, 1000);

			}).catch((error) => {
				if (error && error.code === 209) {
					externalUserModule.handleUserLogOut(true);
				} else {

					// Update display
					document.getElementById('person-action-button').disabled = false;
					document.getElementById('person-action-button-text').innerHTML = buttonTitle;
					document.getElementById('person-action-button-arrow').style.display = 'inline-block';
					document.getElementById('person-button-activity-indicator').style.display = 'none';

					// Display error
					inputModule.showModalWithId('person-save-error-modal');
				}
			});
		} else {

			// Update display
			document.getElementById('person-action-button').disabled = false;
			document.getElementById('person-action-button-text').innerHTML = buttonTitle;
			document.getElementById('person-action-button-arrow').style.display = 'inline-block';
			document.getElementById('person-button-activity-indicator').style.display = 'none';
		}
	}
};

const removePerson = () => {

	// Get user role
	const userRole = getCookie('userRole');

	// Validate user role
	if (userRole === 'leader' || userRole === 'super' || userRole === 'admin') {

		// Show remove confirmation
		inputModule.showModalWithId('person-single-archive-confirmation');
	}
};

const handleRemovePerson = (confirm) => {
	if (confirm === true && personGlobalPersonId != null) {

		// Hide remove confirmation
		inputModule.hideModalWithId('person-single-archive-confirmation');

		// Remove people from person dictionary
		const personArray = [];
		// eslint-disable-next-line no-restricted-syntax
		for (const property in window.peopleGlobalPeopleDictionary) {
			if (Object.prototype.hasOwnProperty.call(window.peopleGlobalPeopleDictionary, property)) {
				const displayArray = window.peopleGlobalPeopleDictionary[property];
				for (let i = displayArray.length - 1; i >= 0; i -= 1) {
					if (personGlobalPersonId === displayArray[i].id) {
						displayArray.splice(i, 1);
						break;
					}
				}
				Array.prototype.push.apply(personArray, displayArray);
			}
		}

		// Remove people from display dictionary
		// eslint-disable-next-line no-restricted-syntax
		for (const property in window.peopleGlobalDisplayDictionary) {
			if (Object.prototype.hasOwnProperty.call(window.peopleGlobalDisplayDictionary, property)) {
				const displayArray = window.peopleGlobalDisplayDictionary[property];
				for (let i = displayArray.length - 1; i >= 0; i -= 1) {
					if (personGlobalPersonId === displayArray[i].id) {
						displayArray.splice(i, 1);
					}
				}
			}
		}

		// Subtract from total
		if (window.peopleGlobalTotalNumber) window.peopleGlobalTotalNumber -= 1;

		// Set person array to session storage
		try {
			sessionStorage.setItem('user-people', JSON.stringify(personArray));
		} catch (e) { }

		// Archive personnel
		Parse.Cloud.run('archivePersonnelWithParameters', { personnelIDArray: [personGlobalPersonId], platform: 'web' }).then(async () => {

			// Get person data
			const people = await userModule.getPeopleForUser(false);

			// Set new person data to storage
			let personIndex = -1;
			for (let i = 0; i < people.length; i += 1) {
				if (people[i].id === personGlobalPersonData.id) {
					personIndex = i;
					break;
				}
			}
			if (personIndex > -1) people.splice(personIndex, 1);

			// Set person array to storage
			window.peopleGlobalPeopleArray = [...people];

			// Update state
			window.peopleShouldReloadPeople = true;

			// Back to people
			appModule.handleRouting('/dashboard/groups');

		}).catch((error) => {
			if (error && error.code === 209) {
				externalUserModule.handleUserLogOut(true);
			} else {

				// Show error
				inputModule.showModalWithId('person-single-archive-error-modal');
			}
		});
	} else {

		// Hide remove confirmation
		inputModule.hideModalWithId('person-single-archive-confirmation');
	}
};


/**
 * Action Handlers
 */

const createActionHandlers = () => {

	// Handle click on back button
	$('#person-back-button').click(() => {
		appModule.handleRouting('/dashboard/groups');
	});

	// Handle click on action button
	$('#person-action-button').click(() => {
		handlePersonPageActionButton();
	});

	// Handle click on delete button
	$('#person-delete-person-button').click(() => {
		removePerson();
	});

	// Handle click on modal confirm button
	$('#person-type-error-modal-confirm').click(() => {
		inputModule.hideModalWithId('person-type-error-modal');
	});

	// Handle click on modal confirm button
	$('#person-email-phone-error-modal-confirm').click(() => {
		inputModule.hideModalWithId('person-email-phone-error-modal');
	});

	// Handle click on modal confirm button
	$('#person-save-error-modal-confirm').click(() => {
		inputModule.hideModalWithId('person-save-error-modal');
	});

	// Handle click on modal cancel button
	$('#person-single-archive-confirmation-cancel').click(() => {
		handleRemovePerson(false);
	});

	// Handle click on modal remove button
	$('#person-single-archive-confirmation-remove').click(() => {
		handleRemovePerson(true);
	});

	// Handle click on modal confirm button
	$('#person-single-archive-error-modal-confirm').click(() => {
		inputModule.hideModalWithId('person-single-archive-error-modal');
	});

	// Handle click on modal remove button
	$('#person-person-duplicate-confirmation-duplicate').click(() => {
		performDuplicatePerson();
	});

	// Handle click on modal confirm button
	$('#person-duplicate-confirmation-cancel').click(() => {
		inputModule.hideModalWithId('person-duplicate-confirmation');
	});

	// Handle click on increment button
	$(document).off('click', "button[name='person-increment-button']");
	$(document).on('click', "button[name='person-increment-button']", function () {
		inputModule.handleIncrementInput($(this).data('input-id'), false);
	});

	// Handle click on decrement button
	$(document).off('click', "button[name='person-decrement-button']");
	$(document).on('click', "button[name='person-decrement-button']", function () {
		inputModule.handleDecrementInput($(this).data('input-id'), false);
	});

	// Handle click on options menu
	$('#person-options-menu').click(() => {
		helperModule.displayActionSheet('person-option-dropdown');
	});

	// Handle click on duplicate option
	$('#person-duplicate-person').click(() => {

		// Handle duplicate person
		handleDuplicatePerson();
	});

	// Handle click on person activity option
	$('#person-view-person-activity').click(() => {

		// Move to activity
		appModule.handleRouting(`/dashboard/person/activity/${personGlobalPersonId}`);
	});

	// Handle click on person health history
	$('#person-health-history').click(() => {

		// Move to activity
		appModule.handleRouting(`/dashboard/person/history/${personGlobalPersonId}`);
	});
};

const createActivityActionHandlers = () => {

	// Handle click on activity back button
	$(document).off('click', '#personactivity-back-button');
	$(document).on('click', '#personactivity-back-button', () => {
		appModule.handleRouting(`/dashboard/person/${personGlobalPersonId}`);
	});
};

const createHistoryActionHandlers = () => {

	// Handle click on history back button
	$(document).off('click', '#personhistory-back-button');
	$(document).on('click', '#personhistory-back-button', () => {
		appModule.handleRouting(`/dashboard/person/${personGlobalPersonId}`);
	});
};


/**
 * Input Handlers
 */

const createInputHandlers = () => {

	// Handle blur action
	$(document).off('blur', '#identifier');
	$(document).on('blur', '#identifier', function () {
		inputModule.removeAlert('identifierLabel', $(this).data('person-prompt'), true);
	});
	$(document).off('blur', '#owner');
	$(document).on('blur', '#owner', () => {
		const personPrompt = `${personGlobalConfiguration.input.personnel_type_schemas[personGlobalPersonData.type].display} #`;
		inputModule.removeAlert('ownerLabel', personPrompt, true);
	});
	$(document).off('blur', "[id$='-person-input']");
	$(document).on('blur', "[id$='-person-input']", function () {
		if (!$(this).is('select')) {
			inputModule.removeAlert(`${$(this).attr('id')}Label`, $(this).data('prompt'), true);
		} else {
			inputModule.removeBlockAlert();
		}
	});

	// Handle keydown action
	$(document).off('keydown', '#identifier');
	$(document).on('keydown', '#identifier', function () {
		inputModule.removeAlert('identifierLabel', $(this).data('person-prompt'), true);
	});
	$(document).off('keydown', '#owner');
	$(document).on('keydown', '#owner', () => {
		const personPrompt = `${personGlobalConfiguration.input.personnel_type_schemas[personGlobalPersonData.type].display} #`;
		inputModule.removeAlert('ownerLabel', personPrompt, true);
	});
	$(document).off('keydown', "[id$='-person-input']");
	$(document).on('keydown', "[id$='-person-input']", function () {
		inputModule.removeAlert(`${$(this).attr('id')}Label`, $(this).data('prompt'), true);
	});

	// Handle keyup action
	$(document).off('keyup', '#personPhone');
	$(document).on('keyup', '#personPhone', () => {
		$('#personPhone').intlTelInput('setNumber', $('#personPhone').val());
	});

	// Handle change content
	$(document).off('change', '#person-type-person-input-element');
	$(document).on('change', '#person-type-person-input-element', function () {
		personTypeDidChange(this);
	});

	// Handle focus action
	$(document).off('focus', '#identifier');
	$(document).on('focus', '#identifier', () => {
		inputModule.removeBlockAlert();
	});
	$(document).off('focus', '#owner');
	$(document).on('focus', '#owner', () => {
		inputModule.removeBlockAlert();
	});
	$(document).off('focus', "[id$='-person-input']");
	$(document).on('focus', "[id$='-person-input']", () => {
		inputModule.removeBlockAlert();
	});
	$(document).off('focus', '#personEmail');
	$(document).on('focus', '#personEmail', () => {
		inputModule.removeBlockAlert();
	});
	$(document).off('focus', '#personPhone');
	$(document).on('focus', '#personPhone', () => {
		inputModule.removeBlockAlert();
	});
};


/**
 * State Handlers
 */

exports.handlerDidLoad = async () => {

	// Create action handlers
	createActionHandlers();

	// Create input handlers
	createInputHandlers();

	// Reset variables
	personGlobalPersonData = null;
	personGlobalPersonType = null;
	personGlobalPersonId = null;

	// Fetch configuration for user
	const config = await userModule.handleConfigurationForUser(false);
	personGlobalConfiguration = JSON.parse(config);

	// Fetch organizations for user
	const { organization } = await externalUserModule.fetchConfigurationForUser();
	personGlobalOrganization = organization;

	// Fetch organizations for user
	const organizations = await userModule.fetchPlatformOrganizationsForUser(true);
	window.personGlobalOrganizationArray = [...organizations];

	// Get available types
	personGlobalAvailableTypes = [];
	Object.keys(personGlobalConfiguration.input.personnel_type_schemas).forEach((property) => {
		if (Object.prototype.hasOwnProperty.call(personGlobalConfiguration.input.personnel_type_schemas, property)) {
			personGlobalAvailableTypes.push({
				value: property,
				display: personGlobalConfiguration.input.personnel_type_schemas[property].display
			});
		}
	});

	// Get person id
	personGlobalPersonId = window.location.pathname.replace('/dashboard/person/', '');
	if (personGlobalPersonId !== 'new') { // Existing person

		// Get person data
		const people = await userModule.getPeopleForUser(false);
		for (let i = 0; i < people.length; i += 1) {
			if (people[i].id === personGlobalPersonId) {
				personGlobalPersonData = people[i];
				break;
			}
		}
		if (personGlobalPersonData != null && personGlobalPersonData.history != null) {

			// Hide loading indicator
			const loadingIndicator = document.getElementById('person-activity-indicator');
			$(loadingIndicator).ready(() => {
				loadingIndicator.remove();
			});

			// Get elements
			const optionsMenuHolder = document.getElementById('person-options-menu-holder');
			const currentHealthStatus = document.getElementById('person-current-health-status');
			const healthHistoryHolder = document.getElementById('person-health-status-holder');

			// Update display
			optionsMenuHolder.style.display = 'inline-block';
			currentHealthStatus.style.display = 'inline-block';
			healthHistoryHolder.style.display = 'block';

			// Populate schema fields
			populatePersonSchema();

		} else { // Fetch person with id
			Parse.Cloud.run('fetchPersonnelWithId', { fetchId: personGlobalPersonId }).then((person) => {
				if (person != null) {

					// Set personnel data
					personGlobalPersonData = person;

					// Hide loading indicator
					const loadingIndicator = document.getElementById('person-activity-indicator');
					$(loadingIndicator).ready(() => {
						loadingIndicator.remove();
					});

					// Get elements
					const optionsMenuHolder = document.getElementById('person-options-menu-holder');
					const currentHealthStatus = document.getElementById('person-current-health-status');
					const healthHistoryHolder = document.getElementById('person-health-status-holder');

					// Update display
					optionsMenuHolder.style.display = 'inline-block';
					currentHealthStatus.style.display = 'inline-block';
					healthHistoryHolder.style.display = 'block';

					// Populate schema fields
					populatePersonSchema();

				} else {
					appModule.handleRouting('/dashboard/groups');
				}
			}).catch(() => {
				appModule.handleRouting('/dashboard/groups');
			});
		}
	} else {
		personGlobalPersonData = {
			data: {}
		};

		// Hide loading indicator
		const loadingIndicator = document.getElementById('person-activity-indicator');
		$(loadingIndicator).ready(() => {
			loadingIndicator.remove();
		});

		// Populate schema fields
		populatePersonSchema();
	}
};

exports.handlerDidAppear = async () => { };

exports.handlerPersonActivity = async () => {

	// Create action handlers
	createActivityActionHandlers();

	// Get configuration
	if (personGlobalConfiguration == null) {
		const config = await userModule.handleConfigurationForUser(false);
		personGlobalConfiguration = JSON.parse(config);
	}

	// Get activity id
	personGlobalPersonId = window.location.pathname.replace('/dashboard/person/activity/', '');
	if (personGlobalPersonId != null && personGlobalPersonId !== '') {

		// Check for person data
		if (personGlobalPersonData != null && personGlobalPersonData.activity != null) {

			// Hide loading indicator
			$('#personactivity-activity-indicator').ready(() => {
				$('#personactivity-activity-indicator').remove();
			});

			// Create activity feed
			populateActivityFeed();

		} else { // Fetch person with id
			Parse.Cloud.run('fetchPersonnelWithId', { fetchId: personGlobalPersonId }).then((person) => {
				if (person != null) {

					// Set organization data
					personGlobalPersonData = person;

					// Hide loading indicator
					$('#personactivity-activity-indicator').ready(() => {
						$('#personactivity-activity-indicator').remove();
					});

					// Create activity feed
					populateActivityFeed();

				} else {
					appModule.handleRouting('/dashboard/groups');
				}
			}).catch(() => {
				appModule.handleRouting('/dashboard/groups');
			});
		}
	} else {
		appModule.handleRouting('/dashboard/groups');
	}
};

exports.handlerPersonHealthHistory = async () => {

	// Create action handlers
	createHistoryActionHandlers();

	// Get configuration
	if (personGlobalConfiguration == null) {
		const config = await userModule.handleConfigurationForUser(false);
		personGlobalConfiguration = JSON.parse(config);
	}

	// Get history id
	personGlobalPersonId = window.location.pathname.replace('/dashboard/person/history/', '');
	if (personGlobalPersonId != null && personGlobalPersonId !== '') {

		// Check for person data
		if (personGlobalPersonData != null && personGlobalPersonData.history != null) {

			// Hide loading indicator
			$('#personhistory-activity-indicator').ready(() => {
				$('#personhistory-activity-indicator').remove();
			});

			// Create history feed
			populateHealthHistoryFeed();

		} else { // Fetch person with id
			Parse.Cloud.run('fetchPersonnelWithId', { fetchId: personGlobalPersonId }).then((person) => {
				if (person != null) {

					// Set organization data
					personGlobalPersonData = person;

					// Hide loading indicator
					$('#personhistory-activity-indicator').ready(() => {
						$('#personhistory-activity-indicator').remove();
					});

					// Create history feed
					populateHealthHistoryFeed();

				} else {
					appModule.handleRouting('/dashboard/groups');
				}
			}).catch(() => {
				appModule.handleRouting('/dashboard/groups');
			});
		}
	} else {
		appModule.handleRouting('/dashboard/groups');
	}
};
