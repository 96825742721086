// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

const PhoneNumberUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
const inputModule = require('../modules/input');
const externalUserModule = require('../modules/external-user');
const userModule = require('../modules/user');
const helperModule = require('../modules/helper');
const appModule = require('../modules/app');
const { getCookie, formatDateTime } = require('../modules/utilities');


/**
 * State
 */

let peopleGlobalAvailableTypes;
let peopleGlobalSelectedFilter;
let peopleGlobalSelectedSortDict;
let peopleGlobalTableState;
let peopleGlobalSearchTimeout;
let peopleGlobalConfiguration;
let isSettingUpStickHeaders = false;


/**
 * Drag State
 */

let dragX;
let dragY;
let dragTop;
let dragLeft;
let dragDown;
let dragElement;
let isDragging = false;


/**
 * Global State
 */

window.peopleGlobalTotalNumber = 0;
window.peopleShouldReloadPeople = false;


/**
 * Handlers
 */

const updatePeopleDisplay = () => {

	// Get user role
	const userRole = getCookie('userRole');

	// Get elements
	const holder = document.getElementById('people-table-holder');
	const titleNumber = document.getElementById('people-title-number');
	const removeButton = document.getElementById('people-remove-button');

	// Create available types array
	let availableTypes = JSON.parse(JSON.stringify(peopleGlobalAvailableTypes));
	if (peopleGlobalSelectedFilter != null) availableTypes = [peopleGlobalSelectedFilter];

	// Ensure element is loaded
	if (titleNumber != null) {

		// Get total count
		let count = 0;
		const types = JSON.parse(JSON.stringify(peopleGlobalAvailableTypes));
		for (let i = 0; i < types.length; i += 1) {
			const property = types[i].value;
			if (window.peopleGlobalPeopleDictionary[property] != null) {
				count += window.peopleGlobalPeopleDictionary[property].length;
			}
		}

		// Set person count
		titleNumber.innerHTML = `(${inputModule.formatCountNumber(count)}/${inputModule.formatCountNumber(window.peopleGlobalTotalNumber)})`;
	}

	// Hide remove button
	if (removeButton != null) {
		removeButton.style.display = 'none';
		removeButton.enabled = true;
	}

	// Ensure element is loaded
	if (holder != null) {

		// Initialize content
		let htmlContent = '';

		// Create person type collections
		for (let i = 0; i < availableTypes.length; i += 1) {

			// Get person collection
			const type = availableTypes[i];
			let displayArray = window.peopleGlobalDisplayDictionary[type.value];
			if (displayArray == null) displayArray = [];
			const sortSchema = peopleGlobalSelectedSortDict[type.value];

			// Create collection content
			let html = `<h3>Group<span class='bubble'>${type.display}</span><span>(${displayArray.length})</span>
				<a id='people-table-load-more-${type.value}' class='load-more-button'>Load More<i class="fa fa-arrow-right"
				style="margin-left:5px; font-size:11px;" aria-hidden="true"></i></a></h3>
				<div class='card-content'><div class='table-container' id='people-table-view-${type.value}'><div>
				<table><thead><tr>`;

			// Get schema for type
			const columnArray = JSON.parse(JSON.stringify(peopleGlobalConfiguration.display.personnel_type_schemas[type.value].columns));

			// Append selector schema item
			let offset = 0;
			if (userRole === 'leader' || userRole === 'super' || userRole === 'admin') {
				columnArray.unshift({
					type: 'selector'
				});
				offset = 1;
			}

			// Create column headers
			for (let j = 0; j < columnArray.length; j += 1) {
				const column = columnArray[j];
				let arrow = '';
				if (sortSchema.column === (j - offset)) {
					if (sortSchema.reverse === false) {
						arrow = " <img src='/img/icons/menu-arrow.png' class='sort-arrow' alt='Sort Arrow' title='Sort Arrow'>";
					} else {
						arrow = " <img src='/img/icons/menu-arrow-open.png' class='sort-arrow' alt='Sort Arrow' title='Sort Arrow'>";
					}
				}
				if (column.type === 'selector') {
					html += "<th style='width:40px;'></th>";
				} else {
					html += `<th name='people-row-sort-element' data-type-value='${type.value}' data-offset='${(j - offset)}'>${column.label}${arrow}</th>`;
				}
			}
			html += '</tr></thead><tbody>';

			// Iterate through people
			for (let j = 0; j < displayArray.length; j += 1) {
				const person = displayArray[j];

				// Create new person row
				html += `<tr name='people-row-element' data-people-id='${person.id}'>`;
				for (let k = 0; k < columnArray.length; k += 1) {
					const column = columnArray[k];

					// Get content
					let fieldContent = '';
					if (column.type === 'health') {
						const { healthStatus } = person;
						if (healthStatus === 'green') {
							fieldContent = '<span class="health-span green">Healthy</span><div class="health-indicator right green"></div>';
						} else if (healthStatus === 'yellow') {
							fieldContent = '<span class="health-span yellow">Check In</span><div class="health-indicator right yellow"></div>';
						} else if (healthStatus === 'red') {
							fieldContent = '<span class="health-span red">Not Healthy</span><div class="health-indicator right red"></div>';
						} else {
							fieldContent = 'No Data';
						}
					} else if (column.type === 'identifier') {
						fieldContent = person.identifier;
					} else if (column.type === 'phone') {
						try {
							fieldContent = PhoneNumberUtil.formatInOriginalFormat(PhoneNumberUtil.parse(person.phone), 'US');
						} catch (e) {}
					} else if (column.type === 'email') {
						fieldContent = person.email;
					} else if (column.type === 'user') {
						fieldContent = person.user;
					} else if (column.type === 'organization') {
						fieldContent = person.organization;
					} else if (column.type === 'createdAt') {
						const date = new Date(person.createdAt);
						fieldContent = formatDateTime(date);
					} else if (column.type === 'updatedAt') {
						const date = new Date(person.updatedAt);
						fieldContent = formatDateTime(date);
					} else {
						let refData = person.data;
						if (column.ref != null) {
							for (let m = 0; m < column.ref.length; m += 1) {
								const ref = column.ref[m];
								const check = refData[ref];
								if (check != null) {
									if (Array.isArray(check)) {
										fieldContent = check;
									} else if (typeof check === 'string') {
										fieldContent = check;
									} else if (typeof check === 'object') {
										refData = check;
									}
								}
							}
						}
					}
					if (fieldContent != null && fieldContent !== '') {
						if (column.type === 'array' && fieldContent != null) fieldContent = fieldContent.join(', ');
						else if (column.type === 'choice' && fieldContent != null) {
							const { choices } = column;
							for (let m = 0; m < choices.length; m += 1) {
								if (choices[m].id === fieldContent) {
									fieldContent = choices[m].value;
									break;
								}
							}
						}
					} else fieldContent = '';

					// Set content
					if (column.type === 'selector') {
						html += (`<td style='width:40px; padding:0px 0px 0px 0px;'>
							</div>
							<div class='row-selector' name='people-selector' id='people-selector-${person.id}'
							data-people-id='${person.id}'></div>
							</div>
							</td>`);
					} else {
						html += (`<td>${fieldContent}</td>`);
					}
				}
				html += '</tr>';
			}

			// Append html
			html += '</tbody></table>';
			htmlContent += (`${html}</div></div>`);

			// If no people, append empty state
			if (displayArray.length === 0) {
				htmlContent += ("<h2 class='table-empty-state-text'>Create and add a person to this group to get started.</h2>");
			}
			htmlContent += '</div>';
		}

		// Set content
		holder.innerHTML = htmlContent;

		// Hide loading indicator
		const loadingIndicator = document.getElementById('people-activity-indicator');
		if (loadingIndicator != null) {
			if (loadingIndicator != null) {
				loadingIndicator.remove();
			}
		}

		// Set current scroll position
		Object.keys(peopleGlobalTableState).forEach((property) => {
			if (Object.prototype.hasOwnProperty.call(peopleGlobalTableState, property)) {
				const { scroll } = peopleGlobalTableState[property];
				$(`#people-table-view-${property}`).scrollLeft(scroll);
			}
		});

		// Set up table sticky table headers
		$('#people-table-holder table').stickyTableHeaders({
			fixedOffset: $('#header-section .header'),
			cacheHeaderHeight: true
		});

		// Handle action on enable sticky header
		$('#people-table-holder table').on('enabledStickiness.stickyTableHeaders', function () {

			// Set state
			isSettingUpStickHeaders = true;

			// Update header width
			const width = $(this).parent().width();
			$(this).find('.tableFloatingHeaderOriginal').each(function () {
				$(this).attr('style', `${$(this).attr('style')}; width: ${width}px !important`);
			});

		});

		// Handle actions on header width update
		$('#people-table-holder table').on('updatedWidth.stickyTableHeaders', function () {

			// Set state
			isSettingUpStickHeaders = false;

			// Get current scroll value
			const scrollLeft = $(this).parent().parent().scrollLeft();

			// Update header width
			const width = $(this).parent().width();
			$(this).find('.tableFloatingHeaderOriginal').each(function () {
				$(this).attr('style', `${$(this).attr('style')}; width: ${width}px !important`);
				if (scrollLeft > 0) $(this).scrollLeft(scrollLeft);
			});
		});

		// Handle actions on manual header scroll
		$('#people-table-holder table thead').scroll(function () {
			if (this.scrollLeft > 0 || isSettingUpStickHeaders !== true) {
				$(this).parent().parent().parent()
					.scrollLeft(this.scrollLeft);
			}
		});

		// Handle actions on manual table scroll
		$('#people-table-holder table thead').parent().parent().parent()
			.scroll(function () {
				if (this.scrollLeft > 0 || isSettingUpStickHeaders !== true) {
					$(this).find('table thead').scrollLeft(this.scrollLeft);
				}
			});
	}
};

const updatePeopleForUser = async (shouldReload, typeArray, page) => {

	// Initialize dictionaries
	window.peopleGlobalPeopleDictionary = {};
	window.peopleGlobalDisplayDictionary = {};

	// Get people for user
	const people = await userModule.getPeopleForUser(shouldReload, typeArray, page);

	// Set people to dictionaries
	for (let i = 0; i < people.length; i += 1) {
		const person = people[i];

		// Get person count
		if (i === people.length - 1) window.peopleGlobalTotalNumber = person.personCount;

		// Set people
		let typeArr = window.peopleGlobalPeopleDictionary[person.type];
		if (typeArr == null) typeArr = [];
		typeArr.push(person);
		window.peopleGlobalPeopleDictionary[person.type] = typeArr;
	}

	// Sort type array by largest number of people
	peopleGlobalAvailableTypes.sort((a, b) => {
		if (window.peopleGlobalPeopleDictionary[a.value] == null) window.peopleGlobalPeopleDictionary[a.value] = [];
		if (window.peopleGlobalPeopleDictionary[b.value] == null) window.peopleGlobalPeopleDictionary[b.value] = [];
		return window.peopleGlobalPeopleDictionary[b.value].length - window.peopleGlobalPeopleDictionary[a.value].length;
	});

	// Copy data to display dictionary
	window.peopleGlobalDisplayDictionary = JSON.parse(JSON.stringify(window.peopleGlobalPeopleDictionary));

	// Update person display
	$(document).ready(() => {
		updatePeopleDisplay();
	});
};

const loadMorePeople = (type) => {

	// Create type array
	const typeArray = [type];

	// Update people for user
	updatePeopleForUser(true, typeArray, window.peopleGlobalPageNumberDict[type] + 1);
};

const loadAllPeople = async () => {

	// Initialize dictionaries
	window.peopleGlobalPeopleDictionary = {};
	window.peopleGlobalDisplayDictionary = {};

	// Fetch all people
	const people = await userModule.fetchPeopleForUser(true);

	// Set people to dictionaries
	for (let i = 0; i < people.length; i += 1) {
		const person = people[i];

		// Get person count
		if (i === people.length - 1) window.peopleGlobalTotalNumber = person.personCount;

		// Set people
		let typeArray = window.peopleGlobalPeopleDictionary[person.type];
		if (typeArray == null) typeArray = [];
		typeArray.push(person);
		window.peopleGlobalPeopleDictionary[person.type] = typeArray;
	}

	// Sort type array by largest number of people
	peopleGlobalAvailableTypes.sort((a, b) => {
		if (window.peopleGlobalPeopleDictionary[a.value] == null) window.peopleGlobalPeopleDictionary[a.value] = [];
		if (window.peopleGlobalPeopleDictionary[b.value] == null) window.peopleGlobalPeopleDictionary[b.value] = [];
		return window.peopleGlobalPeopleDictionary[b.value].length - window.peopleGlobalPeopleDictionary[a.value].length;
	});

	// Copy data to display dictionary
	window.peopleGlobalDisplayDictionary = JSON.parse(JSON.stringify(window.peopleGlobalPeopleDictionary));

	// Update person display
	$(document).ready(() => {
		updatePeopleDisplay();
	});
};

const setupPeopleFilter = () => {

	// Get filter elements
	$(document).ready(() => {
		const elements = document.getElementsByName('people-type-filter');
		for (let i = 0; i < elements.length; i += 1) {
			const element = elements[i];

			// Ensure element is loaded
			if (element.children.length <= 1) {

				// Create person type filter options
				let html = '';
				for (let j = 0; j < peopleGlobalAvailableTypes.length; j += 1) {

					// Build new option
					html += (`<option value='${peopleGlobalAvailableTypes[j].value.replace(/"/g, '&#34;').replace(/'/g, '&#39;')}'>${peopleGlobalAvailableTypes[j].display}</option>`);
				}

				// Append option to filter
				element.innerHTML += html;
			}

		}
	});
};

const filterPeopleType = (element) => {

	// Get selected option
	peopleGlobalSelectedFilter = {
		value: element.options[element.selectedIndex].value,
		display: element.options[element.selectedIndex].text
	};
	if (peopleGlobalSelectedFilter.value === '') peopleGlobalSelectedFilter = null;

	// Update person display
	updatePeopleDisplay();
};

const performGlobalPeopleSearch = (searchValue) => {

	// Perform global search
	Parse.Cloud.run('performPersonnelSearchWithParameters', { searchText: searchValue }).then((people) => {

		// Create people dict
		const peopleDict = {};
		for (let i = 0; i < people.length; i += 1) {
			const person = people[i];

			// Set people
			let typeArray = peopleDict[person.type];
			if (typeArray == null) typeArray = [];
			typeArray.push(person);
			peopleDict[person.type] = typeArray;
		}

		// Iterate through person types
		Object.keys(window.peopleGlobalDisplayDictionary).forEach((property) => {
			if (Object.prototype.hasOwnProperty.call(window.peopleGlobalDisplayDictionary, property)) {
				let displayArray = (window.peopleGlobalDisplayDictionary[property] != null) ? window.peopleGlobalDisplayDictionary[property] : [];
				const personArray = (peopleDict[property] != null) ? peopleDict[property] : [];
				if (displayArray != null) {

					// Filter out existing people
					for (let i = 0; i < personArray.length; i += 1) {
						const person = personArray[i];
						let found = false;
						for (let j = 0; j < displayArray.length; j += 1) {
							const testPerson = displayArray[j];
							const { id } = person;
							const testId = testPerson.id;
							if (id != null && testId != null) {
								if (id === testId) found = true;
							}
						}
						if (found === false) {
							displayArray.push(person);
						}
					}
				} else {
					displayArray = [...personArray];
				}
				window.peopleGlobalDisplayDictionary[property] = [...displayArray];
			}
		});

		// Update person display
		updatePeopleDisplay();
	});
};

const performPeopleSearch = (element) => {

	// Cancel global search timer
	if (peopleGlobalSearchTimeout != null) clearTimeout(peopleGlobalSearchTimeout);

	// Get search value
	let searchValue = element.value;

	// If search value is empty, reset display array
	if (searchValue === '') {
		window.peopleGlobalDisplayDictionary = JSON.parse(JSON.stringify(window.peopleGlobalPeopleDictionary));
	} else {

		// Format search value
		searchValue = searchValue.toLowerCase().replace(/\W/g, '');

		// Update values matching search string
		Object.keys(window.peopleGlobalPeopleDictionary).forEach((property) => {
			if (Object.prototype.hasOwnProperty.call(window.peopleGlobalPeopleDictionary, property)) {
				const displayArray = window.peopleGlobalPeopleDictionary[property];
				const newDisplayArray = [];
				if (displayArray != null) {
					for (let i = 0; i < displayArray.length; i += 1) {
						const { searchTag } = displayArray[i];
						if (searchTag.indexOf(searchValue) > -1) newDisplayArray.push(displayArray[i]);
					}
				}
				window.peopleGlobalDisplayDictionary[property] = newDisplayArray;
			}
		});

		// Create global search timer
		peopleGlobalSearchTimeout = setTimeout(() => {
			performGlobalPeopleSearch(searchValue);
		}, 800);
	}

	// Update person display
	updatePeopleDisplay();
};

const performPeopleSort = (type, columnIndex) => {

	// Update selected sort
	let reverse = false;
	if (peopleGlobalSelectedSortDict[type].column === columnIndex) {
		if (peopleGlobalSelectedSortDict[type].reverse === false) {
			peopleGlobalSelectedSortDict[type].reverse = true;
			reverse = true;
		} else peopleGlobalSelectedSortDict[type].reverse = false;
	} else {
		peopleGlobalSelectedSortDict[type].column = columnIndex;
		peopleGlobalSelectedSortDict[type].reverse = false;
	}

	// Get sort column from schema
	const columnArray = peopleGlobalConfiguration.display.personnel_type_schemas[type].columns;
	const column = columnArray[columnIndex];

	// Sort display array
	window.peopleGlobalDisplayDictionary[type].sort((a, b) => {

		// Get content
		let aContent = '';
		let bContent = '';
		if (column.type === 'health') {
			const healthStatusA = a.healthStatus;
			const healthStatusB = b.healthStatus;
			aContent = -1;
			bContent = -1;
			if (healthStatusA === 'green') aContent = 2;
			else if (healthStatusA === 'yellow') aContent = 1;
			else if (healthStatusA === 'red') aContent = 0;
			if (healthStatusB === 'green') bContent = 2;
			else if (healthStatusB === 'yellow') bContent = 1;
			else if (healthStatusB === 'red') bContent = 0;
		} else if (column.type === 'identifier') {
			aContent = a.identifier;
			bContent = b.identifier;
		} else if (column.type === 'phone') {
			aContent = a.phone;
			bContent = b.phone;
		} else if (column.type === 'email') {
			aContent = a.email;
			bContent = b.email;
		} else if (column.type === 'user') {
			aContent = a.user;
			bContent = b.user;
		} else if (column.type === 'organization') {
			aContent = a.organization;
			bContent = b.organization;
		} else if (column.type === 'createdAt') {
			aContent = a.createdAt;
			bContent = b.createdAt;
		} else if (column.type === 'updatedAt') {
			aContent = a.updatedAt;
			bContent = b.updatedAt;
		} else {
			let aRefData = a.data;
			let bRefData = b.data;
			for (let m = 0; m < column.ref.length; m += 1) {
				const ref = column.ref[m];
				const aCheck = aRefData[ref];
				const bCheck = bRefData[ref];
				if (aCheck != null) {
					if (Array.isArray(aCheck)) {
						aContent = aCheck;
					} else if (typeof aCheck === 'string') {
						aContent = aCheck;
					} else if (typeof aCheck === 'object') {
						aRefData = aCheck;
					}
				}
				if (bCheck != null) {
					if (Array.isArray(bCheck)) {
						bContent = bCheck;
					} else if (typeof bCheck === 'string') {
						bContent = bCheck;
					} else if (typeof bCheck === 'object') {
						bRefData = bCheck;
					}
				}
			}
		}
		if (aContent != null && aContent !== '') {
			if (column.type === 'array' && aContent != null) aContent = aContent.join(', ');
			else if (column.type === 'choice' && aContent != null) {
				const { choices } = column;
				for (let m = 0; m < choices.length; m += 1) {
					if (choices[m].id === aContent) {
						aContent = choices[m].value;
						break;
					}
				}
			}
		} else aContent = '';
		if (bContent != null && bContent !== '') {
			if (column.type === 'array' && bContent != null) bContent = bContent.join(', ');
			else if (column.type === 'choice' && bContent != null) {
				const { choices } = column;
				for (let m = 0; m < choices.length; m += 1) {
					if (choices[m].id === bContent) {
						bContent = choices[m].value;
						break;
					}
				}
			}
		} else bContent = '';
		if (column.type !== 'number') {
			if (aContent != null) aContent = aContent.toLowerCase();
			if (bContent != null) bContent = bContent.toLowerCase();
		} else {
			aContent = parseInt(aContent, 10);
			bContent = parseInt(bContent, 10);
		}

		// Sort content
		if (!reverse) return (bContent < aContent) ? 1 : ((aContent < bContent) ? -1 : 0); // eslint-disable-line no-nested-ternary
		return (aContent < bContent) ? 1 : ((bContent < aContent) ? -1 : 0); // eslint-disable-line no-nested-ternary
	});

	// Update person display
	updatePeopleDisplay();
};

const selectPeopleRow = (e, id) => {
	e.stopPropagation();

	// Select or deselect row
	if (document.getElementById(`people-selector-${id}`).style.backgroundColor === '') {
		document.getElementById(`people-selector-${id}`).style.backgroundColor = 'var(--brand-primary)';
	} else {
		document.getElementById(`people-selector-${id}`).style.backgroundColor = '';
	}

	// Check selected counts
	let count = 0;
	const people = document.getElementsByName('people-selector');
	for (let i = 0; i < people.length; i += 1) {
		if (people[i].style.backgroundColor !== '') {
			count += 1;
		}
	}

	// Show or hide remove button
	if (count > 0) {
		document.getElementById('people-remove-button').style.display = 'block';
		helperModule.handleRemoveButtonPosition();
	} else {
		document.getElementById('people-remove-button').style.display = 'none';
	}
};

const removePeople = () => {

	// Get user role
	const userRole = getCookie('userRole');

	// Validate user role
	if (userRole === 'leader' || userRole === 'super' || userRole === 'admin') {

		// Disable remove button
		document.getElementById('people-remove-button').enabled = false;

		// Show remove confirmation
		inputModule.showModalWithId('people-archive-confirmation');
	}
};

const handleRemovePeople = (confirm) => {
	if (confirm === true) {

		// Hide remove confirmation
		inputModule.hideModalWithId('people-archive-confirmation');

		// Initialize remove array
		const removeArray = [];

		// Get selected people
		const people = document.getElementsByName('people-selector');
		if (people.length > 0) {
			for (let i = people.length - 1; i >= 0; i -= 1) {
				if (people[i].style.backgroundColor !== '') {

					// Get person id
					const personId = people[i].id.replace('people-selector-', '');

					// Append to remove array
					removeArray.push(personId);

					// Remove row from display
					people[i].parentElement.parentElement.remove();
				}
			}

			// Hide remove button
			document.getElementById('people-remove-button').enabled = true;
			document.getElementById('people-remove-button').style.display = 'none';

			// Remove people from person dictionary
			const personArray = [];
			Object.keys(window.peopleGlobalPeopleDictionary).forEach((property) => {
				if (Object.prototype.hasOwnProperty.call(window.peopleGlobalPeopleDictionary, property)) {
					const displayArray = window.peopleGlobalPeopleDictionary[property];
					for (let i = displayArray.length - 1; i >= 0; i -= 1) {
						if (removeArray.indexOf(displayArray[i].id) > -1) {
							displayArray.splice(i, 1);
						}
					}
					Array.prototype.push.apply(personArray, displayArray);
				}
			});

			// Remove people from display dictionary
			Object.keys(window.peopleGlobalDisplayDictionary).forEach((property) => {
				if (Object.prototype.hasOwnProperty.call(window.peopleGlobalDisplayDictionary, property)) {
					const displayArray = window.peopleGlobalDisplayDictionary[property];
					for (let i = displayArray.length - 1; i >= 0; i -= 1) {
						if (removeArray.indexOf(displayArray[i].id) > -1) {
							displayArray.splice(i, 1);
						}
					}
				}
			});

			// Remove people from person array
			for (let i = window.peopleGlobalPeopleArray.length - 1; i >= 0; i -= 1) {
				if (removeArray.indexOf(window.peopleGlobalPeopleArray[i].id) > -1) {
					window.peopleGlobalPeopleArray.splice(i, 1);
				}
			}

			// Subtract from total
			if (window.peopleGlobalTotalNumber != null) window.peopleGlobalTotalNumber -= removeArray.length;

			// Set person array to session storage
			try {
				sessionStorage.setItem('user-people', JSON.stringify(personArray));
			} catch (e) { }

			// Archive people
			Parse.Cloud.run('archivePersonnelWithParameters', { personnelIDArray: removeArray, platform: 'web' }).then(() => {

				// Update person display
				updatePeopleDisplay();

			}).catch((error) => {
				if (error && error.code === 209) {
					externalUserModule.handleUserLogOut(true);
				} else {

					// Show error
					inputModule.showModalWithId('people-archive-error-modal');
				}
			});
		} else {

			// Enable remove button
			document.getElementById('people-remove-button').enabled = true;
		}
	} else {

		// Hide remove confirmation
		inputModule.hideModalWithId('people-archive-confirmation');

		// Enable remove button
		document.getElementById('people-remove-button').enabled = true;
	}
};

const exportPeople = async () => {

	// Show status modal
	inputModule.showModalWithId('people-export-status-modal');

	// Export personnel
	await Parse.Cloud.run('exportPersonnelForUser', { platform: 'web' });

	// Set timeout for status modal
	setTimeout(() => {

		// Hide status modal
		inputModule.hideModalWithId('people-export-status-modal');

	}, 6000);
};

const importPeople = () => {
	window.origin_importFile = 'people';
	appModule.handleRouting('/dashboard/import');
};

const addPerson = () => {
	appModule.handleRouting('/dashboard/person/new');
};

const editPersonWithId = (id) => {
	appModule.handleRouting(`/dashboard/person/${id}`);
};


/**
 * Action Handlers
 */

const createActionHandlers = () => {

	// Handle click on add button
	$('#people-add-person').click(() => {
		addPerson();
	});

	// Handle click on load all button
	$('#people-load-all').click(() => {
		helperModule.displayActionSheet('people-action-dropdown');
		loadAllPeople();
	});

	// Handle click on export button
	$('#people-export-people').click(() => {
		helperModule.displayActionSheet('people-action-dropdown');
		exportPeople();
	});

	// Handle click on import button
	$('#people-import-people').click(() => {
		helperModule.displayActionSheet('people-action-dropdown');
		importPeople();
	});

	// Handle click on remove button
	$('#people-remove-button').click(() => {
		removePeople();
	});

	// Handle click on modal cancel button
	$('#people-archive-confirmation-cancel').click(() => {
		handleRemovePeople(false);
	});

	// Handle click on modal remove button
	$('#people-archive-confirmation-remove').click(() => {
		handleRemovePeople(true);
	});

	// Handle click on modal confirm button
	$('#people-archive-error-modal-confirm').click(() => {
		inputModule.hideModalWithId('people-archive-error-modal');
	});

	// Handle click on person selector
	$(document).off('click', "[id^='people-table-load-more-']");
	$(document).on('click', "[id^='people-table-load-more-']", function () {
		loadMorePeople($(this).attr('id').replace('people-table-load-more-', ''));
	});

	// Handle click on person selector
	$(document).off('click', "[id^='people-selector-']");
	$(document).on('click', "[id^='people-selector-']", function (event) {
		if (!isDragging) {
			selectPeopleRow(event, $(this).data('people-id'));
			isDragging = false;
		}
	});

	// Handle click on person row
	$(document).off('click', "[name='people-row-element']");
	$(document).on('click', "[name='people-row-element']", function () {
		if (!isDragging) {
			editPersonWithId($(this).data('people-id'));
			isDragging = false;
		}
	});

	// Handle click on person row sort element
	$(document).off('click', "[name='people-row-sort-element']");
	$(document).on('click', "[name='people-row-sort-element']", function () {
		if (!isDragging) {
			performPeopleSort($(this).data('type-value'), $(this).data('offset'));
			isDragging = false;
		}
	});

	// Handle click on action menu
	$('#people-action-menu').click(() => {
		helperModule.displayActionSheet('people-action-dropdown');
	});

	// Handle scroll person table
	document.addEventListener('scroll', (event) => {
		if (event.target != null && event.target.id != null) {
			if (event.target.id.indexOf('people-table-view-') > -1) {
				const value = event.target.id.replace('people-table-view-', '');
				peopleGlobalTableState[value].scroll = $(`#${event.target.id}`).scrollLeft();
			}
		}
	}, true);
};


/**
 * Input Handlers
 */

const createInputHandlers = () => {

	// Handle key up on search
	$('#people-search-input-large').keyup(function () {
		performPeopleSearch(this);
	});

	// Handle change filter
	$('#people-type-filter').change(function () {
		filterPeopleType(this);
	});

	// Handle drag start for table
	$(document).off('mousedown', "[id^='people-table-view-']");
	$(document).on('mousedown', "[id^='people-table-view-']", function (e) {

		// Stop propagation
		e.stopPropagation();
		e.preventDefault();

		// Set state
		dragDown = true;
		isDragging = false;
		dragX = e.pageX;
		dragY = e.pageY;
		dragTop = $(this).scrollTop();
		dragLeft = $(this).scrollLeft();
		dragElement = this;

		// Update cursor
		$(this).addClass('grab');
	});

	// Handle drag for table
	$(document).off('mousemove', dragElement);
	$(document).on('mousemove', dragElement, (e) => {
		if (dragDown) {

			// Update state
			const newX = e.pageX;
			const newY = e.pageY;
			isDragging = true;

			// Scroll element
			$(dragElement).scrollTop(dragTop - newY + dragY);
			$(dragElement).scrollLeft(dragLeft - newX + dragX);
		}
	});

	// Handle drag stop for table
	$(document).off('mouseup', dragElement);
	$(document).on('mouseup', dragElement, (e) => {
		if (dragDown) {

			// Stop propagation
			e.stopPropagation();
			e.preventDefault();

			// Update cursor
			$(dragElement).removeClass('grab');

			// Reset state
			dragDown = false;
			dragElement = null;
		}
	});
};


/**
 * State Handlers
 */

exports.handlerDidLoad = async () => {

	// Create action handlers
	createActionHandlers();

	// Create input handlers
	createInputHandlers();

	// Check for visible activity indicator
	inputModule.checkElementVisible('people-activity-indicator', async () => {

		// Initialize global person array
		window.peopleGlobalPeopleArray = [];

		// Reset variables
		peopleGlobalSelectedFilter = null;
		peopleGlobalSelectedSortDict = null;
		peopleGlobalTableState = null;

		// Fetch configuration for user
		const config = await userModule.handleConfigurationForUser(false);
		peopleGlobalConfiguration = JSON.parse(config);

		// Get available types
		const typeArray = [];
		peopleGlobalAvailableTypes = [];
		peopleGlobalSelectedSortDict = {};
		peopleGlobalTableState = {};
		Object.keys(peopleGlobalConfiguration.display.personnel_type_schemas).forEach((property) => {
			if (Object.prototype.hasOwnProperty.call(peopleGlobalConfiguration.display.personnel_type_schemas, property)) {
				typeArray.push(property);
				peopleGlobalAvailableTypes.push({
					value: property,
					display: peopleGlobalConfiguration.display.personnel_type_schemas[property].display
				});
				peopleGlobalSelectedSortDict[property] = {
					column: 0,
					reverse: false
				};
				peopleGlobalTableState[property] = {
					scroll: 0
				};
			}
		});

		// Set up filter
		setupPeopleFilter();

		// Get people for user
		updatePeopleForUser(true, typeArray, 0);
	});
};

exports.handlerDidAppear = async () => {

	// Update person display
	if (window.peopleShouldReloadPeople === true) {

		// Initialize dictionaries
		window.peopleGlobalPeopleDictionary = {};
		window.peopleGlobalDisplayDictionary = {};

		// Set people to dictionaries
		for (let i = 0; i < window.peopleGlobalPeopleArray.length; i += 1) {
			const person = window.peopleGlobalPeopleArray[i];

			// Set people
			let typeArray = window.peopleGlobalPeopleDictionary[person.type];
			if (typeArray == null) typeArray = [];
			typeArray.push(person);
			window.peopleGlobalPeopleDictionary[person.type] = typeArray;
		}

		// Sort type array by largest number of people
		peopleGlobalAvailableTypes.sort((a, b) => {
			if (window.peopleGlobalPeopleDictionary[a.value] == null) window.peopleGlobalPeopleDictionary[a.value] = [];
			if (window.peopleGlobalPeopleDictionary[b.value] == null) window.peopleGlobalPeopleDictionary[b.value] = [];
			return window.peopleGlobalPeopleDictionary[b.value].length - window.peopleGlobalPeopleDictionary[a.value].length;
		});

		// Perform search and update
		performPeopleSearch($('#people-search-input-large')[0]);

		// Update state
		window.peopleShouldReloadPeople = false;
	}
};
