// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

const Constants = require('../../../Constants');
const inputModule = require('../modules/input');
const externalUserModule = require('../modules/external-user');
const userModule = require('../modules/user');
const appModule = require('../modules/app');
const { getCookie } = require('../modules/utilities');


/**
 * State
 */

let subscriptionGlobalSubscription;
let subscriptionStripe;
let subscriptionElements;
let subscriptionCard;
let subscriptionSubscriptionExpanded = false;
let subscriptionBillingExpanded = false;
let subscriptionBillingFunction = 'edit';
let subscriptionOrgsNewState = -1;
let subscriptionOrgsPrevState = -1;
let subscriptionBillingPrevState = [];


/**
 * Handlers
 */

exports.updateSubscriptionDisplay = () => {

	// Get elements
	const subscriptionStatus = document.getElementById('subscription-status-text');
	const subscriptionCost = document.getElementById('subscription-cost-text');
	const subscriptionLastPayment = document.getElementById('subscription-last-payment-text');
	const subscriptionRenews = document.getElementById('subscription-renews-text');
	const subscriptionOrgLabel = document.getElementById('subscription-organizations-label');
	const subscriptionOrg = document.getElementById('subscription-organizations-text');

	// Get user role
	const userRole = getCookie('userRole');

	// Get subscription
	const subscription = subscriptionGlobalSubscription.customer.subscriptions.data[0];

	// Get subscription status
	let status = 'Active';
	if (subscriptionGlobalSubscription.isActive === false) status = 'Inactive';

	// Get subscription organizations
	const organizations = `${subscriptionGlobalSubscription.usedSeats} used of ${subscriptionGlobalSubscription.totalSeats}`;

	// Get renews date
	const renewsDate = new Date(subscription.current_period_end * 1000);
	const renews = `${Constants.DAYS_OF_WEEK[renewsDate.getDay()]}, ${Constants.MONTH_NAMES[renewsDate.getMonth()]} ${renewsDate.getDate()}, ${renewsDate.getFullYear()}`;

	// Get last payment date
	const lastPaymentDate = new Date(subscription.current_period_start * 1000);
	const lastPayment = `${Constants.DAYS_OF_WEEK[lastPaymentDate.getDay()]}, ${Constants.MONTH_NAMES[lastPaymentDate.getMonth()]} ${lastPaymentDate.getDate()}, ${lastPaymentDate.getFullYear()}`;

	// Calculate total cost
	let amount = (subscription.plan.amount / 100.0) * subscription.quantity;

	// Apply discount if discount
	if (subscription.discount && subscription.discount.coupon) {
		const { coupon } = subscription.discount;
		if (coupon.percent_off) {
			const percentOff = coupon.percent_off;
			amount *= ((100.0 - percentOff) / 100.0);
		} else if (coupon.amount_off) {
			amount -= (coupon.amount_off / 100.0);
			if (amount < 0) amount = 0;
		}
	}

	// Format total cost
	let totalCost = `$${inputModule.formatNumberWithCommas(amount)}.00 / `;
	if (subscriptionGlobalSubscription.paymentPeriod === 'yearly') {
		totalCost += 'Year';
	} else {
		totalCost += 'Month';
	}

	// Set subscription parameters
	subscriptionStatus.innerHTML = status;
	subscriptionCost.innerHTML = totalCost;
	subscriptionLastPayment.innerHTML = lastPayment;
	subscriptionRenews.innerHTML = renews;

	// Update display for role
	if (userRole === 'leader') {
		subscriptionOrgLabel.innerHTML = 'Subscription Type';
		subscriptionOrg.innerHTML = 'Organization';
	} else {
		subscriptionOrgLabel.innerHTML = 'Organization Seats';
		subscriptionOrg.innerHTML = organizations;
		const numberOfSeats = document.getElementById('numberOfSeatsCard');
		numberOfSeats.value = subscriptionGlobalSubscription.totalSeats;
		subscriptionOrgsPrevState = subscriptionGlobalSubscription.totalSeats;
	}
};

const toggleEditSubscription = () => {

	// Get user role
	const userRole = getCookie('userRole');

	// Get elements
	const detailsView = document.getElementById('subscription-details-view');
	const editText = document.getElementById('subscription-edit-text');

	// Check expanded state
	if (subscriptionSubscriptionExpanded === false) {

		// Set expanded state
		subscriptionSubscriptionExpanded = true;

		// Set edit text
		editText.innerHTML = 'Hide Detail';

		// Animate height
		let height = '235px';
		if (userRole === 'leader') height = '55px';
		$(detailsView).animate({ height }, 200);

	} else {

		// Set expanded state
		subscriptionSubscriptionExpanded = false;

		// Set edit text
		editText.innerHTML = 'Edit Subscription';

		// Animate height
		$(detailsView).animate({ height: '0px' }, 200);
	}
};

const toggleCardDetail = (type) => {

	// Get subscription
	const subscription = subscriptionGlobalSubscription.customer.subscriptions.data[0];

	// Validate if card type
	if (subscription.billing === 'charge_automatically') {

		// Get elements
		const detailsView = document.getElementById('payment-details-view');
		const editText = document.getElementById('card-edit-text');
		const changeButton = document.getElementById('card-change-button');

		// Check expanded state
		if (subscriptionBillingExpanded === false) {

			// Set expanded state
			subscriptionBillingExpanded = true;

			// Update display
			editText.innerHTML = 'Hide Detail';
			changeButton.style.display = 'none';

			// Animate height
			if (type === 'change' && process.env.ENTITY === 'keelerqik') {
				$(detailsView).animate({ height: '940px' }, 300);
			} else {
				$(detailsView).animate({ height: '740px' }, 300);
			}
		} else {

			// Set expanded state
			subscriptionBillingExpanded = false;

			// Set edit text
			editText.innerHTML = 'Edit Card';
			changeButton.style.display = 'block';

			// Animate height
			$(detailsView).animate({ height: '0px' }, 300);
		}
	}
};

const toggleEditCard = () => {

	// Get subscription
	const subscription = subscriptionGlobalSubscription.customer.subscriptions.data[0];

	// Validate if card type
	if (subscription.billing === 'charge_automatically') {

		// Set billing function
		subscriptionBillingFunction = 'edit';

		// Update display
		if (subscriptionBillingExpanded === false) {

			// Get elements
			const changeElements = document.getElementById('change-card-elements');
			const editElements = document.getElementById('edit-card-elements');
			const cardButton = document.getElementById('add-update-card-button');
			const cardButtonContent = document.getElementById('add-update-card-button-text');
			const cardNameEdit = document.getElementById('cardNameEdit');
			const expirationDate = document.getElementById('expirationDate');
			const addressLine1 = document.getElementById('addressLine1');
			const addressLine2 = document.getElementById('addressLine2');
			const addressCity = document.getElementById('addressCity');
			const addressState = document.getElementById('addressState');
			const addressPostal = document.getElementById('addressPostal');
			const addressCountry = document.getElementById('addressCountry');

			// Get card
			let card;
			const defaultId = subscriptionGlobalSubscription.customer.default_source;
			const cards = subscriptionGlobalSubscription.customer.sources.data;
			for (let i = 0; i < cards.length; i += 1) {
				if (cards[i].id === defaultId) card = cards[i];
			}

			// Get billing parameters
			let premonth = '';
			if (card.exp_month < 10) premonth = '0';

			// Set billing parameters
			cardNameEdit.value = card.name;
			expirationDate.value = `${premonth + card.exp_month}/${card.exp_year}`;
			addressLine1.value = card.address_line1;
			addressLine2.value = card.address_line2 == null ? '' : card.address_line2;
			addressCity.value = card.address_city;
			addressState.value = card.address_state;
			addressPostal.value = card.address_zip;
			addressCountry.value = card.address_country;

			// Set previous state
			subscriptionBillingPrevState = [
				{ id: 'cardNameEdit', value: cardNameEdit.value },
				{ id: 'expirationDate', value: expirationDate.value },
				{ id: 'addressLine1', value: addressLine1.value },
				{ id: 'addressLine2', value: addressLine2.value },
				{ id: 'addressCity', value: addressCity.value },
				{ id: 'addressState', value: addressState.value },
				{ id: 'addressPostal', value: addressPostal.value },
				{ id: 'addressCountry', value: addressCountry.value }
			];

			// Update display
			changeElements.style.display = 'none';
			editElements.style.display = 'block';
			cardButton.disabled = true;
			cardButtonContent.innerHTML = 'Update Payment Info';
		}

		// Toggle card detail
		toggleCardDetail('edit');
	}
};

const toggleChangeCard = () => {

	// Get subscription
	const subscription = subscriptionGlobalSubscription.customer.subscriptions.data[0];

	// Validate if card type
	if (subscription.billing === 'charge_automatically') {

		// Set billing function
		subscriptionBillingFunction = 'change';

		// Update display
		if (subscriptionBillingExpanded === false) {

			// Get elements
			const changeElements = document.getElementById('change-card-elements');
			const editElements = document.getElementById('edit-card-elements');
			const cardButton = document.getElementById('add-update-card-button');
			const cardButtonContent = document.getElementById('add-update-card-button-text');
			const cardNumber = document.getElementById('cardNumber');
			const cardType = document.getElementById('cardType');
			const expirationDateNew = document.getElementById('expirationDateNew');
			const cardCVC = document.getElementById('cardCVC');
			const cardNameNew = document.getElementById('cardNameNew');
			const cardNameEdit = document.getElementById('cardNameEdit');
			const expirationDate = document.getElementById('expirationDate');
			const addressLine1 = document.getElementById('addressLine1');
			const addressLine2 = document.getElementById('addressLine2');
			const addressCity = document.getElementById('addressCity');
			const addressState = document.getElementById('addressState');
			const addressPostal = document.getElementById('addressPostal');
			const addressCountry = document.getElementById('addressCountry');

			// Reset billing parameters
			cardNameNew.value = '';
			cardNameEdit.value = '';
			expirationDate.value = '';
			addressLine1.value = '';
			addressLine2.value = '';
			addressCity.value = '';
			addressState.value = '';
			addressPostal.value = '';
			addressCountry.value = '';

			// Reset specific billing parameters
			if (cardNumber) cardNumber.value = '';
			if (cardType) cardType.value = '';
			if (expirationDateNew) expirationDateNew.value = '';
			if (cardCVC) cardCVC.value = '';

			// Reset previous state
			subscriptionBillingPrevState = [];

			// Update display
			changeElements.style.display = 'block';
			editElements.style.display = 'none';
			cardButton.disabled = false;
			cardButtonContent.innerHTML = 'Save Payment Info';
		}

		// Toggle card detail
		toggleCardDetail('change');
	}
};

const updatePaymentTotal = () => {

	// Get subscription
	const subscription = subscriptionGlobalSubscription.customer.subscriptions.data[0];

	// Get elements
	let numberOfSeatsCard = document.getElementById('numberOfSeatsCard').value;
	if (numberOfSeatsCard === '') numberOfSeatsCard = subscription.quantity;
	else numberOfSeatsCard = parseInt(numberOfSeatsCard, 10);

	// Calculate total cost
	let amount = (subscription.plan.amount / 100.0) * numberOfSeatsCard;

	// Apply discount if discount
	if (subscription.discount && subscription.discount.coupon) {
		const { coupon } = subscription.discount;
		if (coupon.percent_off) {
			const percentOff = coupon.percent_off;
			amount *= ((100.0 - percentOff) / 100.0);
		} else if (coupon.amount_off) {
			amount -= (coupon.amount_off / 100.0);
			if (amount < 0) amount = 0;
		}
	}

	// Format total cost
	let totalCost = `$${inputModule.formatNumberWithCommas(amount)}.00 / `;
	if (subscriptionGlobalSubscription.paymentPeriod === 'yearly') {
		totalCost += 'Year';
	} else {
		totalCost += 'Month';
	}

	// Return cost
	return totalCost;
};

const updateSubscription = async () => {

	// Get user role
	const userRole = getCookie('userRole');

	// Check user role
	if (userRole === 'super' || userRole === 'admin') {

		// Get elements
		let numberOfSeats = document.getElementById('numberOfSeatsCard').value;
		const updatePromptContent = document.getElementById('subscription-update-prompt-content');
		const updateErrorTitle = document.getElementById('subscription-update-error-title');
		const updateErrorContent = document.getElementById('subscription-update-error-content');
		const actionButton = document.getElementById('update-subscription-button');
		const actionText = document.getElementById('update-subscription-button-text');
		const actionIndicator = document.getElementById('update-subscription-button-activity-indicator');

		// Check number of seats to change
		if (numberOfSeats === '') numberOfSeats = 1;
		const orgChange = numberOfSeats - subscriptionOrgsPrevState;

		// Validate parameters
		if (numberOfSeats >= 1 && orgChange !== 0) {

			// Update display
			actionButton.disabled = true;
			actionButton.style.backgroundColor = 'var(--brand-primary)';
			actionText.innerHTML = '';
			actionIndicator.style.display = 'block';

			// Validate number of organizations
			const orgs = await userModule.fetchPlatformOrganizationsForUser(true);
			if (numberOfSeats >= orgs.length) {

				// Set new orgs state
				subscriptionOrgsNewState = numberOfSeats;

				// Display confirmation prompt
				const totalCost = updatePaymentTotal();
				let quantifier = 'organizations';
				if (numberOfSeats === 1) quantifier = 'organization';
				let content = `Are you ready to increase your subscription to ${numberOfSeats} ${quantifier}? Your total subscription cost will be ${totalCost}.`;
				if (orgChange < 0) {
					content = `Are you sure you want to decrease your subscription to ${numberOfSeats} ${quantifier}? Your total subscription cost will be ${totalCost}.`;
				}
				updatePromptContent.innerHTML = content;
				inputModule.showModalWithId('subscription-update-prompt-modal');

				// Update display
				actionButton.disabled = false;
				actionText.innerHTML = 'Update My Subscription';
				actionIndicator.style.display = 'none';
				if (actionButton.style.removeProperty) {
					actionButton.style.removeProperty('background-color');
				} else {
					actionButton.style.removeAttribute('background-color');
				}
			} else {

				// Show error modal
				updateErrorTitle.innerHTML = 'Existing Organizations';
				updateErrorContent.innerHTML = 'You already have more active organizations than the new number you selected. To change your subscription, please remove these organizations.';
				inputModule.showModalWithId('subscription-update-error-modal');

				// Update display
				actionButton.disabled = false;
				actionText.innerHTML = 'Update My Subscription';
				actionIndicator.style.display = 'none';
				if (actionButton.style.removeProperty) {
					actionButton.style.removeProperty('background-color');
				} else {
					actionButton.style.removeAttribute('background-color');
				}
			}
		} else {

			// Show error modal
			updateErrorTitle.innerHTML = 'Oh Snap...';
			updateErrorContent.innerHTML = 'Please update the number of organizations in your subscription to continue.';
			inputModule.showModalWithId('subscription-update-error-modal');

			// Update display
			actionButton.disabled = false;
			actionText.innerHTML = 'Update My Subscription';
			actionIndicator.style.display = 'none';
			if (actionButton.style.removeProperty) {
				actionButton.style.removeProperty('background-color');
			} else {
				actionButton.style.removeAttribute('background-color');
			}
		}
	}
};

const handleUpdateSubscription = () => {

	// Hide modal
	inputModule.hideModalWithId('subscription-update-prompt-modal');

	// Get elements
	const actionButton = document.getElementById('update-subscription-button');
	const actionText = document.getElementById('update-subscription-button-text');
	const actionIndicator = document.getElementById('update-subscription-button-activity-indicator');

	// Update display
	actionButton.disabled = true;
	actionButton.style.backgroundColor = 'var(--brand-primary)';
	actionText.innerHTML = '';
	actionIndicator.style.display = 'block';

	// Validate number of seats
	if (subscriptionOrgsNewState > 0 && subscriptionOrgsNewState !== subscriptionBillingPrevState) {

		// Update subscription for user
		Parse.Cloud.run('updateSubscriptionForUser', { seats: subscriptionOrgsNewState }).then(() => {

			// Update previous state
			subscriptionOrgsPrevState = subscriptionOrgsNewState;

			// Set subscription quantity
			const subscription = subscriptionGlobalSubscription.customer.subscriptions.data[0];
			subscription.quantity = subscriptionOrgsNewState;
			subscriptionGlobalSubscription.totalSeats = subscriptionOrgsNewState;

			// Update subscription display
			exports.updateSubscriptionDisplay();

			// Update display
			actionText.innerHTML = 'Successfully Updated';
			actionIndicator.style.display = 'none';
			if (actionButton.style.removeProperty) {
				actionButton.style.removeProperty('background-color');
			} else {
				actionButton.style.removeAttribute('background-color');
			}

			// Set delay
			setTimeout(() => {

				// Update display
				actionButton.disabled = true;
				actionText.innerHTML = 'Update My Subscription';

			}, 2000);
		}).catch((error) => {
			if (error && error.code === 209) {
				externalUserModule.handleUserLogOut(true);
			} else {

				// Get elements
				const updateErrorTitle = document.getElementById('subscription-update-error-title');
				const updateErrorContent = document.getElementById('subscription-update-error-content');

				// Show error modal
				updateErrorTitle.innerHTML = 'Oh Snap...';
				updateErrorContent.innerHTML = "We're having trouble updating your subscription. Please try again, or email us at support@gosmarttrack.com for help.";
				inputModule.showModalWithId('subscription-update-error-modal');

				// Update display
				actionButton.disabled = false;
				actionText.innerHTML = 'Update My Subscription';
				actionIndicator.style.display = 'none';
				if (actionButton.style.removeProperty) {
					actionButton.style.removeProperty('background-color');
				} else {
					actionButton.style.removeAttribute('background-color');
				}
			}
		});
	}
};

const handleAddUpdateCard = (parameters, buttonTitle) => {

	// Get elements
	const actionButton = document.getElementById('add-update-card-button');
	const actionText = document.getElementById('add-update-card-button-text');
	const actionIndicator = document.getElementById('add-update-card-button-activity-indicator');

	// Update display
	actionButton.disabled = true;
	actionButton.style.backgroundColor = 'var(--brand-primary)';
	actionText.innerHTML = '';
	actionIndicator.style.display = 'block';

	// Update source
	Parse.Cloud.run('addUpdateSourceForUser', parameters).then((result) => {

		// Set new card info
		const defaultId = subscriptionGlobalSubscription.customer.default_source;
		const cards = subscriptionGlobalSubscription.customer.sources.data;
		for (let i = 0; i < cards.length; i += 1) {
			if (cards[i].id === defaultId) {
				cards[i] = result;
				subscriptionGlobalSubscription.customer.default_source = result.id;
			}
		}

		// Update subscription info
		updateBillingDisplay(); // eslint-disable-line no-use-before-define

		// Update display
		actionText.innerHTML = 'Successfully Updated';
		actionIndicator.style.display = 'none';
		if (actionButton.style.removeProperty) {
			actionButton.style.removeProperty('background-color');
		} else {
			actionButton.style.removeAttribute('background-color');
		}
		document.getElementById('cardNameNew').value = '';

		// Set delay
		setTimeout(() => {

			// Update display
			actionButton.disabled = true;
			actionText.innerHTML = buttonTitle;

			// Toggle display
			toggleCardDetail();

		}, 2000);
	}).catch((error) => {
		if (error && error.code === 209) {
			externalUserModule.handleUserLogOut(true);
		} else {

			// Show error
			inputModule.showBlockAlert("Please make sure you've entered your card info correctly");

			// Update display
			actionButton.disabled = false;
			actionText.innerHTML = buttonTitle;
			actionIndicator.style.display = 'none';
			if (actionButton.style.removeProperty) {
				actionButton.style.removeProperty('background-color');
			} else {
				actionButton.style.removeAttribute('background-color');
			}
		}
	});
};

const addUpdateCard = () => {

	// Get values
	const cardNameNew = document.getElementById('cardNameNew').value;
	const cardNameEdit = document.getElementById('cardNameEdit').value;
	const expirationDate = document.getElementById('expirationDate').value;
	const addressLine1 = document.getElementById('addressLine1').value;
	let addressLine2 = document.getElementById('addressLine2').value;
	const addressCity = document.getElementById('addressCity').value;
	const addressState = $('#addressState option:selected').val();
	const addressPostal = document.getElementById('addressPostal').value;
	const addressCountry = $('#addressCountry option:selected').val();

	// Get labels
	const cardNameNewLabel = document.getElementById('cardNameNewLabel');
	const cardNameEditLabel = document.getElementById('cardNameEditLabel');
	const expirationDateLabel = document.getElementById('expirationDateLabel');
	const addressLine1Label = document.getElementById('addressLine1Label');
	const addressCityLabel = document.getElementById('addressCityLabel');
	const addressPostalLabel = document.getElementById('addressPostalLabel');

	// Get validation results
	const cardNameNewResult = inputModule.validateText(cardNameNew);
	const cardNameEditResult = inputModule.validateText(cardNameEdit);
	const expirationDateResult = inputModule.validateExpDate(expirationDate);
	const addressLine1Result = inputModule.validateText(addressLine1);
	const addressCityResult = inputModule.validateText(addressCity);
	const addressStateResult = inputModule.validateText(addressState);
	const addressPostalResult = inputModule.validateText(addressPostal);
	const addressCountryResult = inputModule.validateText(addressCountry);

	// Get subscription
	const subscription = subscriptionGlobalSubscription.customer.subscriptions.data[0];

	// Validate if card type
	if (subscription.billing === 'charge_automatically') {

		// Get elements
		const actionButton = document.getElementById('add-update-card-button');
		const actionText = document.getElementById('add-update-card-button-text');
		const actionIndicator = document.getElementById('add-update-card-button-activity-indicator');

		// Check billing function
		if (subscriptionBillingFunction === 'change') {

			// Handle card element validation
			let cardNumber = null;
			let cardExpiration = null;
			let cardCVC = null;
			let cardType = null;
			if (process.env.ENTITY === 'keelerqik') {

				// Get values
				cardNumber = $('#cardNumber').val();
				cardExpiration = $('#expirationDateNew').val();
				cardCVC = $('#cardCVC').val();
				cardType = $('#cardType option:selected').val();

				// Get labels
				const cardNumberLabel = $('#cardNumberLabel')[0];
				const cardExpirationLabel = $('#expirationDateNewLabel')[0];
				const cardCVCLabel = $('#cardCVCLabel')[0];

				// Get validation results
				const cardNumberResult = inputModule.validateText(cardNumber);
				const cardCVCResult = inputModule.validateText(cardCVC);
				const cardTypeResult = inputModule.validateText(cardType);

				// Handle card element validation
				if (cardNumberResult[0] === false) {
					cardNumberLabel.innerHTML = `Card Number (${cardNumberResult[1]})`;
					cardNumberLabel.className = 'errorLabel';
					cardNumberLabel.focus();
					return;
				} if (cardTypeResult[0] === false) {
					inputModule.showBlockAlert('Please select your credit card type');
					document.body.scrollTop = 0;
					document.documentElement.scrollTop = 0;
					return;
				} if (/[\d]{2}\/[\d]{4}/.test(cardExpiration) !== true) {
					cardExpirationLabel.innerHTML = 'Expiration (MM/YYYY)';
					cardExpirationLabel.className = 'errorLabel';
					cardExpirationLabel.focus();
					return;
				} if (cardCVCResult[0] === false) {
					cardCVCLabel.innerHTML = `CVC (${cardCVCResult[1]})`;
					cardCVCLabel.className = 'errorLabel';
					cardCVCLabel.focus();
					return;
				}
			}

			// Handle validation
			if (cardNameNewResult[0] === false) {
				cardNameNewLabel.innerHTML = `Name on the card (${cardNameNewResult[1]})`;
				cardNameNewLabel.className = 'errorLabel';
				cardNameNewLabel.focus();
			} else if (addressLine1Result[0] === false) {
				addressLine1Label.innerHTML = `Address (${addressLine1Result[1]})`;
				addressLine1Label.className = 'errorLabel';
				addressLine1Label.focus();
			} else if (addressCityResult[0] === false) {
				addressCityLabel.innerHTML = `City (${addressCityResult[1]})`;
				addressCityLabel.className = 'errorLabel';
				addressCityLabel.focus();
			} else if (addressStateResult[0] === false) {
				inputModule.showBlockAlert('Please select your billing state');
				document.body.scrollTop = 300;
				document.documentElement.scrollTop = 300;
			} else if (addressPostalResult[0] === false) {
				addressPostalLabel.innerHTML = `Postal Code (${addressPostalResult[1]})`;
				addressPostalLabel.className = 'errorLabel';
				addressPostalLabel.focus();
			} else if (addressCountryResult[0] === false) {
				inputModule.showBlockAlert('Please select your billing country');
				document.body.scrollTop = 300;
				document.documentElement.scrollTop = 300;
			} else {

				// Update display
				const buttonTitle = actionText.innerHTML;
				actionButton.disabled = true;
				actionButton.style.backgroundColor = 'var(--brand-primary)';
				actionText.innerHTML = '';
				actionIndicator.style.display = 'block';

				// Handle payment flow for entity
				if (process.env.ENTITY === 'keelerqik') {

					// Create parameters
					const parameters = {
						paymentToken: {
							cardNumber,
							cardCVC,
							cardType
						},
						cardName: cardNameNew,
						expirationDate: cardExpiration,
						addressLine1,
						addressLine2,
						addressCity,
						addressState,
						addressPostal,
						addressCountry
					};

					// Handle replace card
					handleAddUpdateCard(parameters, buttonTitle);

				} else {

					// Create token from card
					subscriptionStripe.createToken(subscriptionCard, {
						name: cardNameNew,
						address_line1: addressLine1,
						address_line2: addressLine2,
						address_city: addressCity,
						address_state: addressState,
						address_zip: addressPostal,
						address_country: addressCountry
					}).then((result) => {
						if (result.error) { // An error occurred

							// Show error
							inputModule.showBlockAlert(result.error.message);

							// Remove error with delay
							setTimeout(() => {
								inputModule.removeBlockAlert();
							}, 4000);

							// Update display
							actionButton.disabled = false;
							actionText.innerHTML = buttonTitle;
							actionIndicator.style.display = 'none';
							if (actionButton.style.removeProperty) {
								actionButton.style.removeProperty('background-color');
							} else {
								actionButton.style.removeAttribute('background-color');
							}
						} else { // Token created

							// Create parameters
							const parameters = {
								paymentToken: result.token,
								addressLine1,
								addressLine2,
								addressCity,
								addressState,
								addressPostal,
								addressCountry
							};

							// Handle replace card
							handleAddUpdateCard(parameters, buttonTitle);
						}
					});
				}
			}
		} else if (subscriptionBillingFunction === 'edit') {

			// Handle validation
			if (cardNameEditResult[0] === false) {
				cardNameEditLabel.innerHTML = `Name on the card (${cardNameEditResult[1]})`;
				cardNameEditLabel.className = 'errorLabel';
				cardNameEditLabel.focus();
			} else if (expirationDateResult[0] === false) {
				expirationDateLabel.innerHTML = `Expiration (MM/YYYY) (${expirationDateResult[1]})`;
				expirationDateLabel.className = 'errorLabel';
				expirationDateLabel.focus();
			} else if (addressLine1Result[0] === false) {
				addressLine1Label.innerHTML = `Address (${addressLine1Result[1]})`;
				addressLine1Label.className = 'errorLabel';
				addressLine1Label.focus();
			} else if (addressCityResult[0] === false) {
				addressCityLabel.innerHTML = `City (${addressCityResult[1]})`;
				addressCityLabel.className = 'errorLabel';
				addressCityLabel.focus();
			} else if (addressStateResult[0] === false) {
				inputModule.showBlockAlert('Please select your billing state');
				document.body.scrollTop = 300;
				document.documentElement.scrollTop = 300;
			} else if (addressPostalResult[0] === false) {
				addressPostalLabel.innerHTML = `Postal Code (${addressPostalResult[1]})`;
				addressPostalLabel.className = 'errorLabel';
				addressPostalLabel.focus();
			} else if (addressCountryResult[0] === false) {
				inputModule.showBlockAlert('Please select your billing country');
				document.body.scrollTop = 300;
				document.documentElement.scrollTop = 300;
			} else {

				// Validate items have changed
				let isUpdated = false;
				for (let i = 0; i < subscriptionBillingPrevState.length; i += 1) {
					const newState = subscriptionBillingPrevState[i];
					const newItem = document.getElementById(newState.id);
					if (newItem.value !== newState.value) isUpdated = true;
				}
				if (isUpdated === true) {

					// Create parameters
					if (addressLine2 === '') addressLine2 = null;
					const parameters = {
						cardName: cardNameEdit,
						expirationDate,
						addressLine1,
						addressLine2,
						addressCity,
						addressState,
						addressPostal,
						addressCountry
					};

					// Update display
					const buttonTitle = actionText.innerHTML;

					// Handle update card
					handleAddUpdateCard(parameters, buttonTitle);
				}
			}
		}
	}
};

const updateBillingDisplay = () => {

	// Get elements
	const editCardButton = document.getElementById('card-edit-button');
	const changeCardButton = document.getElementById('card-change-button');
	const label1 = document.getElementById('billing-label-1');
	const label2 = document.getElementById('billing-label-2');
	const label3 = document.getElementById('billing-label-3');
	const label4 = document.getElementById('billing-label-4');
	const field1 = document.getElementById('billing-field-1');
	const field2 = document.getElementById('billing-field-2');
	const field3 = document.getElementById('billing-field-3');
	const field4 = document.getElementById('billing-field-4');

	// Get current user
	const user = Parse.User.current();

	// Get subscription
	const subscription = subscriptionGlobalSubscription.customer.subscriptions.data[0];

	// Check billing method
	if (subscription.billing === 'send_invoice') {

		// Get billing parameters
		const email = user.get('email');
		const daysUntilDue = subscription.days_until_due;

		// Get last payment date
		const lastPaymentDate = new Date(subscription.current_period_start * 1000);
		const lastPayment = `${Constants.DAYS_OF_WEEK[lastPaymentDate.getDay()]}, ${Constants.MONTH_NAMES[lastPaymentDate.getMonth()]} ${lastPaymentDate.getDate()}, ${lastPaymentDate.getFullYear()}`;

		// Set billing labels
		label1.innerHTML = 'Payment Method';
		label2.innerHTML = 'Invoiced Email';
		label3.innerHTML = 'Available Payment Window';
		label4.innerHTML = 'Last Invoice Sent';

		// Set billing parameters
		field1.innerHTML = 'Invoice';
		field2.innerHTML = email;
		field3.innerHTML = `${daysUntilDue} Days`;
		field4.innerHTML = lastPayment;

		// Remove elements
		editCardButton.outerHTML = '';
		changeCardButton.outerHTML = '';
	} else {

		// Get card
		let card;
		const defaultId = subscriptionGlobalSubscription.customer.default_source;
		const cards = subscriptionGlobalSubscription.customer.sources.data;
		for (let i = 0; i < cards.length; i += 1) {
			if (cards[i].id === defaultId) card = cards[i];
		}

		// Get billing parameters
		const cardDetails = `${card.brand} •••• •••• •••• ${card.last4}`;
		const expiration = `${Constants.MONTH_NAMES[card.exp_month - 1]} ${card.exp_year}`;
		const { name } = card;

		// Create billing address;
		let address = card.address_line1;
		if (card.address_line2 != null) {
			address += ` ${card.address_line2}`;
		}
		address += `, ${card.address_city}, ${card.address_state} ${card.address_zip}`;

		// Set billing labels
		label1.innerHTML = 'Card';
		label2.innerHTML = 'Expiration';
		label3.innerHTML = 'Name on Card';
		label4.innerHTML = 'Billing Address';

		// Set billing parameters
		field1.innerHTML = cardDetails;
		field2.innerHTML = expiration;
		field3.innerHTML = name;
		field4.innerHTML = address;

		// Create Stripe instance
		subscriptionStripe = Stripe(process.env.STRIPE_PUBLIC_KEY);
		subscriptionElements = subscriptionStripe.elements({
			fonts: [{
				cssSrc: process.env.ENTITY === 'smarttrack' ? 'https://fonts.googleapis.com/css?family=Barlow:500' : 'https://fonts.googleapis.com/css?family=Poppins:500'
			}]
		});

		// Ensure payment form is loaded
		$('#payment-form').ready(function () {

			// Handle payment elements for entity
			if (process.env.ENTITY !== 'keelerqik') {

				// Create style for card element
				const style = {
					base: {
						color: '#413e4d',
						fontFamily: process.env.ENTITY === 'smarttrack' ? 'Barlow' : 'Poppins',
						fontSmoothing: 'antialiased',
						fontSize: '18.4px',
						'::placeholder': {
							color: '#c3c3c3'
						}
					},
					invalid: {
						color: '#E74C3C',
						iconColor: '#E74C3C'
					}
				};

				// Create instance of the card element
				subscriptionCard = subscriptionElements.create('card', { hidePostalCode: true, style });

				// Append card element
				subscriptionCard.mount('#card-element');
			}

			// Set form submit listener
			$(this).on('submit', (event) => {

				// Prevent form submission
				event.preventDefault();

				// Add or update card
				addUpdateCard();
			});
		});
	}
};

const showCancelSubscriptionPrompt = () => {
	if (process.env.ENTITY === 'keelerqik') {

		// Remove to contact
		window.open('/contact', '_blank');

	} else {

		// Show modal
		inputModule.showModalWithId('subscription-cancel-modal');
	}
};

const handleCancelSubscription = () => {

	// Hide modal
	inputModule.hideModalWithId('subscription-cancel-modal');

	// Cancel user subscription
	Parse.Cloud.run('cancelSubscriptionForUser', null).then(() => {

		// Log user out
		externalUserModule.handleUserLogOut(true);

	}).catch((error) => {
		if (error && error.code === 209) {
			externalUserModule.handleUserLogOut(true);
		} else {

			// Display error modal
			inputModule.showModalWithId('subscription-cancel-error-modal');
		}
	});
};

const handleSubscriptionChange = (item, entity) => {

	// Get element to update
	let element = document.getElementById('update-subscription-button');
	if (entity === 'billing') {
		element = document.getElementById('add-update-card-button');
	}

	// Update display
	if (entity === 'subscription') {
		if (subscriptionOrgsPrevState !== item.value) {
			element.disabled = false;
		} else {
			element.disabled = true;
		}
	} else if (subscriptionBillingFunction === 'edit') {
		let isUpdated = false;
		for (let i = 0; i < subscriptionBillingPrevState.length; i += 1) {
			const newState = subscriptionBillingPrevState[i];
			const newItem = document.getElementById(newState.id);
			if (newItem.value !== newState.value) isUpdated = true;
		}
		if (isUpdated === true) {
			element.disabled = false;
		} else {
			element.disabled = true;
		}
	} else {
		element.disabled = false;
	}
};

const updatePaymentAmount = () => {

	// Get elements
	const subscriptionCost = document.getElementById('subscription-cost-text');

	// Set cost parameters
	subscriptionCost.innerHTML = updatePaymentTotal();
};


/**
 * Action Handlers
 */

const createActionHandlers = () => {

	// Handle click on back button
	$('#subscription-back-button').click(() => {
		appModule.handleRouting('/dashboard/account');
	});

	// Handle click on edit link
	$('#subscription-edit-link').click(() => {
		toggleEditSubscription();
	});

	// Handle click on update button
	$('#update-subscription-button').click(() => {
		updateSubscription();
	});

	// Handle click on cancel button
	$('#cancel-subscription-button').click(() => {
		showCancelSubscriptionPrompt();
	});

	// Handle click on edit card button
	$('#card-edit-button').click(() => {
		toggleEditCard();
	});

	// Handle click on change card toggle
	$('#card-change-button').click(() => {
		toggleChangeCard();
	});

	// Handle click on add card button
	$('#add-update-card-button').click(() => {
		addUpdateCard();
	});

	// Handle click on cancel modal cancel button
	$('#subscription-cancel-modal-cancel').click(() => {
		inputModule.hideModalWithId('subscription-cancel-modal');
	});

	// Handle click on cancel modal confirm button
	$('#subscription-cancel-modal-confirm').click(() => {
		handleCancelSubscription();
	});

	// Handle click on cancel error modal confirm button
	$('#subscription-cancel-error-modal-confirm').click(() => {
		inputModule.hideModalWithId('subscription-cancel-error-modal');
	});

	// Handle click on update modal cancel button
	$('#subscription-update-prompt-modal-cancel').click(() => {
		inputModule.hideModalWithId('subscription-update-prompt-modal');
	});

	// Handle click on update modal update button
	$('#subscription-update-prompt-modal-update').click(() => {
		handleUpdateSubscription();
	});

	// Handle click on update error modal confirm button
	$('#subscription-update-error-modal-confirm').click(() => {
		inputModule.hideModalWithId('subscription-update-error-modal');
	});
};


/**
 * Input Handlers
 */

const createInputHandlers = () => {

	// Handle blur
	$('#cardNameNew').blur(() => {
		inputModule.removeAlert('cardNameNewLabel', 'Name on the card', true);
	});
	$('#cardNumber').blur(() => {
		inputModule.removeAlert('cardNumberLabel', 'Card Number', true);
	});
	$('#expirationDateNew').blur(() => {
		inputModule.removeAlert('expirationDateNewLabel', 'Expiration (MM/YYYY)', true);
	});
	$('#cardCVC').blur(() => {
		inputModule.removeAlert('cardCVCLabel', 'CVC', true);
	});
	$('#cardType').blur(() => {
		inputModule.removeBlockAlert();
	});
	$('#cardNameEdit').blur(() => {
		inputModule.removeAlert('cardNameEditLabel', 'Name on the card', true);
	});
	$('#expirationDate').blur(() => {
		inputModule.removeAlert('expirationDateLabel', 'Expiration (MM/YYYY)', true);
	});
	$('#addressLine1').blur(() => {
		inputModule.removeAlert('addressLine1Label', 'Address', true);
	});
	$('#addressLine2').blur(() => {
		inputModule.removeAlert('addressLine2Label', 'Address (cont.)', true);
	});
	$('#addressCity').blur(() => {
		inputModule.removeAlert('addressCityLabel', 'City', true);
	});
	$('#addressState').blur(() => {
		inputModule.removeBlockAlert();
	});
	$('#addressPostal').blur(() => {
		inputModule.removeAlert('addressPostalLabel', 'Postal Code', true);
	});
	$('#addressCountry').blur(() => {
		inputModule.removeBlockAlert();
	});

	// Handle change content
	$('#numberOfSeatsCard').keyup(function () {
		updatePaymentAmount();
		handleSubscriptionChange(this, 'subscription');
	});
	$('#cardNameNew').keyup(function () {
		handleSubscriptionChange(this, 'billing');
	});
	$('#cardNumber').keyup(function () {
		handleSubscriptionChange(this, 'billing');
	});
	$('#expirationDateNew').keyup(function () {
		handleSubscriptionChange(this, 'billing');
	});
	$('#cardCVC').keyup(function () {
		handleSubscriptionChange(this, 'billing');
	});
	$('#cardNameEdit').keyup(function () {
		handleSubscriptionChange(this, 'billing');
	});
	$('#expirationDate').keyup(function () {
		handleSubscriptionChange(this, 'billing');
	});
	$('#addressLine1').keyup(function () {
		handleSubscriptionChange(this, 'billing');
	});
	$('#addressLine2').keyup(function () {
		handleSubscriptionChange(this, 'billing');
	});
	$('#addressCity').keyup(function () {
		handleSubscriptionChange(this, 'billing');
	});
	$('#addressPostal').keyup(function () {
		handleSubscriptionChange(this, 'billing');
	});

	// Handle change content
	$('#cardNameNew').keydown(() => {
		inputModule.removeAlert('cardNameNewLabel', 'Name on the card', true);
	});
	$('#cardNameEdit').keydown(() => {
		inputModule.removeAlert('cardNameEditLabel', 'Name on the card', true);
	});
	$('#cardNumber').keydown(() => {
		inputModule.removeAlert('cardNumberLabel', 'Card Number', true);
	});
	$('#expirationDateNew').keydown(() => {
		inputModule.removeAlert('expirationDateNewLabel', 'Expiration (MM/YYYY)', true);
	});
	$('#cardCVC').keydown(() => {
		inputModule.removeAlert('cardCVCLabel', 'CVC', true);
	});
	$('#expirationDate').keydown(() => {
		inputModule.removeAlert('expirationDateLabel', 'Expiration (MM/YYYY)', true);
	});
	$('#addressLine1').keydown(() => {
		inputModule.removeAlert('addressLine1Label', 'Address', true);
	});
	$('#addressLine2').keydown(() => {
		inputModule.removeAlert('addressLine2Label', 'Address (cont.)', true);
	});
	$('#addressCity').keydown(() => {
		inputModule.removeAlert('addressCityLabel', 'City', true);
	});
	$('#addressPostal').keydown(() => {
		inputModule.removeAlert('addressPostalLabel', 'Postal Code', true);
	});

	// Handle focus
	$('#cardNameNew').focus(() => {
		inputModule.removeBlockAlert();
	});
	$('#cardNameEdit').focus(() => {
		inputModule.removeBlockAlert();
	});
	$('#cardNumber').focus(() => {
		inputModule.removeBlockAlert();
	});
	$('#expirationDateNew').focus(() => {
		inputModule.removeBlockAlert();
	});
	$('#cardCVC').focus(() => {
		inputModule.removeBlockAlert();
	});
	$('#cardType').focus(() => {
		inputModule.removeBlockAlert();
	});
	$('#expirationDate').focus(() => {
		inputModule.removeBlockAlert();
	});
	$('#addressLine1').focus(() => {
		inputModule.removeBlockAlert();
	});
	$('#addressLine2').focus(() => {
		inputModule.removeBlockAlert();
	});
	$('#addressCity').focus(() => {
		inputModule.removeBlockAlert();
	});
	$('#addressState').focus(() => {
		inputModule.removeBlockAlert();
	});
	$('#addressPostal').focus(() => {
		inputModule.removeBlockAlert();
	});
	$('#addressCountry').focus(() => {
		inputModule.removeBlockAlert();
	});

	// Handle change value
	$('#addressState').change(function () {
		handleSubscriptionChange(this, 'billing');
	});
	$('#addressCountry').change(function () {
		handleSubscriptionChange(this, 'billing');
	});
};


/**
 * State Handlers
 */

exports.handlerDidLoad = async () => {

	// Create action handlers
	createActionHandlers();

	// Create input handlers
	createInputHandlers();

	// Fetch subscription for user
	const subscription = await externalUserModule.fetchSubscriptionForUser(true);
	subscriptionGlobalSubscription = subscription;

	// Get current user
	const user = Parse.User.current();

	// Validate owner
	if (subscription.isActive === true && subscription.owner && subscription.owner.id === user.id) {

		// Update subscription display
		exports.updateSubscriptionDisplay();

		// Update billing display
		updateBillingDisplay();

		// Hide loading indicator
		const loadingIndicator = document.getElementById('subscription-activity-indicator');
		$(loadingIndicator).ready(() => {
			loadingIndicator.remove();
		});

		// Check toggle parameter
		if (window.location.search.indexOf('toggle=subscription') > -1) {
			toggleEditSubscription();
		}

		// Show content
		const container = document.getElementById('subscription-container');
		container.style.display = 'block';
	} else {
		appModule.handleRouting('/dashboard/account');
	}
};

exports.handlerDidAppear = async () => { };
