// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

// Utilities
const userModule = require('../modules/user');
const inputModule = require('../modules/input');
const externalUserModule = require('../modules/external-user');

// Constants
const { PURCHASE_FLOW_IDS } = require('../../../Constants');


/**
 * Global Variables
 */

let flowAction = 'Check Out';
let cartObj = null;
let currentPayload = null;
let assetData = [];
let purchaseConfig = null;
let payloadTimeout = null;
let cartGlobalConfiguration;


/**
 * Cart Handlers
 */

const updateCheckOutButton = () => {
	if (assetData.length > 0) {
		$('#cart-check-out-button').show();
	} else {
		$('#cart-check-out-button').hide();
	}
};

const updateCartDisplay = () => {

	// Get asset data
	assetData = cartObj.assets != null ? cartObj.assets : [];

	// Update view title
	const title = `My Cart (${assetData.length})`;
	$('#cart-title-view').html(title);
	document.title = `${title} | ${process.env.ENTITY_OBJECT.NAME}`;

	// Hide loading indicator
	const loadingIndicator = document.getElementById('cart-activity-indicator');
	if (loadingIndicator != null) {
		$(loadingIndicator).ready(() => {
			loadingIndicator.remove();
		});
	}

	// Show container
	const container = document.getElementById('cart-container');
	container.style.display = 'inline-block';

	// Display empty view display
	if (assetData.length === 0) {
		$('#cart-empty-view').show();
	} else {
		$('#cart-empty-view').hide();
	}

	// Update check out button
	updateCheckOutButton();

	// Get schema types
	let typeSchemas = [];
	if (cartGlobalConfiguration.search != null && cartGlobalConfiguration.search.type_schemas != null) {
		typeSchemas = cartGlobalConfiguration.search.type_schemas;
	}

	// Create asset content
	let html = '';
	assetData.forEach((asset) => {

		// Get item parameters
		const {
			id, type, data
		} = asset;
		const quantity = asset.quantity != null && asset.quantity !== '' ? asset.quantity : '0';
		const elementId = `${id}-cart-asset-element`;

		// Get type schema for type
		if (typeSchemas[type] != null) {
			const schema = typeSchemas[type];

			// Get schema parameters
			const displayType = schema.display;
			const { primary } = schema;
			const { secondary } = schema;
			const { detail } = schema;
			const detailImage = detail.image;
			const detailFields = detail.fields;
			const initialStateExpanded = schema.initial_state_expanded;

			// Create primary display
			const primaryLabel = primary.label;
			let primaryContent = '';
			if (primary.type === 'identifier') {
				primaryContent = asset.identifier;
			} else {
				let refData = data;
				for (let m = 0; m < primary.ref.length; m += 1) {
					const ref = primary.ref[m];
					const check = refData[ref];
					if (check != null) {
						if (Array.isArray(check)) {
							primaryContent = check;
						} else if (typeof check === 'string') {
							primaryContent = check;
						} else if (typeof check === 'object') {
							refData = check;
						}
					}
				}
			}
			if (primaryContent != null && primaryContent !== '') {
				if (primary.type === 'array' && primaryContent != null) primaryContent = primaryContent.join(', ');
			} else primaryContent = '';

			// Create secondary display
			const secondaryLabel = secondary.label;
			let secondaryContent = '';
			if (secondary.type === 'identifier') {
				secondaryContent = asset.identifier;
			} else {
				let refData = data;
				for (let m = 0; m < secondary.ref.length; m += 1) {
					const ref = secondary.ref[m];
					const check = refData[ref];
					if (check != null) {
						if (Array.isArray(check)) {
							secondaryContent = check;
						} else if (typeof check === 'string') {
							secondaryContent = check;
						} else if (typeof check === 'object') {
							refData = check;
						}
					}
				}
			}
			if (secondaryContent != null && secondaryContent !== '') {
				if (secondary.type === 'array') secondaryContent = secondaryContent.join(', ');
			} else secondaryContent = '';

			// Setup asset inner height
			let assetInnerHeight = '';
			let assetInnerPrevHeight = "data-previous-height='100'";
			if (initialStateExpanded === false) {
				assetInnerHeight = "style='height:100px;'";
				assetInnerPrevHeight = "data-previous-height='#prevHeight'";
			}

			// Create action component
			const inputId = `${id}-cart-asset-quantity`;
			const actionComponent = `<div class='incr-decr-holder'>
			<button class='increment-button animate' name='cart-increment-button' data-input-type='quantity' data-input-id='${inputId}'>
			<div class="widget-plus-icon">
			</button>
			<button class='decrement-button animate' name='cart-decrement-button' data-input-type='quantity' data-input-id='${inputId}'>
			<div class="widget-minus-icon">
			</button>
			</div><input id='${inputId}' value='${quantity}' class='cart-asset-quantity' name='cart-asset-quantity-input' type='number'>`;

			// Create new guess element
			let element = `<div class='asset-holder'>
            <div class='type-holder'><p>${displayType}</p></div><div data-asset-id='${id}' class='action-holder' name='cart-action-holder'>${actionComponent}</div>
            <div class='asset-card' data-element-id='${elementId}' name='cart-asset-card'
            data-expanded='${initialStateExpanded}' style='width:100%;'><div class='asset-card-inner' id='${elementId}' ${assetInnerHeight}${assetInnerPrevHeight}>
            <div style='float:left; width:#leftFloatWidth;'><p>${primaryLabel}<p><h3>${primaryContent}</h3><p>${secondaryLabel}</p><h3>${secondaryContent}</h3>`;

			// Parse detail content
			if (detailFields.length > 0) {

				// Create detail fields
				let validCount = 0;
				for (let j = 0; j < detailFields.length; j += 1) {

					// Get detail content
					const fieldLabel = detailFields[j].label;
					let fieldContent = '';
					if (detailFields[j].type === 'identifier') {
						fieldContent = asset.identifier;
					} else {
						let refData = data;
						for (let m = 0; m < detailFields[j].ref.length; m += 1) {
							const ref = detailFields[j].ref[m];
							const check = refData[ref];
							if (check != null) {
								if (Array.isArray(check)) {
									fieldContent = check;
								} else if (typeof check === 'string') {
									fieldContent = check;
								} else if (typeof check === 'object') {
									refData = check;
								}
							}
						}
					}
					if (fieldContent != null && fieldContent !== '') {
						if (detailFields[j].type === 'array' && fieldContent != null) fieldContent = fieldContent.join(', ');
					} else fieldContent = '';

					// If content exists, set content
					if (fieldContent !== '') {
						element += `<p>${fieldLabel}<p><h3>${fieldContent}</h3>`;
						validCount += 1;
					}
				}
				const expandedHeight = 100 + (validCount * 50);
				element = element.replace('#prevHeight', expandedHeight.toString());
			}

			// Close left element
			element += "</div><div style='float:right; width:#rightFloatWidth; text-align:right;'>";

			// Create image
			if (detailImage != null) {

				// Get image url
				let imageURL = '';
				let refData = JSON.parse(JSON.stringify(data));
				for (let j = 0; j < detailImage.ref.length; j += 1) {
					const ref = detailImage.ref[j];
					const check = refData[ref];
					if (check != null) {
						if (typeof check === 'string') {
							imageURL = check;
						} else if (typeof check === 'object') {
							refData = check;
						}
					}
				}

				// If image url exists, display image
				if (imageURL !== '') {
					imageURL = imageURL.replace('http://', 'https://');
					element += `<img style='margin-top:5px; height:84px; width:auto; border-radius:4px;' src='${imageURL}' alt='Asset Preview' title='Asset Preview'>`;
					element = element.replace('#leftFloatWidth', '80%').replace('#rightFloatWidth', '20%');
				} else {
					element = element.replace('#leftFloatWidth', '100%').replace('#rightFloatWidth', '0%');
				}
			}

			// Close element
			element += '</div></div></div>';

			// Append new element
			html += element;
		}
	});

	// Set asset content
	$('#cart-asset-container').html(html);
};

const updateCart = (payload) => {

	// Update cart
	Parse.Cloud.run('updateCartForUser', { payload: [payload], platform: 'web' }).then((success) => {
		if (success === true) {

			// Update interface
			const { id, quantity } = payload;

			// Search for matching data item
			let row = -1;
			for (let i = 0; i < assetData.length; i += 1) {
				if (assetData[i].id === id) {
					row = i;
					break;
				}
			}
			if (row > -1) {
				if (quantity === 0) {

					// Remove asset data
					assetData.splice(row, 1);

				} else {

					// Update asset data
					assetData[row].quantity = quantity;
				}
			}

			// Destroy current payload
			currentPayload = null;

			// Update cart display
			updateCartDisplay();

		} else {

			// Create error alert
			document.getElementById('cart-error-modal-title').innerHTML = 'Whoops!';
			document.getElementById('cart-error-modal-description').innerHTML = 'We\'re Having Trouble Updating Your Cart. Please Try Again.';

			// Display error alert
			inputModule.showModalWithId('cart-error-modal');
		}
	}).catch((error) => {
		if (error && error.code === 209) {
			externalUserModule.handleUserLogOut(true);
		} else {

			// Create error alert
			document.getElementById('cart-error-modal-title').innerHTML = 'Whoops!';
			document.getElementById('cart-error-modal-description').innerHTML = 'We\'re Having Trouble Updating Your Cart. Please Try Again.';

			// Display error alert
			inputModule.showModalWithId('cart-error-modal');
		}
	});
};

const updateCartPayload = (payload) => {

	// Get payload parameters
	const { quantity } = payload;

	// Check if should be remove payload
	if (quantity === 0) {

		// Set current payload
		currentPayload = payload;

		// Prompt for removal
		inputModule.showModalWithId('cart-remove-asset-confirmation-modal');

	} else {

		// Update cart
		updateCart(payload);
	}
};

const queueForCartUpdate = (payload) => {

	// Invalidate timer
	if (payloadTimeout != null) clearTimeout(payloadTimeout);

	// Set payload timer
	payloadTimeout = setTimeout(() => {
		updateCartPayload(payload);
	}, 800);
};

const handleCartCheckoutPrompt = () => {
	if (assetData.length > 0) {

		// Get purchase flow id
		const flowId = purchaseConfig.flow_id;
		if (flowId != null && flowId === PURCHASE_FLOW_IDS.KEELER_QIK_PO) {

			// Create confirmation alert
			document.getElementById('cart-check-out-confirmation-modal-title').innerHTML = flowAction;
			document.getElementById('cart-check-out-confirmation-modal-description').innerHTML = 'Are You Ready To Check Out With This Cart?';
			document.getElementById('cart-check-out-confirmation-modal-confirm-text').innerHTML = flowAction;

			// Show confirmation alert
			inputModule.showModalWithId('cart-check-out-confirmation-modal');
		}
	}
};

const handleCartCheckout = () => {

	// Get elements
	const checkOutButton = document.getElementById('cart-check-out-button');
	const checkOutButtonText = document.getElementById('cart-check-out-button-text');
	const activityIndicator = document.getElementById('cart-check-out-activity-indicator');

	// Update display
	const buttonTitle = checkOutButtonText.innerHTML;
	checkOutButton.disabled = true;
	checkOutButtonText.style.display = 'none';
	activityIndicator.style.display = 'block';

	// Check out current cart
	Parse.Cloud.run('checkoutCartForUser', { platform: 'web' }).then(async (payload) => {

		// Update display
		checkOutButton.disabled = false;
		checkOutButtonText.innerHTML = buttonTitle;
		checkOutButtonText.style.display = 'block';
		activityIndicator.style.display = 'none';

		// Parse response
		if (payload != null && payload.success === true) {

			// Reset cart display
			cartObj = await Parse.Cloud.run('fetchActiveCartForUser', null);

			// Update cart display
			updateCartDisplay();

			// Parse PDF url
			const { url } = payload;
			if (url != null) {

				// Open url in new tab
				window.open(url, '_blank');
			}
		} else {

			// Create error alert
			document.getElementById('cart-error-modal-title').innerHTML = 'Whoops!';
			document.getElementById('cart-error-modal-description').innerHTML = 'We\'re Having Trouble Checking Out Your Cart. Please Try Again.';

			// Display error alert
			inputModule.showModalWithId('cart-error-modal');
		}
	}).catch((error) => {
		if (error && error.code === 209) {
			externalUserModule.handleUserLogOut(true);
		} else {

			// Update display
			checkOutButton.disabled = false;
			checkOutButtonText.innerHTML = buttonTitle;
			checkOutButtonText.style.display = 'block';
			activityIndicator.style.display = 'none';

			// Create error alert
			document.getElementById('cart-error-modal-title').innerHTML = 'Whoops!';
			document.getElementById('cart-error-modal-description').innerHTML = 'We\'re Having Trouble Checking Out Your Cart. Please Try Again.';

			// Display error alert
			inputModule.showModalWithId('cart-error-modal');
		}
	});
};


/**
 * Input Handlers
 */

const handleQuantityDidChange = (elementId) => {

	// Get quantity
	const quantity = parseInt($(`#${elementId}`).val(), 10);
	if (!(Number.isNaN(quantity) === true || quantity < 0)) {

		// Queue for update
		const assetID = elementId.replace('-cart-asset-quantity', '');
		let currentQuantity = 1;
		for (let i = 0; i < assetData.length; i += 1) {
			if (assetData[i].id === assetID) {
				currentQuantity = assetData[i].quantity;
				break;
			}
		}
		if (assetID != null && currentQuantity != null) {
			if (quantity !== currentQuantity) {
				queueForCartUpdate({ id: assetID, quantity, action: 'update' });
			}
		}
	}
};

const handleResetQuantity = (elementId) => {

	// Get value
	const value = parseInt($(`#${elementId}`).val(), 10);

	// Reset to 1 if necessary
	if (Number.isNaN(value) === true || value < 0) {
		$(`#${elementId}`).val('1');

		// Handle quantity changed
		handleQuantityDidChange(elementId);
	}
};


/**
 * Display Handlers
 */

const expandCartElement = (elementId) => {

	// Get element
	const element = document.getElementById(elementId);

	// Get new height
	const newHeight = `${element.getAttribute('data-previous-height')}px`;

	// Set previous height
	element.setAttribute('data-previous-height', $(element).outerHeight());

	// Animate
	$(element).animate({ height: newHeight }, 200);
};


/**
 * Action Handlers
 */

const createActionHandlers = () => {

	/**
	 * General Action Handlers
	 */

	// Handle click on check out button
	$('#cart-check-out-button').click(() => {
		handleCartCheckoutPrompt();
	});


	/**
	 * Cart Card Action Handlers
	 */

	// Handle click on cart asset card
	$(document).off('click', "[name='cart-asset-card']");
	$(document).on('click', "[name='cart-asset-card']", function () {
		expandCartElement($(this).data('element-id'));
	});

	// Handle click on increment button
	$(document).off('click', "button[name='cart-increment-button']");
	$(document).on('click', "button[name='cart-increment-button']", function () {

		// Handle increment input
		inputModule.handleIncrementInput($(this).data('input-id'), false);

		// Handle quantity changed
		handleQuantityDidChange($(this).data('input-id'));
	});

	// Handle click on decrement button
	$(document).off('click', "button[name='cart-decrement-button']");
	$(document).on('click', "button[name='cart-decrement-button']", function () {

		// Handle decrement input
		inputModule.handleDecrementInput($(this).data('input-id'), false);

		// Handle quantity changed
		handleQuantityDidChange($(this).data('input-id'));
	});


	/**
	 * Check Out Confirmation Modal Action Handlers
	 */

	// Handle click on check out confirmation confirm button
	$('#cart-check-out-confirmation-modal-confirm').click(() => {

		// Check out cart
		handleCartCheckout();

		// Hide modal
		inputModule.hideModalWithId('cart-check-out-confirmation-modal');
	});

	// Handle click on check out confirmation cancel button
	$('#cart-check-out-confirmation-modal-cancel').click(() => {
		inputModule.hideModalWithId('cart-check-out-confirmation-modal');
	});


	/**
	 * Remove Confirmation Modal Action Handlers
	 */

	// Handle click on remove confirmation confirm button
	$('#cart-remove-asset-confirmation-modal-confirm').click(() => {

		// Update payload
		currentPayload.action = 'remove';

		// Update cart
		updateCart(currentPayload);

		// Hide modal
		inputModule.hideModalWithId('cart-remove-asset-confirmation-modal');
	});

	// Handle click on remove confirmation cancel button
	$('#cart-remove-asset-confirmation-modal-cancel').click(() => {

		// Update cart
		currentPayload.quantity = 1;
		updateCart(currentPayload);

		// Hide modal
		inputModule.hideModalWithId('cart-remove-asset-confirmation-modal');
	});


	/**
	 * Error Modal Action Handlers
	 */

	// Handle click on error modal confirm button
	$('#cart-error-modal-confirm').click(() => {
		inputModule.hideModalWithId('cart-error-modal');
	});
};


/**
 * Input Handlers
 */

const createInputHandlers = () => {

	// Handle quantity change content
	$(document).off('keyup', "[name='cart-asset-quantity-input']");
	$(document).on('keyup', "[name='cart-asset-quantity-input']", function () {
		handleQuantityDidChange($(this).attr('id'));
	});

	// Handle quantity blur
	$(document).off('blur', "[name='cart-asset-quantity-input']");
	$(document).on('blur', "[name='cart-asset-quantity-input']", function () {
		handleResetQuantity($(this).attr('id'));
	});
};


/**
 * State Handlers
 */

exports.handlerDidLoad = async () => {

	// Create action handlers
	createActionHandlers();

	// Create input handlers
	createInputHandlers();

	// Fetch configuration for user
	const config = await userModule.handleConfigurationForUser(false);
	cartGlobalConfiguration = JSON.parse(config);

	// Get configuration
	if (cartGlobalConfiguration != null) {

		// Get features config
		const featuresDict = cartGlobalConfiguration.features;

		// Set purchase config
		const configObj = featuresDict.purchase_option_configuration;
		if (configObj != null) {
			purchaseConfig = configObj;
		}
	}

	// Set check out button action
	const action = purchaseConfig.flow_action;
	if (action != null) {
		flowAction = action;
	}
	$('#cart-check-out-button-text').html(flowAction);

	// Update button display
	updateCheckOutButton();

	// Get active cart
	cartObj = await Parse.Cloud.run('fetchActiveCartForUser', null);

	// Ensure elements loaded
	$(document).ready(async () => {

		// Update cart display
		updateCartDisplay();
	});
};

exports.handlerDidAppear = async () => {

	// Get active cart
	cartObj = await Parse.Cloud.run('fetchActiveCartForUser', null);

	// Update cart display
	updateCartDisplay();
};
