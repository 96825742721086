// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

const inputModule = require('../modules/input');
const externalUserModule = require('../modules/external-user');
const Constants = require('../../../Constants');
const { getCookie } = require('../modules/utilities');


/**
 * State
 */

let lastValue = '';


/**
 * Handlers
 */

const skipInvitationOnboarding = async () => {

	// Get elements
	const skipButton = document.getElementById('skip-button');
	const actionButton = document.getElementById('invite-action-button');
	const actionText = document.getElementById('invite-action-button-text');
	const activeArrow = document.getElementById('invite-action-button-arrow');
	const activityIndicator = document.getElementById('invite-button-activity-indicator');

	// Update display
	skipButton.disabled = true;
	actionButton.disabled = true;
	actionText.innerHTML = '';
	activeArrow.style.display = 'none';
	activityIndicator.style.display = 'block';

	// Handle skip invites onboarding
	try {

		// Handle skip onboarding
		const result = await Parse.Cloud.run('handleSkipInviteOnboarding', null);

		// Get current user data
		const current = Parse.User.current();
		const email = current.get('email');

		// Set route for result
		if (result != null) {
			let url;
			if (result.next === 'configuration') {
				url = `/enroll/configuration${window.location.search}`;
			} else if (result.next === 'subscription') {
				url = `/enroll/payment${window.location.search}`;
			} else if (result.next === 'invite') {
				url = `/enroll/invite${window.location.search}`;
			} else if (result.next === 'confirmation') {
				let { search } = window.location;
				if (search !== '') search += '&status=complete';
				else search += '?status=complete';
				search += `&em=${email}`;
				url = `/enroll/confirmation${search}`;
			} else if (result.next === 'waiting-for-approval') {
				url = '/waiting-for-approval';
			}
			if (url != null) {
				await externalUserModule.fetchStateParametersForUser(false);
				window.location = url;
			} else {

				// Display error
				inputModule.showModalWithId('invite-skip-error-modal');

				// Update display
				skipButton.disabled = false;
				actionButton.disabled = false;
				actionText.innerHTML = 'Invite Users';
				activeArrow.style.display = 'inline-block';
				activityIndicator.style.display = 'none';
			}
		}
	} catch (error) {
		if (error && error.code === 209) {
			externalUserModule.handleUserLogOut(true);
		} else {

			// Display error
			inputModule.showModalWithId('invite-skip-error-modal');

			// Update display
			skipButton.disabled = false;
			actionButton.disabled = false;
			actionText.innerHTML = 'Invite Users';
			activeArrow.style.display = 'inline-block';
			activityIndicator.style.display = 'none';
		}
	}
};

const handleInviteActionButton = async () => {

	// Get content
	const content = $('#email-container').text();

	// Get all valid email addresses
	const emailRegex = new RegExp(Constants.EMAIL_REGEX);
	const matches = content.match(emailRegex);

	// Validate matches
	if (!matches || matches.length === 0) {

		// Show block alert
		inputModule.showBlockAlert();
	} else {

		// Get user role
		const userRole = getCookie('userRole');
		let role = 'standard';
		if (userRole === 'super') role = 'leader';
		if (userRole === 'admin') role = 'super';

		// Create user array
		const userArray = [];

		// Create user object for each email
		for (let i = 0; i < matches.length; i += 1) {
			const userObj = {
				email: matches[i],
				role
			};
			userArray.push(userObj);
		}

		// Get elements
		const skipButton = document.getElementById('skip-button');
		const actionButton = document.getElementById('invite-action-button');
		const actionText = document.getElementById('invite-action-button-text');
		const activeArrow = document.getElementById('invite-action-button-arrow');
		const activityIndicator = document.getElementById('invite-button-activity-indicator');
		const emailContainer = document.getElementById('email-container');

		// Update display
		if (skipButton) skipButton.disabled = true;
		actionButton.disabled = true;
		actionText.innerHTML = '';
		activeArrow.style.display = 'none';
		activityIndicator.style.display = 'block';
		emailContainer.style.pointerEvents = 'none';

		// Validate emails
		try {
			let result = await Parse.Cloud.run('checkEmailExists', { emailArray: matches });
			if (result && result.length === 0) {

				// Save users with parameters
				try {

					// Save users
					result = await Parse.Cloud.run('saveUsersWithParameters', { platform: 'web', userArray });

					// Get current user data
					const current = Parse.User.current();
					const email = current.get('email');

					// Set route for result
					if (result != null) {
						let url;
						if (result.next === 'configuration') {
							url = `/enroll/configuration${window.location.search}`;
						} else if (result.next === 'subscription') {
							url = `/enroll/payment${window.location.search}`;
						} else if (result.next === 'invite') {
							url = `/enroll/invite${window.location.search}`;
						} else if (result.next === 'confirmation') {
							let { search } = window.location;
							if (search !== '') search += '&status=complete';
							else search += '?status=complete';
							search += `&em=${email}`;
							url = `/enroll/confirmation${search}`;
						} else if (result.next === 'waiting-for-approval') {
							url = '/waiting-for-approval';
						}
						if (url != null) {
							await externalUserModule.fetchStateParametersForUser(false);
							window.location = url;
						} else {

							// Update display
							actionText.innerHTML = 'Successfully Sent Invites';
							activityIndicator.style.display = 'none';
							emailContainer.style.pointerEvents = 'auto';

							// Set delay
							setTimeout(() => {

								// Update display
								if (skipButton) skipButton.disabled = false;
								actionButton.disabled = false;
								actionText.innerHTML = 'Invite Users';
								activeArrow.style.display = 'inline-block';

							}, 2000);
						}
					}
				} catch (error) {
					if (error && error.code === 209) {
						externalUserModule.handleUserLogOut(true);
					} else {

						// Display error
						inputModule.showModalWithId('invite-save-error-modal');

						// Update display
						if (skipButton) skipButton.disabled = false;
						actionButton.disabled = false;
						actionText.innerHTML = 'Invite Users';
						activeArrow.style.display = 'inline-block';
						activityIndicator.style.display = 'none';
						emailContainer.style.pointerEvents = 'auto';
					}
				}
			} // Some emails are invalid

			// Show block alert
			inputModule.showBlockAlert(`A user with the email ${result[0]} already exists. Please enter a different email.`);

			// Update display
			if (skipButton) skipButton.disabled = false;
			actionButton.disabled = false;
			actionText.innerHTML = 'Invite Users';
			activeArrow.style.display = 'inline-block';
			activityIndicator.style.display = 'none';
			emailContainer.style.pointerEvents = 'auto';

		} catch (error) {

			// Display error
			inputModule.showModalWithId('invite-save-error-modal');

			// Update display
			if (skipButton) skipButton.disabled = false;
			actionButton.disabled = false;
			actionText.innerHTML = 'Invite Users';
			activeArrow.style.display = 'inline-block';
			activityIndicator.style.display = 'none';
			emailContainer.style.pointerEvents = 'auto';
		}
	}
};

const setEndOfContenteditable = (contentEditableElement) => {
	let range; let
		selection;
	if (document.createRange) { // Firefox, Chrome, Opera, Safari, IE 9+
		range = document.createRange(); // Create a range (a range is a like the selection but invisible)
		range.selectNodeContents(contentEditableElement); // Select the entire contents of the element with the range
		range.collapse(false); // collapse the range to the end point. false means collapse to end rather than the start
		selection = window.getSelection(); // get the selection object (allows you to change selection)
		selection.removeAllRanges(); // remove any selections already made
		selection.addRange(range); // make the range you have just created the visible selection
	} else if (document.selection) { // IE 8 and lower
		range = document.body.createTextRange(); // Create a range (a range is a like the selection but invisible)
		range.moveToElementText(contentEditableElement); // Select the entire contents of the element with the range
		range.collapse(false); // collapse the range to the end point. false means collapse to end rather than the start
		range.select(); // Select the range (make it the visible selection
	}
};

const parseEmailAddresses = () => {

	// Set delay for cursor
	setTimeout(() => {

		// Check if content is updated
		const content = $('#email-container').text();
		if (lastValue !== content && content) {

			// Set new last value
			lastValue = content;

			// Update matching email addressed
			const emailRegex = new RegExp(Constants.EMAIL_REGEX);
			const finalStr = content.replace(emailRegex, (str) => `<strong>${str}</strong>`);

			// Set content and update cursor
			document.getElementById('email-container').innerHTML = finalStr;
			setEndOfContenteditable($('#email-container')[0]);
		}
	}, 400);
};

const insertTextAtCursor = (text) => {
	let sel; let range;
	if (window.getSelection) {
		sel = window.getSelection();
		if (sel.getRangeAt && sel.rangeCount) {
			range = sel.getRangeAt(0);
			range.deleteContents();
			range.insertNode(document.createTextNode(text));
		}
	} else if (document.selection && document.selection.createRange) {
		document.selection.createRange().text = text;
	}
};


/**
 * Action Handlers
 */

const createActionHandlers = () => {

	// Handle click on action button
	$('#invite-action-button').click(() => {
		handleInviteActionButton();
	});

	// Handle click on back button
	$('#invite-back-button').click(function () {
		window.location = $(this).data('back-url');
	});

	// Handle click on skip button
	$('#skip-button').click(() => {
		skipInvitationOnboarding();
	});

	// Handle click on save error button
	$('#invite-save-error-modal-confirm').click(() => {
		inputModule.hideModalWithId('invite-save-error-modal');
	});

	// Handle click on skip error button
	$('#invite-skip-error-modal-confirm').click(() => {
		inputModule.hideModalWithId('invite-skip-error-modal');
	});
};


/**
 * Input Handlers
 */

const createInputHandlers = () => {

	// Handle blur
	$('#email-container').blur(() => {
		inputModule.removeBlockAlert();
	});

	// Handle change content
	$('#email-container').keydown(() => {
		parseEmailAddresses();
	});

	// Handle change content
	$('#email-container').keyup(() => {
		inputModule.removeBlockAlert();
	});

	// Handle focus
	$('#email-container').focus(() => {
		inputModule.removeBlockAlert();
	});
};


/**
 * State Handlers
 */

exports.handlerDidLoad = async (context) => {

	// Create action handlers
	createActionHandlers();

	// Create input handlers
	createInputHandlers();

	// Set up view for context
	if (context === Constants.VIEW_LOAD_CONTEXTS.CONTEXT_ONBOARDING) {

		// Handle onboarding user
		await externalUserModule.handleOnboardingUser();

		// Check user role
		const userRole = getCookie('userRole');
		if (userRole !== 'standard') {

			// Add paste listener for email container
			$('#email-container').ready(() => {
				document.getElementById('email-container').addEventListener('paste', (e) => {
					e.preventDefault();
					if (e.clipboardData && e.clipboardData.getData) {
						let text = e.clipboardData.getData('text/plain');
						try { text = $(text).text(); } catch (err) { }
						document.execCommand('insertHTML', false, text);
					} else if (window.clipboardData && window.clipboardData.getData) {
						const text = window.clipboardData.getData('Text');
						insertTextAtCursor(text);
					}
				});
			});
		} else {
			externalUserModule.handleExternalUser(false, true);
		}
	} else {

		// Add paste listener for email container
		$('#email-container').ready(() => {
			document.getElementById('email-container').addEventListener('paste', (e) => {
				e.preventDefault();
				if (e.clipboardData && e.clipboardData.getData) {
					let text = e.clipboardData.getData('text/plain');
					try { text = $(text).text(); } catch (err) { }
					document.execCommand('insertHTML', false, text);
				} else if (window.clipboardData && window.clipboardData.getData) {
					const text = window.clipboardData.getData('Text');
					insertTextAtCursor(text);
				}
			});
		});
	}
};

exports.handlerDidAppear = async () => { };
