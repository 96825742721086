// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

const axios = require('axios');
const inputModule = require('../modules/input');
const userModule = require('../modules/user');
const navigationModule = require('../modules/navigation');
const accountHandler = require('./account-handler');


/**
 * State
 */

let profileGlobalDidChangePassword = false;
let profileGlobalConfiguration;
let profileProfileButtonTitle;


/**
 * Handlers
 */

const populateProfileSchema = () => {

	// Get current user
	const currentUser = Parse.User.current();

	// Get elements
	const formHolder = document.getElementById('profile-form-holder');

	// Ensure element is loaded
	$(formHolder).ready(() => {

		// Get first name value
		let firstNameValue = '';
		let initial = 'S';
		if (currentUser.get('firstName') != null) {
			initial = currentUser.get('firstName').charAt(0).toUpperCase();
			firstNameValue = ` value='${currentUser.get('firstName').replace(/"/g, '&#34;').replace(/'/g, '&#39;')}'`;
		}

		// Get last name value
		let lastNameValue = '';
		if (currentUser.get('lastName') != null) {
			lastNameValue = ` value='${currentUser.get('lastName').replace(/"/g, '&#34;').replace(/'/g, '&#39;')}'`;
		}

		// Get email value
		let emailValue = '';
		if (currentUser.get('username') != null) {
			emailValue = ` value='${currentUser.get('username').replace(/"/g, '&#34;').replace(/'/g, '&#39;')}'`;
		}

		// Set profile image content
		let html = "<div style='display:inline-block;'>";
		const profileImage = currentUser.get('profileImageUrl');
		if (profileImage != null && profileImage !== '') {
			html += (`<div name='comp-profile-image-icon' id='comp-profile-image-icon' class='profile-image-icon'
                 data-url='${profileImage}' style='background-image:url("${profileImage}"); float:left; background-color:transparent;'>
                <h3 name='comp-default-initial' id='comp-default-initial' style='display:none;'>${initial}</h3>
                </div>`);
		} else {
			html += (`<div name='comp-profile-image-icon' id='comp-profile-image-icon' class='profile-image-icon'
                style='float:left; background-color:white;'>
                <h3 name='comp-default-initial' id='comp-default-initial'>${initial}</h3>
                </div>`);
		}
		html += ("<button class='small-button' style='width:80px; margin:23px 0px 0px 23px;' id='change-picture-button'>Change</button>"
            + "<input type='file' id='profile-picture-file-input' accept='image/x-png,image/jpeg' style='display:none;'/>"
            + "<button class='small-button' style='width:40px; margin:23px 0px 0px 10px;' id='remove-picture-button'>"
            + "<i class='far fa-trash-alt'></i></button></div>");

		// Create first name field
		html += (`<fieldset style='margin:25px auto 0px;'>
            <input id='firstName' name='firstName' type='text'${firstNameValue}
             style='margin:0px;' required><label for='firstName' id='firstNameLabel'>First Name</label>
            </fieldset>`);

		// Create last name field
		html += (`<fieldset style='margin:15px auto 0px;'>
            <input id='lastName' name='lastName' type='text'${lastNameValue}
             style='margin:0px;' required><label for='lastName' id='lastNameLabel'>Last Name</label>
            </fieldset>`);

		// Create email field
		html += (`<fieldset style='margin:15px auto 0px;'>
            <input id='email' name='email' type='text'${emailValue}
             style='margin:0px;' required><label for='email' id='emailLabel'>Email</label>
            </fieldset>`);

		// Get schema
		const inputArray = profileGlobalConfiguration.input.user_profile.fields;

		// Create schema for user
		for (let i = 0; i < inputArray.length; i += 1) {

			// Get configuration
			const inputConfig = inputArray[i];
			const { prompt } = inputConfig;
			const inputType = inputConfig.type;
			const refArray = inputConfig.ref;
			const inputId = `${inputConfig.id}-user-input`;
			const { choices } = inputConfig;

			// Get current data
			let refData = currentUser.get('data');
			let displayData = '';
			for (let j = 0; j < refArray.length; j += 1) {
				const ref = refArray[j];
				const check = refData[ref];
				if (check != null) {
					if (Array.isArray(check)) {
						for (let k = 0; k < check.length; k += 1) {
							if (k !== 0) {
								displayData += ', ';
							}
							displayData += check[k];
						}
					} else if (typeof check === 'string') {
						displayData = check;
					} else if (typeof check === 'object') {
						refData = check;
					}
				}
			}

			// Create input for type
			if (inputType === 'text' || inputType === 'array' || inputType === 'number') { // Input

				// Get input type
				let inputDisplayType = 'text';
				if (inputType === 'number') inputDisplayType = 'number';

				// If numeric input, display increment / decrement buttons
				let buttonContent = '';
				if (inputType === 'number') {
					buttonContent = `<div class='incr-decr-holder'>
                    <button class='increment-button animate' name='profile-increment-button' data-input-id='${inputId}'>
                    <i class="fas fa-plus"></i>
                    </button>
                    <button class='decrement-button animate' name='profile-decrement-button' data-input-id='${inputId}'>
                    <i class="fas fa-minus"></i>
                    </button>
                    </div>`;
				}

				// Set html
				html += (`<fieldset style='margin:15px auto 0px;'>
                <input id='${inputId}' name='${inputId}' type='${inputDisplayType}'
                data-name-type='profile-input-field-element' data-prompt='${prompt}'
                value='${displayData.replace(/"/g, '&#34;').replace(/'/g, '&#39;')}'
                style='margin:0px;' required><label for='${inputId}' id='${inputId}Label'>${prompt}</label>
                ${buttonContent}</fieldset>`);
			} else if (inputType === 'choice' && choices != null && choices.length > 0) {

				// Set html
				html += (`<fieldset style='margin:15px auto 0px;'>
                    <select id='${inputId}' name='${inputId}' data-name-type='profile-input-field-element' required>
                    <option value=''>Select an Option</option>`);

				// Create options
				for (let j = 0; j < choices.length; j += 1) {
					let selected = '';
					if (choices[j] != null && choices[j].id === displayData) {
						selected = " selected='selected' ";
					}
					html += (`<option ${selected}value='${choices[j].id}'>${choices[j].value}</option>`);
				}

				// Set html
				html += (`</select>
                    <label for='${inputId}' id='${inputId}Label' class='activated-label'>${prompt}</label>
                    </fieldset>`);
			}
		}

		// Create password section
		html += (
			`<div id='password-section'>
            <div class='collection-content-container' style='width:85%; margin:20px auto 20px;'>
            <h3><span class='bubble' style='margin:0px;'>Password</span></h3>
            </div>
            <fieldset id='old-password-holder' style='margin:0px auto 0px; display:none; width:85%;'>
            <input id='oldPass' name='oldPass' type='password' required>
            <label for='oldPass' id='oldPassLabel'>Current Password</label>
            </fieldset>
            <fieldset style='margin:0px auto 0px; width:85%;'>
            <input id='pass' name='pass' type='password' value='----------' required disabled>
            <label for='pass' id='passLabel'>Password</label>
            </fieldset>
            <fieldset id='confirm-password-holder' style='margin:0px auto 0px; display:none; width:85%;'>
            <input id='confirmPass' name='confirmPass' type='password' required>
            <label for='confirmPass' id='confirmPassLabel'>Confirm New Password</label>
            </fieldset>
            <div style='margin-top:0px;'>
            <button class='page-button' id='change-password-button' style='margin-top:10px;'>
            <span>Change My Password</span>
            </button>
            </div></div>`);

		// Add html content
		formHolder.innerHTML = html;
	});
};

const updateUserInitialProfile = () => {

	// Get elements
	const firstName = document.getElementById('firstName').value;
	const defaultInitial = document.getElementById('comp-default-initial');

	// Set new initial
	if (firstName !== '') {
		defaultInitial.innerHTML = firstName.charAt(0).toUpperCase();
	} else {
		defaultInitial.innerHTML = 'S';
	}
};

const changeUserProfilePicture = () => {

	// Select file input
	$('#profile-picture-file-input').click();
};

const updateUserProfileImage = () => {

	// Get current user
	const currentUser = Parse.User.current();

	// Get general parameters
	const profileImageURL = document.getElementById('comp-profile-image-icon').getAttribute('data-url');

	// Set profile picture
	currentUser.set('profileImageUrl', profileImageURL);

	// Save current user
	currentUser.save(null).then(() => {

		// Update asset collection view
		if (document.getElementById('/dashboard/account')) {
			accountHandler.updateAccountProfileDisplay();
		}

		// Update state
		const shouldRemoveImage = (profileImageURL == null);
		navigationModule.handleNavigationDisplay(shouldRemoveImage);

	}).catch(() => {

		// Display error
		inputModule.showModalWithId('profile-save-error-modal');
	});
};

const removeUserProfilePicture = () => {

	// Get profile picture elements
	const profileImageIcons = document.getElementsByName('comp-profile-image-icon');
	const defaultInitials = document.getElementsByName('comp-default-initial');

	// Set profile picture content
	for (let i = 0; i < defaultInitials.length; i += 1) {
		defaultInitials[i].style.display = 'block';
	}
	for (let i = 0; i < profileImageIcons.length; i += 1) {
		profileImageIcons[i].style.backgroundImage = '';
		profileImageIcons[i].removeAttribute('data-url');
	}

	// Update profile image
	updateUserProfileImage();
};

const updateUserProfilePicture = () => {

	// Get image file
	const image = document.getElementById('profile-picture-file-input').files[0];

	// Get session token
	const sessionToken = Parse.User.current().getSessionToken();

	// Validate session
	if (sessionToken == null) {

		// Display error
		inputModule.showModalWithId('profile-save-error-modal');

		// Return
		return;
	}

	// Validate image
	if (image == null) {

		// Display error
		inputModule.showModalWithId('profile-save-error-modal');

		// Return
		return;
	}

	// Create form data
	const formData = new FormData();
	formData.append('file', image);

	// Post profile image
	axios.post(`${process.env.BASE_API_URL}/media/profile`, formData, {
		headers: {
			'x-parse-session-token': sessionToken,
			'Content-Type': 'multipart/form-data'
		}
	}).then((response) => {

		// Get profile image url
		const profileImage = response.data;

		// Get profile picture elements
		const profileImageIcons = document.getElementsByName('comp-profile-image-icon');
		const defaultInitials = document.getElementsByName('comp-default-initial');

		// Set profile picture content
		for (let i = 0; i < defaultInitials.length; i += 1) {
			defaultInitials[i].style.display = 'none';
		}
		for (let i = 0; i < profileImageIcons.length; i += 1) {
			profileImageIcons[i].style.backgroundImage = `url('${profileImage}')`;
			profileImageIcons[i].setAttribute('data-url', profileImage);
		}

		// Update profile image
		updateUserProfileImage();

	}).catch(() => {

		// Display error
		inputModule.showModalWithId('profile-save-error-modal');
	});
};

const changeUserPassword = (placeholderLength) => {
	if (profileGlobalDidChangePassword === true) { // Cancelled change password
		profileGlobalDidChangePassword = false;

		// Create placeholder
		let placeholder = '----------';
		if (placeholderLength != null) {
			placeholder = '';
			for (let i = 0; i < placeholderLength; i += 1) {
				placeholder += '-';
			}
		}

		// Update element display
		document.getElementById('pass').disabled = true;
		document.getElementById('pass').value = placeholder;
		document.getElementById('pass').onblur = function () { inputModule.removeAlert('passLabel', 'Password', true); };
		document.getElementById('pass').onkeydown = function () { inputModule.removeAlert('passLabel', 'Password', true); };
		document.getElementById('passLabel').innerHTML = 'New Password';
		document.getElementById('confirm-password-holder').style.display = 'none';
		document.getElementById('old-password-holder').style.display = 'none';
		document.getElementById('change-password-button').innerHTML = 'Change Password';
	} else { // Began change password
		profileGlobalDidChangePassword = true;

		// Update element display
		document.getElementById('pass').disabled = false;
		document.getElementById('pass').value = '';
		document.getElementById('pass').onblur = function () { inputModule.removeAlert('passLabel', 'New Password', true); };
		document.getElementById('pass').onkeydown = function () { inputModule.removeAlert('passLabel', 'New Password', true); };
		document.getElementById('passLabel').innerHTML = 'New Password';
		document.getElementById('confirm-password-holder').style.display = 'block';
		document.getElementById('old-password-holder').style.display = 'block';
		document.getElementById('change-password-button').innerHTML = 'Cancel';
	}
};

const updateUserProfile = () => {

	// Get current user
	const currentUser = Parse.User.current();

	// Get general parameters
	const firstName = document.getElementById('firstName').value;
	const lastName = document.getElementById('lastName').value;
	const email = document.getElementById('email').value;
	const profileImageURL = document.getElementById('comp-profile-image-icon').getAttribute('data-url');

	// Set general parameters
	currentUser.set('firstName', firstName);
	currentUser.set('lastName', lastName);
	currentUser.set('username', email);
	currentUser.set('email', email);

	// Set profile picture
	currentUser.set('profileImageUrl', profileImageURL);

	// Get user data
	let schema = currentUser.get('data');

	// Get specific parameters
	const inputArray = profileGlobalConfiguration.input.user_profile.fields;
	for (let i = 0; i < inputArray.length; i += 1) {

		// Get configuration
		const inputConfig = inputArray[i];
		const inputType = inputConfig.type;
		const refArray = inputConfig.ref;
		const inputId = `${inputConfig.id}-user-input`;

		// Get current parameter
		let parameter;
		if (document.getElementById(inputId)) {
			parameter = document.getElementById(inputId).value;
		}

		// Validate parameter
		if (parameter != null) {
			if (inputType === 'array') {
				parameter = parameter.split(',');
				for (let j = 0; j < parameter.length; j += 1) {
					parameter[j] = parameter[j].trim();
				}
			}

			// Set parameter
			for (let j = 0; j < refArray.length - 1; j += 1) {
				const ref = refArray[j];
				if (!schema[ref]) schema[ref] = {};
				schema = schema[ref];
			}
			schema[refArray[refArray.length - 1]] = parameter;
		}
	}

	// Set user data
	currentUser.set('data', schema);

	// Save current user
	currentUser.save(null).then(() => {

		// Update state
		profileGlobalDidChangePassword = false;
		const shouldRemoveImage = (profileImageURL == null);
		navigationModule.handleNavigationDisplay(shouldRemoveImage);

		// Update asset collection view
		if (document.getElementById('/dashboard/account')) {
			accountHandler.updateAccountProfileDisplay();
		}

		// Update display
		document.getElementById('profile-action-button-text').innerHTML = 'Successfully Saved Profile';
		document.getElementById('profile-button-activity-indicator').style.display = 'none';

		// Set delay
		setTimeout(() => {

			// Update display
			document.getElementById('profile-action-button').disabled = false;
			document.getElementById('profile-action-button-text').innerHTML = profileProfileButtonTitle;
			document.getElementById('profile-action-button-arrow').style.display = 'inline-block';

		}, 2000);
	}).catch(() => {

		// Update display
		document.getElementById('profile-action-button').disabled = false;
		document.getElementById('profile-action-button-text').innerHTML = profileProfileButtonTitle;
		document.getElementById('profile-action-button-arrow').style.display = 'inline-block';
		document.getElementById('profile-button-activity-indicator').style.display = 'none';

		// Display error
		inputModule.showModalWithId('profile-save-error-modal');
	});
};

const saveProfileChanges = async () => {

	// Get current user
	const currentUser = Parse.User.current();

	// Get general parameters
	const firstName = document.getElementById('firstName').value;
	const lastName = document.getElementById('lastName').value;
	const email = document.getElementById('email').value;
	const oldPass = document.getElementById('oldPass').value;
	const pass = document.getElementById('pass').value;
	const confirmPass = document.getElementById('confirmPass').value;

	// Get labels
	const firstNameLabel = document.getElementById('firstNameLabel');
	const lastNameLabel = document.getElementById('lastNameLabel');
	const emailLabel = document.getElementById('emailLabel');
	const oldPassLabel = document.getElementById('oldPassLabel');
	const passLabel = document.getElementById('passLabel');
	const confirmPassLabel = document.getElementById('confirmPassLabel');

	// Get validation results
	const firstNameResult = inputModule.validateText(firstName);
	const lastNameResult = inputModule.validateText(lastName);
	const oldPassResult = inputModule.validatePass(oldPass);
	const passResult = inputModule.validatePass(pass);
	const confirmPassResult = inputModule.verifyPass(pass, confirmPass);

	// Handle validation
	if (firstNameResult[0] === false) {
		firstNameLabel.innerHTML = `First Name (${firstNameResult[1]})`;
		firstNameLabel.className = 'errorLabel';
		firstNameLabel.focus();
	} else if (lastNameResult[0] === false) {
		lastNameLabel.innerHTML = `Last Name (${lastNameResult[1]})`;
		lastNameLabel.className = 'errorLabel';
		lastNameLabel.focus();
	} else {
		const emailResult = await inputModule.validateEmail(email, true);
		if (emailResult[0] === false && emailResult[1] === 'Required') {
			emailLabel.innerHTML = `Email (${emailResult[1]})`;
			emailLabel.className = 'errorLabel';
			emailLabel.focus();
		} else if (emailResult[0] === false && (currentUser.get('username') == null || (currentUser.get('username') !== email))) {
			[, emailLabel.innerHTML] = emailResult;
			emailLabel.className = 'errorLabel';
			emailLabel.focus();
		} else if (profileGlobalDidChangePassword === true && oldPassResult[0] === false) {
			oldPassLabel.innerHTML = `Current Password (${oldPassResult[1]})`;
			oldPassLabel.className = 'errorLabel';
			oldPassLabel.focus();
		} else if (profileGlobalDidChangePassword === true && passResult[0] === false) {
			[, passLabel.innerHTML] = passResult;
			passLabel.className = 'errorLabel';
			passLabel.focus();
		} else if (profileGlobalDidChangePassword === true && confirmPassResult[0] === false) {
			confirmPassLabel.innerHTML = `Confirm New Password (${confirmPassResult[1]})`;
			confirmPassLabel.className = 'errorLabel';
			confirmPassLabel.focus();
		} else {

			// Update display
			profileProfileButtonTitle = document.getElementById('profile-action-button-text').innerHTML;
			document.getElementById('profile-action-button').disabled = true;
			document.getElementById('profile-action-button-text').innerHTML = '';
			document.getElementById('profile-action-button-arrow').style.display = 'none';
			document.getElementById('profile-button-activity-indicator').style.display = 'block';

			// Set new password
			if (profileGlobalDidChangePassword === true) {

				// Get current user
				const existingEmail = currentUser.get('username');

				// Validate login
				Parse.Cloud.run('loginUser', {
					username: existingEmail.trim().toLowerCase(),
					password: oldPass,
					platform: 'web',
					entity: process.env.ENTITY
				}).then(() => {

					// Get new current user
					const newCurrentUser = Parse.User.current();

					// Set new password
					newCurrentUser.set('password', pass);

					// Update display
					changeUserPassword(pass.length);

					// Update user profile
					updateUserProfile();

				}).catch(() => {

					// Update display
					document.getElementById('profile-action-button').disabled = false;
					document.getElementById('profile-action-button-text').innerHTML = profileProfileButtonTitle;
					document.getElementById('profile-action-button-arrow').style.display = 'inline-block';
					document.getElementById('profile-button-activity-indicator').style.display = 'none';

					// Show error
					oldPassLabel.innerHTML = 'Must Match Your Current Password';
					oldPassLabel.className = 'errorLabel';
					oldPassLabel.focus();
				});
			} else {

				// Update user profile
				updateUserProfile();
			}
		}
	}
};


/**
 * Action Handlers
 */

const createActionHandlers = () => {

	// Handle click on action button
	$('#profile-action-button').click(() => {
		saveProfileChanges();
	});

	// Handle click on modal error button
	$('#profile-save-error-modal-confirmation').click(() => {
		inputModule.hideModalWithId('profile-save-error-modal');
	});

	// Handle click on change picture button
	$(document).off('click', '#change-picture-button');
	$(document).on('click', '#change-picture-button', () => {
		changeUserProfilePicture();
	});

	// Handle click on remove picture button
	$(document).off('click', '#remove-picture-button');
	$(document).on('click', '#remove-picture-button', () => {
		removeUserProfilePicture();
	});

	// Handle click on change password button
	$(document).off('click', '#change-password-button');
	$(document).on('click', '#change-password-button', () => {
		changeUserPassword();
	});

	// Handle click on increment button
	$(document).off('click', "button[name='profile-increment-button']");
	$(document).on('click', "button[name='profile-increment-button']", function () {
		inputModule.handleIncrementInput($(this).data('input-id'), false);
	});

	// Handle click on decrement button
	$(document).off('click', "button[name='profile-decrement-button']");
	$(document).on('click', "button[name='profile-decrement-button']", function () {
		inputModule.handleDecrementInput($(this).data('input-id'), false);
	});
};


/**
 * Input Handlers
 */

const createInputHandlers = () => {

	// Handle change actions
	$(document).off('change', '#profile-picture-file-input');
	$(document).on('change', '#profile-picture-file-input', () => {
		updateUserProfilePicture();
	});

	// Handle blur actions
	$(document).off('blur', '#firstName');
	$(document).on('blur', '#firstName', () => {
		inputModule.removeAlert('firstNameLabel', 'First Name', true);
	});
	$(document).off('blur', '#lastName');
	$(document).on('blur', '#lastName', () => {
		inputModule.removeAlert('lastNameLabel', 'Last Name', true);
	});
	$(document).off('blur', '#email');
	$(document).on('blur', '#email', () => {
		inputModule.removeAlert('emailLabel', 'Email', true);
	});
	$(document).off('blur', "[data-name-type='profile-input-field-element']");
	$(document).on('blur', "[data-name-type='profile-input-field-element']", function () {
		if (!$(this).is('select')) {
			inputModule.removeAlert(`${$(this).attr('id')}Label`, $(this).data('prompt'), true);
		} else {
			inputModule.removeBlockAlert();
		}
	});
	$(document).off('blur', '#oldPass');
	$(document).on('blur', '#oldPass', () => {
		inputModule.removeAlert('oldPassLabel', 'Current Password', true);
	});
	$(document).off('blur', '#pass');
	$(document).on('blur', '#pass', () => {
		inputModule.removeAlert('passLabel', 'New Password', true);
	});
	$(document).off('blur', '#confirmPass');
	$(document).on('blur', '#confirmPass', () => {
		inputModule.removeAlert('confirmPassLabel', 'Confirm New Password', true);
	});

	// Handle keyup actions
	$(document).off('keyup', '#firstName');
	$(document).on('keyup', '#firstName', () => {
		updateUserInitialProfile();
	});

	// Handle keydown actions
	$(document).off('keydown', '#firstName');
	$(document).on('keydown', '#firstName', () => {
		inputModule.removeAlert('firstNameLabel', 'First Name', true);
	});
	$(document).off('keydown', '#lastName');
	$(document).on('keydown', '#lastName', () => {
		inputModule.removeAlert('lastNameLabel', 'Last Name', true);
	});
	$(document).off('keydown', '#email');
	$(document).on('keydown', '#email', () => {
		inputModule.removeAlert('emailLabel', 'Email', true);
	});
	$(document).off('keydown', "[data-name-type='profile-input-field-element']");
	$(document).on('keydown', "[data-name-type='profile-input-field-element']", function () {
		inputModule.removeAlert(`${$(this).attr('id')}Label`, $(this).data('prompt'), true);
	});
	$(document).off('keydown', '#oldPass');
	$(document).on('keydown', '#oldPass', () => {
		inputModule.removeAlert('oldPassLabel', 'Current Password', true);
	});
	$(document).off('keydown', '#pass');
	$(document).on('keydown', '#pass', () => {
		inputModule.removeAlert('passLabel', 'New Password', true);
	});
	$(document).off('keydown', '#confirmPass');
	$(document).on('keydown', '#confirmPass', () => {
		inputModule.removeAlert('confirmPassLabel', 'Confirm New Password', true);
	});

	// Handle focus actions
	$(document).off('focus', '#firstName');
	$(document).on('focus', '#firstName', () => {
		inputModule.removeBlockAlert();
	});
	$(document).off('focus', '#lastName');
	$(document).on('focus', '#lastName', () => {
		inputModule.removeBlockAlert();
	});
	$(document).off('focus', '#email');
	$(document).on('focus', '#email', () => {
		inputModule.removeBlockAlert();
	});
	$(document).off('focus', "[data-name-type='profile-input-field-element']");
	$(document).on('focus', "[data-name-type='profile-input-field-element']", () => {
		inputModule.removeBlockAlert();
	});
	$(document).off('focus', '#oldPass');
	$(document).on('focus', '#oldPass', () => {
		inputModule.removeBlockAlert();
	});
	$(document).off('focus', '#pass');
	$(document).on('focus', '#pass', () => {
		inputModule.removeBlockAlert();
	});
	$(document).off('focus', '#confirmPass');
	$(document).on('focus', '#confirmPass', () => {
		inputModule.removeBlockAlert();
	});
};


/**
 * State Handlers
 */

exports.handlerDidLoad = async () => {

	// Create action handlers
	createActionHandlers();

	// Create input handlers
	createInputHandlers();

	// Fetch configuration for user
	const config = await userModule.handleConfigurationForUser(false);
	profileGlobalConfiguration = JSON.parse(config);

	// Hide loading indicator
	const loadingIndicator = document.getElementById('profile-activity-indicator');
	$(loadingIndicator).ready(() => {
		loadingIndicator.remove();
	});

	// Populate schema fields
	populateProfileSchema();
};

exports.handlerDidAppear = async () => { };
