// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

const inputModule = require('../modules/input');
const appModule = require('../modules/app');


/**
 * State
 */

let importColumnType;
let importSkippedColumns = [];


/**
 * Handlers
 */

const setupHeader = (column, index) => {

	// Build column name
	const columnName = window.origin_importFile === 'assets' ? column : inputModule.camelToTitle(inputModule.toCamelCase(column));

	// Create column content
	const html = (`<th style='text-align: center'>${columnName}
		<div id='define-edit-column-selector${index}' style='display: none;'>
		<select class='define-select-element' id='define-column-type-filter-id${index}' name='define-column-type-filter${index}'>
		<option value='' disabled selected><b>Available Column Names...</b></option>
		</select>
		<button style='width:85px;' name='define-cancel-edit-column' data-index='${index}'>Cancel</button>
		&nbsp;&nbsp;&nbsp;
		<button style='width:70px; margin-top:10px;' name='define-save-edit-column' data-index='${index}'>Save</button>
		</div>
		<div id='define-edit-column-${index}'>
		<button name='define-edit-column-element' data-index='${index}' style='width:70px; margin-top:10px;'>Edit</button>
		</div>`
	);
	return html;
};

const editColumnType = (element) => {
	const selectValue = element.options[element.selectedIndex].text;
	importColumnType = selectValue;
};

const checkIfHeaderExists = (header, index) => {
	for (let i = 0; i < window.import_globalFileHeader.length; i += 1) {
		if (inputModule.toCamelCase(header) === inputModule.toCamelCase(window.import_globalFileHeader[i]) && i !== index) {
			return true;
		}
	}
	return false;
};

const backToUploadDefine = () => {
	appModule.handleRouting('/dashboard/import');
};

const getSchemaColumns = () => {

	// Initialize column types
	let importGlobalColumnTypes = [];

	// Set columns
	if (window.origin_importFile === 'assets') {
		importGlobalColumnTypes = window.importAssetSchemaColumns;
	} else if (window.origin_importFile === 'users') {
		importGlobalColumnTypes = window.importUsersGlobalSchemaColumns;
	} else {
		importGlobalColumnTypes = window.importOrgsGlobalSchemaColumns;
	}

	// Return column types
	return importGlobalColumnTypes;
};

const setupColumnFilter = (index) => {

	// Get filter elements
	const elements = document.getElementsByName(`define-column-type-filter${index}`);

	// Add options to elements
	for (let i = 0; i < elements.length; i += 1) {
		const element = elements[i];

		// Ensure element is loaded
		if (element.children.length <= 1) {

			// Create column name filter options
			let html = '';
			const importGlobalColumnTypes = getSchemaColumns();

			// Build new options
			for (let j = 0; j < importGlobalColumnTypes.length; j += 1) {
				html += `<option value='${importGlobalColumnTypes[j].toLowerCase()}'>${importGlobalColumnTypes[j]}</option>`;
			}

			// Append option to filter
			element.innerHTML += html;
		}
	}

	// Set column options
	document.getElementById(`define-column-type-filter-id${index}`).value = window.import_globalFileHeader[index].toLowerCase();
};

const editColumn = (index) => {

	// Update all columns
	for (let i = 0; i < window.import_globalFileHeader.length; i += 1) {
		if (document.getElementById(`define-edit-column-selector${i}`).style.display === 'block') {
			document.getElementById(`define-edit-column-selector${i}`).style.display = 'none';
			document.getElementById(`define-edit-column-${i}`).style.display = 'block';
		}
	}

	// Update selected column
	document.getElementById(`define-edit-column-${index}`).style.display = 'none';
	document.getElementById(`define-edit-column-selector${index}`).style.display = 'block';

	// Set state
	importColumnType = window.import_globalFileHeader[index];

	// Set up column filter
	setupColumnFilter(index);
};

const cancelEditColumn = (index) => {
	document.getElementById(`define-edit-column-${index}`).style.display = 'block';
	document.getElementById(`define-edit-column-selector${index}`).style.display = 'none';
};

const saveEditColumn = (index) => {

	// Check if header exists
	if (!checkIfHeaderExists(importColumnType, index)) {

		// Update display
		document.getElementById(`define-edit-column-${index}`).style.display = 'block';
		document.getElementById(`define-edit-column-selector${index}`).style.display = 'none';

		// Update column header
		for (let i = 0; i < window.import_globalFileData.length; i += 1) {
			const oldColumnHeader = window.import_globalFileHeader[index];
			const columnValue = window.import_globalFileData[i][inputModule.toCamelCase(oldColumnHeader)];
			delete window.import_globalFileData[i][inputModule.toCamelCase(oldColumnHeader)];
			window.import_globalFileData[i][inputModule.toCamelCase(importColumnType)] = columnValue;
		}

		// Set new state
		window.import_globalFileHeader[index] = importColumnType;
		document.getElementById('define-table').rows[0].cells[index].innerHTML = setupHeader(importColumnType, index);
	} else { // Column is already matched
		const html = `<p>Another column is already matched to <b>${importColumnType}</b>. Please try again.</p>`;
		document.getElementById('define-error-message-holder').innerHTML = html;
		inputModule.showModalWithId('define-archive-error-modal');
	}
};

const filterMatchedColumns = () => {

	// Get schema columns
	const schemaColumns = getSchemaColumns();

	// Filter columns
	importSkippedColumns = window.import_globalFileHeader.filter((item) => {
		const columnItem = window.origin_importFile === 'assets' ? item : inputModule.camelToTitle(inputModule.toCamelCase(item));
		return !schemaColumns.includes(columnItem);
	});
};

const toConfirmDefine = () => {

	// Filter matched columns
	filterMatchedColumns();

	// Set file header
	window.import_globalFileHeader = window.import_globalFileHeader.filter((x) => !importSkippedColumns.includes(x));

	// Show confirmation modal
	let html = '<h3>All columns defined. Building column preview.</h3>';
	if (importSkippedColumns.length > 0) html = `<h3>All columns defined. Building column preview.<br><br>Skipped Column(s): ${importSkippedColumns.join()}</h3>`;
	document.getElementById('define-skipped-columns-message-holder').innerHTML = html;
	inputModule.showModalWithId('define-confirm-modal');

	// Set delay
	setTimeout(() => { appModule.handleRouting('/dashboard/confirm'); }, 3000);
};


/**
 * Action Handlers
 */

const createActionHandlers = () => {

	// Handle click on back button
	$('#define-back-button').click(() => {
		backToUploadDefine();
	});

	// Handle click on confirm button
	$('#define-confirm-button').click(() => {
		toConfirmDefine();
	});

	// Handle click on modal confirm button
	$('#define-archive-error-modal-confirm').click(() => {
		inputModule.hideModalWithId('define-archive-error-modal');
	});

	// Handle click on cancel edit column
	$(document).off('click', "[name='define-cancel-edit-column']");
	$(document).on('click', "[name='define-cancel-edit-column']", function () {
		cancelEditColumn($(this).data('index'));
	});

	// Handle click on save edit column
	$(document).off('click', "[name='define-save-edit-column']");
	$(document).on('click', "[name='define-save-edit-column']", function () {
		saveEditColumn($(this).data('index'));
	});

	// Handle click on edit column element
	$(document).off('click', "[name='define-edit-column-element']");
	$(document).on('click', "[name='define-edit-column-element']", function () {
		editColumn($(this).data('index'));
	});
};


/**
 * Input Handlers
 */

const createInputHandlers = () => {

	// Handle change define column filter
	$(document).off('change', "[id^='define-column-type-filter-id']");
	$(document).on('change', "[id^='define-column-type-filter-id']", function () {
		editColumnType(this);
	});
};


/**
 * State Handlers
 */

exports.handlerDidLoad = async () => {

	// Ensure origin exists
	if (window.origin_importFile != null) {

		// Create action handlers
		createActionHandlers();

		// Create input handlers
		createInputHandlers();

		// Get elements
		const holder = document.getElementById('define-table-holder');
		if (holder != null) {

			// Initialize content
			let htmlContent = '';

			// Create collection content
			let html = "<div class='card-content'>"
				+ "<div style='overflow-y:hidden; overflow-x:scroll;'>"
				+ "<table id='define-table' class='plain-table'><tr>";

			// Create column headers
			for (let j = 0; j < window.import_globalFileHeader.length; j += 1) {
				const column = window.import_globalFileHeader[j];
				html += setupHeader(column, j);
			}
			html += '</tr>';

			// Append html
			html += '</table>';
			htmlContent += (`${html}</div></div>`);

			// Set content
			holder.innerHTML = htmlContent;
		}
	} else {
		appModule.handleRouting('/dashboard/assets');
	}
};

exports.handlerDidAppear = async () => { };
