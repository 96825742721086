// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

const inputModule = require('../modules/input');
const externalUserModule = require('../modules/external-user');
const userModule = require('../modules/user');
const helperModule = require('../modules/helper');
const appModule = require('../modules/app');
const subscriptionHandler = require('./subscription-handler');
const { getCookie } = require('../modules/utilities');


/**
 * State
 */

let orgsGlobalConfiguration;
let orgsGlobalDisplayArray;
let orgsGlobalSearchTimeout;


/**
 * Global State
 */

window.orgs_globalTotalNumber = 0;
window.orgs_shouldReloadOrgs = false;


/**
 * Handlers
 */

const updateOrgDisplay = () => {

	// Get elements
	const holder = document.getElementById('orgs-collection');
	const titleNumber = document.getElementById('orgs-title-number');
	const removeButton = document.getElementById('orgs-remove-button');
	const emptyView = document.getElementById('orgs-empty-view');

	// Ensure element is loaded
	if (titleNumber != null) {

		// Set org count
		titleNumber.innerHTML = `(${inputModule.formatCountNumber(orgsGlobalDisplayArray.length)}/${inputModule.formatCountNumber(window.orgs_globalTotalNumber)})`;
	}

	// Hide remove button
	if (removeButton != null) {
		removeButton.style.display = 'none';
		removeButton.enabled = true;
	}

	// Ensure element is loaded
	if (holder != null) {

		// Iterate through organizations
		let html = '';
		for (let i = 0; i < orgsGlobalDisplayArray.length; i += 1) {
			const org = orgsGlobalDisplayArray[i];

			// Create org parameters
			let initial = 'S';
			if (org.name) initial = org.name.charAt(0).toUpperCase();
			const url = `/dashboard/organization/${org.id}`;

			// Create card content
			html += (`<div class='org-card' style='height:#cardHeight'
				data-url='${url}' name='orgs-card-element' id='${org.id}'>
				<div id='profile-image-icon' class='profile-image-icon'>
				<h3 name='default-initial' id='comp-default-initial'>${initial}</h3>
				</div>
				<div class='name-section'>
				<h2>${org.name}</h2>`);

			// Add card content from schema
			let emptyFields = 0;
			let cardHeight = 210;
			if (orgsGlobalConfiguration.display.org_schema.fields.length > 0) {
				for (let j = 0; j < orgsGlobalConfiguration.display.org_schema.fields.length; j += 1) {
					let field = orgsGlobalConfiguration.display.org_schema.fields[j];
					if (field.type === 'choice') {
						if (field.choices != null) {
							const choiceId = org.data[field.id];
							for (let k = 0; k < field.choices.length; k += 1) {
								if (field.choices[k].id === choiceId) {
									field = field.choices[k].value;
									break;
								}
							}
						}
					} else {
						field = org.data[field.id];
					}
					if (field != null && field !== '' && typeof field === 'string') {
						html += (`<h3>${field}</h3>`);
					} else {
						emptyFields += 1;
					}
					cardHeight += 20;
				}
			}

			// Set subtitle content
			if (org.parent != null && !(process.env.ENTITY === 'keelerqik' && org.parentLevel === 2)) {
				html += (`<h3>${org.parent}</h3>`);
			} else {
				html += (`<h3>${org.name}</h3>`);
			}

			// Set empty fields
			if (emptyFields > 0) {
				for (let j = 0; j < emptyFields; j += 1) {
					html += ('<h3></h3>');
				}
			}

			// Cap card content
			html += '</div>';

			// Set selector content
			html += (`<div class='card-selector' name='orgs-selector' id='orgs-selector-${org.id}'
				data-org-id='${org.id}'></div>
				</div>`);

			// Set card height
			html = html.replace('#cardHeight', `${cardHeight}px;`);
		}

		// Hide loading indicator
		const loadingIndicator = document.getElementById('orgs-activity-indicator');
		if (loadingIndicator != null) {
			loadingIndicator.remove();
		}

		// Append html
		holder.innerHTML = html;

		// Update empty display
		if (html === '') {
			emptyView.style.display = 'block';
		} else {
			emptyView.style.display = 'none';
		}
	}
};

const updateOrganizationsForUser = async (shouldReload, page) => {

	// Get organizations for user
	const orgs = await userModule.getOrganizationsForUser(shouldReload, page);

	// Get organizations count
	if (orgs.length > 0) window.orgs_globalTotalNumber = orgs[orgs.length - 1].organizationCount;

	// Set organization arrays
	orgsGlobalDisplayArray = [...window.orgsGlobalOrgArray];

	// Update org display
	$(document).ready(() => {
		updateOrgDisplay();
	});
};

const loadMoreOrganizations = () => {

	// Update organizations
	updateOrganizationsForUser(true, window.orgsGlobalPageNumber + 1);
};


const setupOrgFilter = () => {

	// Get filter elements
	const elements = document.getElementsByName('org-type-filter');
	for (let i = 0; i < elements.length; i += 1) {
		const element = elements[i];

		// Ensure element is loaded
		$(element).ready(() => {
			if (element.children.length <= 1) { /* Placeholder */ }
		});
	}
};

const filterOrgType = () => { };

const performGlobalOrgSearch = (searchValue) => {

	// Perform global search
	Parse.Cloud.run('performPlatformOrganizationSearchWithParameters', { searchText: searchValue }).then((orgs) => {
		if (orgsGlobalDisplayArray != null) {

			// Filter out existing orgs
			for (let i = 0; i < orgs.length; i += 1) {
				const org = orgs[i];
				let found = false;
				for (let j = 0; j < orgsGlobalDisplayArray.length; j += 1) {
					const testOrg = orgsGlobalDisplayArray[j];
					const { id } = org;
					const testId = testOrg.id;
					if (id != null && testId != null) {
						if (id === testId) found = true;
					}
				}
				if (found === false) {
					orgsGlobalDisplayArray.push(org);
				}
			}
		} else {
			orgsGlobalDisplayArray = [...orgs];
		}

		// Update org display
		updateOrgDisplay();
	});
};

const performOrgSearch = (element) => {

	// Cancel global search timer
	if (orgsGlobalSearchTimeout != null) clearTimeout(orgsGlobalSearchTimeout);

	// Get search value
	let searchValue = element.value;

	// If search value is empty, reset display array
	if (searchValue === '') {
		orgsGlobalDisplayArray = [...window.orgsGlobalOrgArray];
	} else {

		// Format search value
		searchValue = searchValue.toLowerCase().replace(/\W/g, '');

		// Update values matching search string
		orgsGlobalDisplayArray = [];
		for (let i = 0; i < window.orgsGlobalOrgArray.length; i += 1) {
			const { searchTag } = window.orgsGlobalOrgArray[i];
			if (searchTag.indexOf(searchValue) > -1) orgsGlobalDisplayArray.push(window.orgsGlobalOrgArray[i]);
		}

		// Create global search timer
		orgsGlobalSearchTimeout = setTimeout(() => {
			performGlobalOrgSearch(searchValue);
		}, 800);
	}

	// Update org display
	updateOrgDisplay();
};

const selectOrgCard = function (e, id) {
	e.stopPropagation();

	// Select or deselect card
	if (document.getElementById(`orgs-selector-${id}`).style.backgroundColor === '') {
		document.getElementById(`orgs-selector-${id}`).style.backgroundColor = 'var(--brand-primary)';
	} else {
		document.getElementById(`orgs-selector-${id}`).style.backgroundColor = '';
	}

	// Check selected counts
	let count = 0;
	const orgs = document.getElementsByName('orgs-selector');
	for (let i = 0; i < orgs.length; i += 1) {
		if (orgs[i].style.backgroundColor !== '') {
			count += 1;
		}
	}

	// Show or hide remove button
	if (count > 0) {
		document.getElementById('orgs-remove-button').style.display = 'block';
		helperModule.handleRemoveButtonPosition();
	} else {
		document.getElementById('orgs-remove-button').style.display = 'none';
	}
};

const removeOrganizations = () => {

	// Disable remove button
	document.getElementById('orgs-remove-button').enabled = false;

	// Show remove confirmation
	inputModule.showModalWithId('org-archive-confirmation');
};

const handleRemoveOrganization = (confirm) => {
	if (confirm === true) {

		// Hide remove confirmation
		inputModule.hideModalWithId('org-archive-confirmation');

		// Initialize remove array
		const removeArray = [];

		// Get selected orgs
		const orgs = document.getElementsByName('orgs-selector');
		if (orgs.length > 0) {
			for (let i = orgs.length - 1; i >= 0; i -= 1) {
				if (orgs[i].style.backgroundColor !== '') {

					// Get org id
					const orgId = orgs[i].id.replace('orgs-selector-', '');

					// Append to remove array
					removeArray.push(orgId);

					// Remove card from display
					orgs[i].parentElement.remove();
				}
			}

			// Hide remove button
			document.getElementById('orgs-remove-button').enabled = true;
			document.getElementById('orgs-remove-button').style.display = 'none';

			// Remove orgs from orgs array
			for (let i = window.orgsGlobalOrgArray.length - 1; i >= 0; i -= 1) {
				if (removeArray.indexOf(window.orgsGlobalOrgArray[i].id) > -1) {
					window.orgsGlobalOrgArray.splice(i, 1);
				}
			}

			// Remove orgs from display array
			for (let i = orgsGlobalDisplayArray.length - 1; i >= 0; i -= 1) {
				if (removeArray.indexOf(orgsGlobalDisplayArray[i].id) > -1) {
					orgsGlobalDisplayArray.splice(i, 1);
				}
			}

			// Set org array to session storage
			try {
				sessionStorage.setItem('user-platform-orgs', JSON.stringify(window.orgsGlobalOrgArray));
			} catch (e) { }

			// Archive orgs
			Parse.Cloud.run('archiveOrganizationsWithParameters', { organizationIDArray: removeArray, platform: 'web' }).then(() => {

				// Update subscription object
				if (document.getElementById('/dashboard/account')) {
					subscriptionHandler.updateSubscriptionDisplay();
				}

				// Subtract from total
				if (window.orgs_globalTotalNumber != null) window.orgs_globalTotalNumber -= removeArray.length;

				// Update org display
				updateOrgDisplay();

			}).catch((error) => {
				if (error && error.code === 209) {
					externalUserModule.handleUserLogOut(true);
				} else {

					// Show error
					inputModule.showModalWithId('org-archive-error-modal');
				}
			});
		} else {

			// Enable remove button
			document.getElementById('orgs-remove-button').enabled = true;
		}
	} else {

		// Hide remove confirmation
		inputModule.hideModalWithId('org-archive-confirmation');

		// Enable remove button
		document.getElementById('orgs-remove-button').enabled = true;
	}
};

const addOrganization = async (btn) => {
	const button = btn;

	// Update display
	button.disabled = true;

	// Check subscription
	const subscription = await externalUserModule.fetchSubscriptionForUser(false);

	// Update display
	button.disabled = false;

	// Validate subscription
	if (subscription != null) {

		// Check if transition user
		if (subscription.isTransition === true) {

			// Show transition modal
			inputModule.showModalWithId('org-subscription-transition-modal');
		} else {

			// Check available seats
			const availableSeats = subscription.totalSeats - subscription.usedSeats;
			if (availableSeats > 0) {

				// Move to new organization
				appModule.handleRouting('/dashboard/organization/new');
			} else { // An error occured
				inputModule.showModalWithId('org-subscription-upgrade-modal');
			}
		}
	} else { // An error occured
		inputModule.showModalWithId('org-subscription-upgrade-modal');
	}
};

const importOrgs = () => {
	window.origin_importFile = 'organizations';
	appModule.handleRouting('/dashboard/import');
};

const moveToEditSubscription = () => {

	// Hide modal
	inputModule.hideModalWithId('org-subscription-upgrade-modal');

	// Move to edit subscription
	appModule.handleRouting('/dashboard/account/subscription?toggle=subscription');
};


/**
 * Action Handlers
 */

const createActionHandlers = () => {

	// Handle click on add button
	$('#org-add-button').click(function () {
		addOrganization(this);
	});

	// Handle click on empty add button
	$('#orgs-empty-create-button').click(function () {
		addOrganization(this);
	});

	// Handle click on load more button
	$('#org-load-more').click(() => {
		helperModule.displayActionSheet('organizations-action-dropdown');
		loadMoreOrganizations();
	});

	// Handle click on import button
	$('#org-import-button').click(() => {
		helperModule.displayActionSheet('organizations-action-dropdown');
		importOrgs();
	});

	// Handle click on remove button
	$('#orgs-remove-button').click(() => {
		removeOrganizations();
	});

	// Handle click on archive confirmation button
	$('#org-archive-confirmation-cancel').click(() => {
		handleRemoveOrganization(false);
	});

	// Handle click on archive remove button
	$('#org-archive-confirmation-remove').click(() => {
		handleRemoveOrganization(true);
	});

	// Handle click on error modal confirm button
	$('#org-archive-error-modal-confirm').click(() => {
		inputModule.hideModalWithId('org-archive-error-modal');
	});

	// Handle click on upgrade modal cancel button
	$('#org-subscription-upgrade-modal-cancel').click(() => {
		inputModule.hideModalWithId('org-subscription-upgrade-modal');
	});

	// Handle click on upgrade modal upgrade button
	$('#org-subscription-upgrade-modal-upgrade').click(() => {
		moveToEditSubscription();
	});

	// Handle click on transition modal confirm button
	$('#org-subscription-transition-modal-confirm').click(() => {
		inputModule.hideModalWithId('org-subscription-transition-modal');
	});

	// Handle click on card element
	$(document).off('click', "[name='orgs-card-element']");
	$(document).on('click', "[name='orgs-card-element']", function () {
		appModule.handleRouting($(this).data('url'));
	});

	// Handle click on org selector
	$(document).off('click', "[id^='orgs-selector-']");
	$(document).on('click', "[id^='orgs-selector-']", function (event) {
		selectOrgCard(event, $(this).data('org-id'));
	});

	// Handle click on action menu
	$('#organizations-action-menu').click(() => {
		helperModule.displayActionSheet('organizations-action-dropdown');
	});
};


/**
 * Input Handlers
 */

const createInputHandlers = () => {

	// Handle filter change
	$("[name='org-type-filter']").change(function () {
		filterOrgType(this);
	});

	// Handle search change content
	$('#org-search-input-large').keyup(function () {
		performOrgSearch(this);
	});
};


/**
 * State Handlers
 */

exports.handlerDidLoad = async () => {

	// Create action handlers
	createActionHandlers();

	// Create input handlers
	createInputHandlers();

	// Check for visible activity indicator
	inputModule.checkElementVisible('orgs-activity-indicator', async () => {

		// Get user role
		const userRole = getCookie('userRole');

		// Validate role
		if (userRole === 'super' || userRole === 'admin') {

			// Fetch configuration for user
			const config = await userModule.handleConfigurationForUser(false);
			orgsGlobalConfiguration = JSON.parse(config);

			// Set up filter
			setupOrgFilter();

			// Initialize arrays
			const shouldReload = true;
			if (shouldReload === true) window.orgsGlobalOrgArray = [];
			orgsGlobalDisplayArray = [];

			// Get assets for user
			updateOrganizationsForUser(shouldReload, 0);
		} else { // Invalid role
			appModule.handleRouting('/dashboard/assets');
		}
	});
};

exports.handlerDidAppear = async () => {

	// Update organization display
	if (window.orgs_shouldReloadOrgs === true) {

		// Perform search and update
		performOrgSearch($('#org-search-input-large')[0]);

		// Update state
		window.orgs_shouldReloadOrgs = false;
	}
};
