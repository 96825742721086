// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

const inputModule = require('../modules/input');
const externalUserModule = require('../modules/external-user');
const userModule = require('../modules/user');
const appModule = require('../modules/app');
const subscriptionHandler = require('./subscription-handler');
const { getCookie } = require('../modules/utilities');


/**
 * State
 */

let orgGlobalOrgId;
let orgGlobalOrgData;
let orgGlobalConfiguration;
let availableTerritories = [];


/**
 * Handlers
 */

const populateOrgSchema = () => {

	// Get user role
	const userRole = getCookie('userRole');

	// Get elements
	const formHolder = document.getElementById('org-form-holder');

	// Ensure element is loaded
	$(formHolder).ready(() => {

		// Get name value
		let nameValue = '';
		if (orgGlobalOrgData.name != null) {
			nameValue = ` value= '${orgGlobalOrgData.name.replace(/"/g, '&#34;').replace(/'/g, '&#39;')}'`;
		}

		// Create organization name field
		let html = `<fieldset style='margin:0px auto 0px;'>
            <input id='orgName' name='orgName' type='text'${nameValue}
             style='margin:0px;' required><label for='orgName' id='orgNameLabel'>Organization&#39;s Name</label>
            </fieldset>`;

		// Get schema
		const inputArray = orgGlobalConfiguration.input.org_profile.fields;

		// Create schema for org
		for (let i = 0; i < inputArray.length; i += 1) {

			// Get configuration
			const inputConfig = inputArray[i];
			const prompt = `Organization&#39;s ${inputConfig.prompt}`;
			const inputType = inputConfig.type;
			const refArray = inputConfig.ref;
			const inputId = `${inputConfig.id}-org-input`;
			const { choices } = inputConfig;

			// Get current data
			let refData = orgGlobalOrgData.data;
			let displayData = '';
			for (let j = 0; j < refArray.length; j += 1) {
				const ref = refArray[j];
				const check = refData[ref];
				if (check != null) {
					if (Array.isArray(check)) {
						for (let k = 0; k < check.length; k += 1) {
							if (k !== 0) {
								displayData += ', ';
							}
							displayData += check[k];
						}
					} else if (typeof check === 'string') {
						displayData = check;
					} else if (typeof check === 'object') {
						refData = check;
					}
				}
			}
			displayData = displayData.replace(/"/g, '&#34;').replace(/'/g, '&#39;');

			// Create input for type
			if (inputType === 'text' || inputType === 'array' || inputType === 'number') { // Input

				// Get input type
				let inputDisplayType = 'text';
				if (inputType === 'number') inputDisplayType = 'number';

				// If numeric input, display increment / decrement buttons
				let buttonContent = '';
				if (inputType === 'number') {
					buttonContent = `<div class='incr-decr-holder'>
                    <button class='increment-button animate' name='org-increment-button' data-input-id='${inputId}'>
                    <i class="fas fa-plus"></i>
                    </button>
                    <button class='decrement-button animate' name='org-decrement-button' data-input-id='${inputId}'>
                    <i class="fas fa-minus"></i>
                    </button>
                    </div>`;
				}

				// Set html
				html += (`<fieldset style='margin:15px auto 0px;'>
                    <input id='${inputId}' name='${inputId}' type='${inputDisplayType}'
                    data-name-type='org-input-field-element' data-prompt='${prompt}' value='${displayData}'
                     style='margin:0px;' required><label for='${inputId}' id='${inputId}Label'>${prompt}</label>
                    ${buttonContent}</fieldset>`);
			} else if (inputType === 'choice' && choices != null && choices.length > 0) {

				// Set html
				html += (`<fieldset style='margin:15px auto 0px;'>
                    <select id='${inputId}' name='${inputId}' data-name-type='org-input-field-element' required>
                    <option value=''>Select an Option</option>`);

				// Create options
				for (let j = 0; j < choices.length; j += 1) {
					let selected = '';
					if (choices[j] != null && choices[j].id === displayData) {
						selected = " selected='selected' ";
					}
					html += (`<option ${selected}value='${choices[j].id}'>${choices[j].value}</option>`);
				}

				// Set html
				html += (`</select>
                    <label for='${inputId}' id='${inputId}Label' class='activated-label'>${prompt}</label>
                    </fieldset>`);
			}
		}

		// Add sales territory dropdown if admin role
		if (process.env.ENTITY === 'keelerqik' && userRole === 'admin') {

			// Add territory selection field
			html += (`<fieldset style='margin:15px auto 0px;'>
                <select id='organization-territory-input'>`);

			// Add default territory option
			const defaultSelected = orgGlobalOrgData.territoryId != null ? '' : " selected='selected' ";
			html += (`<option ${defaultSelected}value='none'>Select a Sales Territory</option>`);

			// Create options
			availableTerritories.forEach((territory) => {
				let selected = '';
				if (orgGlobalOrgData.territoryId != null && territory.id === orgGlobalOrgData.territoryId) {
					selected = " selected='selected' ";
				}
				html += (`<option ${selected}value='${territory.id}'>${territory.name}</option>`);
			});
			html += (`</select>
                <label for='organizationTerritory' id='organizationTerritoryLabel'>Organization's Sales Territory</label>
                </fieldset>`);
		}

		// Add html content
		formHolder.innerHTML = html;
	});
};

const handlePageOrgActionButton = async () => {

	// Get user role
	const userRole = getCookie('userRole');

	// Get general parameters
	const orgName = document.getElementById('orgName').value;

	// Get labels
	const orgNameLabel = document.getElementById('orgNameLabel');

	// Get validation results
	const nameResult = inputModule.validateText(orgName);

	// Handle validation
	if (nameResult[0] === false) {
		orgNameLabel.innerHTML = `Organization Name (${nameResult[1]})`;
		orgNameLabel.className = 'errorLabel';
		orgNameLabel.focus();
	} else {

		// Update display
		const buttonTitle = document.getElementById('org-action-button-text').innerHTML;
		document.getElementById('org-action-button').disabled = true;
		document.getElementById('org-action-button-text').innerHTML = '';
		document.getElementById('org-action-button-arrow').style.display = 'none';
		document.getElementById('org-button-activity-indicator').style.display = 'block';

		// Set general parameters
		orgGlobalOrgData.name = orgName;
		if (orgGlobalOrgData.data == null) orgGlobalOrgData.data = {};

		// Set territory id
		if (userRole === 'admin') {
			const territoryId = document.getElementById('organization-territory-input');
			if (territoryId != null) {
				if (territoryId.value !== '' && territoryId.value !== 'none') {
					orgGlobalOrgData.territoryId = territoryId.value;
				}
			}
		}

		// Get specific parameters
		const inputArray = orgGlobalConfiguration.input.org_profile.fields;
		for (let i = 0; i < inputArray.length; i += 1) {

			// Get configuration
			const inputConfig = inputArray[i];
			const inputType = inputConfig.type;
			const refArray = inputConfig.ref;
			const inputId = `${inputConfig.id}-org-input`;

			// Get current parameter
			let parameter;
			if (document.getElementById(inputId)) {
				parameter = document.getElementById(inputId).value;
			}

			// Validate parameter
			if (parameter != null) {
				if (inputType === 'array') {
					parameter = parameter.split(',');
					for (let j = 0; j < parameter.length; j += 1) {
						parameter[j] = parameter[j].trim();
					}
				}

				// Set parameter
				let schema = orgGlobalOrgData.data;
				for (let j = 0; j < refArray.length - 1; j += 1) {
					const ref = refArray[j];
					if (!schema[ref]) schema[ref] = {};
					schema = schema[ref];
				}
				schema[refArray[refArray.length - 1]] = parameter;
			}
		}

		// Save organization
		await Parse.Cloud.run('saveOrganizationsWithParameters', {
			organizationArray: [orgGlobalOrgData], platform: 'web'
		}).then(async (orgIds) => {

			// Get organization data
			const organizations = await userModule.getOrganizationsForUser(false);

			// Add standard parameters
			const now = new Date().toISOString();
			orgGlobalOrgData.createdAt = now;
			orgGlobalOrgData.updatedAt = now;

			// Set new organization data to storage
			let foundOrg = false;
			for (let i = 0; i < organizations.length; i += 1) {
				if (organizations[i].id === orgGlobalOrgData.id) {
					organizations[i] = orgGlobalOrgData;
					foundOrg = true;
					break;
				}
			}
			if (foundOrg === false && orgIds.length > 0) {

				// Update global count
				if (window.orgs_globalTotalNumber != null) window.orgs_globalTotalNumber += 1;

				// Set parameters and append
				orgGlobalOrgData.id = orgIds[0].id;
				orgGlobalOrgData.parent = orgIds[0].parent;
				orgGlobalOrgData.searchTag = orgIds[0].searchTag;
				orgGlobalOrgData.organizationCount = window.orgs_globalTotalNumber;
				organizations.unshift(orgGlobalOrgData);

				// Set global count
				organizations[organizations.length - 1].organizationCount = window.orgs_globalTotalNumber;
			}

			// Set organization array to storage
			window.orgsGlobalOrgArray = [...organizations];

			// Update state
			window.orgs_shouldReloadOrgs = true;

			// Update display
			document.getElementById('org-button-activity-indicator').style.display = 'none';
			document.getElementById('org-action-button-text').innerHTML = 'Successfully Saved Organization';

			// Update subscription object
			if (document.getElementById('/dashboard/account')) {
				subscriptionHandler.updateSubscriptionDisplay();
			}

			// Set delay to Organizations
			setTimeout(() => { appModule.handleRouting('/dashboard/organizations'); }, 1000);

		}).catch((error) => {
			if (error && error.code === 209) {
				externalUserModule.handleUserLogOut(true);
			} else {

				// Update display
				document.getElementById('org-action-button').disabled = false;
				document.getElementById('org-action-button-text').innerHTML = buttonTitle;
				document.getElementById('org-action-button-arrow').style.display = 'inline-block';
				document.getElementById('org-button-activity-indicator').style.display = 'none';

				// Display error
				inputModule.showModalWithId('org-save-error-modal');
			}
		});
	}
};


/**
 * Action Handlers
 */

const createActionHandlers = () => {

	// Handle click on  button
	$('#org-back-button').click(() => {
		appModule.handleRouting('/dashboard/organizations');
	});

	// Handle click on  button
	$('#org-action-button').click(() => {
		handlePageOrgActionButton();
	});

	// Handle click on  button
	$('#org-save-error-modal-confirm').click(() => {
		inputModule.hideModalWithId('org-save-error-modal');
	});

	// Handle click on increment button
	$(document).off('click', "button[name='org-increment-button']");
	$(document).on('click', "button[name='org-increment-button']", function () {
		inputModule.handleIncrementInput($(this).data('input-id'), false);
	});

	// Handle click on decrement button
	$(document).off('click', "button[name='org-decrement-button']");
	$(document).on('click', "button[name='org-decrement-button']", function () {
		inputModule.handleDecrementInput($(this).data('input-id'), false);
	});
};


/**
 * Input Handler
 */

const createInputHandlers = () => {

	// Handle blur actions
	$(document).off('blur', '#orgName');
	$(document).on('blur', '#orgName', () => {
		inputModule.removeAlert('orgNameLabel', "Organization's Name", true);
	});
	$(document).off('blur', '#organization-territory-input');
	$(document).on('blur', '#organization-territory-input', () => {
		inputModule.removeAlert('organizationTerritoryLabel', "Organization's Sales Territory", true);
	});
	$(document).off('blur', "[data-name-type='org-input-field-element']");
	$(document).on('blur', "[data-name-type='org-input-field-element']", function () {
		if (!$(this).is('select')) {
			inputModule.removeAlert(`${$(this).attr('id')}Label`, $(this).data('prompt'), true);
		} else {
			inputModule.removeBlockAlert();
		}
	});

	// Handle keydown actions
	$(document).off('keydown', '#orgName');
	$(document).on('keydown', '#orgName', () => {
		inputModule.removeAlert('orgNameLabel', "Organization's Name", true);
	});
	$(document).off('keydown', "[data-name-type='org-input-field-element']");
	$(document).on('keydown', "[data-name-type='org-input-field-element']", function () {
		inputModule.removeAlert(`${$(this).attr('id')}Label`, $(this).data('prompt'), true);
	});

	// Handle focus actions
	$(document).off('focus', '#orgName');
	$(document).on('focus', '#orgName', () => {
		inputModule.removeBlockAlert();
	});
	$(document).off('focus', '#organization-territory-input');
	$(document).on('focus', '#organization-territory-input', () => {
		inputModule.removeBlockAlert();
	});
	$(document).off('focus', "[data-name-type='org-input-field-element']");
	$(document).on('focus', "[data-name-type='org-input-field-element']", () => {
		inputModule.removeBlockAlert();
	});
};


/**
 * State Handlers
 */

exports.handlerDidLoad = async () => {

	// Get user role
	const userRole = getCookie('userRole');

	// Validate role
	if (userRole === 'super' || userRole === 'admin') {

		// Create action handlers
		createActionHandlers();

		// Create input handlers
		createInputHandlers();

		// Fetch territories
		if (process.env.ENTITY === 'keelerqik' && userRole === 'admin') {
			availableTerritories = await Parse.Cloud.run('fetchAvailableTerritoriesForUser', null);
		}

		// Fetch configuration for user
		const config = await userModule.handleConfigurationForUser(false);
		orgGlobalConfiguration = JSON.parse(config);

		// Get organization id
		orgGlobalOrgId = window.location.pathname.replace('/dashboard/organization/', '');
		if (orgGlobalOrgId !== 'new') { // Existing organization

			// Get organization data
			const organizations = await userModule.getOrganizationsForUser(false);
			for (let i = 0; i < organizations.length; i += 1) {
				if (organizations[i].id === orgGlobalOrgId) {
					orgGlobalOrgData = organizations[i];
					break;
				}
			}
			if (orgGlobalOrgData != null) {

				// Hide loading indicator
				const loadingIndicator = document.getElementById('org-activity-indicator');
				$(loadingIndicator).ready(() => {
					loadingIndicator.remove();
				});

				// Populate schema fields
				populateOrgSchema();
			} else { // Fetch organization with id
				Parse.Cloud.run('fetchPlatformOrganizationWithId', { fetchId: orgGlobalOrgId }).then((organization) => {
					if (organization != null) {

						// Set organization data
						orgGlobalOrgData = organization;

						// Hide loading indicator
						const loadingIndicator = document.getElementById('org-activity-indicator');
						$(loadingIndicator).ready(() => {
							loadingIndicator.remove();
						});

						// Populate schema fields
						populateOrgSchema();

					} else {
						appModule.handleRouting('/dashboard/organizations');
					}
				}).catch(() => {
					appModule.handleRouting('/dashboard/organizations');
				});
			}
		} else {
			orgGlobalOrgData = {
				data: {}
			};

			// Populate schema fields
			populateOrgSchema();
		}
	} else { // Invalid role
		appModule.handleRouting('/dashboard/assets');
	}
};

exports.handlerDidAppear = async () => { };
