// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

const Papa = require('papaparse');
const inputModule = require('../modules/input');
const userModule = require('../modules/user');
const appModule = require('../modules/app');


/**
 * State
 */

const importUsersGlobalRequiredColumns = [
	'firstname',
	'lastname',
	'email'
];
const importOrgsGlobalRequiredColumns = ['name'];
let importErrorMessage = '';
let importGlobalConfiguration;


/**
 * Global State
 */

window.importAssetFilter = '';
window.importAssetSchemaColumns = [];
window.importUsersGlobalSchemaColumns = [];
window.importOrgsGlobalSchemaColumns = [];


/**
 * Handlers
 */

const updateFileName = (element) => {
	if (element.files != null && element.files[0] != null) {
		document.getElementById('import-file-button-name').innerHTML = `Choose a file (${element.files[0].name})`;
	}
};

const validateFileType = (type) => {
	if (type === 'text/csv') {
		return true;
	}
	importErrorMessage = 'Only .CSV file types are allowed. Please try again.';
	return false;

};

const validateAssetType = () => {
	if (window.origin_importFile !== 'assets') return true;
	if (window.origin_importFile === 'assets' && window.importAssetFilter !== '') return true;

	importErrorMessage = 'Please select an Asset Type from the dropdown to continue.';
	return false;
};

const createUserColumnValues = () => {

	// Initialize user columns
	window.importUsersGlobalSchemaColumns = ['First Name',
		'Last Name',
		'Email'];

	// Add user data columns
	const additionalOptions = [];
	const { fields } = importGlobalConfiguration.input.user_profile;
	for (let i = 0; i < fields.length; i += 1) {
		additionalOptions.push(fields[i].prompt);
	}

	// Add additional standard columns
	additionalOptions.push(...['Role',
		'Organization']);
	window.importUsersGlobalSchemaColumns.push(...additionalOptions);

	// Return additional options
	return additionalOptions;
};

const createOrganizationColumnValues = () => {

	// Initialize organization columns
	window.importOrgsGlobalSchemaColumns = ['Name'];

	// Add organization data columns
	const additionalOptions = [];
	const { fields } = importGlobalConfiguration.input.org_profile;
	for (let i = 0; i < fields.length; i += 1) {
		window.importOrgsGlobalSchemaColumns.push(fields[i].prompt);
		additionalOptions.push(fields[i].prompt);
	}

	// Return additional options
	return additionalOptions;
};

const importAssetType = (element) => {

	// Set state
	window.importAssetFilter = element.options[element.selectedIndex].value;
	const assetTypeColumns = importGlobalConfiguration.scan.type_schemas[window.importAssetFilter].input;

	// Initialize asset columns
	window.importAssetSchemaColumns = [];

	// Add column schema
	for (let i = 0; i < assetTypeColumns.length; i += 1) {
		const columnId = assetTypeColumns[i].id;
		if (columnId !== 'createdAt' && columnId !== 'scannedAt' && columnId !== 'organization' && columnId !== 'user') window.importAssetSchemaColumns.push(assetTypeColumns[i].prompt);
	}

	// Create description content
	const html = `<p><b>Required CSV File Header(s):</b> The following fields are required for importing asset(s): Asset #</p>
		<p><b>CSV File Header Naming Convention:</b> The naming convention is Title Case e.g. <b>S</b>ervice <b>T</b>ag</p>
		<p><b>Optional Field(s):</b> ${window.importAssetSchemaColumns.join(', ')} can also be added to the CSV</p>`;

	// Set description content
	document.getElementById('import-description').innerHTML = html;
};

const convertCase = (header) => {
	const caseHeader = [];
	for (let i = 0; i < header.length; i += 1) {
		caseHeader.push(inputModule.toCamelCase(header[i]).toLowerCase());
	}
	return caseHeader;
};

const checkRequiredColumns = (userFileHead) => {
	let userFileHeader = userFileHead;
	if (window.origin_importFile === 'assets') {
		if (userFileHeader.includes('Asset #')) {
			return true;
		}
		return false;

	} if (window.origin_importFile === 'users') {
		userFileHeader = convertCase(userFileHeader);
		return importUsersGlobalRequiredColumns.every((e) => userFileHeader.includes(e));
	}
	userFileHeader = convertCase(userFileHeader);
	return importOrgsGlobalRequiredColumns.every((e) => userFileHeader.includes(e));

};

const handleImport = (confirm) => {

	// Get uploaded file
	const file = document.getElementById('fileToUpload').files[0];

	// Ensure file is valid
	if (confirm && file !== undefined) {
		if (validateFileType(file.type) && validateAssetType()) {

			// Parse local CSV file
			Papa.parse(file, {
				skipEmptyLines: true,
				header: true,
				complete(results) {

					// Get header fields
					const header = results.meta.fields;

					// Validate required columns
					if (!checkRequiredColumns(header)) {

						// Hide confirmation modal
						inputModule.hideModalWithId('import-confirmation');

						// Create error message
						importErrorMessage = 'The imported file is missing required column(s). Please refer to the required '
							+ 'columns and naming convention and try again.';

						// Show error modal
						const html = `<p>${importErrorMessage}</p>`;
						document.getElementById('import-error-message-holder').innerHTML = html;
						inputModule.showModalWithId('import-error-modal');
					} else {

						// Set state
						window.import_globalFile = file;
						window.import_globalFileHeader = header;
						window.import_globalFileData = inputModule.keysToCamel(results.data);

						// Add Asset # to asset schema
						window.importAssetSchemaColumns.unshift('Asset #');

						// Hide confirmation modal
						inputModule.hideModalWithId('import-confirmation');

						// Set delay
						setTimeout(() => { appModule.handleRouting('/dashboard/define'); }, 800);
					}
				},
				error(errors) {

					// Set error modal content
					const message = `We found an issue in your file. Here are the details: \nIssue Type: ${errors.type}\nIssue Code: ${errors.code}\n`
						+ `Message: ${errors.message}\nAt Row: ${errors.row}`;

					// Show error modal
					document.getElementById('import-error-message-holder').innerHTML = `<p>${message}</p>`;
					inputModule.showModalWithId('import-error-modal');
				}
			});
		} else {

			// Hide confirmation modal
			inputModule.hideModalWithId('import-confirmation');

			// Show error modal
			const html = `<p>${importErrorMessage}</p>`;
			document.getElementById('import-error-message-holder').innerHTML = html;
			inputModule.showModalWithId('import-error-modal');
		}
	} else if (!confirm) {

		// Update display
		document.getElementById('fileToUpload').value = '';
		document.getElementById('import-asset-type-filter').value = '';

		// Hide confirmation modal
		inputModule.hideModalWithId('import-confirmation');
	} else {

		// Hide confirmation modal
		inputModule.hideModalWithId('import-confirmation');

		// Create error content
		importErrorMessage = 'Please upload a .CSV file to continue.';

		// Show error modal
		const html = `<p>${importErrorMessage}</p>`;
		document.getElementById('import-error-message-holder').innerHTML = html;
		inputModule.showModalWithId('import-error-modal');
	}
};


/**
 * Action Handlers
 */

const createActionHandlers = () => {

	// Handle click on  button
	$('#import-back-button').click(() => {
		if (window.origin_importFile === 'assets') {
			appModule.handleRouting('/dashboard/assets');
		} else if (window.origin_importFile === 'users') {
			appModule.handleRouting('/dashboard/users');
		} else {
			appModule.handleRouting('/dashboard/organizations');
		}
	});

	// Handle click on  button
	$('#import-action-button').click(() => {
		inputModule.showModalWithId('import-confirmation');
	});

	// Handle click on  button
	$('#import-submit-button').click(() => {
		inputModule.showModalWithId('import-confirmation');
	});

	// Handle click on  button
	$('#import-confirmation-cancel').click(() => {
		handleImport(false);
	});

	// Handle click on  button
	$('#import-confirmation-confirm').click(() => {
		handleImport(true);
	});

	// Handle click on  button
	$('#import-error-modal-confirm').click(() => {
		inputModule.hideModalWithId('import-error-modal');
	});

	// Handle click on upload button
	$('#import-select-file-button').click(() => {
		$('#fileToUpload').click();
	});
};


/**
 * Input Handlers
 */

const createInputHandlers = () => {

	// Handle asset type change
	$("[name='import-asset-type-filter']").change(function () {
		importAssetType(this);
	});

	// Handle file changed
	$('#fileToUpload').change(function () {
		updateFileName(this);
	});
};


/**
 * State Handlers
 */

exports.handlerDidLoad = async () => {

	// Create action handlers
	createActionHandlers();

	// Create input handlers
	createInputHandlers();

	// Ensure origin exists
	if (window.origin_importFile != null) {

		// Fetch configuration for user
		const config = await userModule.handleConfigurationForUser(false);
		importGlobalConfiguration = JSON.parse(config);

		// Create user column values
		const additionalUserValues = createUserColumnValues();

		// Create organization column values
		const additionalOrgValues = createOrganizationColumnValues();

		// Create content for origin
		if (window.origin_importFile === 'assets') {

			// Create asset types
			let optionsHtml = '';
			Object.keys(importGlobalConfiguration.scan.type_schemas).forEach((property) => {
				if (Object.prototype.hasOwnProperty.call(importGlobalConfiguration.scan.type_schemas, property)) {

					// Get parameters
					const value = property.replace(/"/g, '&#34;').replace(/'/g, '&#39;');
					const { display } = importGlobalConfiguration.scan.type_schemas[property];

					// Create option
					optionsHtml += `<option value='${value}'>${display}</option>`;
				}
			});

			// Update element visibility
			document.getElementById('import-asset-type-filter').innerHTML += optionsHtml;
			document.getElementById('import-assets-type-filter').style.display = 'block';

			// Create import description
			const html = '<p><b>Required CSV File Header(s):</b> The following fields are required for importing asset(s): <b><i>[Please select an asset type]</i></b></p>'
				+ '<p><b>CSV File Header Naming Convention:</b> The naming convention is Title Case e.g. <b>S</b>ervice <b>T</b>ag</p>'
				+ '<p><b>Optional Field(s):</b> <b><i>[Please select an asset type]</i></b> </p>';

			// Set content
			document.getElementById('import-title-label').innerHTML = 'Import Assets';
			document.getElementById('import-back-button-label').innerHTML = 'Back To Assets';
			document.getElementById('import-section-divider').innerHTML = 'Import Assets';
			document.getElementById('import-description').innerHTML = html;
		} else if (window.origin_importFile === 'users') {

			// Create additional fields
			const additionalFields = inputModule.formatCommasAndSentence(additionalUserValues);

			// Create import description
			const html = `${'<p><b>Required CSV File Header(s):</b> The following fields are required for importing user(s): First Name, Last Name, Email</p>'
				+ '<p><b>CSV File Header Naming Convention:</b> The naming convention is Title Case e.g. <b>F</b>irst <b>N</b>ame</p>'
				+ '<p><b>Optional Field(s):</b> Additional fields like '}${additionalFields} can also be added to the CSV</p>`;

			// Set content
			document.getElementById('import-title-label').innerHTML = 'Import Users';
			document.getElementById('import-back-button-label').innerHTML = 'Back To Users';
			document.getElementById('import-section-divider').innerHTML = 'Import Users';
			document.getElementById('import-description').innerHTML = html;
		} else {

			// Create additional fields
			const additionalFields = inputModule.formatCommasAndSentence(additionalOrgValues);

			// Create import description
			const html = `${'<p><b>Required CSV File Header(s):</b> The following fields are required for importing organization(s): Name</p>'
				+ '<p><b>CSV File Header Naming Convention:</b> The naming convention is Title Case e.g. <b>N</b>ame</p>'
				+ '<p><b>Optional Field(s):</b> Additional fields like '}${additionalFields} can also be added to the CSV</p>`;

			// Set content
			document.getElementById('import-title-label').innerHTML = 'Import Organizations';
			document.getElementById('import-back-button-label').innerHTML = 'Back To Organizations';
			document.getElementById('import-section-divider').innerHTML = 'Import Organizations';
			document.getElementById('import-description').innerHTML = html;
		}
	} else {
		appModule.handleRouting('/dashboard/assets');
	}
};

exports.handlerDidAppear = async () => { };
