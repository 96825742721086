// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

// Modules
const moment = require('moment-timezone');

// Utilities
const inputModule = require('../modules/input');
const userModule = require('../modules/user');
const externalUserModule = require('../modules/external-user');

// Constants
const Constants = require('../../../Constants');


/**
 * Global Variables
 */

let requestsGlobalConfiguration = null;
let requestsObj = null;
let currentRequestId = null;
let currentRequestAction = null;


/**
 * Handlers
 */

const updateRequestsDisplay = () => {

	// Update view title
	const title = `Requests (${requestsObj.length})`;
	$('#requests-title-view').html(title);
	document.title = `${title} | ${process.env.ENTITY_OBJECT.NAME}`;

	// Hide loading indicator
	const loadingIndicator = document.getElementById('requests-activity-indicator');
	if (loadingIndicator != null) {
		$(loadingIndicator).ready(() => {
			loadingIndicator.remove();
		});
	}

	// Show container
	const container = document.getElementById('requests-container');
	container.style.display = 'inline-block';

	// Display empty view display
	if (requestsObj.length === 0) {
		$('#requests-empty-view').show();
	} else {
		$('#requests-empty-view').hide();
	}

	// Create request content
	let html = '';
	requestsObj.forEach((request) => {

		// Get parameters
		const {
			id, organization, user, role, shippingAddress, isNewOrganization, hasTerritories, userOnboardingComplete, createdAt
		} = request;

		// Create display role
		let displayRole = requestsGlobalConfiguration.general.role_names[role];

		// Modify role if necessary
		if (role === 'super' && hasTerritories === true && process.env.ENTITY === 'keelerqik') {
			displayRole = Constants.ENTITY_CONSTANTS[process.env.ENTITY].SALES_REP_ROLE_DISPLAY;
		}

		// Create display type
		const displayType = isNewOrganization === true ? 'Organization Request' : `User Request (${displayRole})`;

		// Get moment dates
		const dateMoment = moment(createdAt);

		// Create new guess element
		const element = `
		<div class='asset-holder'>
			<div class='type-holder'><p>${displayType}</p></div>
			<div class='asset-card' data-request-id='${id}' name='request-card' style='width:100%;'>
				<div class='asset-card-inner'>
					<div>
						<p>User Name<p>
						<h3>${user.firstName} ${user.lastName}</h3>
						<p>User Email<p>
						<h3>${user.email}</h3>
						<p>Organization Name</p>
						<h3>${organization}</h3>
						${shippingAddress != null ? `<p>Shipping Address</p><h3>${shippingAddress}</h3>` : ''}
						<p>Date Requested</p>
						<h3>${dateMoment.format('MMM D, YYYY')} at ${dateMoment.format('h:mm A')}</h3>
						<p>Onboarding & Payment Complete</p>
						<h3>${userOnboardingComplete ? 'Yes' : 'No'}</h3>
					</div>
				</div>
			</div>
			<div class="request-action-container" data-request-id="${id}">
				<button class="negative animate" name="requests-row-decline-action" data-request-id="${id}">Decline</button>
				${userOnboardingComplete
		? `<button class="positive animate" name="requests-row-accept-action" data-request-id="${id}">Approve</button>`
		: `<button class="positive animate" name="requests-row-contact-action" data-request-id="${id}">Contact</button>`}
			</div>
		</div>`;

		// Append new element
		html += element;
	});

	// Set request content
	$('#requests-data-container').html(html);
};

const handleRequestResponse = () => {

	// Update display
	$(`button[data-request-id='${currentRequestId}']`).each(function () {
		$(this).attr('disabled', true);
	});

	// Create parameters
	const parameters = {
		transitionId: currentRequestId,
		action: currentRequestAction,
		platform: 'web'
	};

	// Check out current cart
	Parse.Cloud.run('handleTransitionRequestResponse', parameters).then(async (success) => {

		// Parse response
		if (success === true) {

			// Remove request from array
			for (let i = 0; i < requestsObj.length; i += 1) {
				if (requestsObj[i].id === currentRequestId) {
					requestsObj.splice(i, 1);
					break;
				}
			}

			// Update request display
			updateRequestsDisplay();

		} else {

			// Create error alert
			document.getElementById('requests-error-modal-title').innerHTML = 'Whoops!';
			document.getElementById('requests-error-modal-description').innerHTML = 'We\'re Having Trouble Updating This Request. Please Try Again.';

			// Display error alert
			inputModule.showModalWithId('requests-error-modal');
		}

		// Update display
		$(`button[data-request-id='${currentRequestId}']`).each(function () {
			$(this).attr('disabled', false);
		});

	}).catch((error) => {
		if (error && error.code === 209) {
			externalUserModule.handleUserLogOut(true);
		} else {

			// Update display
			$(`button[data-request-id='${currentRequestId}']`).each(function () {
				$(this).attr('disabled', false);
			});

			// Create error alert
			document.getElementById('requests-error-modal-title').innerHTML = 'Whoops!';
			document.getElementById('requests-error-modal-description').innerHTML = 'We\'re Having Trouble Updating This Request. Please Try Again.';

			// Display error alert
			inputModule.showModalWithId('requests-error-modal');
		}
	});
};

const handleRequestResponsePrompt = (id, action) => {

	// Set state
	currentRequestId = id;
	currentRequestAction = action;

	// Get current request
	let requestItem = null;
	for (let i = 0; i < requestsObj.length; i += 1) {
		if (requestsObj[i].id === id) {
			requestItem = requestsObj[i];
			break;
		}
	}

	// Create content
	let modalTitle = '';
	let modalDescription = '';
	let modalAction = '';
	if (requestItem.isNewOrganization === true) {
		if (action === 'approve') {
			modalTitle = 'Approve Organization?';
			modalDescription = 'Are You Sure You Want To Approve This Organization To Join Your Network?';
			modalAction = 'Approve';
		} else {
			modalTitle = 'Decline Organization?';
			modalDescription = 'Are You Sure You Want To Decline This Organization?';
			modalAction = 'Decline';
		}
	} else if (action === 'approve') {
		modalTitle = 'Approve User?';
		modalDescription = 'Are You Sure You Want To Approve This User To Join Your Organization?';
		modalAction = 'Approve';
	} else {
		modalTitle = 'Decline User?';
		modalDescription = 'Are You Sure You Want To Decline This User?';
		modalAction = 'Decline';
	}

	// Create confirmation alert
	document.getElementById('request-action-confirmation-modal-title').innerHTML = modalTitle;
	document.getElementById('request-action-confirmation-modal-description').innerHTML = modalDescription;
	document.getElementById('request-action-confirmation-modal-confirm-text').innerHTML = modalAction;

	// Show confirmation alert
	inputModule.showModalWithId('request-action-confirmation-modal');
};

const handleRequestContact = (id) => {

	// Get current request
	let requestItem = null;
	for (let i = 0; i < requestsObj.length; i += 1) {
		if (requestsObj[i].id === id) {
			requestItem = requestsObj[i];
			break;
		}
	}

	// Open mail client to send email
	window.location.href = `mailto:${requestItem.user.email}?subject=Reaching Out About Keeler QIK`;
};


/**
  * Action Handlers
  */

const createActionHandlers = () => {

	/**
	 * General Actions
	 */

	// Handle click on accept request action
	$(document).off('click', "[name='requests-row-accept-action']");
	$(document).on('click', "[name='requests-row-accept-action']", function () {
		handleRequestResponsePrompt($(this).parent().data('request-id'), 'approve');
	});

	// Handle click on contact request action
	$(document).off('click', "[name='requests-row-contact-action']");
	$(document).on('click', "[name='requests-row-contact-action']", function () {
		handleRequestContact($(this).parent().data('request-id'));
	});

	// Handle click on decline request action
	$(document).off('click', "[name='requests-row-decline-action']");
	$(document).on('click', "[name='requests-row-decline-action']", function () {
		handleRequestResponsePrompt($(this).parent().data('request-id'), 'reject');
	});


	/**
	 * Action Confirmation Modal Action Handlers
	 */

	// Handle click on action confirmation confirm button
	$('#request-action-confirmation-modal-confirm').click(() => {

		// Respond to request
		handleRequestResponse();

		// Hide modal
		inputModule.hideModalWithId('request-action-confirmation-modal');
	});

	// Handle click on action confirmation cancel button
	$('#request-action-confirmation-modal-cancel').click(() => {
		inputModule.hideModalWithId('request-action-confirmation-modal');
	});


	/**
	 * Error Modal Action Handlers
	 */

	// Handle click on error modal confirm button
	$('#requests-error-modal-confirm').click(() => {
		inputModule.hideModalWithId('requests-error-modal');
	});
};


/**
  * State Handlers
  */

exports.handlerDidLoad = async () => {

	// Fetch configuration for user
	const config = await userModule.handleConfigurationForUser(false);
	requestsGlobalConfiguration = JSON.parse(config);

	// Create action handlers
	createActionHandlers();

	// Get requests
	requestsObj = await Parse.Cloud.run('fetchTransitionRequestsForUser', null);

	// Ensure elements loaded
	$(document).ready(async () => {

		// Update requests display
		updateRequestsDisplay();
	});
};

exports.handlerDidAppear = async () => { };
