// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

// Babel
require('core-js/stable');
require('regenerator-runtime/runtime');

// Modules
require('../../css/dashboard.css');
require('../plugins/sticky-table-headers/jquery.stickytableheaders');
const appModule = require('../modules/app');
const helperModule = require('../modules/helper');
const navigationModule = require('../modules/navigation');


/**
 * Init
 */

// Initialize navigation
navigationModule.initialize();

// Handle application setup
appModule.beginApplicationSetup();

// Handle scroll changes
$(document).scroll(() => {
	helperModule.handleRemoveButtonPosition();
});
