// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

// Utilities
const inputModule = require('../modules/input');
const externalUserModule = require('../modules/external-user');
const userModule = require('../modules/user');
const appModule = require('../modules/app');
const { getCookie } = require('../modules/utilities');

// Constants
const Constants = require('../../../Constants');


/**
 * State
 */

let userGlobalUserId;
let userGlobalUserData;
let userGlobalConfiguration;
let userGlobalOrganization;


/**
 * Handlers
 */

const populateUserSchema = () => {

	// Get user role
	const userRole = getCookie('userRole');

	// Get elements
	const formHolder = document.getElementById('user-form-holder');

	// Ensure element is loaded
	$(formHolder).ready(() => {

		// Get first name value
		let firstNameValue = '';
		let initial = 'S';
		if (userGlobalUserData.firstName != null) {
			initial = userGlobalUserData.firstName.charAt(0).toUpperCase();
			firstNameValue = ` value='${userGlobalUserData.firstName.replace(/"/g, '&#34;').replace(/'/g, '&#39;')}'`;
		}

		// Get last name value
		let lastNameValue = '';
		if (userGlobalUserData.lastName != null) {
			lastNameValue = ` value='${userGlobalUserData.lastName.replace(/"/g, '&#34;').replace(/'/g, '&#39;')}'`;
		}

		// Get email value
		let emailValue = '';
		if (userGlobalUserData.email != null) {
			emailValue = ` value='${userGlobalUserData.email.replace(/"/g, '&#34;').replace(/'/g, '&#39;')}'`;
		}

		// Create profile initial
		let html = `<div name='profile-image-icon' id='profile-image-icon' class='profile-image-icon'>
            <h3 name='default-initial' id='default-initial'>${initial}</h3>
            </div>`;

		// Create first name field
		html += (`<fieldset style='margin:25px auto 0px;'>
            <input id='firstName' name='firstName' type='text'${firstNameValue}
             style='margin:0px;' required><label for='firstName' id='firstNameLabel'>User&#39;s First Name</label>
            </fieldset>`);

		// Create last name field
		html += (`<fieldset style='margin:15px auto 0px;'>
            <input id='lastName' name='lastName' type='text'${lastNameValue}
             style='margin:0px;' required><label for='lastName' id='lastNameLabel'>User&#39;s Last Name</label>
            </fieldset>`);

		// Create email field
		html += (`<fieldset style='margin:15px auto 0px;'>
            <input id='email' name='email' type='text'${emailValue}
             style='margin:0px;' required><label for='email' id='emailLabel'>User&#39;s Email</label>
            </fieldset>`);

		// Get schema
		const inputArray = userGlobalConfiguration.input.user_profile.fields;

		// Create schema for user
		for (let i = 0; i < inputArray.length; i += 1) {

			// Get configuration
			const inputConfig = inputArray[i];
			const prompt = `User&#39;s ${inputConfig.prompt}`;
			const inputType = inputConfig.type;
			const refArray = inputConfig.ref;
			const inputId = `${inputConfig.id}-user-input`;
			const { choices } = inputConfig;

			// Get current data
			let refData = userGlobalUserData.data;
			let displayData = '';
			for (let j = 0; j < refArray.length; j += 1) {
				const ref = refArray[j];
				const check = refData[ref];
				if (check != null) {
					if (Array.isArray(check)) {
						for (let k = 0; k < check.length; k += 1) {
							if (k !== 0) {
								displayData += ', ';
							}
							displayData += check[k];
						}
					} else if (typeof check === 'string') {
						displayData = check;
					} else if (typeof check === 'object') {
						refData = check;
					}
				}
			}

			// Create input for type
			if (inputType === 'text' || inputType === 'array' || inputType === 'number') { // Input

				// Get input type
				let inputDisplayType = 'text';
				if (inputType === 'number') inputDisplayType = 'number';

				// If numeric input, display increment / decrement buttons
				let buttonContent = '';
				if (inputType === 'number') {
					buttonContent = `<div class='incr-decr-holder'>
                    <button class='increment-button animate' name='user-increment-button' data-input-id='${inputId}'>
                    <i class="fas fa-plus"></i>
                    </button>
                    <button class='decrement-button animate' name='user-decrement-button' data-input-id='${inputId}'>
                    <i class="fas fa-minus"></i>
                    </button>
                    </div>`;
				}

				// Set html
				html += (`<fieldset style='margin:15px auto 0px;'>
                    <input id='${inputId}' name='${inputId}' type='${inputDisplayType}'
                    data-name-type='user-input-field-element' data-prompt='${prompt}' value='${displayData.replace(/"/g, '&#34;').replace(/'/g, '&#39;')}'
                     style='margin:0px;' required><label for='${inputId}' id='${inputId}Label'>${prompt}</label>
                    ${buttonContent}</fieldset>`);
			} else if (inputType === 'choice' && choices != null && choices.length > 0) {

				// Set html
				html += (`<fieldset style='margin:15px auto 0px;'>
                    <select id='${inputId}' name='${inputId}' data-name-type='user-input-field-element' required>
                    <option value=''>Select an Option</option>`);

				// Create options
				for (let j = 0; j < choices.length; j += 1) {
					let selected = '';
					if (choices[j] != null && choices[j].id === displayData) {
						selected = " selected='selected' ";
					}
					html += (`<option ${selected}value='${choices[j].id}'>${choices[j].value}</option>`);
				}

				// Set html
				html += (`</select>
                    <label for='${inputId}' id='${inputId}Label' class='activated-label'>${prompt}</label>
                    </fieldset>`);
			}
		}

		// Check if user role can be changed
		if ((userGlobalUserData.roles != null
            && ((userRole === 'leader' && userGlobalUserData.roles[0] === 'standard')
                || (userRole === 'super' && (userGlobalUserData.roles[0] === 'standard' || userGlobalUserData.roles[0] === 'leader'))
                || (userRole === 'admin' && userGlobalUserData.roles[0] !== 'admin')))
            || userGlobalUserData.roles == null) {

			// Add role selection field
			html += (`<fieldset style='margin:15px auto 0px;'>
                <select id='user-role-user-input'>`);

			// Get available roles
			let availableRoles = [];
			if (userRole === 'leader') {
				availableRoles = [
					'standard',
					'leader'
				];
			} else if (userRole === 'super') {
				availableRoles = [
					'standard',
					'leader',
					'super'
				];
			} else if (userRole === 'admin') {
				availableRoles = [
					'standard',
					'leader',
					'super',
					process.env.ENTITY === 'keelerqik' && Constants.ENTITY_CONSTANTS[process.env.ENTITY].SALES_REP_ROLE_VALUE,
					'admin'
				].filter(Boolean);
			}

			// Create options
			for (let i = 0; i < availableRoles.length; i += 1) {
				let selected = '';

				// Build role display
				let display = userGlobalConfiguration.general.role_names[availableRoles[i]];
				if (availableRoles[i] === Constants.ENTITY_CONSTANTS[process.env.ENTITY].SALES_REP_ROLE_VALUE) {
					display = Constants.ENTITY_CONSTANTS[process.env.ENTITY].SALES_REP_ROLE_DISPLAY;
				}

				// Get role value
				const value = availableRoles[i].replace(/"/g, '&#34;').replace(/'/g, '&#39;');

				// Build configuration
				if (userGlobalUserData.roles != null && availableRoles[i] === userGlobalUserData.roles[0]) {
					selected = " selected='selected' ";
				} else if (userGlobalUserData.roles != null && availableRoles[i] === Constants.ENTITY_CONSTANTS[process.env.ENTITY].SALES_REP_ROLE_VALUE && userGlobalUserData.roles[0] === 'super' && userGlobalUserData.hasTerritories === true) {
					selected = " selected='selected' ";
				}
				if (value === 'admin' && userGlobalConfiguration.features.show_admin_role !== true && selected === '') {
					continue; // eslint-disable-line no-continue
				}
				html += (`<option ${selected}value='${value}'>${display}</option>`);
			}
			html += (`</select>
                <label for='userRole' id='userRoleLabel'>User's Role</label>
                </fieldset>`);
		}

		// Add organization dropdown if super or admin role
		if (userRole === 'super' || userRole === 'admin') {

			// Add org selection field
			html += (`<fieldset style='margin:15px auto 0px;'>
                <select id='user-org-user-input'>`);

			// Create options
			for (let i = 0; i < window.userGlobalOrganizationArray.length; i += 1) {
				let selected = '';
				if (userGlobalUserData.organizationId != null) {
					if (window.userGlobalOrganizationArray[i].id === userGlobalUserData.organizationId) {
						selected = " selected='selected' ";
					}
				} else if (window.userGlobalOrganizationArray[i].id === userGlobalOrganization.id) {
					selected = " selected='selected' ";
				}
				html += (`<option ${selected}value='${window.userGlobalOrganizationArray[i].id}'>
                    ${window.userGlobalOrganizationArray[i].name}</option>`);
			}
			html += (`</select>
                <label for='userOrg' id='userOrgLabel'>User's Organization</label>
                </fieldset>`);
		}

		// Add html content
		formHolder.innerHTML = html;
	});
};

const updateUserInitial = () => {

	// Get elements
	const firstName = document.getElementById('firstName').value;
	const defaultInitial = document.getElementById('default-initial');

	// Set new initial
	if (firstName !== '') {
		defaultInitial.innerHTML = firstName.charAt(0).toUpperCase();
	} else {
		defaultInitial.innerHTML = 'S';
	}
};

const handlePageUserActionButton = async () => {

	// Get user role
	const userRole = getCookie('userRole');

	// Get general parameters
	const firstName = document.getElementById('firstName').value;
	const lastName = document.getElementById('lastName').value;
	const email = document.getElementById('email').value;

	// Get labels
	const firstNameLabel = document.getElementById('firstNameLabel');
	const lastNameLabel = document.getElementById('lastNameLabel');
	const emailLabel = document.getElementById('emailLabel');

	// Get validation results
	const firstNameResult = inputModule.validateText(firstName);
	const lastNameResult = inputModule.validateText(lastName);

	// Handle validation
	if (firstNameResult[0] === false) {
		firstNameLabel.innerHTML = `User's First Name (${firstNameResult[1]})`;
		firstNameLabel.className = 'errorLabel';
		firstNameLabel.focus();
	} else if (lastNameResult[0] === false) {
		lastNameLabel.innerHTML = `User's Last Name (${lastNameResult[1]})`;
		lastNameLabel.className = 'errorLabel';
		lastNameLabel.focus();
	} else {
		const emailResult = await inputModule.validateEmail(email, true);
		if (emailResult[0] === false && emailResult[1] === 'Required') {
			emailLabel.innerHTML = `User's Email (${emailResult[1]})`;
			emailLabel.className = 'errorLabel';
			emailLabel.focus();
		} else if (emailResult[0] === false && (userGlobalUserData.email == null || (userGlobalUserData.email !== email))) {
			[, emailLabel.innerHTML] = emailResult;
			emailLabel.className = 'errorLabel';
			emailLabel.focus();
		} else {

			// Update display
			const buttonTitle = document.getElementById('user-action-button-text').innerHTML;
			document.getElementById('user-action-button').disabled = true;
			document.getElementById('user-action-button-text').innerHTML = '';
			document.getElementById('user-action-button-arrow').style.display = 'none';
			document.getElementById('user-button-activity-indicator').style.display = 'block';

			// Set general parameters
			userGlobalUserData.firstName = firstName;
			userGlobalUserData.lastName = lastName;
			userGlobalUserData.email = email;

			// Get user role
			let role;
			let hasTerritories = false;
			if (document.getElementById('user-role-user-input') != null) {
				const roleOptions = document.getElementById('user-role-user-input').value.split('-');
				[role] = roleOptions;
				if (roleOptions.length > 1 && roleOptions[1] === 'sales') hasTerritories = true;
			} else if (userGlobalUserData.roles != null && userGlobalUserData.roles.length > 0) {
				[role] = userGlobalUserData.roles;
			}

			// Get user organization
			let organizationId;
			if (userRole === 'leader') {
				organizationId = userGlobalOrganization.id;
			} else if (userRole === 'super' || userRole === 'admin') {
				organizationId = document.getElementById('user-org-user-input').value;
			}

			// Validate user role and organization
			if (role != null && organizationId != null) {

				// Set role and organization
				userGlobalUserData.role = role;
				userGlobalUserData.roles = [role];
				userGlobalUserData.organizationId = organizationId;
				userGlobalUserData.hasTerritories = hasTerritories;

				// Get specific parameters
				const inputArray = userGlobalConfiguration.input.user_profile.fields;
				for (let i = 0; i < inputArray.length; i += 1) {

					// Get configuration
					const inputConfig = inputArray[i];
					const inputType = inputConfig.type;
					const refArray = inputConfig.ref;
					const inputId = `${inputConfig.id}-user-input`;

					// Get current parameter
					let parameter;
					if (document.getElementById(inputId)) {
						parameter = document.getElementById(inputId).value;
					}

					// Validate parameter
					if (parameter != null) {
						if (inputType === 'array') {
							parameter = parameter.split(',');
							for (let j = 0; j < parameter.length; j += 1) {
								parameter[j] = parameter[j].trim();
							}
						}

						// Set parameter
						let schema = userGlobalUserData.data;
						for (let j = 0; j < refArray.length - 1; j += 1) {
							const ref = refArray[j];
							if (!schema[ref]) schema[ref] = {};
							schema = schema[ref];
						}
						schema[refArray[refArray.length - 1]] = parameter;
					}
				}

				// Save user
				Parse.Cloud.run('saveUsersWithParameters', {
					userArray: [userGlobalUserData], platform: 'web'
				}).then(async (entityArray) => {

					// Get user data
					const users = await userModule.getPlatformUsersForUser(false);

					// Add standard parameters
					const now = new Date().toISOString();
					userGlobalUserData.createdAt = now;
					userGlobalUserData.updatedAt = now;

					// Set new user data to storage
					let foundUser = false;
					for (let i = 0; i < users.length; i += 1) {
						if (users[i].id === userGlobalUserData.id) {
							users[i] = userGlobalUserData;
							foundUser = true;
							break;
						}
					}
					if (foundUser === false && entityArray.length > 0) {

						// Update global count
						if (window.users_globalTotalNumber != null) window.users_globalTotalNumber += 1;

						// Set parameters and append
						userGlobalUserData.id = entityArray[0].id;
						userGlobalUserData.organization = entityArray[0].organization;
						userGlobalUserData.parent = entityArray[0].parent;
						userGlobalUserData.searchTag = entityArray[0].searchTag;
						userGlobalUserData.userCount = window.users_globalTotalNumber;
						users.unshift(userGlobalUserData);

						// Set global count
						users[users.length - 1].userCount = window.users_globalTotalNumber;
					}

					// Set user array to storage
					window.usersGlobalUserArray = [...users];

					// Update state
					window.users_shouldReloadUsers = true;

					// Update display
					document.getElementById('user-button-activity-indicator').style.display = 'none';
					document.getElementById('user-action-button-text').innerHTML = 'Successfully Saved User';

					// Set delay to Users
					setTimeout(() => { appModule.handleRouting('/dashboard/users'); }, 1000);

				}).catch((error) => {
					if (error && error.code === 209) {
						externalUserModule.handleUserLogOut(true);
					} else {

						// Update display
						document.getElementById('user-action-button').disabled = false;
						document.getElementById('user-action-button-text').innerHTML = buttonTitle;
						document.getElementById('user-action-button-arrow').style.display = 'inline-block';
						document.getElementById('user-button-activity-indicator').style.display = 'none';

						// Display error
						inputModule.showModalWithId('user-save-error-modal');
					}
				});
			} else {

				// Update display
				document.getElementById('user-action-button').disabled = false;
				document.getElementById('user-action-button-text').innerHTML = buttonTitle;
				document.getElementById('user-action-button-arrow').style.display = 'inline-block';
				document.getElementById('user-button-activity-indicator').style.display = 'none';
			}
		}
	}
};


/**
 * Action Handlers
 */

const createActionHandlers = () => {

	// Handle click on back button button
	$('#user-back-button').click(() => {
		appModule.handleRouting('/dashboard/users');
	});

	// Handle click on action button
	$('#user-action-button').click(() => {
		handlePageUserActionButton();
	});

	// Handle click on error modal confirm button
	$('#user-save-error-modal-confirm').click(() => {
		inputModule.hideModalWithId('user-save-error-modal');
	});

	// Handle click on increment button
	$(document).off('click', "button[name='user-increment-button']");
	$(document).on('click', "button[name='user-increment-button']", function () {
		inputModule.handleIncrementInput($(this).data('input-id'), false);
	});

	// Handle click on decrement button
	$(document).off('click', "button[name='user-decrement-button']");
	$(document).on('click', "button[name='user-decrement-button']", function () {
		inputModule.handleDecrementInput($(this).data('input-id'), false);
	});
};


/**
 * Input Handlers
 */

const createInputHandlers = () => {

	// Handle blur actions
	$(document).off('blur', "[data-name-type='user-input-field-element']");
	$(document).on('blur', "[data-name-type='user-input-field-element']", function () {
		if (!$(this).is('select')) {
			inputModule.removeAlert(`${$(this).attr('id')}Label`, $(this).data('prompt'), true);
		} else {
			inputModule.removeBlockAlert();
		}
	});
	$(document).off('blur', '#firstName');
	$(document).on('blur', '#firstName', () => {
		inputModule.removeAlert('firstNameLabel', "User's First Name", true);
	});
	$(document).off('blur', '#lastName');
	$(document).on('blur', '#lastName', () => {
		inputModule.removeAlert('lastNameLabel', "User's Last Name", true);
	});
	$(document).off('blur', '#email');
	$(document).on('blur', '#email', () => {
		inputModule.removeAlert('emailLabel', "User's Email", true);
	});

	// Handle keyup actions
	$(document).off('keyup', '#firstName');
	$(document).on('keyup', '#firstName', () => {
		updateUserInitial();
	});

	// Handle keydown actions
	$(document).off('keydown', "[data-name-type='user-input-field-element']");
	$(document).on('keydown', "[data-name-type='user-input-field-element']", function () {
		inputModule.removeAlert(`${$(this).attr('id')}Label`, $(this).data('prompt'), true);
	});
	$(document).off('keydown', '#firstName');
	$(document).on('keydown', '#firstName', () => {
		inputModule.removeAlert('firstNameLabel', "User's First Name", true);
	});
	$(document).off('keydown', '#lastName');
	$(document).on('keydown', '#lastName', () => {
		inputModule.removeAlert('lastNameLabel', "User's Last Name", true);
	});
	$(document).off('keydown', '#email');
	$(document).on('keydown', '#email', () => {
		inputModule.removeAlert('emailLabel', "User's Email", true);
	});

	// Handle focus actions
	$(document).off('focus', "[data-name-type='user-input-field-element']");
	$(document).on('focus', "[data-name-type='user-input-field-element']", () => {
		inputModule.removeBlockAlert();
	});
	$(document).off('focus', '#firstName');
	$(document).on('focus', '#firstName', () => {
		inputModule.removeBlockAlert();
	});
	$(document).off('focus', '#lastName');
	$(document).on('focus', '#lastName', () => {
		inputModule.removeBlockAlert();
	});
	$(document).off('focus', '#email');
	$(document).on('focus', '#email', () => {
		inputModule.removeBlockAlert();
	});
};


/**
 * State Handlers
 */

exports.handlerDidLoad = async () => {

	// Get user role
	const userRole = getCookie('userRole');

	// Validate role
	if (userRole === 'leader' || userRole === 'super' || userRole === 'admin') {

		// Create action handlers
		createActionHandlers();

		// Create input handlers
		createInputHandlers();

		// Fetch configuration for user
		const config = await userModule.handleConfigurationForUser(false);
		userGlobalConfiguration = JSON.parse(config);

		// Fetch organizations for user
		const { organization } = await externalUserModule.fetchConfigurationForUser();
		userGlobalOrganization = organization;

		// Fetch organizations for user
		const organizations = await userModule.fetchPlatformOrganizationsForUser(true);
		window.userGlobalOrganizationArray = [...organizations];

		// Get user id
		userGlobalUserId = window.location.pathname.replace('/dashboard/user/', '');
		if (userGlobalUserId !== 'new') { // Existing user

			// Get user data
			const users = await userModule.getPlatformUsersForUser(false);
			for (let i = 0; i < users.length; i += 1) {
				if (users[i].id === userGlobalUserId) {
					userGlobalUserData = users[i];
					break;
				}
			}
			if (userGlobalUserData != null) {

				// Hide loading indicator
				const loadingIndicator = document.getElementById('user-activity-indicator');
				$(loadingIndicator).ready(() => {
					loadingIndicator.remove();
				});

				// Populate schema fields
				populateUserSchema();
			} else {
				Parse.Cloud.run('fetchPlatformUserWithId', { fetchId: userGlobalUserId }).then((user) => {
					if (user != null) {

						// Set user data
						userGlobalUserData = user;

						// Hide loading indicator
						const loadingIndicator = document.getElementById('user-activity-indicator');
						$(loadingIndicator).ready(() => {
							loadingIndicator.remove();
						});

						// Populate schema fields
						populateUserSchema();

					} else {
						appModule.handleRouting('/dashboard/users');
					}
				}).catch(() => {
					appModule.handleRouting('/dashboard/users');
				});
			}
		} else {
			userGlobalUserData = {
				data: {}
			};

			// Hide loading indicator
			const loadingIndicator = document.getElementById('user-activity-indicator');
			$(loadingIndicator).ready(() => {
				loadingIndicator.remove();
			});

			// Populate schema fields
			populateUserSchema();
		}
	} else { // Invalid role
		appModule.handleRouting('/dashboard/assets');
	}
};

exports.handlerDidAppear = async () => { };
