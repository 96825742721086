/* eslint-disable no-param-reassign */
// COPYRIGHT SMARTTRACK

/**
 * Configuration Handlers
 */

exports.filterDefaultConfigurationItems = (configuration) => {

	// Check if object or json
	const isObj = typeof configuration.get === 'function';

	// Get current payloads
	const searchPayload = isObj ? configuration.get('search') : configuration.search;
	const scanPayload = isObj ? configuration.get('scan') : configuration.scan;
	const displayPayload = isObj ? configuration.get('display') : configuration.display;

	// Remove necessary search entries
	const searchKeys = Object.keys(searchPayload.type_schemas);
	searchKeys.forEach((key) => {
		if (searchPayload.type_schemas[key].hide_if_empty === true) {
			delete searchPayload.type_schemas[key];
		}
	});

	// Remove necessary search entries
	const scanKeys = Object.keys(scanPayload.type_schemas);
	scanKeys.forEach((key) => {
		if (scanPayload.type_schemas[key].hide_if_empty === true) {
			delete scanPayload.type_schemas[key];
		}
	});

	// Remove necessary search entries
	const displayKeys = Object.keys(displayPayload.type_schemas);
	displayKeys.forEach((key) => {
		if (displayPayload.type_schemas[key].hide_if_empty === true) {
			delete displayPayload.type_schemas[key];
		}
	});

	// Set payloads
	if (isObj) {
		configuration.set('search', searchPayload);
		configuration.set('scan', scanPayload);
		configuration.set('display', displayPayload);
	} else {
		configuration.search = searchPayload;
		configuration.scan = scanPayload;
		configuration.display = displayPayload;
	}

	// Return configuration
	return configuration;
};
